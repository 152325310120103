import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as $ from 'jquery';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { IDayCalendarConfig } from 'ng2-date-picker';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-skill-set',
  templateUrl: './admin-skill-set.component.html',
  styleUrls: ['./admin-skill-set.component.scss'],
})
export class AdminSkillSetComponent implements OnInit {
  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */
  moduleArray: any;
  candiSkillSetList: any = [];
  details: any;
  category_name: any;
  area_name: any;
  jobDomain: any = [];
  filterArray: any = [];
  search_data: any;
  user_id: any;
  excelfileName: any;
  showDivPdf: boolean = false;

  datePickerConfig = <IDayCalendarConfig>{
    drops: 'down',
    format: 'MM/DD/YYYY',
  };
  user_type: any;

  get apiUrl(): string {
    return environment.apiUrl;
  }
  
  constructor(
    public http: AdminService,
    public route: ActivatedRoute,
    public router: Router,
    public fb: UntypedFormBuilder
  ) {
    this.user_id = sessionStorage.getItem('user_id');
    this.excelfileName =
      'skill_set_report(' + moment(new Date()).format('MM-DD-YYYY') + ')';
    this.user_type = sessionStorage.getItem('user_type');
  }

  ngOnInit() {
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 800);
    this.getskillsetCandidate();
  }

  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }
  ///////////////////

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  ////////////////////

  getskillsetCandidate() {
    this.http.getcandiSkillSet().subscribe(
      (res: any) => {
        //  // //console.log(res)

        if (res.length !== 0) {
          this.candiSkillSetList = res;
          this.filterArray = res;
        } else {
          this.errorMsg('No search result found.');
        }
      },
      (err) => {
        this.errorMsg('Something went wrong. Please Try Again.');
      }
    );
  }

  get searchData() {
    return this.search_data;
  }

  set searchData(value) {
    this.search_data = value;
    this.candiSkillSetList = this.search_data
      ? this.filterList(this.search_data)
      : this.filterArray;
  }

  filterList(filterby) {
    filterby = filterby.toLocaleLowerCase();
    return this.filterArray.filter(
      (list: any) =>
        list.candidate_email.toLocaleLowerCase().indexOf(filterby) !== -1 ||
        list.candidate_name.toLocaleLowerCase().indexOf(filterby) !== -1 ||
        list.skill_area_name.toLocaleLowerCase().indexOf(filterby) !== -1
    );
  }

  viewSkillModal(val) {
    // //// //console.log(val)
    this.details = val;
    let data = {
      candidate_id: val.candidate_id,
      skill_area_id: val.skill_area_id,
    };
    this.http.getSkillsetByCandi(data).subscribe(
      (res: any) => {
        // //// //console.log(res)
        let result: any = res;
        if (result.length > 0) {
          this.category_name = result[0].skill_category_name;
          this.area_name = result[0]['area'][0].skill_area_name;
          //// //console.log(this.area_name)
          this.jobDomain = result[0]['area'][0]['domain'];
          //// //console.log(this.jobDomain)
        } else {
          this.errorMsg('Skill checklist not added yet.');
        }
      },
      (err) => {
        this.errorMsg('Something went wrong. Please Try Again.');
      }
    );
  }

  delete_date: any;

  setThresholds(getValue)
  {
    // //console.log(this.delete_date = moment(getValue).format('MM/DD/YYYY'))           
  }

  deleteSkillset() {
    this.http.spinnerShow();
    let data = {
      skillset_delete_date: moment(this.delete_date).format('MM/DD/YYYY'),
    };
    this.http.deleteMultipleSkillset(data).subscribe(
      (res: any) => {
        this.http.spinnerHide();
        if (res === 'success') {
          this.getskillsetCandidate();
          this.successMsg('Skillsets deleted successfully.');
        } else if (res === 'no skillset found') {
          this.errorMsg('No skillset found.');
        }
      },
      (err) => {
        this.http.spinnerHide();
        this.errorMsg('Something went wrong. Please Try Again.');
      }
    );
  }

  downloadPDF() {
    this.showDivPdf = true;
    setTimeout(() => {
      var HTML_Width = $('.canvas_div_pdf').width();
      var HTML_Height = $('.canvas_div_pdf').height();
      var top_left_margin = 10;
      var PDF_Width = HTML_Width + top_left_margin * 2;
      var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;
      var heightLeft = canvas_image_height;
      var pageHeight = 210;

      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas($('.canvas_div_pdf')[0], { allowTaint: true }).then(function (
        canvas
      ) {
        canvas.getContext('2d');
        var imgData = canvas.toDataURL('image/jpeg', 1.0);
        var pdf: any = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
        pdf.page = 1; // use this as a counter.
        var position = 0;

        pdf.addImage(
          imgData,
          'JPG',
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );
        heightLeft -= pageHeight;

        for (var i = 1; i <= totalPDFPages; i++) {
          pdf.addPage();
          //footer();
          pdf.addImage(
            imgData,
            'JPG',
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          );
        }

        pdf.save('Skill_Checklist.pdf');
      });
    }, 100);
    setTimeout(() => {
      this.showDivPdf = false;
    }, 100);
  }

  errorMsg(msg) {
    Swal.fire({
      title: msg,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  successMsg(msg) {
    Swal.fire({
      title: msg,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }
}
