<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase m-b30">
          Payroll Invoice And Reconciliation
        </h5>
      </div>

      <div class="job-bx-title clearfix">
        <div class="form-group" style="display: flex">
          <div>
            <label style="font-size: 14px">Client</label>
            <select class="form-control" [(ngModel)]="client_id1" #myClient1
              (change)="onOptionsSelected(myClient1.value)">
              <option value="ALL" selected>All</option>
              <option *ngFor="let data of clientList1 | sortBy:'client_name'" [value]="data.client_id"
                style="text-transform: capitalize">
                {{ data.client_name }}
              </option>
            </select>
          </div>


          <div style="margin-left: 12px">
            <label style="font-size: 14px">Year</label>
            <select [style.min-width]="'75px'" class="form-control" [(ngModel)]="year1" #myyear2
              (change)="onYearSelected2(myyear2.value)">
              <option value="ALL" selected>All</option>
              <option *ngFor="let data of yearList2" [value]="data" style="text-transform: capitalize">
                {{ data }}
              </option>
            </select>
          </div>

          <div style="margin-left: 12px">
            <label style="font-size: 14px">Month</label>
            <select [style.min-width]="'75px'" class="form-control" [(ngModel)]="month1" #myMonth2
              (change)="onMonthSelected2(myMonth2.value)">
              <option value="ALL" selected>All</option>
              <option *ngFor="let data of monthList2" [value]="data.month_no"
                style="text-transform: capitalize">
                {{ data.month_name }}
              </option>
            </select>
          </div>

          <div style="margin-left: 12px">
            <label style="font-size: 14px">Week</label>
            <select [style.min-width]="'75px'" class="form-control" [(ngModel)]="week1">
              <option value="ALL" selected>All</option>
              <option *ngFor="let data of weekList2 | sortBy:'start_date'"
                [value]="data.start_date + '-' + data.end_date" style="text-transform: capitalize">
                {{ data.start_date }} - {{ data.end_date }}
              </option>
            </select>
          </div>

          <div style="margin-left: 12px">
            <label style="font-size: 14px">Status</label>
            <select class="form-control" [(ngModel)]="status">
              <option value="ALL" selected>All</option>
              <option value="pending">Pending</option>
              <option value="paid">Paid</option>
            </select>
          </div>

          <div>
            <div style="display: inline-block; margin-bottom: 0.5rem"></div>
            <div type="button" class="site-button2" (click)="searchList()">
              View
            </div>
          </div>
        </div>
      </div>

      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th class="feature"></th>
            <th>Payroll File no.</th>
            <th>Client</th>
            <th>Year</th>
            <th>Month</th>
            <th>Week</th>
            <th>Status</th>
            <th style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
              let a of payrollDataListMain
                | paginate
                  : { itemsPerPage: count, currentPage: page }
            ">
            <td class="feature"></td>
            <td class="application text-primary">
              <a>{{ a.file_no }}</a>
            </td>
            <td class="application text-primary">
              <a>{{ a.client_name }}</a>
            </td>
            <td class="application text-primary">{{ a.year && a.year != null ? a.year : (a.end_date |
              date:'YYY') }}</td>
            <td class="application text-primary">
              <a>{{ a.month && a.month != null ? a.month : (a.end_date | date:'MMM') }}</a>
            </td>
            <td class="application text-primary">
              <a>{{ a.start_date }} - {{ a.end_date }}</a>
            </td>
            <td class="expired pending" style="text-transform: capitalize" *ngIf="a.paid_status !== 'paid'">
              <a>Pending</a>
            </td>
            <td class="expired success" style="text-transform: capitalize" *ngIf="a.paid_status === 'paid'">
              <a>Paid</a>
            </td>

            <td class="job-links">
              <div class="dropdown">
                <a href="javascript:void(0);">
                  <i class="fa fa-cog"></i>
                </a>
                <div class="dropdown-content">
                  <a href="avascript:void(0);" (click)="editSubmit(a)" data-toggle="modal"
                    data-target="#processPayrollSecond">
                    Edit
                  </a>
                  <a href="javascript:void(0);" (click)="paidUpdate(a)">
                    Paid
                  </a>
                  <a 
                    href="{{apiUrl}}vcsapi/get/api/payrollInvoiceReconciliation/download/excelfile/{{ a.acc_file_id }}/{{ excelfileName }}" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    download
                  >
                    Download
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <pagination-controls *ngIf="payrollDataListMain.length" class="product-pagination" autoHide="false"
          maxSize="8" (pageChange)="onPageChanged($event)"></pagination-controls>
      </div>

      <!-- Edit and Approve Modal -->
      <div class="modal fade modal-bx-info editor" id="processPayrollSecond" tabindex="-1" role="dialog"
        aria-labelledby="PayrollInvoiceModal" aria-hidden="true" *ngIf="showModalBox">
        <div class="modal-dialog" role="document" style="max-width: 70% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="PayrollInvoiceModalTitle">
                Edit
              </h5>
              <button type="button" class="close" data-dismiss="modal" #processSecondClose
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="payrollDataList.length !== 0">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Client :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">{{ payrollDataList[0].client_name }} </span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Start Date :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">{{
                      payrollDataList[0].acc_start_date }}</span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">End Date :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">{{
                      payrollDataList[0].acc_end_date }}</span>
                  </div>
                </div>
                <div class="max-h-[400px] overflow-auto">
                  <table class="table-job-bx cv-manager company-manage-job d-block overflow-auto">
                    <thead>
                      <tr>
                        <th class="feature"></th>
                        <th>Employee Name</th>
                        <th>Total Hours Worked</th>
                        <th>Total Earnings</th>
                        <th>Invoice Amount</th>
                        <th>Invoice After MSP fee</th>
                        <th>Received Amount</th>
                        <th>NBO</th>
                        <th>VCS profit</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let a of payrollDataList;
                          let i = index
                        ">
                        <td class="feature"></td>

                        <td class="application text-primary" *ngIf="a.payroll_status === 'inprocess'"
                          style="color: red !important">
                          {{a.emp_name}}
                        </td>
                        <td class="application text-primary" *ngIf="a.payroll_status === 'submitted'"
                          style="color: blue !important">
                          {{a.emp_name}}
                        </td>
                        <td class="application text-primary" *ngIf="a.payroll_status === 'approved'">
                          {{a.emp_name}}
                        </td>
                        <td class="application text-primary">
                          {{ a?.total_hr_worked }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.total_pay }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.invoice_income }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.invoice_after_msp_fee }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.received_amount }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.nbo_amount }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.profit }}
                        </td>
                        <td 
                          class="application expired pending"
                          *ngIf="a.paid_status === 'pending'"
                        >
                          {{ a?.paid_status }}
                        </td>
                        <td 
                          class="application expired success"
                          *ngIf="a.paid_status === 'paid'"
                        >
                          {{ a?.paid_status }}
                        </td>

                        <td class="application text-primary">
                          <div class="appCount" style="
                              background-color: #563a10;
                              width: 72px;
                              margin-left: -10px;
                            " (click)="openCalculation(a)" data-toggle="modal"
                            data-target="#calculatePayrole">
                            Calculate
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-lg-12 col-md-12" style="margin-top: 10px">
                  
                  <div class="form-group">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Total Earnings :&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{payrollDataTotalList.total_earnings}}
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Invoice Amount ($):&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{payrollDataTotalList.invoice_amount}}
                      </span>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Invoice After MSP fees ($):&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{payrollDataTotalList.invoice_after_msp_fee}}
                      </span>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Received Amount ($):&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{payrollDataTotalList.received_amount}}
                      </span>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">NBO ($):&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{payrollDataTotalList.nbo_amount}}
                      </span>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">VCS Profit ($):&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{payrollDataTotalList.vcs_profit}}
                      </span>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="site-button" (click)="approvePayroll()" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="approvePayroll()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Calculate Payrole Modal -->
      <div class="modal fade modal-bx-info editor" id="calculatePayrole" tabindex="-1" role="dialog"
        aria-labelledby="CalculateInvoicePayroll" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 80% !important;">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="CalculateInvoicePayrollTitle">
                Calculate Weekly Pay
              </h5>
              <button type="button" class="close" data-dismiss="modal" #calculatePayroleClose
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details && data">
              <form>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Employee Name :&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">{{ details.emp_name }}</span>
                    </div>
                    <div class="clearfix m-b20 " style="display: flex">
                      <label class="m-b0">Client :&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">{{ details.client_name }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Start Date:&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">{{ details.start_date }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">End Date:&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">{{ details.end_date }}</span>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="row">

                  
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="clearfix m-b20" style="display: flex">
                        <label class="m-b0">Total hours Worked :&nbsp;&nbsp;</label>
                        <span class="clearfix font-13" style="margin-top: 3px">
                          $ {{total_hr_worked}}
                        </span>
                      </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="clearfix m-b20" style="display: flex">
                        <label class="m-b0">Total Earnings ($):&nbsp;&nbsp;</label>
                        <span class="clearfix font-13" style="margin-top: 3px">
                          $ {{total_pay}}
                        </span>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="clearfix m-b20" style="display: flex">
                        <label class="m-b0">Invoice Amount :&nbsp;&nbsp;</label>
                        <span class="clearfix font-13" style="margin-top: 3px">
                          $ {{ data.invoice_income }}
                        </span>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="clearfix m-b20" style="display: flex">
                        <label class="m-b0">Invoice after MSP fees :&nbsp;&nbsp;</label>
                        <span class="clearfix font-13" style="margin-top: 3px">
                          $ {{ data.invoice_after_msp_fee }}
                        </span>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="clearfix m-b20" style="display: flex">
                        <label class="m-b0">VCS Profit :&nbsp;&nbsp;</label>
                        <span class="clearfix font-13" style="margin-top: 3px">
                          $ {{ profit }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="divider"></div>

                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>Amount Received<span style="color: red">*</span></label>
                      <input type="number" class="form-control" placeholder="Enter Amount Received"
                        [(ngModel)]="received_amount" [ngModelOptions]="{ standalone: true }"
                        (input)="calculateFieldAutomation($event)" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>NBO Amount
                        <span style="color: red">*</span>
                      </label>
                      <input type="number" class="form-control" placeholder="Enter NBO Amount"
                        [(ngModel)]="nbo_amount" [ngModelOptions]="{ standalone: true }"
                        (input)="calculateFieldAutomation($event)" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>Pending Amount<span style="color: red">*</span></label>
                      <input type="hidden" class="form-control" placeholder="Enter Pending Amount"
                        [(ngModel)]="pending_amount" [ngModelOptions]="{ standalone: true }" />
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{pending_amount}}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>Line/Check No.
                        <!-- <span style="color: red">*</span> -->
                      </label>
                      <input type="text" class="form-control" placeholder="Enter Line/Check No" [(ngModel)]="check_no"
                        [ngModelOptions]="{ standalone: true }" (input)="calculateFieldAutomation($event)" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>Date Received
                        <!-- <span style="color: red">*</span> -->
                      </label>
                      <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY"
                        [(ngModel)]="received_date" [ngModelOptions]="{ standalone: true }" [config]="datePickerConfig">
                      </dp-date-picker>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>Status
                        <span style="color: red">*</span>
                      </label>
                      <select class="form-control" [(ngModel)]="paid_status" [ngModelOptions]="{ standalone: true }">
                        <option value="paid">Paid</option>
                        <option value="pending">Pending</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-lg-12 col-md-12" style="margin-top: 10px">
                    <div class="form-group">
                      <label>Comments</label>
                      <textarea type="text" class="form-control" rows="2" cols="50"
                        placeholder="Enter Comment" [(ngModel)]="comment"
                        [ngModelOptions]="{ standalone: true }"></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="submitPayroll()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->
    </div>
  </div>
</app-admin-default-layout>