import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ia-faq',
  templateUrl: './ia-faq.component.html',
})
export class IaFaqComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
