import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AdminService } from "src/app/services/admin.service";
import Swal from "sweetalert2";
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { IDayCalendarConfig } from "ng2-date-picker";
import * as moment from "moment";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-hired-applicant",
  templateUrl: "./hired-applicant.component.html",
  styleUrls: ["./hired-applicant.component.scss"],
})
export class HiredApplicantComponent implements OnInit {
  @ViewChild("changeStatusclose", { static: false })
  private changeStatusclose: ElementRef;
  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */
  
  get apiUrl(): string {
    return environment.apiUrl;
  }

  moduleArray: any = [];
  applicationList: any = [];

  client_id: any = "ALL";
  job_id: any = "ALL";
  status: any = "ALL";

  clientList: any = [];
  details: any;
  user_id: any;
  fac_specc_doc: any = [];
  others_doc: any = [];
  standard_doc: any = [];
  jobList: any = [];

  showDivPdf: boolean = false;
  assignment_status: any = "";
  effective_date: any;
  effective_end_date: any;
  datePickerConfig = <IDayCalendarConfig>{
    drops: "up",
    format: "MM/DD/YYYY",
  };
  user_id_by: any;
  excelfileName: any;
  clientListFilter: any = [];
  clientListShow: boolean = false;
  clientName: any = "ALL";
  jobListFilter: any = "";
  user_type: any;

  constructor(
    public http: AdminService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.user_type = sessionStorage.getItem('user_type');
    this.user_id_by = sessionStorage.getItem("user_id");
    this.excelfileName =
      "hired_applicant_report(" + moment(new Date()).format("MM-DD-YYYY") + ")";
  }

  ngOnInit() {
    this.getClients();
    if (
      this.client_id === "ALL" &&
      this.job_id === "ALL" &&
      this.status === "ALL"
    ) {
      this.searchJob();
    }
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
  }
  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }
  ///////////////////////

  getClients() {
    this.http.getClientHired().subscribe((res: any) => {
  
      this.clientList = res;
      this.clientListFilter = res;
    });
  }

  selectclientName(val) {

    this.client_id = val.client_id;
    this.clientName = val.client_name;
    this.clientListShow = false;
    if (val !== "ALL") {
      let data = {
        client_id: val.client_id,
      };
      this.http.getJobByClient(data).subscribe((res: any) => {
    
        this.jobList = res;
        this.jobListFilter = res;
      });
    }
  }

  focusClientList() {
    this.clientListShow = true;
  }

  searchClient(ev) {

    let search_data = this.clientName;
    this.clientList = search_data
      ? this.filterListClient(search_data)
      : this.clientListFilter;
  }

  filterListClient(filterby) {
    filterby = filterby.toLocaleLowerCase();
    return this.clientListFilter.filter(
      (list: any) =>
        list.client_name.toLocaleLowerCase().indexOf(filterby) !== -1
    );
  }

  searchJob() {
    this.http.spinnerShow();
    this.clientListShow = false;
    this.applicationList = [];

    if (this.clientName.toLowerCase() === "all") {
      this.client_id = "ALL";
    }
    let data = {
      client_id: this.client_id,
      job_id: this.job_id,
      status: this.status,
    };
    this.http.searchHiredAppl(data).subscribe(
      (res: any) => {
    
        if (res.length !== 0) {
          this.applicationList = res;
          this.http.spinnerHide();
        } else {
          this.http.spinnerHide();
          this.errorMsg("No search result found!");
        }
      },
      (err) => {
        this.http.spinnerHide();
        this.errorMsg("Something went wrong,please try again.");
      }
    );
  }

  openFinish(val) {

    this.details = "";
    this.details = val;
    this.user_id = val.user_id;
    this.assignment_status = val.assignment_status;
    this.effective_date = moment(val.proposed_start_date).format("MM/DD/YYYY");
    this.effective_end_date = moment(val.proposed_end_date).format(
      "MM/DD/YYYY"
    );
  }
  hired_id_list: any = [];
  selectApplicantToDelete(value) {
    let index = this.hired_id_list.indexOf(value);
    if (index > -1) {
      this.hired_id_list.splice(index, 1);
    } else {
      this.hired_id_list.push(value);
    }

  }

  deleteSelectedApplicants() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          hired_ids: this.hired_id_list,
        };
        this.http.deleteMultipleHired(data).subscribe(
          (res: any) => {
        
            if (res === 'success') {
              this.successMsg('Hired deteted successfully.');
              this.hired_id_list = [];
            } else {
              this.errorMsg('Something went wrong,please try again!');
            }
          },
          (err) => {
        
            this.errorMsg('Something went wrong,please try again!');
          }
        );
      }
    });
  }
  openAssignment(val) {

    this.details = "";
    this.details = val;
    this.user_id = val.user_id;
    this.getAllDocs(this.user_id);
  }

  getAllDocs(user_id) {
    this.fac_specc_doc = [];
    this.others_doc = [];
    this.standard_doc = [];
    this.http.getAllDocs(user_id).subscribe((res: any) => {
  
      for (let a of res) {
        if (a.rec_doc_status === "current") {
          if (a.rec_doc_type === "standard") {
            this.standard_doc.push(a);
          } else if (a.rec_doc_type === "facility_spec") {
            this.fac_specc_doc.push(a);
          } else if (a.rec_doc_type === "other") {
            this.others_doc.push(a);
          }
        }
      }
    });

  }

  downloadApplForm() {
    this.showDivPdf = true;
    setTimeout(() => {
      let data = document.getElementById("assignFormFDiv");
  
      html2canvas(data).then((canvas) => {
        var imgWidth = 22;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL("image/png");
        //let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
        let pdf = new jspdf("p", "cm", "a4"); //Generates PDF in portrait mode
        var position = 0;
        pdf.setFont("helvetica");
        pdf.setFontSize(20);
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        pdf.save("AssignmentDetails.pdf");
      });
    }, 100);

    setTimeout(() => {
      this.showDivPdf = false;
    }, 100);
  }

  changeStatus() {
    let data = {
      assignment_id: this.details.assignment_id,
      assignment_status: this.assignment_status,
      effective_date: moment(this.effective_date).format("MM/DD/YYYY"),
      closing_date: moment(this.effective_end_date).format("MM/DD/YYYY"),
    };
    this.http.changeAssignmentStatus(data).subscribe(
      (res: any) => {
    
        if (res === "success") {
          this.successMsg("Status changed successfully.");
          this.changeStatusclose.nativeElement.click();
        } else {
          this.errorMsg("Something went wrong,please try again.");
        }
      },
      (err) => {
        this.errorMsg("Something went wrong,please try again.");
      }
    );
  }

  ////////////////////////////

  errorMsg(msg) {
    Swal.fire({
      title: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  successMsg(msg) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        //window.location.reload();
        this.searchJob();
      }
    });
  }

  successMsg2(msg) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }
}
