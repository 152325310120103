import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swall from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientAlertServiceService {
  header = new HttpHeaders().set('Authorization', `Bearear ${sessionStorage.getItem('user_token')}`);

  get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(public http: HttpClient) {}

  //get All Document
  getApiClientExpiredDocument() {
    return this.http.get(`${this.apiUrl}vcsapi/clientexpire-alert/`, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  //MArk document
  markApiClient(data){
    return this.http.post(`${this.apiUrl}vcsapi/status-alert`, data, { headers: this.header })
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  handleError(error: any) {
    let Swal: any = Swall;
    if (error.status == 0) {
      Swal.fire({
        text: 'No Internet Connection',
        type: 'error'
      }).then(() => {
        location.reload();
      })
    }
    else if (error.status == 400) {
      Swal.fire({
        text: 'Invalid Syntex',
        type: 'error'
      })
    }
    else if (error.status == 403) {
      Swal.fire({
        text: 'Unauthorized Access',
        type: 'error'
      })
    }
    else if (error.status == 404) {
      Swal.fire({
        text: 'URL Is Not Recognized',
        type: 'error'
      })
    }
    else if (error.status == 500) {
      Swal.fire({
        text: 'Internal Server Error',
        type: 'error'
      })
    }
    else if (error.status == 501) {
      Swal.fire({
        text: 'Not Implemented',
        type: 'error'
      })
    }
    else if (error.status == 503) {
      Swal.fire({
        text: 'Service Unavailable',
        type: 'error'
      })
    }
    else if (error.status == 511) {
      Swal.fire({
        text: 'Network Authentication Required',
        type: 'error'
      })
    }
    return throwError(error.statusText);
  }

}
