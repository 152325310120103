<app-page-header
  heading="Information Technology Services"
  subheading="Technology to solve real life business problems"
></app-page-header>
<div class="relative overflow-hidden bg-white pt-16 pb-32">
  <div class="relative">
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div
        class="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0"
      >
        <div>
          <div class="mt-2">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">
              IT Staffing & Recruitment
            </h2>
            <ul class="mt-6 leading-8">
              <li>System Architecture</li>
              <li>Project Management</li>
              <li>Software Development</li>
              <li>System Testing &amp; QA</li>
              <li>Business Analysis</li>
              <li>System Support Engineer</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div
          class="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0"
        >
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src="../../assets/images/main/IT-Jobs.webp"
            alt="Inbox user interface"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mt-24">
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div
        class="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0"
      >
        <div>
          <div class="mt-6">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">
              Our Expertise
            </h2>
            <ul class="mt-6 leading-8">
              <li>Cloud-based Systems</li>
              <li>Artifical Intelligence</li>
              <li>Machine Learning</li>
              <li>Big Data Technology</li>
              <li>Cyber Security</li>
              <li>System Development</li>
              <li>Automation Systems</li>
              <li>LoT</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
        <div
          class="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0"
        >
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
            src="../../assets/images/main/itservice2.webp"
            alt="Customer profile user interface"
            loading="lazy"
            width="1060"
            height="596"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mt-24">
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div
        class="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0"
      >
        <div>
          <div class="mt-2">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">
              Industry Experience
            </h2>
            <ul class="mt-6 leading-8">
              <li>Healthcare</li>
              <li>Pharmaceutical/Life Science</li>
              <li>Infrastructure</li>
              <li>Retail</li>
              <li>Banking/Finance</li>
              <li>Aviation</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div
          class="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0"
        >
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src="../../assets/images/main/itservice3.webp"
            alt="Inbox user interface"
            loading="lazy"
            width="582"
            height="388"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<app-cta></app-cta>
