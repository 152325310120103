<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Applicants
        </h5>
        <div class="float-right" style="display: flex">
          <div class="form-group" style="margin-right: 10px">
            <input type="text" class="form-control2" [(ngModel)]="searchData" placeholder="Search" />
          </div>
          <a type="button" class="site-button m-b30" style="margin-right: 10px; color: white" data-toggle="modal" data-target="#applSearch" *ngIf="user_type === 'admin'">Appl. Search</a>
          <a type="button" *ngIf="user_type === 'admin'" class="site-button m-b30" href="{{apiUrl}}/vcsapi/api/generate/excel/applicants/aplicant/{{ user_id_by }}/{{ excelfileName }}" target="_blank" rel="noopener noreferrer" download>Report</a>
        </div>
      </div>

      <div class="job-bx-title clearfix">
        <div class="form-group" style="display: flex">
          <div>
            <label style="font-size: 14px">Applicant Status</label>
            <select class="form-control" [(ngModel)]="recruitment_status">
              <option value="all">All</option>
              <option value="applicant" selected>Applicant</option>
              <option value="hired">Hired</option>
            </select>
          </div>

          <div style="margin-left: 12px">
            <label style="font-size: 14px">Position Type</label>
            <select class="form-control" [(ngModel)]="position_type">
              <option value="ALL" selected>All</option>
              <option [value]="a.position_type_id" *ngFor="let a of positiontype | sortBy:'position_type_name'">
                {{ a.position_type_name }}
              </option>
            </select>
          </div>

          <div style="margin-left: 12px">
            <label style="font-size: 14px">Profession</label>
            <select class="form-control" [(ngModel)]="profession_type">
              <option value="ALL" selected>All</option>
              <option [value]="a.profession_id" *ngFor="let a of profession | sortBy:'profession_name'">
                {{ a.profession_name }}
              </option>
            </select>
          </div>

          <div style="margin-left: 12px">
            <label style="font-size: 14px">Prefered Location</label>
            <select class="form-control" [(ngModel)]="prefered_location">
              <option value="ALL" selected>All</option>
              <option *ngFor="let a of prefLocation | sortBy:'name'" [value]="a.name"
                style="text-transform: capitalize">
                {{ a.name }} ({{ a.isoCode }})
              </option>
            </select>
          </div>

          <div>
            <div style="display: inline-block; margin-bottom: 0.5rem"></div>
            <div type="button" class="site-button2" (click)="searchAppl()">
              Search
            </div>
          </div>
        </div>
      </div>

      <span style="float: right; margin-top: -18px; margin-bottom: 10px" *ngIf="user_type === 'admin'">
        <a 
          href="javascript:void(0);" 
          data-toggle="modal" 
          data-target="#addApplicant" 
          class="site-button m-b30 mr-2" 
          style="color: white; background: red; margin-bottom: 0px" 
        >
          Add Applicant
        </a>

        <a 
          type="button" 
          class="site-button m-b30" 
          style="color: white; background: red; margin-bottom: 0px"
          (click)="deleteSelectedApplicants()" 
          [ngStyle]="{
            cursor:
              applicant_id_list.length === 0
                ? 'not-allowed'
                : 'pointer',
            opacity: applicant_id_list.length === 0 ? '0.3' : ''
          }"
        >
          Delete Selected Applicants
        </a>
        
      </span>

      <table class="table-job-bx cv-manager company-manage-job" style="margin-bottom: 18% !important">
        <thead>
          <tr>
            <th class="feature"></th>
            <th>Code</th>
            <th>Name</th>
            <th style="text-align: center">Applications</th>
            <th style="text-align: center">Assignments</th>
            <th>Apply Status</th>
            <th>Job Status</th>
            <th>User Status</th>
            <th style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor=" let a of applicantList | paginate : { itemsPerPage: count, currentPage: page } ">
            <td class="feature">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="check{{ a.user_id }}"
                  name="example{{ a.user_id }}" (click)="selectApplicantToDelete(a.user_id)" />
                <label class="custom-control-label" for="check{{ a.user_id }}"></label>
              </div>
            </td>
            <td class="application text-primary" style="text-align: center">
              {{ a.recruitee_code }}
            </td>
            <td class="job-name">
              <a href="javascript:void(0);" (click)="gotoDetails(a)" class="link2" style="text-transform: capitalize">{{
                a.user_first_name }}
                {{ a.user_middle_name }}
                {{ a.user_last_name }}</a>
            </td>
            <td class="application text-primary job-links">
              <div class="appCount" data-toggle="modal" data-target="#applDetails" (click)="clickOpenAppl(a)"
                *ngIf="a.count !== 0">
                {{ a.count }}
              </div>
            </td>
            <td class="application text-primary job-links">
              <div class="appCount2" data-toggle="modal" data-target="#assgnDetails" (click)="clickOpenASSign(a)"
                *ngIf="a.assignment_count !== 0">
                {{ a.assignment_count }}
              </div>
            </td>

            <td class="expired success" style="text-transform: capitalize" *ngIf="a.apply_status === 'regular'">
              {{ a.apply_status }}
            </td>
            <td class="expired pending" style="text-transform: capitalize" *ngIf="a.apply_status === 'DNH'">
              {{ a.apply_status }}
            </td>
            <td class="expired success" style="text-transform: capitalize; color: blue">
              {{ a.recruit_status }}
            </td>
            <td class="expired success" style="text-transform: capitalize; color: #333">
              {{ a.user_status }}
            </td>
            <td class="job-links">
              <div class="dropdown">
                <a href="javascript:void(0);">
                  <i class="fa fa-cog"></i></a>
                <div class="dropdown-content">
                  <a href="avascript:void(0);" data-toggle="modal" data-target="#editDetails"
                    (click)="EditApp(a)">Edit</a>
                  <a href="avascript:void(0);" data-toggle="modal" data-target="#changeStatus"
                    (click)="clickOpen(a)">Change Status</a>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#uploadDoc"
                    (click)="clickOpen(a)">Upload Apl. Doc</a>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#requestForDoc"
                    (click)="clickOpenReqDoc(a)">Req. Apl. for Doc
                  </a>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#uploadConfdDoc"
                    (click)="clickOpenConfDoc(a)">VCS Confd. doc
                  </a>
                  <a href="avascript:void(0);" data-toggle="modal" data-target="#applyBlock"
                    (click)="clickOpen(a)">Block To Apply</a>
                  <a href="avascript:void(0);" data-toggle="modal" data-target="#changePassword"
                    (click)="clickOpen(a)">Change Password</a>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#changePascode"
                    (click)="clickOpen(a)">Change Passcode</a>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#viewDoc"
                    (click)="clickOpenDoc(a)">View Documents</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <pagination-controls *ngIf="applicantList.length" class="product-pagination" autoHide="false"
          (pageChange)="onPageChanged($event)">
        </pagination-controls>
      </div>
      <!--Application search Modal -->
      <div class="modal fade modal-bx-info editor" id="applSearch" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle">
                Appl. Search
              </h5>
              <button type="button" class="close" data-dismiss="modal" #applSearchClose aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" id="mbody">
              <form>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>From Date<span style="color: red">*</span></label>

                      <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                        [(ngModel)]="from_date" [ngModelOptions]="{ standalone: true }" [config]="datePickerConfig2"
                        (open)="focusFunction()" (close)="focusOutFunction()">
                      </dp-date-picker>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>To Date<span style="color: red">*</span></label>

                      <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                        [(ngModel)]="to_date" [ngModelOptions]="{ standalone: true }" [config]="datePickerConfig3"
                        (open)="focusFunction()" (close)="focusOutFunction()">
                      </dp-date-picker>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <a type="button" class="site-button" href="{{apiUrl}}vcsapi/api/generate/excel/appplicant/and/application/{{
                              from_date
                            }}/{{ to_date }}/{{ user_id_by }}/Appl_searchList" target="_blank"
                rel="noopener noreferrer" download>Report</a>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Edit Modal -->
      <div class="modal fade modal-bx-info editor" id="editDetails" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                Edit Applicant
              </h5>
              <button type="button" class="close" data-dismiss="modal" #closeEdit aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <form [formGroup]="editApplicant">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" id="first_name" [(ngModel)]="first_name"
                        formControlName="first_name" />

                      <div *ngIf="editApplicant.controls['first_name'].invalid && (editApplicant.controls['first_name'].dirty || editApplicant.controls['first_name'].touched)" 
                        class="alert alert-danger" style="
                        font-size: 12px;
                        padding: 0.5rem 1.25rem;
                      ">
                        <div *ngIf=" editApplicant.controls['first_name'].errors ?.['required'] ">
                          First name is required.
                        </div>
                        <div *ngIf=" editApplicant.controls['first_name'].errors ?.['maxlength'] ">
                          First name must be at most 100 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control" id="last_name" [(ngModel)]="last_name" formControlName="last_name" />
                      <div
                        *ngIf=" editApplicant.controls['last_name'] .invalid && (editApplicant.controls['last_name'].dirty || editApplicant.controls['last_name'] .touched) "
                        class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                        <div *ngIf=" editApplicant.controls['last_name'].errors ?.['required'] ">
                          Last name is required.
                        </div>
                        <div *ngIf=" editApplicant.controls['last_name'].errors ?.['maxlength'] ">
                          Last name must be at most 100 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="text" class="form-control" id="email" [(ngModel)]="email" formControlName="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />

                      <div
                        *ngIf=" editApplicant.controls['email'].invalid && (editApplicant.controls['email'].dirty || editApplicant.controls['email'].touched) "
                        class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                        <div *ngIf=" editApplicant.controls['email'].errors ?.['required'] ">
                          Email is required.
                        </div>
                        <div *ngIf=" editApplicant.controls['email'].invalid ">
                          Email is invalid.
                        </div>
                        <div *ngIf=" editApplicant.controls['email'].errors ?.['maxlength'] ">
                          Email must be at most 60 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Phone</label>
                      <input type="number" class="form-control" [(ngModel)]="phone_no" formControlName="phone_no"
                        style="margin-left: 10px" pattern="[-+]?\d*" />

                      <div
                        *ngIf=" editApplicant.controls['phone_no'].invalid && (editApplicant.controls['phone_no'].dirty || editApplicant.controls['phone_no'].touched) "
                        class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                        <div *ngIf=" editApplicant.controls['phone_no'].invalid ">
                          Enter valid number.
                        </div>
                        <div *ngIf=" editApplicant.controls['phone_no'].errors?.['max'] ">
                          Phone number must be at most 14 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Date of Birth</label>
                      <!-- <input type="date" class="form-control" [(ngModel)]="dob" formControlName="dob"> -->
                      <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="false"
                        [(ngModel)]="dob" formControlName="dob" [config]="datePickerConfig">
                      </dp-date-picker>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>SSN(Last 4 Digit)</label>
                      <input type="number" class="form-control" [(ngModel)]="ssn_4digit" formControlName="ssn_4digit"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="4" />

                      <div
                        *ngIf=" editApplicant.controls['ssn_4digit'] .invalid && (editApplicant.controls['ssn_4digit'] .dirty || editApplicant.controls['ssn_4digit'] .touched) "
                        class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                        <div *ngIf=" editApplicant.controls['ssn_4digit'] .invalid ">
                          Enter valid code.
                        </div>
                        <div *ngIf=" editApplicant.controls['ssn_4digit'].errors ?.['maxlength'] ">
                          SSN code must be at most 4 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group" style="display: grid">
                      <label>Profession</label>
                      <select class="form-control" [(ngModel)]="profession_id" formControlName="profession_id">
                        <option *ngFor="let data of profession | sortBy:'profession_name'" [value]="data.profession_id"
                          style="text-transform: capitalize">
                          {{ data.profession_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Speciality</label>
                      <select class="form-control" [(ngModel)]="speciality_id" formControlName="speciality_id">
                        <option *ngFor="let data of speciality | sortBy:'speciality_name'" [value]="data.speciality_id"
                          style="text-transform: capitalize">
                          {{ data.speciality_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="updateDetails()" [disabled]="editApplicant.invalid" [ngStyle]="{ cursor: editApplicant.invalid ? 'not-allowed' : 'pointer' }">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--Change Status Modal -->
      <div class="modal fade modal-bx-info editor" id="changeStatus" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle">
                Change Status
              </h5>
              <button type="button" class="close" data-dismiss="modal" #closeUserStatus aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Status</label>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="active" name="active1"
                              [(ngModel)]="user_status" value="active" />
                            <label class="custom-control-label" for="active">Active</label>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="inactive" name="inactive1"
                              [(ngModel)]="user_status" value="inactive" />
                            <label class="custom-control-label" for="inactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="changeUserStatus()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--Block Apply Modal -->
      <div class="modal fade modal-bx-info editor" id="applyBlock" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle">
                Block to Apply
              </h5>
              <button type="button" class="close" data-dismiss="modal" #closeApplyStatus aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Status</label>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="regular" name="regular1"
                              [(ngModel)]="apply_status" value="regular" />
                            <label class="custom-control-label" for="regular">Regular</label>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="DNH" name="DNH1"
                              [(ngModel)]="apply_status" value="DNH" />
                            <label class="custom-control-label" for="DNH">Don't Hire</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="changeApplyStatus()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Edit Modal -->
      <div class="modal fade modal-bx-info editor" id="addApplicant" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                Add Applicant
              </h5>
              <button type="button" class="close" data-dismiss="modal" #closeAdd aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="addApplicant">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" id="first_name" formControlName="first_name" />

                      <div *ngIf="addApplicant.controls['first_name'].invalid && (addApplicant.controls['first_name'].dirty || addApplicant.controls['first_name'].touched)" 
                        class="alert alert-danger" style="
                        font-size: 12px;
                        padding: 0.5rem 1.25rem;
                      ">
                        <div *ngIf=" addApplicant.controls['first_name'].errors ?.['required'] ">
                          First name is required.
                        </div>
                        <div *ngIf=" addApplicant.controls['first_name'].errors ?.['maxlength'] ">
                          First name must be at most 100 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control" id="last_name" formControlName="last_name" />
                      <div
                        *ngIf=" addApplicant.controls['last_name'] .invalid && (addApplicant.controls['last_name'].dirty || addApplicant.controls['last_name'] .touched) "
                        class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                        <div *ngIf=" addApplicant.controls['last_name'].errors ?.['required'] ">
                          Last name is required.
                        </div>
                        <div *ngIf=" addApplicant.controls['last_name'].errors ?.['maxlength'] ">
                          Last name must be at most 100 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="text" class="form-control" id="email" formControlName="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" (input)="checkUniqueEmail()"/>

                        <div 
                          *ngIf="addApplicant.controls['email'].invalid && (addApplicant.controls['email'].dirty || addApplicant.controls['email'].touched) "
                          class="alert alert-danger" 
                          style=" font-size: 12px; padding: 0.5rem 1.25rem; "
                        >
                          <div *ngIf=" addApplicant.controls['email'].errors ?.['required'] "> Email is required. </div>
                          <div *ngIf=" addApplicant.controls['email'].invalid "> Email is invalid. </div>
                          <div *ngIf=" addApplicant.controls['email'].errors ?.['maxlength'] "> Email must be at most 60 characters long. </div>
                        </div>
                        <div *ngIf="(
                          addApplicant.controls['email'].dirty ||
                          addApplicant.controls['email'].touched) &&
                          checkEmail" 
                          class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                          <div *ngIf="checkEmail">Email already exist.</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Phone</label>
                      <input type="number" class="form-control" formControlName="phone_no"
                        style="margin-left: 10px" pattern="[-+]?\d*" />

                      <div
                        *ngIf=" addApplicant.controls['phone_no'].invalid && (addApplicant.controls['phone_no'].dirty || addApplicant.controls['phone_no'].touched) "
                        class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">

                        <div *ngIf=" addApplicant.controls['phone_no'].invalid ">
                          Enter valid number.
                        </div>
                        <div *ngIf=" addApplicant.controls['phone_no'].errors?.['max'] ">
                          Phone number must be at most 14 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Date of Birth</label>
                      <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="false" formControlName="dob" [config]="datePickerConfig">
                      </dp-date-picker>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>SSN(Last 4 Digit)</label>
                      <input type="number" class="form-control" formControlName="ssn_4digit"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="4" />

                      <div
                        *ngIf=" addApplicant.controls['ssn_4digit'] .invalid && (addApplicant.controls['ssn_4digit'] .dirty || addApplicant.controls['ssn_4digit'] .touched) "
                        class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                        <div *ngIf=" addApplicant.controls['ssn_4digit'] .invalid ">
                          Enter valid code.
                        </div>
                        <div *ngIf=" addApplicant.controls['ssn_4digit'].errors ?.['maxlength'] ">
                          SSN code must be at most 4 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group" style="display: grid">
                      <label>Profession</label>
                      <select class="form-control" formControlName="profession_id">
                        <option *ngFor="let data of profession | sortBy:'profession_name'" [value]="data.profession_id"
                          style="text-transform: capitalize">
                          {{ data.profession_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Speciality</label>
                      <select class="form-control" formControlName="speciality_id">
                        <option *ngFor="let data of speciality | sortBy:'speciality_name'" [value]="data.speciality_id"
                          style="text-transform: capitalize">
                          {{ data.speciality_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="addApplicantData()" [disabled]="addApplicant.invalid || checkEmail" [ngStyle]="{ cursor: addApplicant.invalid || checkEmail ? 'not-allowed' : 'pointer' }">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--Change Password Modal -->
      <div class="modal fade modal-bx-info editor" id="changePassword" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle">
                Change Password
              </h5>
              <button type="button" class="close" data-dismiss="modal" #changePasswordModal aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p style="text-transform: none">
                Click on reset to change the password. Password will
                be send to the respective email ID.
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="changePassword()">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--Change Password Modal -->
      <div class="modal fade modal-bx-info editor" id="changePascode" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle">
                Change Passcode
              </h5>
              <button type="button" class="close" data-dismiss="modal" #changePasscodeModal aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p style="text-transform: none">
                Click on reset to change the passcode. Passcode will
                be send to the respective email ID.
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="changePasscode()">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Upload Doc Modal -->
      <div class="modal fade modal-bx-info editor" id="uploadDoc" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                Upload Document
              </h5>
              <button type="button" class="close" data-dismiss="modal" #uploadDocModal aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <form>
                <div class="row">
                  <div *ngIf="viewfinalErr" class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                    <div>
                      Something went wrong,please try again.
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Document Name</label>
                      <select class="form-control" #docValue (change)="onSelectedDoc(docValue.value)"
                        [(ngModel)]="doc_id" [ngModelOptions]="{ standalone: true }">
                        <option *ngFor="let data of docType | sortBy:'doc_name'" [value]="data.doc_id"
                          style="text-transform: capitalize">
                          {{ data.doc_name }}
                        </option>
                      </select>

                      <input type="text" class="form-control" placeholder="Enter document name" [(ngModel)]="doc_name"
                        [ngModelOptions]="{ standalone: true }" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                        *ngIf="showSecInput" style="margin-top: 10px" />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Upload File</label>
                      <div style="display: flex">
                        <div class="custom-file" style="width: 60%">
                          <p class="m-a0" *ngIf="!file_name">
                            <i class="fa fa-upload"></i>
                            Upload File
                          </p>

                          <p class="m-a0" *ngIf="file_name">
                            {{ file_name }}
                          </p>
                          <input type="file" class="site-button form-control" id="customFile" (change)="
                                          handleFileInput($event.target.files)
                                        " />
                        </div>
                        <div *ngIf="showProgressBar" style="margin-top: -20px">
                          <circle-progress [percent]="showPercentage" [radius]="40" [outerStrokeWidth]="6"
                            [innerStrokeWidth]="4" [outerStrokeColor]="'#4C96D7'" [innerStrokeColor]="'#a6cbeb'"
                            [animation]="false" [animationDuration]="10" [titleFontSize]="10" [renderOnClick]="false">
                          </circle-progress>
                        </div>
                      </div>
                      <div *ngIf="viewShow === 'true'" class="alert alert-success" style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    ">
                        <div>Document uploaded successfully.</div>
                      </div>

                      <div *ngIf="viewShow === 'false'" class="alert alert-danger" style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    ">
                        <div *ngIf="viewShow === 'false'" class="">
                          Document can not be uploaded,please try
                          again.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Expiry Date<span style="color: red">*</span></label>

                      <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                        [(ngModel)]="doc_expiry_date" [ngModelOptions]="{ standalone: true }"
                        [config]="datePickerConfig">
                      </dp-date-picker>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="uploadFileToActivity()"
                [disabled]="doc_name === '' || !fileToUpload" [ngStyle]="{
                              cursor:
                                doc_name === '' || !fileToUpload
                                  ? 'not-allowed'
                                  : 'pointer'
                            }">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--Send Remark Modal -->
      <div class="modal fade modal-bx-info editor" id="sendRemark" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle">
                Send Remark
              </h5>
              <button type="button" class="close" data-dismiss="modal" #sendRemarkclose aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-lg-12 col-md-12" style="margin-top: 10px">
                          <div class="form-group">
                            <label>Remark</label>
                            <textarea type="text" class="form-control" [(ngModel)]="remark" [ngModelOptions]="{
                                            standalone: true
                                          }"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="sendRemark()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--View Doc Modal -->
      <div class="modal fade modal-bx-info editor" id="viewDoc" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 60% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle">
                Document History
              </h5>
              <button type="button" class="close" data-dismiss="modal" #aaa aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table class="table-job-bx cv-manager company-manage-job">
                <thead>
                  <tr>
                    <th class="feature"></th>
                    <th>Doc Name</th>
                    <th>Doc Type</th>
                    <th>Expiry Date</th>
                    <th>Upload Date</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let a of docs; let i = index">
                    <td class="feature"></td>

                    <td class="application text-primary">
                      {{ a.rec_doc_name }}
                    </td>
                    <td class="application text-primary">
                      {{ a.rec_doc_type }}
                    </td>
                    <td class="application text-primary">
                      <a *ngIf="a.expirystatus === 'current'">{{
                        a.expiry_date | date : "MM/dd/yyyy"
                        }}</a>
                      <a *ngIf="a.expirystatus === 'expired'" style="color: red">{{
                        a.expiry_date | date : "MM/dd/yyyy"
                        }}</a>
                    </td>

                    <td class="application text-primary">
                      {{
                      a.upload_date_time
                      | date : "MM/dd/yyyy hh:mm a"
                      }}
                    </td>

                    <td class="expired success" *ngIf="a.rec_doc_status === 'current'"
                      style="text-transform: capitalize">
                      Current
                    </td>
                    <td class="expired pending" *ngIf="a.rec_doc_status !== 'current'"
                      style="text-transform: capitalize">
                      Previous
                    </td>

                    <td class="application text-light">
                      <a class="site-button" href="{{apiUrl}}vcsapi/download/{{
                                      a.rec_doc_id
                                    }}/{{ user_id }}/{{ a.rec_doc_name }}" target="_blank" rel="noopener noreferrer"
                        download>Download</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--Upload Confidential Doc Modal -->
      <div class="modal fade modal-bx-info editor" id="uploadConfdDoc" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 60% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle" *ngIf="details">
                Confidential Document (
                {{ details.user_first_name }}
                {{ details.user_middle_name }}
                {{ details.user_last_name }} )
              </h5>
              <button type="button" class="close" data-dismiss="modal" #aaaa aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>Document Name</label>
                  <input type="text" class="form-control" placeholder="Enter document name" [(ngModel)]="conf_doc_name"
                    [ngModelOptions]="{ standalone: true }" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <div style="display: flex">
                    <div class="custom-file" style="width: 60%">
                      <p class="m-a0" *ngIf="!conf_file_name">
                        <i class="fa fa-upload"></i>
                        Upload Document
                      </p>

                      <p class="m-a0" *ngIf="conf_file_name">
                        {{ conf_file_name }}
                      </p>
                      <input type="file" class="site-button form-control" id="customFile" (change)="
                                      ConfFileUpload($event.target.files)
                                    " />
                    </div>

                    <div *ngIf="showProgressBar" style="margin-top: -20px">
                      <circle-progress [percent]="showPercentage" [radius]="40" [outerStrokeWidth]="6"
                        [innerStrokeWidth]="4" [outerStrokeColor]="'#4C96D7'" [innerStrokeColor]="'#a6cbeb'"
                        [animation]="false" [animationDuration]="10" [titleFontSize]="10" [renderOnClick]="false">
                      </circle-progress>
                    </div>
                  </div>
                  <div *ngIf="viewShow === 'true'" class="alert alert-success"
                    style="font-size: 12px; padding: 0.5rem 1.25rem">
                    <div>Document uploaded successfully.</div>
                  </div>

                  <div *ngIf="viewShow === 'false'" class="alert alert-danger"
                    style="font-size: 12px; padding: 0.5rem 1.25rem">
                    <div *ngIf="viewShow === 'false'" class="">
                      Document can not be uploaded,please try again.
                    </div>
                  </div>
                </div>
              </div>

              <table class="table-job-bx cv-manager company-manage-job">
                <thead>
                  <tr>
                    <th class="feature"></th>
                    <th>Doc Name</th>
                    <th>Datetime</th>
                    <th>Uploaded By</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody *ngIf="conf_doc_list.length !== 0">
                  <tr *ngFor="let a of conf_doc_list; let i = index">
                    <td class="feature"></td>

                    <td class="application text-primary">
                      {{ a.conf_doc_name }}
                    </td>

                    <td class="application text-primary">
                      {{
                      a.upload_datetime
                      | date : "MM/dd/yyyy hh:mm a"
                      }}
                    </td>

                    <td class="application text-primary">
                      {{ a.uploadby_fname }} {{ a.uploadby_mname }}
                      {{ a.uploadby_lname }}
                    </td>

                    <td class="application text-light">
                      <a class="site-button" href="{{apiUrl}}vcsapi/get/api/tbl/conf_document/{{
                                      a.conf_doc_id
                                    }}/{{ a.conf_doc_name }}" target="_blank" rel="noopener noreferrer"
                        download>Download</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Appl Details Modal -->
      <div class="modal fade modal-bx-info editor" id="applDetails" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 60% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                All Applications Details
              </h5>
              <button type="button" class="close" data-dismiss="modal" #abcd aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="detailsData.length !== 0">
              <table class="table-job-bx cv-manager company-manage-job">
                <thead>
                  <tr>
                    <th class="feature"></th>
                    <th>Application No.</th>
                    <th>Date of Apply</th>
                    <th>Job title</th>
                    <th>Job Location</th>
                    <th>Application Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let a of detailsData; let i = index">
                    <td class="feature"></td>

                    <td class="application text-primary">
                      {{ a.application_no }}
                    </td>

                    <td class="application text-primary">
                      {{ a.apply_date | date : "MM/dd/yyyy" }}
                    </td>

                    <td class="application text-primary">
                      {{ a.job_title }}
                    </td>
                    <td class="application text-primary">
                      {{ a.state }}
                    </td>
                    <td class="application text-primary" *ngIf="a.application_stage !== 'rejected'"
                      style="text-transform: capitalize">
                      {{ a.application_stage }}
                    </td>
                    <td class="expired pending" *ngIf="a.application_stage === 'rejected'"
                      style="text-transform: capitalize">
                      {{ a.application_stage }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Assignment Details Modal -->
      <div class="modal fade modal-bx-info editor" id="assgnDetails" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 60% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                Assignment Details
              </h5>
              <button type="button" class="close" data-dismiss="modal" #abcdd aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="assignment_data.length !== 0">
              <table class="table-job-bx cv-manager company-manage-job">
                <thead>
                  <tr>
                    <th class="feature"></th>
                    <th>Application No.</th>
                    <th>Date of Hiring</th>
                    <th>Job title</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let a of assignment_data; let i = index">
                    <td class="feature"></td>

                    <td class="application text-primary">
                      {{ a.application_no }}
                    </td>

                    <td class="application text-primary">
                      {{ a.hiring_date | date : "MM/dd/yyyy" }}
                    </td>

                    <td class="application text-primary">
                      {{ a.job_title }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Request Document Modal -->
      <div class="modal fade modal-bx-info editor" id="requestForDoc" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 60% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                Request For Documents
              </h5>
              <button type="button" class="close" data-dismiss="modal" #requestForDocClose aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <form>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Applicant Name :&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">{{ details.user_first_name }}
                        {{ details.user_middle_name }}
                        {{ details.user_last_name }}</span>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <h5 class="font-weight-700 text-uppercase">
                      Pending Request
                    </h5>
                  </div>

                  <table class="table-job-bx cv-manager company-manage-job">
                    <thead>
                      <tr>
                        <th class="feature"></th>
                        <th>Doc. Name</th>
                        <th>Doc. Type</th>
                        <th>Request Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                                      let a of pendingReqDoc;
                                      let i = index
                                    ">
                        <td class="feature"></td>

                        <td class="application text-primary">
                          {{ a.req_doc_name }}
                        </td>
                        <td class="application text-primary">
                          {{ a.req_doc_type }}
                        </td>

                        <td class="application text-primary">
                          {{ a.requested_doc_date }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 10px">
                    <h5 class="font-weight-700 text-uppercase">
                      New Request
                    </h5>
                  </div>

                  <div style="display: flex; width: 100%">
                    <div class="divider2"></div>
                    <div style="
                                    width: 32%;
                                    margin-top: 4px;
                                    text-align: center;
                                  ">
                      <h5 class="font-weight-700 text-uppercase">
                        Document Required
                      </h5>
                    </div>
                    <div class="divider2"></div>
                  </div>

                  <table class="table-job-bx cv-manager company-manage-job">
                    <tbody>
                      <tr *ngFor="let a of docType; let i = index">
                        <td class="feature" *ngIf="
                                        a.doc_name !== 'other' &&
                                        a.doc_name !== 'facility_spec'
                                      ">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="check{{ i }}" [checked]="a.value"
                              (click)="entryValue(a)" />

                            <label class="custom-control-label" for="check{{ i }}"></label>
                          </div>
                        </td>

                        <td class="application text-primary" *ngIf="
                                        a.doc_name !== 'other' &&
                                        a.doc_name !== 'facility_spec'
                                      ">
                          {{ a.doc_name }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div style="display: flex; width: 100%">
                    <div class="divider2" style="width: 25%; margin-top: 4%"></div>
                    <div style="
                                    width: 46%;
                                    margin-top: 22px;
                                    text-align: center;
                                  ">
                      <h5 class="font-weight-700 text-uppercase">
                        Facility Specific Document Required
                      </h5>
                    </div>
                    <div class="divider2" style="width: 25%; margin-top: 4%"></div>
                  </div>

                  <table class="table-job-bx cv-manager company-manage-job">
                    <tbody>
                      <tr *ngFor="
                                      let a of fac_specc_doc;
                                      let i = index
                                    ">
                        <td class="feature">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="check11{{ i }}" [checked]="a.value"
                              (click)="entryValue22(a)" />

                            <label class="custom-control-label" for="check11{{ i }}"></label>
                          </div>
                        </td>

                        <td class="application text-primary">
                          {{ a.rec_doc_name }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div style="margin-top: 15px; margin-left: 2%">
                    <button type="button" class="site-button" (click)="addNewSpecDoc()">
                      Add New Document
                    </button>
                  </div>

                  <div class="col-lg-6 col-md-6" *ngIf="showSecInput2" style="margin-top: 15px">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Enter document name"
                        [(ngModel)]="doc_name_spec" [ngModelOptions]="{ standalone: true }"
                        onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3" *ngIf="showSecInput2" style="margin-top: 15px">
                    <button type="button" class="site-button" (click)="entryValueSpec()">
                      Add
                    </button>
                  </div>

                  <div style="display: flex; width: 100%">
                    <div class="divider2" style="width: 25%; margin-top: 4%"></div>
                    <div style="
                                    width: 46%;
                                    margin-top: 22px;
                                    text-align: center;
                                  ">
                      <h5 class="font-weight-700 text-uppercase">
                        Other Document Required
                      </h5>
                    </div>
                    <div class="divider2" style="width: 25%; margin-top: 4%"></div>
                  </div>

                  <table class="table-job-bx cv-manager company-manage-job">
                    <tbody>
                      <tr *ngFor="let a of others_doc; let i = index">
                        <td class="feature">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="check3{{ i }}" [checked]="a.value"
                              (click)="entryValue3(a)" />

                            <label class="custom-control-label" for="check3{{ i }}"></label>
                          </div>
                        </td>

                        <td class="application text-primary">
                          {{ a.rec_doc_name }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div style="margin-top: 15px; margin-left: 2%">
                    <button type="button" class="site-button" (click)="addNewOtherDoc()">
                      Add New Document
                    </button>
                  </div>

                  <div class="col-lg-6 col-md-6" *ngIf="showtrdInput" style="margin-top: 15px">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Enter document name"
                        [(ngModel)]="doc_name_Other" [ngModelOptions]="{ standalone: true }"
                        onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3" *ngIf="showtrdInput" style="margin-top: 15px">
                    <button type="button" class="site-button" (click)="entryValueOther()">
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="sendRequest()" [disabled]="req_doc_list.length === 0"
                [ngStyle]="{
                              cursor:
                                req_doc_list.length === 0
                                  ? 'not-allowed'
                                  : 'pointer'
                            }">
                Send Request
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->
    </div>
  </div>
</app-admin-default-layout>