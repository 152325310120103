import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AdminService } from "src/app/services/admin.service";
import Swal from "sweetalert2";
import * as moment from "moment";
import { IDayCalendarConfig } from 'ng2-date-picker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-income-expenses',
  templateUrl: './income-expenses.component.html',
  styleUrls: ['./income-expenses.component.scss']
})

export class IncomeExpensesComponent implements OnInit {
  inputStyles = {
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    height: '50px',
    width: '100%'
  };
  moduleArray: any = [];
  type_vendor: any = [];
  
  selected_income_expense: any = "";
  selected_type: any = "";
  selected_amount: any = 0.00;
  selected_vendor: any = "";
  comment: any = "";
  date: any;

  ///////////////
  loading: boolean = false;
  excelfileName: any;
  user_id: any;

  datePickerConfig = <IDayCalendarConfig>{
    drops: 'down',
    weekDayFormat: 'dd',
    dayBtnFormat: 'D',
    format: 'MM/DD/YYYY',
  } 

  get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {
    this.user_id = sessionStorage.getItem("user_id");
    this.excelfileName =
      "payroll_approve(" + moment(new Date()).format("MM-DD-YYYY") + ")";
  }

  ngOnInit() {
    this.getDataTypesVendor()
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 400);
  }

  getDataTypesVendor() {
    this.http.getApiTypesVendorActive().subscribe((res: any) => {
      this.type_vendor = res;
    });
  }

  navigateTo(val: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }
  submitIncomeExpenses() {
    const {
      selected_income_expense,
      selected_type,
      selected_amount,
      selected_vendor,
      date,
      comment
    } = this
    if(selected_income_expense == '' || selected_type == '' || selected_vendor == '' || date == '' || typeof date == 'undefined' || selected_amount == null) return
    this.loading = true
    let data = {
      financial: selected_income_expense,
      type: selected_type,
      amount: parseFloat(selected_amount).toFixed(2),
      vendor: selected_vendor,
      date: moment(new Date(date)).format( "MM/DD/YYYY" ),
      comment: comment,
    }
    this.http.submitIncomeExpense(data).subscribe(
      (res: any) => {
        if (res === "success") {
          this.loading = false
          this.successMsg("Added Successfully.");
        } else {
          this.loading = false
          this.errorMsg("Something went wrong. Please Try Again.");
        }
      },
      (err) => {
        this.loading = false
        this.errorMsg("Something went wrong. Please Try Again.");
      }
    );
  }
  errorMsg(msg: string) {
    Swal.fire({
      title: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }
  
  successMsg(msg: string) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.selected_income_expense = "";
        this.selected_type = "";
        this.selected_amount = 0;
        this.selected_vendor = "";
        this.comment = "";
        this.date = '';
      }
    });
  }

  successMsg2(msg: string) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }
}
