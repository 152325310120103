import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  header = new HttpHeaders().set(
    'Authorization',
    `Bearear ${sessionStorage.getItem('user_token')}`  );
  get apiUrl(): string {
    return environment.apiUrl;
  }
  constructor(private _http: HttpClient) {}

  fetchJobs(datas: any) {
    return this._http.post(
      this.apiUrl+"vcsapi/get/api/tbl/job/search_job/with/filter",
      datas,
      { headers: this.header }
    );
  }

  
  fetchJobsTop5(datas: any) {
    return this._http.post(
      this.apiUrl+"vcsapi/get/api/tbl/job/top/5",
      datas,
      { headers: this.header }
    );
  }
}
