<app-page-header heading="Research" subheading=""></app-page-header>
<div class="relative overflow-hidden bg-white">
  <div
    class="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]"
  >
    <div class="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
      <svg
        class="absolute top-12 left-full translate-x-32 transform"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
      >
        <defs>
          <pattern
            id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="384"
          fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
        />
      </svg>
      <svg
        class="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
      >
        <defs>
          <pattern
            id="f210dbf6-a58d-4871-961e-36d5016a0f49"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="384"
          fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
        />
      </svg>
      <svg
        class="absolute bottom-12 left-full translate-x-32 transform"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
      >
        <defs>
          <pattern
            id="d3eb07ae-5182-43e6-857d-35c643af9034"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="384"
          fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
        />
      </svg>
    </div>
  </div>
  <section class="section-container">
    <div class="prose prose-lg prose-indigo mx-auto text-gray-600">
      <p>
        Manufacturing plays a major role in our economy, with 12.88 million
        employees producing goods that we consume domestically or export to
        other countries. The number is significantly growing every year. The
        annual payroll for manufacturing workers was $765 billion in 2021 which
        is on the rising trend. Manufacturing is the fifth largest employer 
        in the Globe with an average earning of $61,127
        earned by a worker each year. Their salaries are also supplemented by other
        benefits as an overwhelming majority of the workers are eligible for
        significant benefits such as health insurance coverage, 401k etc. which
        increases the figure by a much higher amount. The American economy is
        heavily dependent on exports as over 60% of US exports are contributed
        by the manufacturing sector alone.
      </p>
      <figure class="py-2 md:py-10">
        <img
          class="w-full rounded-lg"
          src="../../assets/images/main/research_stats.png"
          alt=""
          width="1310"
          height="873"
        />
      </figure>
      <p>
        The industries with strong growth prospects include semiconductors,
        medical devices, communications equipment, and electronics. With the
        passage of the CHIPS and Science Act of 2022, which was signed into law
        by President Biden the semiconductor industry is poised for a major
        growth unseen by any other sector. The law provides $52.7 billion for
        American semiconductor research, development, manufacturing, and
        workforce development. With the latest Those with the greatest potential
        to support jobs in a wide set of communities include autos and parts,
        metals, and machinery; collectively, they employ 3.4 million workers and
        involve 75,000 suppliers. Pharmaceuticals, electronics, semiconductors,
        and medical devices have high intensities of R&D spending, which
        underpins innovation and competitiveness. All 16 industries and their
        supply chains are important to national security, resilience, or
        emergency preparedness.
      </p>
      <figure class="py-2 md:py-10">
        <img
          class="w-full rounded-lg"
          src="../../assets/images/main/research_stat2.webp"
          alt=""
          width="1310"
          height="873"
        />
      </figure>
    </div>
  </section>
</div>
<app-cta></app-cta>
