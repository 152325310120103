import { Component, OnInit } from '@angular/core';
import { CompanyServiceService } from './company-service.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroup,
  FormControl,
  FormBuilder,
  Validators

} from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  get apiUrl(): string {
    return environment.apiUrl;
  }

  cmpId: any;
  cmpName: any;
  cmpaddress: any;
  cmpPhone: any;
  CmpEmail: any;

  cmpNamecopy: any;
  cmpaddresscopy: any;
  cmpPhonecopy: any;
  CmpEmailcopy: any;
  compantDetailsForm: UntypedFormGroup;

  data: any;
  moduleArray: any = [];
  addDocumentsList: FormGroup;
  companyDocumentData: any = [];
  orignalFileId:string = "";
  editDocumentsList: FormGroup;
  orignalFileName: string = "";
  documentId: any;
  datePickerConfig = <IDayCalendarConfig>{
    drops: 'up',
    format: 'MM/DD/YYYY'
  }

  constructor(
    public service: CompanyServiceService,
    public fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {}

  ngOnInit() {
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
    this.getCompanyAllData().then(() => {
      this.documentData();
    })

    this.compantDetailsForm = this.fb.group({
      name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      address: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(80),
      ]),
      phone: new UntypedFormControl(null, [
        Validators.required,
        Validators.min(10000000),
        Validators.max(999999999999999),
      ]),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(80),
        Validators.email,
        Validators.maxLength(40),
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'),
      ]),
    });

    this.addDocumentsList = this.fb.group({
      document_name: new FormControl(null, [Validators.required,Validators.maxLength(80)]),
      expirDate: new FormControl(),
      file: new FormControl(null, []),
      fileSource: new FormControl('', [Validators.required])
    });

    this.editDocumentsList = this.fb.group({
      edit_document_name: new FormControl(null, [Validators.required,Validators.maxLength(80)]),
      edit_expirDate: new FormControl(),
      edit_file: new FormControl(null, []),
      edit_fileSource: new FormControl(),
    });

  }

  setDocumentId(value) {
    this.documentId = value.id;
    this.orignalFileName =value.doc_name;
    this.orignalFileId =value.company_id;
    this.editDocumentsList.setValue({
      edit_document_name: value.doc_name,
      edit_expirDate: value.expiry_date,
      edit_file: '',
      edit_fileSource:'',
    });
  }

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  getCompanyAllData() {
    return new Promise((resolve, reject) => {
      this.service.getCompanyDetails().subscribe((r: any) => {
        this.cmpId = r[0].company_id;
        this.cmpName = r[0].company_name;
        this.cmpaddress = r[0].company_addr;
        this.cmpPhone = parseInt(r[0].company_phone);
        this.CmpEmail = r[0].company_email;
        this.cmpNamecopy = r[0].company_name;
        this.cmpaddresscopy = r[0].company_addr;
        this.cmpPhonecopy = parseInt(r[0].company_phone);
        this.CmpEmailcopy = r[0].company_email;

        resolve(true);
      });
    })
  }

  setFormData() {
    this.compantDetailsForm.setValue({
      name: this.cmpName,
      address: this.cmpaddress,
      phone: this.cmpPhone,
      email: this.CmpEmail,
    });
  }

  submitDetails() {
    const data = {
      company_name: this.compantDetailsForm.controls['name'].value,
      company_addr: this.compantDetailsForm.controls['address'].value,
      company_phone: this.compantDetailsForm.controls['phone'].value,
      company_email: this.compantDetailsForm.controls['email'].value,
    };
    this.service.updateCompanyDetails(data).subscribe((r) => {
      if (r === 'success') {
        Swal.fire({
          title: 'Company name updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.compantDetailsForm.reset();
            this.getCompanyAllData();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  submitDocuments() {
    var document_name = this.addDocumentsList.controls['document_name'].value;
    var expirDate = this.addDocumentsList.controls['expirDate'].value;
    // Create form data
    const formData = new FormData(); 
    formData.append('file', this.addDocumentsList.get('fileSource').value);
    formData.append("document_name", document_name); // same as above
    formData.append("expirDate", expirDate); // same as above
    formData.append("user_id", sessionStorage.getItem("user_id"));
    formData.append("company_id", this.cmpId);
    this.service.uploadApiDocument(formData).subscribe(r => {
      if (r === "success") {
        Swal.fire({
          title: 'Document uploaded successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDocumentsList.reset();
            this.documentData();
          }
        });
      }else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {

          }
        })
      }

    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addDocumentsList.patchValue({
        fileSource: file
      });
    }
  }

  onUpdateFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      debugger;
      this.editDocumentsList.patchValue({
        edit_fileSource: file
      });
    }
  }

  get f(){
    return this.addDocumentsList.controls;
  }

  documentData() {
    var company_id = this.cmpId;
    this.service.getApiAllDocumentCompany(company_id).subscribe(r => {
     this.companyDocumentData = r;
    });
  }

  updateDocument() {
    var doc_name = this.editDocumentsList.controls['edit_document_name'].value;
    var expirDate = this.editDocumentsList.controls['edit_expirDate'].value;
    var documentId = this.documentId;
    const formData = new FormData(); 
    formData.append('file', this.editDocumentsList.get('edit_fileSource').value);
    formData.append("document_name", doc_name); // same as above
    formData.append("expirDate", expirDate); // same as above
    formData.append("user_id", sessionStorage.getItem("user_id"));
    formData.append("company_id", this.cmpId);
    formData.append("id", documentId);

    this.service.updateDocuments(formData).subscribe(rep => {
      if (rep === 'success') {
        Swal.fire({
          title: 'Document Updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDocumentsList.reset();
            this.documentData();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {

          }
        })
      }

    });
  }
}
