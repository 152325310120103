import { Injectable } from "@angular/core";
import { catchError, retry } from "rxjs/operators";
import { throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import Swall from "sweetalert2";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class UserRoleServiceService {
  header = new HttpHeaders().set(
    "Authorization",
    `Bearear ${sessionStorage.getItem('user_token')}`);

  get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(public http: HttpClient) { }

  getUserRoleDetails() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/user_roles", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  addUserRole(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/add/api/tbl/role/user_role",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  updateUserRole(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/edit/api/change/role_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  changeStatusUserRole(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/edit/api/change/role_status",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  moduleSubModuleShow() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/user/access", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getDefaultRoleData(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/get/api/module/accesses/by/role_id",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  postAccessRole(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/api/post/insert/role_id",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  duplicateRoleCheck(details) {
    return this.http
      .post(this.apiUrl+"vcsapi/api/check_post_role", details, {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  //delete role
  deleteRoleData(role_id) {

    return this.http.get(`${this.apiUrl}vcsapi/userRoleDelete/${role_id}`, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  handleError(error: any) {
    let Swal: any = Swall;
    if (error.status == 0) {
      Swal.fire({
        text: "No Internet Connection",
        type: "error",
      }).then(() => {
        location.reload();
      });
    } else if (error.status == 400) {
      Swal.fire({
        text: "Invalid Syntex",
        type: "error",
      });
    } else if (error.status == 403) {
      Swal.fire({
        text: "Unauthorized Access",
        type: "error",
      });
    } else if (error.status == 404) {
      Swal.fire({
        text: "URL Is Not Recognized",
        type: "error",
      });
    } else if (error.status == 500) {
      Swal.fire({
        text: "Internal Server Error",
        type: "error",
      });
    } else if (error.status == 501) {
      Swal.fire({
        text: "Not Implemented",
        type: "error",
      });
    } else if (error.status == 503) {
      Swal.fire({
        text: "Service Unavailable",
        type: "error",
      });
    } else if (error.status == 511) {
      Swal.fire({
        text: "Network Authentication Required",
        type: "error",
      });
    }
    return throwError(error.statusText);
  }
}
