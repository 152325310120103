<app-admin-default-layout>
  <div class="col-xl-10 col-lg-8 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Department
        </h5>
        <div class="float-right" style="display: flex">
          <button
            type="button"
            class="site-button m-b30"
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#departmentAdd"
          >
            Add Department
          </button>
        </div>
      </div>
      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th class="feature">
            </th>
            <th>Dept. Name</th>
            <th style="text-align: center">Nos.of Emp</th>
            <th>Status</th>
            <th style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="allDepartment">
          <tr
            *ngFor=" let d of allDepartment | sortBy:'dept_name' | paginate : { itemsPerPage: count, currentPage: page } "
          >
            <td class="feature"></td>
            <td class="job-name"> {{ d.dept_name }} </td>
            <td class="application text-primary" style="text-align: center" > {{ d.count }} </td>
            <td class="expired pending" style="color: green" *ngIf="d.dept_status == 'active'" > Active </td>
            <td class="expired pending" *ngIf="d.dept_status == 'inactive'" > Inactive </td>

            <td class="job-links">
              <div class="dropdown">
                <a href="javascript:void(0);"> <i class="fa fa-cog"></i ></a>
                <div class="dropdown-content">
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#departmentEdit"
                    (click)="setDeptId(d)"
                  >Edit</a>
                  <a
                    href="#"
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#departmentChangeStatus"
                    (click)="setDeptId(d)"
                  >Change Status</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" *ngIf="allDepartment">
        <pagination-controls
          *ngIf="allDepartment.length"
          class="product-pagination"
          autoHide="false"
          maxSize="8"
          (pageChange)="onPageChanged($event)"
        ></pagination-controls>
      </div>

      <!--model start-->
      <div
        class="modal fade modal-bx-info editor"
        id="departmentAdd"
        tabindex="-1"
        role="dialog"
        aria-labelledby="departmentAddModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="departmentAddModalLongTitle"
              >
                Add Department
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="addDeptForm">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Name"
                        formControlName="dept"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                [disabled]="!addDeptForm.valid"
                (click)="insertDept()"
                [ngStyle]="{
                  cursor: !addDeptForm.valid
                    ? 'not-allowed'
                    : 'pointer'
                }"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="departmentEdit"
        tabindex="-1"
        role="dialog"
        aria-labelledby="departmentEditModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="departmentEditModalLongTitle"
              >
                Edit Department
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="editDeptForm">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Name"
                        formControlName="editDept"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                [disabled]="!editDeptForm.valid"
                (click)="updateDept()"
                [ngStyle]="{
                  cursor: !editDeptForm.valid
                    ? 'not-allowed'
                    : 'pointer'
                }"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="departmentChangeStatus"
        tabindex="-1"
        role="dialog"
        aria-labelledby="departmentChangeStatusModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="departmentChangeStatusModalLongTitle"
              >
                Change Status
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Status</label>
                      <div class="row" style="margin-top: 10px">
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              [(ngModel)]="defaultStatus"
                              id="active"
                              name="example1"
                              value="active"
                            />
                            <label
                              class="custom-control-label"
                              for="active"
                              >Active
                            </label>
                          </div>
                        </div>
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="inactive"
                              [(ngModel)]="defaultStatus"
                              name="example2"
                              value="inactive"
                            />
                            <label
                              class="custom-control-label"
                              for="inactive"
                              >Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
                (click)="changeStatusDept()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--model end-->
    </div>
  </div>
</app-admin-default-layout>