import { Component } from '@angular/core';
import { RecruiteeService } from 'src/app/services/recruitee.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-incentive-details',
  templateUrl: './incentive-details.component.html',
  styleUrls: ['./incentive-details.component.scss']
})
export class IncentiveDetailsComponent {

  myIncentive: any = ''
  myIncentiveDate: any = ''
  myIncentiveData: any = []
  myIncentiveDataAll: any = []
  user_id: any = ''
  user_type: any = ''
  total_incentive: any = 0

  constructor(public service: RecruiteeService, public route: ActivatedRoute, public router: Router) { }

  ngOnInit(){
    this.user_id = sessionStorage.getItem('user_id');
    this.user_type = sessionStorage.getItem('user_type');

    this.getIncentivesDate();
  }

  onOptionsSelected(val: any) {
    this.total_incentive = 0
    this.myIncentive = val
    this.myIncentiveData = this.myIncentiveDataAll.filter((el: any) => el.inc_file_id == val)

    this.myIncentiveData.forEach((el: any) => {
      this.total_incentive = parseFloat((parseFloat(this.total_incentive) + parseFloat(el.type_incentive_amt)).toFixed(2)).toString();
    });
    
  }

  getIncentivesDate() {
    this.service.getIncentivesDate(this.user_id).subscribe((res: any) => {
      if(res){
        this.myIncentiveDate = res.myIncentive;
        this.myIncentiveDataAll = res.allData;
      }
    });
  }
}
