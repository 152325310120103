<body id="bg">
      <div class="page-wraper">
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#4C96D7" type="ball-square-clockwise-spin">
    <p style="color: white">Loading...</p>
  </ngx-spinner>
            <!-- Content -->
            <div class="page-content bg-white">
                  <!-- contact area -->
                  <div class="content-block">
                        <!-- Browse Jobs -->
                        <div class="section-full bg-white p-t50 p-b20">
                              <div class="container">
                                    <div class="row">
                                          <div class="col-xl-2 col-lg-2 m-b30">
                                                <div class="sticky-top">
                                                      <div class="candidate-info company-info">
                                                            <div class="candidate-detail text-center">
                                                                  <div class="canditate-des">
                                                                        <a href="javascript:void(0);">
                                                                              <img alt=""
                                                                                    src="../assets/images/logo/logo.png" />
                                                                        </a>
                                                                  </div>
                                                                  <div class="candidate-title">
                                                                        <h4 class="m-b5">
                                                                              <a href="javascript:void(0);">
                                                                                    <!-- {{ 
                                                                                          currentRoute === 'manage-jobs' || currentRoute === 'post-jobs' ?
                                                                                          'Jobs'
                                                                                          : currentRoute === 'applicants' ?
                                                                                          'Applicants'
                                                                                          : currentRoute === 'healthcare-engagements' ?
                                                                                          'Engagements'
                                                                                          : currentRoute === 'payroll-processing' ?
                                                                                          'Finance'
                                                                                          : currentRoute === 'company' ?
                                                                                          'Setup'
                                                                                          : currentRoute === 'reminder' ?
                                                                                          'Reminder'
                                                                                          : ''
                                                                                    }} -->
                                                                                    Admin Panel
                                                                              </a>
                                                                        </h4>
                                                                  </div>
                                                            </div>
                                                            <ul>
                                                                  <li *ngFor="let module of moduleArray">
                                                                        <a *ngIf="module.innerNav && module.innerNav.length > 0"
                                                                              (click)="toggleInnerList(module.module_id)"
                                                                              class="{{ module_id == module.module_id ? 'active oldclass' : 'oldclass'}}"
                                                                              id="clsActive{{ module.module_id }}"
                                                                              style="text-decoration: none; border-bottom: 1px solid #e9e7e7;"
                                                                        >
                                                                              {{ module.module_name_lower }}
                                                                              <mat-icon class="text-2xl ml-1 md:ml-2 order-2 -mt-[6px]">
                                                                                    {{ module_id == module.module_id || selectedModule == module.module_id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                                                                              </mat-icon>
                                                                        </a>
                                                                        <a 
                                                                              *ngIf="module.innerNav && module.innerNav.length === 0"
                                                                              (click)="navigateTo(module)"
                                                                              class="{{ module_id == module.module_id ? 'active oldclass' : 'oldclass'}}"
                                                                              id="clsActive{{ module.module_id }}"
                                                                              style="text-decoration: none; padding: 15px 5px; border-bottom: 1px solid #e9e7e7;"
                                                                        >
                                                                              <i class="fa fa-briefcase" aria-hidden="true"></i> <span>{{ module.module_name_lower }}</span>
                                                                        </a>
                                                                        <ul *ngIf="module.innerNav && module.innerNav.length > 0 && selectedModule == module.module_id">
                                                                              <li *ngFor="let innerModule of module.innerNav">
                                                                                    <a 
                                                                                          (click)="navigateTo(innerModule)"
                                                                                          class="{{ module_id == module.module_id && currentRoute === innerModule.route.slice(1, innerModule.route.length) ? 'border-0 bg-white active oldclass' : 'oldclass'}}"
                                                                                          id="clsActive{{ innerModule.submodule_id }}"
                                                                                          style="text-decoration: none; padding: 12px 20px;"
                                                                                    >
                                                                                          <i *ngIf="module.module_name_lower == 'Jobs' || module.module_name_lower == 'My Jobs'" class="fa fa-briefcase" aria-hidden="true"></i>
                                                                                          <i *ngIf="module.module_name_lower == 'Applicants' || module.module_name_lower == 'Finance'" class="fa fa-id-card-o" aria-hidden="true"></i>
                                                                                          <i *ngIf="module.module_name_lower == 'Engagements' || module.module_name_lower == 'Setup'" class="fa fa-cog" aria-hidden="true"></i>
                                                                                          <span>{{ innerModule.submodule_name_lower }}</span>
                                                                                    </a>
                                                                              </li>
                                                                        </ul>
                                                                  </li>
                                                                  <li>
                                                                        <a
                                                                              (click)="toggleInnerList('reminder')"
                                                                              class="{{ ['history-alert', 'client-alert', 'reminders'].includes(currentRoute) ? 'active oldclass' : 'oldclass'}}"
                                                                              id="clsActiveReminder"
                                                                              style="text-decoration: none; border-bottom: 1px solid #e9e7e7;"
                                                                        >
                                                                              Reminder
                                                                              <mat-icon class="text-2xl ml-1 md:ml-2 order-2 -mt-[6px]">
                                                                                    {{ ['history-alert', 'client-alert', 'reminders'].includes(currentRoute) || selectedModule == 'reminder' ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                                                                              </mat-icon>
                                                                        </a>
                                                                        <ul *ngIf="['history-alert', 'client-alert', 'reminders'].includes(currentRoute) || selectedModule == 'reminder'">
                                                                              <li> 
                                                                                    <a 
                                                                                          (click)="navigateTo('reminders')"
                                                                                          class="{{ currentRoute === 'reminders' ? 'border-0 bg-white active oldclass' : 'oldclass'}}"
                                                                                          style="text-decoration: none;"
                                                                                    >
                                                                                          <i class="fa fa-bell-o" aria-hidden="true" ></i>
                                                                                          <span>Company Alert</span>
                                                                                    </a>
                                                                              </li>
                                                                              <li> 
                                                                                    <a 
                                                                                          (click)="navigateTo('client-alert')"
                                                                                          class="{{ currentRoute === 'client-alert' ? 'border-0 bg-white active oldclass' : 'oldclass'}}"
                                                                                          style="text-decoration: none;"
                                                                                    >
                                                                                          <i class="fa fa-bell-o" aria-hidden="true"></i>
                                                                                          <span>Client Alert</span>
                                                                                    </a>
                                                                              </li>
                                                                              <li> 
                                                                                    <a 
                                                                                          (click)="navigateTo('history-alert')"
                                                                                          class="{{ currentRoute === 'history-alert' ? 'border-0 bg-white active oldclass' : 'oldclass'}}"
                                                                                          style="text-decoration: none;"
                                                                                    >
                                                                                          <i class="fa fa-bell-o" aria-hidden="true"></i>
                                                                                          <span>History of Alert</span>
                                                                                    </a>
                                                                              </li>
                                                                        </ul>
                                                                  </li>
                                                                  <li>
                                                                        <a
                                                                              (click)="toggleInnerList('incentive')"
                                                                              class="{{ ['incentive-details'].includes(currentRoute) ? 'active oldclass' : 'oldclass'}}"
                                                                              id="clsActiveIncentiveDetails"
                                                                              style="text-decoration: none; border-bottom: 1px solid #e9e7e7;"
                                                                        >
                                                                              Incentive Details
                                                                              <mat-icon class="text-2xl ml-1 md:ml-2 order-2 -mt-[6px]">
                                                                                    {{ ['incentive-details'].includes(currentRoute) || selectedModule == 'incentive' ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                                                                              </mat-icon>
                                                                        </a>
                                                                        <ul *ngIf="['incentive-details'].includes(currentRoute) || selectedModule == 'incentive'">
                                                                              <li> 
                                                                                    <a 
                                                                                          (click)="navigateTo('incentive-details')"
                                                                                          class="{{ currentRoute === 'incentive-details' ? 'border-0 bg-white active oldclass' : 'oldclass'}}"
                                                                                          style="text-decoration: none;"
                                                                                    >
                                                                                          <i class="fa fa-bell-o" aria-hidden="true" ></i>
                                                                                          <span>My Incentives</span>
                                                                                    </a>
                                                                              </li>
                                                                        </ul>
                                                                  </li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                          <ng-content></ng-content>
                                          <!-- Content of the child component will be projected here -->
                                    </div>
                              </div>
                        </div>
                        <!-- Browse Jobs END -->
                  </div>
            </div>
            <!-- Content END-->
            <button class="scroltop fa fa-chevron-up"></button>
      </div>
</body>