<app-page-header
  heading="About Us"
  subheading="Experience the difference and participate with us in serving the
community"
></app-page-header>
<div class="relative overflow-hidden bg-white">
  <div
    class="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]"
  >
    <div class="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
      <svg
        class="absolute top-12 left-full translate-x-32 transform"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
      >
        <defs>
          <pattern
            id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="384"
          fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
        />
      </svg>
      <svg
        class="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
      >
        <defs>
          <pattern
            id="f210dbf6-a58d-4871-961e-36d5016a0f49"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="384"
          fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
        />
      </svg>
      <svg
        class="absolute bottom-12 left-full translate-x-32 transform"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
      >
        <defs>
          <pattern
            id="d3eb07ae-5182-43e6-857d-35c643af9034"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="384"
          fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
        />
      </svg>
    </div>
  </div>
  <section class="section-container">
    <div class="mx-auto max-w-prose text-lg">
      <h1 class="typog_h2 text-gray-900">Vish Consulting Services</h1>
      <p class="mt-2 md:mt-8 text-lg md:text-xl leading-8 text-gray-600">
        A leading provider in Recruitment and Staffing Services
      </p>
    </div>
    <div class="prose prose-lg prose-indigo mx-auto mt-6 text-gray-600">
      <p>
        Since 2011 Vish Consulting Services, Inc has been providing staffing
        services to medical providers/facilities, Industrial, Administrative and
        Information Technology Clients, across the globe, in need of qualified
        resources.
      </p>
      <figure class="py-2 md:py-10">
        <img
          class="w-full rounded-lg"
          src="../../assets/images/main/Nursing-jobs.webp"
          alt=""
          width="651"
          height="339"
          loading="lazy"
        />
      </figure>
      <p>
        As a nursing agency, the agency successfully places qualified staff in
        hospitals, nursing homes, ambulatory care, correctional facilities,
        group homes, schools and other specialties.
      </p>
      <p>
        Vish Consulting Services is committed to providing quality services,
        with appropriately credentialed and qualified staff in all clinical
        specialties (i.e., ICU, ER, Med/surg, Behavioral Health, OR, Telemetry,
        PACU, Pediatrics, Long Term Care, Corrections etc.).
      </p>
      <p>
        We offer a variety of highly sought-after positions for RNs, LPN’s,
        CNA’s, Technician’s, Technologist’s, Therapist’s and other healthcare
        workers in some of the most prestigious health care systems across the
        United States.
      </p>
      <p>
        As an IT staffing and Recruitment Agency, the agency successfully
        provides qualified staff with diverse technology/skills to clients in
        many domains like healthcare, Pharma, Life Science, Banking, Cloud
        services, Infrastructure, security etc.
      </p>
      <p>
        Vish Consulting Services is committed to providing quality services,
        with well screened IT resources motivated to make a positive change in
        IT systems. We offer a variety of highly sought after positions for BA,
        QA, Testing, Developers, Architects, Project Manager across the United
        States.
      </p>
      <p>
        As a top commercial staffing provider, Vish Consulting is dedicated to
        sourcing top talent for light industrial, production, manufacturing and
        administrative positions. Our professional recruiters undergo a
        meticulous and well-designed training program around sourcing and
        selection, meeting your requirements, to properly deliver the right
        candidates in a timely manner. Whether you are looking to build or scale
        up your production or add to your administrative staff, we want you to
        feel confident that we will find dependable and safety-conscious
        professionals quickly. Whether it’s a short term project or long term
        position we aim to find the best fit for your organization.
      </p>
      <p>
        At Vish Consulting Services, Inc we respect and value individual
        contributions and are fully supportive of each person’s professional
        goals. We invite you to experience the difference and participate with
        us in serving the community.
      </p>
      <p>
        Vish Consulting Services, Inc. commitment towards inclusion across race,
        gender, age, religion, identity, and experiences drive us forward every
        day. It fuels our innovation and connects us closer to our clients and
        the communities we serve.
      </p>
    </div>
  </section>
</div>
<app-cta></app-cta>
