import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Country, State, City } from 'country-state-city';
import { AdminService } from 'src/app/services/admin.service';
import { EngagementServiceService } from "./non-it-engagement-service.service";
import Swal from 'sweetalert2';
import { IDayCalendarConfig } from 'ng2-date-picker';
import * as XLSX from 'xlsx';
import { StoreDataService } from 'src/app/services/store-data.service';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-non-it-engagement',
  templateUrl: './non-it-engagement.component.html',
  styleUrls: ['../engagement.component.scss']
})
export class NonITEngagementComponent implements OnInit {
  data: any;
  moduleArray: any = [];
  engagementDataListMain: any = [];
  weekList :any =[];

  get apiUrl(): string {
    return environment.apiUrl;
  }
  
  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */

  addEngagementsList:  UntypedFormGroup;
  searchEngagements:  UntypedFormGroup;
  editEngagementsList:  UntypedFormGroup;

  datePickerConfig = <IDayCalendarConfig>{
    drops: 'down',
    weekDayFormat: 'dd',
    dayBtnFormat: 'D',
    format: 'MM/DD/YYYY'
  }
  states: any = [];
  cities: any = [];

  country = "US";
  allClients: any; 
  selectedStatus: string = '';
  allshift = ['Day','Evening','Night'];
  closeReason = ['Positive','Neutral','Negative'];

  statusEng = ['Open','Closed'];
  healthcareEnagementData : any = [];
  filterArray: any = [];

  search_data: any;
  open: any = '';

  eng_status: any = 'ALL';
  clients: any = 'ALL';
  prefered_state: any = 'ALL';
  prefered_city: any = 'ALL';
  fileName= 'healthcareSheet.xlsx';

  user_type: any;
  user_id:any;

  editGethealthCareHistory : any = [];
  viewhealthCareHistoryOld : any =[];
  viewhealthCareHistoryNew : any =[];
  

  state = new UntypedFormControl({ value: null, disabled: true }, [
    Validators.required,
  ]);
  city = new UntypedFormControl({ value: null, disabled: true }, [
    Validators.required,
  ]);

  // New UserList Property
  header = new HttpHeaders().set('Authorization', `Bearear ${sessionStorage.getItem('user_token')}`);
  userList = [];
  selectedUser: number | undefined;
 
  constructor(public service: EngagementServiceService,
     public route: ActivatedRoute,
     public fb: UntypedFormBuilder,
     public storeData: StoreDataService,
     public router: Router, 
     private httpnew: HttpClient,
     public http: AdminService) {

      this.user_type = sessionStorage.getItem('user_type');
      this.user_id = sessionStorage.getItem('user_id');

    //  this.countries = this.service.getCountries();

      this.searchEngagements = new UntypedFormGroup({
        eng_status: new UntypedFormControl(),
        client_id: new UntypedFormControl(),
        state: this.state,
        city: this.city,
      });

      this.addEngagementsList = new UntypedFormGroup({
        eng_statusadd: new UntypedFormControl(),
        startDate: new UntypedFormControl(null, [Validators.required]),
        endDate : new UntypedFormControl(null, [Validators.required]),
        emp_name : new UntypedFormControl(null, [Validators.required,Validators.maxLength(80)]),
        notes : new UntypedFormControl(null, [Validators.maxLength(200)]),
        emp_email : new UntypedFormControl(null, [
          Validators.required,
          Validators.maxLength(80),
          Validators.email,
          Validators.maxLength(40),
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'),
        ]),
        eng_phone : new UntypedFormControl(),
        state: this.state,
        city: this.city,
        eng_client: new UntypedFormControl(),
        eng_facility: new UntypedFormControl(),
        eng_role: new UntypedFormControl(),
        eng_shift: new UntypedFormControl(),
        eng_otstart: new UntypedFormControl(),

        eng_billrateReg_day: new UntypedFormControl(),
        eng_payrateReg_day: new UntypedFormControl(),
        eng_billrateOt_day: new UntypedFormControl(),
        eng_payrateOt_day: new UntypedFormControl(),
        eng_billrateDt_day: new UntypedFormControl(),
        eng_payrateDt_day: new UntypedFormControl(),

        eng_billrateReg_evn: new UntypedFormControl(),
        eng_payrateReg_evn: new UntypedFormControl(),
        eng_billrateOt_evn: new UntypedFormControl(),
        eng_payrateOt_evn: new UntypedFormControl(),
        eng_billrateDt_evn: new UntypedFormControl(),
        eng_payrateDt_evn: new UntypedFormControl(),

        eng_billrateReg_ngt: new UntypedFormControl(),
        eng_payrateReg_ngt: new UntypedFormControl(),
        eng_billrateOt_ngt: new UntypedFormControl(),
        eng_payrateOt_ngt: new UntypedFormControl(),
        eng_billrateDt_ngt: new UntypedFormControl(),
        eng_payrateDt_ngt: new UntypedFormControl(),

        nonTaxable_weekly: new UntypedFormControl(),
        nonTaxable_1timeOnly: new UntypedFormControl(),
        closed_reason: new UntypedFormControl(),

        eng_recruiter: new UntypedFormControl(),
        eng_rlead: new UntypedFormControl(),
        eng_rmanager: new UntypedFormControl(),
        eng_director: new UntypedFormControl(),
        eng_onboarding: new UntypedFormControl(),
      });

      this.editEngagementsList = new UntypedFormGroup({
        edit_eng_statusadd: new UntypedFormControl(),
        edit_startDate: new UntypedFormControl(null, [Validators.required]),
        edit_endDate : new UntypedFormControl(null, [Validators.required]),
        edit_emp_name : new UntypedFormControl(null, [Validators.required,Validators.maxLength(80)]),
        edit_notes : new UntypedFormControl(null, [Validators.maxLength(200)]),
        edit_emp_email : new UntypedFormControl(null, [
          Validators.required,
          Validators.maxLength(80),
          Validators.email,
          Validators.maxLength(40),
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'),
        ]),
        edit_eng_phone : new UntypedFormControl(),
        edit_state: this.state,
        edit_city: this.city,
        edit_eng_client: new UntypedFormControl(),
        edit_eng_facility: new UntypedFormControl(),
        edit_eng_role: new UntypedFormControl(),
        edit_eng_shift: new UntypedFormControl(),
        edit_eng_otstart: new UntypedFormControl(),
        
        edit_eng_billrateReg_day: new UntypedFormControl(),
        edit_eng_payrateReg_day: new UntypedFormControl(),
        edit_eng_billrateOt_day: new UntypedFormControl(),
        edit_eng_payrateOt_day: new UntypedFormControl(),
        edit_eng_billrateDt_day: new UntypedFormControl(),
        edit_eng_payrateDt_day: new UntypedFormControl(),

        edit_eng_billrateReg_evn: new UntypedFormControl(),
        edit_eng_payrateReg_evn: new UntypedFormControl(),
        edit_eng_billrateOt_evn: new UntypedFormControl(),
        edit_eng_payrateOt_evn: new UntypedFormControl(),
        edit_eng_billrateDt_evn: new UntypedFormControl(),
        edit_eng_payrateDt_evn: new UntypedFormControl(),

        edit_eng_billrateReg_ngt: new UntypedFormControl(),
        edit_eng_payrateReg_ngt: new UntypedFormControl(),
        edit_eng_billrateOt_ngt: new UntypedFormControl(),
        edit_eng_payrateOt_ngt: new UntypedFormControl(),
        edit_eng_billrateDt_ngt: new UntypedFormControl(),
        edit_eng_payrateDt_ngt: new UntypedFormControl(),

        edit_nonTaxable_weekly: new UntypedFormControl(),
        edit_nonTaxable_1timeOnly: new UntypedFormControl(),
        edit_closed_reason: new UntypedFormControl(),
        edit_id: new UntypedFormControl(),

        edit_eng_recruiter: new UntypedFormControl(),
        edit_eng_rlead: new UntypedFormControl(),
        edit_eng_rmanager: new UntypedFormControl(),
        edit_eng_director: new UntypedFormControl(),
        edit_eng_onboarding: new UntypedFormControl(),
      });

  }
  
 
  ngOnInit() {
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900)
    this.state.enable();

    this.states = State.getStatesOfCountry("US");

    this.state.valueChanges.subscribe((state) => {
      this.city.reset();
      this.city.disable();
      if (state) {
        this.cities = City.getCitiesOfState('US', state);
        this.city.enable();
      }
    });
    this.getClientDetailsAll();
    this.getHealthcareEnagementList();
    this.getUsersList();
   
  } 

  editHealthEngagementId(engagement_id,formId){
    this.service.getEditHistoryAllData(engagement_id,formId).subscribe((r) => {
      if (r["error"] == false) {
        this.editGethealthCareHistory = r['results']['data'];
      }else{
        this.editGethealthCareHistory = [];
      }
    });
  }

  viewHealthCareHistor(id){
    this.service.getEditHistoryDataById(id).subscribe((r) => {
      if (r["error"] == false) {
        this.viewhealthCareHistoryOld = JSON.parse(r['results']['data'][0]['old_values']);
        this.viewhealthCareHistoryNew = JSON.parse(r['results']['data'][0]['new_values']);
      }else{
        this.viewhealthCareHistoryOld = [];
        this.viewhealthCareHistoryNew = [];
      }
    });

  }

  exportexcel(): void {
    try {
      // Get the table element by ID
      let element = document.getElementById('excel-table');
      
      // Clone the table to avoid modifying the original
      const clonedTable = element.cloneNode(true) as HTMLTableElement;
      
      // Find the index of the "Action" header
      let actionHeaderIndex = -1;
      const thElements = clonedTable.querySelectorAll('th');
      thElements.forEach((th, index) => {
        if (th.textContent === 'Action') {
          actionHeaderIndex = index;
        }
      });
  
      // If the "Action" header is found, remove it from each row
      if (actionHeaderIndex !== -1) {
        const rows = clonedTable.querySelectorAll('tr');
        rows.forEach(row => {
          row.deleteCell(actionHeaderIndex);
        });
      }
      
      // Convert the modified table to a worksheet
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(clonedTable);
      
      // Create a new workbook and add the worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
      // Specify the file name
      this.fileName = 'Non-IT-Engagement.xlsx';  
      
      // Save the workbook to a file
      XLSX.writeFile(wb, this.fileName);
      
    } catch (error) {
      console.error('Export failed:', error);
    }
  }
  get searchData() {
    return this.search_data;
  }

  set searchData(value) { 
    this.search_data = value;
    this.healthcareEnagementData = this.search_data
      ? this.filterList(this.search_data)
      : this.filterArray;
  }

  filterList(filterby) {
    filterby = filterby.toLocaleLowerCase();
    return this.filterArray.filter(
      (list: any) =>
        list.emp_name.toLocaleLowerCase().indexOf(filterby) !== -1
    );
  }
  searchEngagement() {
    this.healthcareEnagementData = [];
    this.http.spinnerShow();
    let selectedState = this.states.filter(el => el.isoCode == this.searchEngagements.controls['state'].value)

    let data = {
      eng_status: this.searchEngagements.controls['eng_status'].value ?? "All",
      clients: this.searchEngagements.controls['client_id'].value ?? "ALL",
      prefered_state: selectedState && selectedState.length > 0 ? selectedState[0].name : "ALL",
      prefered_city: this.searchEngagements.controls['city'].value ?? "ALL",
      form_type: 3,
    };
    this.service.enggagementResults(data).subscribe(
      (res: any) => {
        if (res['message'] === 'OK') {
          this.healthcareEnagementData = res['results']["data"];
          this.filterArray = res['results']["data"];
          this.http.spinnerHide();
        } else {
          this.http.spinnerHide();
          Swal.fire({
            title: 'No search result found!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      },
      (err) => {
        this.http.spinnerHide();
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    );
  }


  selectChangeHandler (event) {
    this.selectedStatus = event.target.value;
  }


  async addEngagement() {
    let addSelectedState = this.states.filter(el => el.isoCode == this.addEngagementsList.controls['state'].value)
    
    let recruiter = this.addEngagementsList.controls['eng_recruiter'].value
    let rLead = this.addEngagementsList.controls['eng_rlead'].value
    let rManager = this.addEngagementsList.controls['eng_rmanager'].value
    let director = this.addEngagementsList.controls['eng_director'].value
    let onboarding = this.addEngagementsList.controls['eng_onboarding'].value


    let recruiterName: string, rLeadName: string, rManagerName: string, directorName: string, onboardingName: string ;
    await this.userList.map(itm => {
      if(itm.user_id == recruiter) {
        recruiterName = itm.EmployeeName
      }
      if(itm.user_id == rLead) rLeadName = itm.EmployeeName
      if(itm.user_id == rManager) rManagerName = itm.EmployeeName
      if(itm.user_id == director) directorName = itm.EmployeeName
      if(itm.user_id == onboarding) onboardingName = itm.EmployeeName
    })
    
    const data = {
      eng_statusadd: "Open",
      startDate: this.addEngagementsList.controls['startDate'].value,
      endDate: this.addEngagementsList.controls['endDate'].value,
      emp_name: this.addEngagementsList.controls['emp_name'].value,
      emp_email: this.addEngagementsList.controls['emp_email'].value,
      eng_phone: this.addEngagementsList.controls['eng_phone'].value,
      state: addSelectedState && addSelectedState.length > 0 ? addSelectedState[0].name : null,
      city: this.addEngagementsList.controls['city'].value,
      eng_client: this.addEngagementsList.controls['eng_client'].value,
      eng_facility: this.addEngagementsList.controls['eng_facility'].value,
      eng_role: this.addEngagementsList.controls['eng_role'].value,
      eng_shift: this.addEngagementsList.controls['eng_shift'].value,
      eng_otstart: this.addEngagementsList.controls['eng_otstart'].value,

   
      eng_billrateReg_day: this.addEngagementsList.controls['eng_billrateReg_day'].value,
      eng_payrateReg_day: this.addEngagementsList.controls['eng_payrateReg_day'].value,
      eng_billrateOt_day: this.addEngagementsList.controls['eng_billrateOt_day'].value,
      eng_payrateOt_day: this.addEngagementsList.controls['eng_payrateOt_day'].value,
      eng_billrateDt_day: this.addEngagementsList.controls['eng_billrateDt_day'].value,
      eng_payrateDt_day: this.addEngagementsList.controls['eng_payrateDt_day'].value,

      eng_billrateReg_evn: this.addEngagementsList.controls['eng_billrateReg_evn'].value,
      eng_payrateReg_evn: this.addEngagementsList.controls['eng_payrateReg_evn'].value,
      eng_billrateOt_evn: this.addEngagementsList.controls['eng_billrateOt_evn'].value,
      eng_payrateOt_evn: this.addEngagementsList.controls['eng_payrateOt_evn'].value,
      eng_billrateDt_evn: this.addEngagementsList.controls['eng_billrateDt_evn'].value,
      eng_payrateDt_evn: this.addEngagementsList.controls['eng_payrateDt_evn'].value,

      eng_billrateReg_ngt: this.addEngagementsList.controls['eng_billrateReg_ngt'].value,
      eng_payrateReg_ngt: this.addEngagementsList.controls['eng_payrateReg_ngt'].value,
      eng_billrateOt_ngt: this.addEngagementsList.controls['eng_billrateOt_ngt'].value,
      eng_payrateOt_ngt:  this.addEngagementsList.controls['eng_payrateOt_ngt'].value,
      eng_billrateDt_ngt: this.addEngagementsList.controls['eng_billrateDt_ngt'].value,
      eng_payrateDt_ngt:  this.addEngagementsList.controls['eng_payrateDt_ngt'].value,

      eng_recruiter_id: this.addEngagementsList.controls['eng_recruiter'].value,
      eng_rlead_id: this.addEngagementsList.controls['eng_rlead'].value,
      eng_rmanager_id: this.addEngagementsList.controls['eng_rmanager'].value,
      eng_director_id: this.addEngagementsList.controls['eng_director'].value,
      eng_onboarding_id: this.addEngagementsList.controls['eng_onboarding'].value,

      eng_recruiter: recruiterName || '',
      eng_rlead: rLeadName || '',
      eng_rmanager: rManagerName || '',
      eng_director: directorName || '',
      eng_onboarding: onboardingName || '',

      nonTaxable_weekly:  this.addEngagementsList.controls['nonTaxable_weekly'].value,
      nonTaxable_1timeOnly:  this.addEngagementsList.controls['nonTaxable_1timeOnly'].value,
      closed_reason: this.addEngagementsList.controls['closed_reason'].value,
      
      notes: this.addEngagementsList.controls['notes'].value,
    };

    this.service.addApiEngagement(data).subscribe((r) => {
      if (r['message'] === 'OK') {
        Swal.fire({
          title: r['results']['data'],
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addEngagementsList.reset();
            this.getHealthcareEnagementList();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  getHealthcareEnagementList() {
    this.service.getHealthcareEnagement().subscribe((r) => {
      this.healthcareEnagementData = r['results']['data'];
      this.filterArray = r['results']["data"];
    });
  }

  getClientDetailsAll() {
    this.service.getClientsDetails().subscribe((r) => {
      this.allClients = r;
    });
  }

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  setEngagementId(val){ 
    let editSelectedState = this.states.filter(el => el.name == val.work_state)
        
    this.editEngagementsList.setValue({
      edit_eng_statusadd: val.status,
      edit_startDate: val.start_date,
      edit_endDate: val.end_date,
      edit_emp_name: val.emp_name,
      edit_emp_email: val.email,
      edit_eng_phone: val.phone,
      edit_notes: val.notes ?? '',
      // edit_state: val.work_state,
      edit_state: editSelectedState && editSelectedState.length > 0 ? editSelectedState[0].isoCode : null,
      edit_city: val.city,
      edit_eng_client: val.client,
      edit_eng_facility: val.facility,
      edit_eng_role: val.role,
      edit_eng_shift: val.shift,
      edit_eng_otstart: val.ot_starts_after,

      edit_eng_billrateReg_day: val.eng_billrateReg_day,
      edit_eng_payrateReg_day: val.eng_payrateReg_day,
      edit_eng_billrateOt_day: val.eng_billrateOt_day,
      edit_eng_payrateOt_day: val.eng_payrateOt_day,
      edit_eng_billrateDt_day: val.eng_billrateDt_day,
      edit_eng_payrateDt_day: val.eng_payrateDt_day,

      edit_eng_billrateReg_evn: val.eng_billrateReg_evn,
      edit_eng_payrateReg_evn: val.eng_payrateReg_evn,
      edit_eng_billrateOt_evn: val.eng_billrateOt_evn,
      edit_eng_payrateOt_evn: val.eng_payrateOt_evn,
      edit_eng_billrateDt_evn: val.eng_billrateDt_evn,
      edit_eng_payrateDt_evn: val.eng_payrateDt_evn,

      edit_eng_billrateReg_ngt: val.eng_billrateReg_ngt,
      edit_eng_payrateReg_ngt: val.eng_payrateReg_ngt,
      edit_eng_billrateOt_ngt: val.eng_billrateOt_ngt,
      edit_eng_payrateOt_ngt:  val.eng_payrateOt_ngt,
      edit_eng_billrateDt_ngt: val.eng_billrateDt_ngt,
      edit_eng_payrateDt_ngt:  val.eng_payrateDt_ngt,

      edit_nonTaxable_weekly: val.nonTaxable_weekly,
      edit_nonTaxable_1timeOnly: val.nonTaxable_1timeOnly,

      edit_eng_recruiter: val.eng_recruiter_id ,
      edit_eng_rlead: val.eng_rlead_id ,
      edit_eng_rmanager: val.eng_rmanager_id,
      edit_eng_director: val.eng_director_id,
      edit_eng_onboarding: val.eng_onboarding_id,

      edit_closed_reason: val.closed_reason,
      edit_id: val.id,
    });

  }

  async updateEngagement() {
    let editSelectedState = this.states.filter(el => el.isoCode == this.editEngagementsList.controls['edit_state'].value)

    let recruiter = this.editEngagementsList.controls['edit_eng_recruiter'].value
    let rLead = this.editEngagementsList.controls['edit_eng_rlead'].value
    let rManager = this.editEngagementsList.controls['edit_eng_rmanager'].value
    let director = this.editEngagementsList.controls['edit_eng_director'].value
    let onboarding = this.editEngagementsList.controls['edit_eng_onboarding'].value


    let recruiterName: string, rLeadName: string, rManagerName: string, directorName: string, onboardingName: string ;
    await this.userList.map(itm => {
      if(itm.user_id == recruiter) {
        recruiterName = itm.EmployeeName
      }
      if(itm.user_id == rLead) rLeadName = itm.EmployeeName
      if(itm.user_id == rManager) rManagerName = itm.EmployeeName
      if(itm.user_id == director) directorName = itm.EmployeeName
      if(itm.user_id == onboarding) onboardingName = itm.EmployeeName
    })

    const data = {
      id : this.editEngagementsList.controls['edit_id'].value,
      eng_statusadd: this.editEngagementsList.controls['edit_eng_statusadd'].value,
      startDate: this.editEngagementsList.controls['edit_startDate'].value,
      endDate: this.editEngagementsList.controls['edit_endDate'].value,
      emp_name: this.editEngagementsList.controls['edit_emp_name'].value,
      emp_email: this.editEngagementsList.controls['edit_emp_email'].value,
      eng_phone: this.editEngagementsList.controls['edit_eng_phone'].value,
      // state: this.editEngagementsList.controls['edit_state'].value,
      state: editSelectedState && editSelectedState.length > 0 ? editSelectedState[0].name : null,
      city: this.editEngagementsList.controls['edit_city'].value,
      eng_client: this.editEngagementsList.controls['edit_eng_client'].value,
      eng_facility: this.editEngagementsList.controls['edit_eng_facility'].value,
      eng_role: this.editEngagementsList.controls['edit_eng_role'].value,
      eng_shift: this.editEngagementsList.controls['edit_eng_shift'].value,
      eng_otstart: this.editEngagementsList.controls['edit_eng_otstart'].value,

      eng_billrateReg_day: this.editEngagementsList.controls['edit_eng_billrateReg_day'].value,
      eng_payrateReg_day: this.editEngagementsList.controls['edit_eng_payrateReg_day'].value,
      eng_billrateOt_day: this.editEngagementsList.controls['edit_eng_billrateOt_day'].value,
      eng_payrateOt_day: this.editEngagementsList.controls['edit_eng_payrateOt_day'].value,
      eng_billrateDt_day: this.editEngagementsList.controls['edit_eng_billrateDt_day'].value,
      eng_payrateDt_day: this.editEngagementsList.controls['edit_eng_payrateDt_day'].value,

      eng_billrateReg_evn: this.editEngagementsList.controls['edit_eng_billrateReg_evn'].value,
      eng_payrateReg_evn: this.editEngagementsList.controls['edit_eng_payrateReg_evn'].value,
      eng_billrateOt_evn: this.editEngagementsList.controls['edit_eng_billrateOt_evn'].value,
      eng_payrateOt_evn: this.editEngagementsList.controls['edit_eng_payrateOt_evn'].value,
      eng_billrateDt_evn: this.editEngagementsList.controls['edit_eng_billrateDt_evn'].value,
      eng_payrateDt_evn: this.editEngagementsList.controls['edit_eng_payrateDt_evn'].value,

      eng_billrateReg_ngt: this.editEngagementsList.controls['edit_eng_billrateReg_ngt'].value,
      eng_payrateReg_ngt: this.editEngagementsList.controls['edit_eng_payrateReg_ngt'].value,
      eng_billrateOt_ngt: this.editEngagementsList.controls['edit_eng_billrateOt_ngt'].value,
      eng_payrateOt_ngt:  this.editEngagementsList.controls['edit_eng_payrateOt_ngt'].value,
      eng_billrateDt_ngt: this.editEngagementsList.controls['edit_eng_billrateDt_ngt'].value,
      eng_payrateDt_ngt:  this.editEngagementsList.controls['edit_eng_payrateDt_ngt'].value,

      eng_recruiter_id: this.editEngagementsList.controls['edit_eng_recruiter'].value,
      eng_rlead_id: this.editEngagementsList.controls['edit_eng_rlead'].value,
      eng_rmanager_id: this.editEngagementsList.controls['edit_eng_rmanager'].value,
      eng_director_id: this.editEngagementsList.controls['edit_eng_director'].value,
      eng_onboarding_id: this.editEngagementsList.controls['edit_eng_onboarding'].value,

      eng_recruiter: recruiterName || '',
      eng_rlead: rLeadName || '',
      eng_rmanager: rManagerName || '',
      eng_director: directorName || '',
      eng_onboarding: onboardingName || '',

      nonTaxable_weekly:  this.editEngagementsList.controls['edit_nonTaxable_weekly'].value,
      nonTaxable_1timeOnly:  this.editEngagementsList.controls['edit_nonTaxable_1timeOnly'].value,
      closed_reason: this.editEngagementsList.controls['edit_closed_reason'].value,
      edit_userId: this.user_id,
      form_id: 3,
      
      notes: this.editEngagementsList.controls['edit_notes'].value,
    };

    this.service.updateApiEngagement(data).subscribe((r) => {
      if (r['message'] === 'OK') {
        Swal.fire({
          title: 'Non IT Engagement updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editEngagementsList.reset();
            this.getHealthcareEnagementList();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  deleteEngagement(val) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          id: val.id
        }
        this.service.deleteApiEngagement(data).subscribe(res => {
          if (res["message"] === 'OK') {
            Swal.fire({
              title: 'Non IT Engagement deteted successfully.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.getHealthcareEnagementList();
              }
            });
            
          }
          else {
            Swal.fire({
              title: 'Something went wrong,please try again.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
          }
        }, err => {
          Swal.fire({
            title: 'Something went wrong,please try again.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        })
      }
    });
  }

  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
    let data2 = {
      eng_status: this.eng_status,
      clients: this.clients,
      prefered_state: this.prefered_state,
      prefered_city: this.prefered_city,
    };
    this.storeData.changeData2(data2);
  }
  /////////////////////////

  // Fetching users data 
  getUsersList() {
    this.httpnew
      .get<any>(this.apiUrl+'vcsapi/get/all/employee/details', { headers: this.header })
      .subscribe(response => {
        this.userList = response.map((o: { search_label: string; EmployeeName: any;}) => {
          o.search_label = `${o.EmployeeName}`;
          return o
        });
      }, error => {
      });
  }

}

