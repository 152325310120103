import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component'

// app-routing.module.ts
const routeConfig = {
  AddApplicantAdminComponent: () => import('./components/admin-portal/applicants/add-applicant-admin/add-applicant-admin.module').then(m => m.AddApplicantAdminRoutingModule),
  AppSummaryComponent: () => import('./components/admin-portal/applicants/app-summary/app-summary.module').then(m => m.AppSummaryRoutingModule),
  ApplicantsDetailsComponent: () => import('./components/admin-portal/applicants/applicants-details/applicants-details.module').then(m => m.ApplicantsDetailsRoutingModule),
  ApplicantsComponent: () => import('./components/admin-portal/applicants/applicants/applicants.module').then(m => m.ApplicantsRoutingModule),
  AssignManagerComponent: () => import('./components/admin-portal/applicants/assign-manager/assign-manager.module').then(m => m.AssignManagerRoutingModule),
  HiredApplicantComponent: () => import('./components/admin-portal/applicants/hired-applicant/hired-applicant.module').then(m => m.HiredApplicantRoutingModule),
  JobApplicationAdminComponent: () => import('./components/admin-portal/applicants/job-application-admin/job-application-admin.module').then(m => m.JobApplicationAdminRoutingModule),
  OnboardingAndHiringComponent: () => import('./components/admin-portal/applicants/onboarding-and-hiring/onboarding-and-hiring.module').then(m => m.OnboardingAndHiringRoutingModule),
  IncentivesComponent: () => import('./components/admin-portal/finance/incentives/incentives.module').then(m => m.IncentivesRoutingModule),
  PayrollApprovalComponent: () => import('./components/admin-portal/finance/payroll-approval/payroll-approval.module').then(m => m.PayrollApprovalRoutingModule),
  PayrollInvoiceComponent: () => import('./components/admin-portal/finance/payroll-invoice/payroll-invoice.module').then(m => m.PayrollInvoiceRoutingModule),
  PayrollProcessingComponent: () => import('./components/admin-portal/finance/payroll-processing/payroll-processing.module').then(m => m.PayrollProcessingRoutingModule),
  ClientComponent: () => import('./components/admin-portal/setup/client/client.module').then(m => m.ClientRoutingModule),
  CompanyComponent: () => import('./components/admin-portal/setup/company/company.module').then(m => m.CompanyRoutingModule),
  DepartmentComponent: () => import('./components/admin-portal/setup/department/department.module').then(m => m.DepartmentRoutingModule),
  DropdownListComponent: () => import('./components/admin-portal/setup/dropdown-list/dropdown-list.module').then(m => m.DropdownListRoutingModule),
  EmployeeComponent: () => import('./components/admin-portal/setup/employee/employee.module').then(m => m.EmployeeRoutingModule),
  UserRoleComponent: () => import('./components/admin-portal/setup/user-role/user-role.module').then(m => m.UserRoleRoutingModule),
  AppliedJobsComponent: () => import('./components/candidate-portal/applied-jobs/applied-jobs.module').then(m => m.AppliedJobsRoutingModule),
  ApplyJobGuestComponent: () => import('./components/candidate-portal/apply-job-guest/apply-job-guest.module').then(m => m.ApplyJobGuestRoutingModule),
  AssignmentHistoryComponent: () => import('./components/candidate-portal/assignment-history/assignment-history.module').then(m => m.AssignmentHistoryRoutingModule),
  CandidateProfileComponent: () => import('./components/candidate-portal/candidate-profile/candidate-profile.module').then(m => m.CandidateProfileRoutingModule),
  ChangePasscodeComponent: () => import('./components/candidate-portal/change-passcode/change-passcode.module').then(m => m.ChangePasscodeRoutingModule),
  CurrentAssignmentComponent: () => import('./components/candidate-portal/current-assignment/current-assignment.module').then(m => m.CurrentAssignmentRoutingModule),
  EditSkillSetComponent: () => import('./components/candidate-portal/edit-skill-set/edit-skill-set.module').then(m => m.EditSkillSetRoutingModule),
  MyDocumentsComponent: () => import('./components/candidate-portal/my-documents/my-documents.module').then(m => m.MyDocumentsRoutingModule),
  OnboardingHiringComponent: () => import('./components/candidate-portal/onboarding-hiring/onboarding-hiring.module').then(m => m.OnboardingHiringRoutingModule),
  PasswordChangeComponent: () => import('./components/candidate-portal/password-change/password-change.module').then(m => m.PasswordChangeRoutingModule),
  ProfileComponent: () => import('./components/candidate-portal/profile/profile.module').then(m => m.ProfileRoutingModule),
  MyjobsDashboardComponent: () => import('./components/common/myjobs-dashboard/myjobs-dashboard.module').then(m => m.MyjobsDashboardRoutingModule),
  SetupDashboardComponent: () => import('./components/common/setup-dashboard/setup-dashboard.module').then(m => m.SetupDashboardRoutingModule),
  ReminderDashboardComponent: () => import('./components/common/reminder-dashboard/reminder-dashboard.module').then(m => m.ReminderDashboardRoutingModule),
  EngagementDashboardComponent: () => import('./components/admin-portal/engagements/engagement-dashboard/engagement-dashboard.module').then(m => m.EngagementDashboardRoutingModule),
  AboutUsComponent: () => import('./components/pages/about-us/about-us.module').then(m => m.AboutUsRoutingModule),
  ApplyJobComponent: () => import('./components/pages/apply-job/apply-jobs.module').then(m => m.ApplyJobRoutingModule),
  BusinessComponent: () => import('./components/pages/business/business.module').then(m => m.BusinessRoutingModule),
  CaseStudyComponent: () => import('./components/pages/case-study/case-study.module').then(m => m.CaseStudyRoutingModule),
  ClientPartnersComponent: () => import('./components/pages/client-partners/client-partners.module').then(m => m.ClientPartnersRoutingModule),
  ContactUsComponent: () => import('./components/pages/contact-us/contact-us.module').then(m => m.ContactUsRoutingModule),
  FaqComponent: () => import('./components/pages/faq/faq.module').then(m => m.FaqRoutingModule),
  HealthcareBlogsComponent: () => import('./components/pages/healthcare-blogs/healthcare-blogs.module').then(m => m.HealthcareBlogsRoutingModule),
  HealthcareStaffingComponent: () => import('./components/pages/healthcare-staffing/healthcare-staffing.module').then(m => m.HealthcareStaffingRoutingModule),
  HsCaseStudyComponent: () => import('./components/pages/hs-case-study/hs-case.module').then(m => m.HsCaseStudyRoutingModule),
  IaBlogComponent: () => import('./components/pages/ia-blog/ia-blog.module').then(m => m.IaBlogRoutingModule),
  IaFaqComponent: () => import('./components/pages/ia-faq/ia-faq.module').then(m => m.IaFaqRoutingModule),
  IaResearchComponent: () => import('./components/pages/ia-research/ia-research.module').then(m => m.IaResearchRoutingModule),
  IaServicesComponent: () => import('./components/pages/ia-services/ia-services.module').then(m => m.IaServicesRoutingModule),
  IpServicesComponent: () => import('./components/pages/ip-services/ip-services.module').then(m => m.IpServicesRoutingModule),
  ITBlogComponent: () => import('./components/pages/it-blog/it-blog.module').then(m => m.ITBlogRoutingModule),
  ITCaseStudyComponent: () => import('./components/pages/it-case-study/it-case-study.module').then(m => m.ITCaseStudyRoutingModule),
  ITServiceComponent: () => import('./components/pages/it-service/it-service.module').then(m => m.ITServiceRoutingModule),
  JobApplicationComponent: () => import('./components/pages/job-application/job-application.module').then(m => m.JobApplicationRoutingModule),
  JobdetailsComponent: () => import('./components/pages/jobdetails/jobdetails.module').then(m => m.JobdetailsRoutingModule),
  JobsComponent: () => import('./components/pages/jobs/jobs.module').then(m => m.JobRoutingModule),
  LoginComponent: () => import('./components/pages/login/login.module').then(m => m.LoginRoutingModule),
  MyProfileComponent: () => import('./components/pages/my-profile/my-profile.module').then(m => m.MyProfileRoutingModule),
  PrivacyPolicyComponent: () => import('./components/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyRoutingModule),
  RegisterComponent: () => import('./components/pages/register/register.module').then(m => m.RegisterRoutingModule),
  SkillChecklistComponent: () => import('./components/pages/skill-checklist/skill-checklist.module').then(m => m.SkillChecklistRoutingModule),
  SkillSetComponent: () => import('./components/pages/skill-set/skill-set.module').then(m => m.SkillSetRoutingModule),
  TermsOfServiceComponent: () => import('./components/pages/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceRoutingModule),
  AdminSkillSetComponent: () => import('./components/recruiter-portal/admin-skill-set/admin-skill-set.module').then(m => m.AdminSkillSetRoutingModule),
  BrowseCandidateComponent: () => import('./components/recruiter-portal/browse-candidate/browse-candidate.module').then(m => m.BrowseCandidateRoutingModule),
  ManageJobsComponent: () => import('./components/recruiter-portal/manage-jobs/manage-jobs.module').then(m => m.ManageJobsRoutingModule),
  PostJobComponent: () => import('./components/recruiter-portal/post-job/post-job.module').then(m => m.PostJobRoutingModule),
  ClientAlertComponent: () => import('./components/admin-portal/reminder/client-alert/client-alert.module').then(m => m.ClientAlertRoutingModule),
  HistoryAlertComponent: () => import('./components/admin-portal/reminder/history-alert/history-alert.module').then(m => m.HistoryAlertRoutingModule),
  ITEngagementComponent: () => import('./components/admin-portal/engagements/it-engagement/it-engagement.module').then(m => m.ITEngagementRoutingModule),
  NonITEngagementComponent: () => import('./components/admin-portal/engagements/non-it-engagement/non-it-engagement.module').then(m => m.NonITEngagementRoutingModule),
  IncentiveDetailsComponent: () => import('./components/candidate-portal/incentive-details/incentive-details.module').then(m => m.IncentiveDetailsRoutingModule),
  IncomeExpensesComponent: () => import('./components/admin-portal/finance/income-expenses/income-expenses.module').then(m => m.IncomeExpensesRoutingModule),
  ProfitCalculationComponent: () => import('./components/admin-portal/finance/profit-calculation/profit-calculation.module').then(m => m.ProfitCalculationRoutingModule)
};

// loadChildren: () => import('./home/home.module').then(m => m.HomeComponent) }
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'register', loadChildren: routeConfig.RegisterComponent },
  { path: 'login', loadChildren: routeConfig.LoginComponent },
  { path: 'candi-profile', loadChildren: routeConfig.CandidateProfileComponent },
  { path: 'applied-jobs', loadChildren: routeConfig.AppliedJobsComponent },

  { path: 'healthcare-services', loadChildren: routeConfig.IpServicesComponent },
  { path: 'client-partners', loadChildren: routeConfig.ClientPartnersComponent },
  { path: 'about-us', loadChildren: routeConfig.AboutUsComponent },
  { path: 'contact-us', loadChildren: routeConfig.ContactUsComponent },
  { path: 'browse-candidate', loadChildren: routeConfig.BrowseCandidateComponent },
  { path: 'jobs', loadChildren: routeConfig.JobsComponent },
  { path: 'company', loadChildren: routeConfig.CompanyComponent },
  { path: 'department', loadChildren: routeConfig.DepartmentComponent },
  { path: 'user-role', loadChildren: routeConfig.UserRoleComponent },
  { path: 'apply-job', loadChildren: routeConfig.ApplyJobComponent },
  { path: 'employee', loadChildren: routeConfig.EmployeeComponent },
  { path: 'job-applications', loadChildren: routeConfig.JobApplicationComponent },
  { path: 'job-applications_admin', loadChildren: routeConfig.JobApplicationAdminComponent },
  { path: 'applicant-details', loadChildren: routeConfig.ApplicantsDetailsComponent },
  { path: 'dropdown-list', loadChildren: routeConfig.DropdownListComponent },
  { path: 'setup-dashboard', loadChildren: routeConfig.SetupDashboardComponent },
  { path: 'reminders', loadChildren: routeConfig.ReminderDashboardComponent },
  { path: 'myjobs-dashboard', loadChildren: routeConfig.MyjobsDashboardComponent },
  { path: 'app-summary', loadChildren: routeConfig.AppSummaryComponent },
  { path: 'add-applicant-admin', loadChildren: routeConfig.AddApplicantAdminComponent },
  { path: 'job-details', loadChildren: routeConfig.JobdetailsComponent },
  { path: 'onboarding-hiring', loadChildren: routeConfig.OnboardingAndHiringComponent },
  { path: 'recruitee-onboarding-hiring', loadChildren: routeConfig.OnboardingHiringComponent },
  { path: 'hired-applicant', loadChildren: routeConfig.HiredApplicantComponent },
  { path: 'assign-Manager', loadChildren: routeConfig.AssignManagerComponent },

  { path: 'assignment-history', loadChildren: routeConfig.AssignmentHistoryComponent },
  { path: 'current-assignment', loadChildren: routeConfig.CurrentAssignmentComponent },
  { path: 'apply-job-guest', loadChildren: routeConfig.ApplyJobGuestComponent },

  { path: 'case-study', loadChildren: routeConfig.CaseStudyComponent },
  { path: 'skill-set', loadChildren: routeConfig.SkillSetComponent },
  { path: 'clients', loadChildren: routeConfig.BusinessComponent },
  { path: 'ia-blog', loadChildren: routeConfig.IaBlogComponent },
  { path: 'ia-services', loadChildren: routeConfig.IaServicesComponent },
  { path: 'ia-faq', loadChildren: routeConfig.IaFaqComponent },
  { path: 'ia-research', loadChildren: routeConfig.IaResearchComponent },
  { path: 'healthcare-staffing', loadChildren: routeConfig.HealthcareStaffingComponent },
  { path: 'my-profile', loadChildren: routeConfig.MyProfileComponent },
  { path: 'profile', loadChildren: routeConfig.ProfileComponent },
  { path: 'password-change', loadChildren: routeConfig.PasswordChangeComponent },
  { path: 'edit-skill-set', loadChildren: routeConfig.EditSkillSetComponent },
  { path: 'change-passcode', loadChildren: routeConfig.ChangePasscodeComponent },
  { path: 'skill-set-admin', loadChildren: routeConfig.AdminSkillSetComponent },
  { path: 'it-blog', loadChildren: routeConfig.HealthcareBlogsComponent },
  { path: 'faq', loadChildren: routeConfig.FaqComponent },
  { path: 'my-documents', loadChildren: routeConfig.MyDocumentsComponent },
  { path: 'research', loadChildren: routeConfig.ITCaseStudyComponent },
  { path: 'blog', loadChildren: routeConfig.ITBlogComponent },
  { path: 'ip-services', loadChildren: routeConfig.ITServiceComponent },
  { path: 'it-services', loadChildren: routeConfig.HsCaseStudyComponent },
  { path: 'terms-of-service', loadChildren: routeConfig.TermsOfServiceComponent },
  { path: 'privacy-policy', loadChildren: routeConfig.PrivacyPolicyComponent },
  { path: 'skill-checklist', loadChildren: routeConfig.SkillChecklistComponent },
  
  //admin Panel
  { path: 'manage-jobs', loadChildren: routeConfig.ManageJobsComponent },
  { path: 'post-jobs', loadChildren: routeConfig.PostJobComponent },
  { path: 'applicants', loadChildren: routeConfig.ApplicantsComponent },
  { path: 'client-alert', loadChildren: routeConfig.ClientAlertComponent },
  { path: 'history-alert', loadChildren: routeConfig.HistoryAlertComponent },
  { path: 'healthcare-engagement', loadChildren: routeConfig.EngagementDashboardComponent },
  { path: 'it-engagement', loadChildren: routeConfig.ITEngagementComponent },
  { path: 'non-it-engagement', loadChildren: routeConfig.NonITEngagementComponent },
  { path: 'incentive-details', loadChildren: routeConfig.IncentiveDetailsComponent },
  { path: 'payroll-processing', loadChildren: routeConfig.PayrollProcessingComponent },
  { path: 'payroll-approval', loadChildren: routeConfig.PayrollApprovalComponent },
  { path: 'payroll-invoice', loadChildren: routeConfig.PayrollInvoiceComponent },
  { path: 'incentives', loadChildren: routeConfig.IncentivesComponent },
  { path: 'income-expenses', loadChildren: routeConfig.IncomeExpensesComponent },
  { path: 'profit-calculation', loadChildren: routeConfig.ProfitCalculationComponent },
  { path: 'client', loadChildren: routeConfig.ClientComponent },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
