<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Incentive Details
        </h5>
        <div class="float-right" style="display: flex">
          <div class="form-group" style="margin-right: 10px"></div>
        </div>
      </div>
      <br />
      <div class="form-group text-center align-items-center justify-content-center" style="display: flex;">
        <div>
          <label style="font-size: 20px">My Incentive</label>
          <br/>
          <select class="" [(ngModel)]="myIncentive" #myIncentive1
            (change)="onOptionsSelected(myIncentive1.value)">
            <option value="" disabled>Select Incentive Date</option>
            <option *ngFor="let data of myIncentiveDate" [value]="data.inc_file_id"
              style="text-transform: capitalize">
              {{ data.start_date }} - {{ data.end_date }}
            </option>
          </select>
        </div>
      </div>
      <br />
      <br />
      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th class="feature"></th>
            <th class="text-center">Client Name</th>
            <th class="text-center">Worker Name</th>
            <th class="text-center">Worker Hours</th>
            <th class="text-center">Incentive amount </th>
          </tr>
        </thead>
        <tbody *ngIf="myIncentiveData.length > 0">
          <tr *ngFor="let a of myIncentiveData" class="itemList">
            <td class="feature"></td>
            <td class="text-center application text-primary">
              {{ a.client_name }}
            </td>
            <td class="text-center application text-primary"
              style=" text-align: center; text-transform: capitalize; font-size: 14px; ">
              {{ a.emp_name }}
            </td>
            <td class="text-center application text-primary">
              {{ a.total_hr_worked }}
            </td>
            <td class="text-center application text-primary"
              style=" text-align: center; text-transform: capitalize; font-size: 14px; ">
              {{ a.type_incentive_amt }}
            </td>
          </tr>
          <tr style="background-color: #e1e7ff;">
            <td class="feature"></td>
            <td class=""></td>
            <td class=""></td>
            <td class="text-center job-name">Total Incentive</td>
            <td class="text-center job-name"> $ {{ total_incentive }} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-admin-default-layout>