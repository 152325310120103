import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortAndFilter',
  pure: false // Set pure to false to ensure the pipe is stateful
})
export class SortAndFilterPipe implements PipeTransform {
  transform(items: any[], designation: string): any[] {
    if (!items || !designation) {
      return items;
    }

    const filteredItems = items.filter(item => item.designation_name === designation);

    return filteredItems.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName));
  }
}
