<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Profit Calculation
        </h5>
        <div class="float-right" style="display: flex">
          <button type="button" class="site-button m-b30" data-toggle="modal" data-target="#processProfit">
            Process Profit
          </button>
        </div>
      </div>

      <div class="w-full">
        <div>
          <table class="table-job-bx cv-manager company-manage-job">
            <thead>
              <tr>
                <th class="feature"></th>
                <th>File Number</th>
                <th>Start – End Date</th>
                <th>Total Profit</th>
                <th style="text-align: center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let a of profitCalculation
                    | paginate
                      : { itemsPerPage: count, currentPage: page }
                ">
                <td class="feature">
                </td>
                <td class="application text-primary">
                  <a>{{ a.file_no }}</a>
                </td>
                <td class="application text-primary">
                  <a>{{ a.start_date }} - {{ a.end_date }}</a>
                </td>
                <td class="application text-primary">{{ a.total_profit }}</td>

                <td class="profit-links" style="display: flex; justify-content: center">
                  <div class="dropdown">
                    <a href="javascript:void(0);">
                      <i class="fa fa-cog"></i>
                    </a>
                    <div class="dropdown-content">
                      <a 
                        href="{{ apiUrl }}vcsapi/get/profit/file/download/excel/{{ a.profit_file_id }}/{{ excelfileName }}"
                        target="_blank" rel="noopener noreferrer" download>
                        Download
                      </a>
                      <a href="avascript:void(0);" (click)="profitCalculationDelete(a)" data-toggle="modal">
                        Delete
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination">
            <pagination-controls *ngIf="profitCalculation.length" class="product-pagination"
              autoHide="false" maxSize="8" (pageChange)="onPageChanged($event)"></pagination-controls>
          </div>
        </div>
        <!-- Process Incentive Modal -->
        <div class="modal fade modal-bx-info editor" id="processProfit" tabindex="-1" role="dialog"
          aria-labelledby="EducationModalLongTitle" aria-hidden="true">
          <div class="modal-dialog" role="document" style="max-width: 50% !important">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="EducationModalLongTitle">
                  Process Profit
                </h5>
                <button type="button" class="close" data-dismiss="modal" #processProfitClose
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label style="font-size: 14px">Start Date</label>
                      <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY"
                        required="true" [(ngModel)]="start_date" [config]="datePickerConfig">
                      </dp-date-picker>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label style="font-size: 14px">End Date</label>
                      <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY"
                        required="true" [(ngModel)]="end_date" [config]="datePickerConfig">
                      </dp-date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="site-button" data-dismiss="modal">
                  Back
                </button>
                <button type="button" class="site-button" (click)="generateProfit()">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal End -->
      </div>
    </div>
  </div>
</app-admin-default-layout>