import { Component, Input } from '@angular/core';
import { RecruiteeService } from 'src/app/services/recruitee.service';
import { Router, NavigationExtras, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-admin-default-layout',
  templateUrl: './admin-default-layout.component.html',
  styleUrls: ['./admin-default-layout.component.scss']
})
export class AdminDefaultLayoutComponent {
  currentRoute: string;
  badge: Number;

  user_id: any;
  user_name: any;
  moduleArray: any;
  subModuleArray: any;
  module_id: number;
  designation: string = '';
  checkUserType: boolean = false;
  navStatus: boolean = false;
  checkAdminType: boolean = true;
  selectedModule: any;

  constructor(private route: ActivatedRoute, public service: RecruiteeService, public router: Router) {
    // this.currentRoute = router.url
  }

  ngOnInit() {
    this.router.events.subscribe((event: NavigationEnd) => {
      // Extract the path segment from the URL
      const urlTree = this.router.parseUrl(event['routerEvent']?.url);
      if(urlTree){
        const segments = urlTree?.root?.children['primary']?.segments;
        const pathSegment = segments && segments?.length > 0 ? segments[0].path : '';
        if(pathSegment) this.currentRoute = pathSegment;
      }
    });
    this.route.queryParams.subscribe((r: any) => {
      this.selectedModule = r.special; // Open the inner list for the selected module
      setTimeout(() => {
        this.module_id = r.special
      }, 200);
    });
    this.service.spinnerShow();
    setTimeout(() => {
      this.service.spinnerHide();
    }, 900);

    this.user_id = sessionStorage.getItem('user_id');
    this.user_name = sessionStorage.getItem('user_name');

    this.functionassignAccess();
    if (sessionStorage.getItem('user_type') === 'recruitee') {
      this.checkUserType = true;
    }
    if (sessionStorage.getItem('user_type') === 'admin') {
      this.checkAdminType = false;
    }
  }

  toggleInnerList(val: number | string): void {
    if (this.selectedModule === val) {
      this.selectedModule = null; // Close the inner list if it's already open
    } else {
      this.selectedModule = val; // Open the inner list for the selected module
    }
  }

  getAssignaccess(val: number | string) {
    return new Promise((resolve, reject) => {

      if (sessionStorage.getItem('user_id')) {
        const data = [];
        const arr = JSON.parse(sessionStorage.getItem('moduleArray'));
        const ids = arr.map((o) => o.submodule_id);
        const arry = arr.filter(
          ({ submodule_id }, index) => !ids.includes(submodule_id, index + 1)
        );
        arry.forEach((e, index) => {
          if (e.module_id === val && e.action_name !== 'INVOICE RECON') {
            data.push(e);
            switch (e.submodule_name) {
              case 'POST JOB': {
                e.submodule_name_lower = 'Post A Job';
                e.route = '/post-jobs';
                break;
              }
              case 'MANAGE JOB': {
                e.submodule_name_lower = 'Manage Jobs';
                e.route = '/manage-jobs';
                break;
              }
              case 'APPLICANT': {
                e.submodule_name_lower = 'Applicants';
                e.route = '/applicants';
                break;
              }
              case 'JOB APPLICATION': {
                e.submodule_name_lower = 'Job Application';
                e.route = '/job-applications_admin';
                break;
              }
              case 'ONBOARDING & HIRING': {
                e.submodule_name_lower = 'On Boarding';
                e.route = '/onboarding-hiring';
                break;
              }
              case 'HIRED': {
                e.submodule_name_lower = 'Hired';
                e.route = '/hired-applicant';
                break;
              }
              case 'ASSIGN MANAGERS': {
                e.submodule_name_lower = 'Assign Manager';
                e.route = '/assign-Manager';
                break;
              }
              case 'SKILLSET': {
                e.submodule_name_lower = 'Skill Set';
                e.route = '/skill-set-admin';
                break;
              }
              case 'HEALTHCARE ENGAGEMENT': {
                e.submodule_name_lower = 'Healthcare Engagement';
                e.route = '/healthcare-engagement';
                break;
              }
              case 'IT ENGAGEMENT': {
                e.submodule_name_lower = 'IT Engagement';
                e.route = '/it-engagement';
                break;
              }
              case 'NON IT ENGAGEMENT': {
                e.submodule_name_lower = 'Non IT Engagement';
                e.route = '/non-it-engagement';
                break;
              }
              case "PAYROLL PROCESSING": {
                e.submodule_name_lower = "Payroll Process";
                e.route = "/payroll-processing";
                break;
              }
              case "PAYROLL APPROVAL": {
                e.submodule_name_lower = "Payroll Approval";
                e.route = "/payroll-approval";
                break;
              }
              case "PAYROLL & INVOICE": {
                e.submodule_name_lower = "Payroll Invoice and Reconciliation";
                e.route = "/payroll-invoice";
                break;
              }
              case "INCENTIVES": {
                e.submodule_name_lower = "Incentives";
                e.route = "/incentives";
                break;
              }
              case "INCOME & EXPENSES": {
                e.submodule_name_lower = "Income & Expenses";
                e.route = "/income-expenses";
                break;
              }
              case "PROFIT CALCULATION": {
                e.submodule_name_lower = "Profit Calculation";
                e.route = "/profit-calculation";
                break;
              }
              case 'COMPANY': {
                e.submodule_name_lower = 'Company';
                e.route = '/company';
                break;
              }
              case 'DEPARTMENT': {
                e.submodule_name_lower = 'Department';
                e.route = '/department';
                break;
              }
              case 'USER ROLE': {
                e.submodule_name_lower = 'User Role';
                e.route = '/user-role';
                break;
              }
              case 'EMPLOYEE': {
                e.submodule_name_lower = 'Employee';
                e.route = '/employee';
                break;
              }
              case 'CLIENT': {
                e.submodule_name_lower = 'Client';
                e.route = '/client';
                break;
              }
              case 'DROPDOWN LIST': {
                e.submodule_name_lower = 'Dropdown-List';
                e.route = '/dropdown-list';
                break;
              }
              case 'SEARCH JOB': {
                e.submodule_name_lower = 'Search job';
                e.route = '/';
                break;
              }
              case 'APPLICATION': {
                e.submodule_name_lower = 'Application';
                e.route = '/job-applications';
                break;
              }
              case 'ONBOARDING & HIRING': {
                e.submodule_name_lower = 'On Boarding';
                e.route = '/recruitee-onboarding-hiring';
                break;
              }
              // default: {
              //   //statements;
              //   break;
              // }
            }
          }
        });
        resolve(data)
      }
    })
  }
  
  functionassignAccess() {
    if (sessionStorage.getItem('user_id')) {
      const arr = JSON.parse(sessionStorage.getItem('moduleArray'));
      const ids = arr.map((o) => o.module_id);
      this.moduleArray = arr.filter(
        ({ module_id }, index) => !ids.includes(module_id, index + 1)
      );
      this.moduleArray.forEach(async (e) => {
        const innerNavBar = await this.getAssignaccess(e.module_id)

        if (e.module_name === 'JOBS') {
          e.module_name_lower = 'Jobs';
          e.innerNav = innerNavBar;
          e.route = '/manage-jobs';
        }
        if (
          e.module_name === 'MY JOBS' &&
          sessionStorage.getItem('user_type') === 'recruitee'
        ) {
          e.module_name_lower = 'My Jobs';
          e.innerNav = innerNavBar;
          e.route = '/job-applications';
        }
        if (e.module_name === 'MY JOBS') {
          e.module_name_lower = 'My Jobs';
          e.innerNav = innerNavBar;
          e.route = '/job-applications';
        }
        if (e.module_name === 'SETUP') {
          e.module_name_lower = 'Setup';
          e.innerNav = innerNavBar;
          e.route = '/company';
        }

        if (e.module_name === 'APPLICANT') {
          e.module_name_lower = 'Applicants';
          e.innerNav = innerNavBar;
          e.route = '/applicants';

        }
        if (e.module_name === 'ENGAGEMENTS') {
          e.module_name_lower = 'Engagements';
          e.innerNav = innerNavBar;
          e.route = '/healthcare-engagement';
        }
        if (e.module_name === 'FINANCE') {
          e.module_name_lower = 'Finance';
          e.innerNav = innerNavBar;
          e.route = '/payroll-processing';
        }

        if (e.module_name === 'ASSIGNMENT') {
          e.module_name_lower = 'Assignment';
          e.innerNav = innerNavBar;
          e.route = '/company';
        }
        if (e.module_name === 'PROFILE') {
          e.module_name_lower = 'Profile';
          e.innerNav = innerNavBar;
          e.route = '/company';
        }
      });


      const fin = this.moduleArray.map(e => e.module_name).indexOf('FINANCE');
      const eng = this.moduleArray.map(e => e.module_name).indexOf("ENGAGEMENTS");

      this.array_move(this.moduleArray, eng, fin)

    }
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  navigateTo(val: any) {
    if(val == 'reminders' || val == 'client-alert' || val == 'history-alert' || val == 'incentive-details'){
      this.router.navigate([val]);
    }else{
      let navigationExtras: NavigationExtras = {
        queryParams: {
          special: JSON.stringify(val.module_id),
        },
      };
      this.router.navigate([val.route], navigationExtras);
    }
  }

  getBadge() {
    this.badge = 0;
    this.service
      .getCurrentReqDocs(sessionStorage.getItem('user_id'))
      .subscribe((res) => {
        let result: any = res;
        if (result.length) {
          this.badge = result.length;
        }
      });
  }
}
