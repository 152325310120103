<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Incentive Processing
        </h5>
        <div class="float-right" style="display: flex">
          <!-- <button *ngIf="reprocess" type="button" class="site-button m-b30 mx-2" data-toggle="modal" (click)="reProcessIncentiveFile()">
            Re-Process
          </button> -->
          <button type="button" class="site-button m-b30" data-toggle="modal"
            data-target="#processIncentive">
            Process Incentive
          </button>
        </div>
      </div>

      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th class="feature"></th>
            <th>Incentive File #</th>
            <th>Incentive Start Date</th>
            <th>Incentive End date</th>
            <th>Total Incentive</th>
            <th style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
              let a of incentiveDataListMain
                | paginate
                  : { itemsPerPage: count, currentPage: page }
            ">
            <td class="feature"></td>
            <td class="application text-primary">
              <a>{{ a.inc_file_no }}</a>
            </td>
            <td class="application text-primary">{{ a.start_date }}</td>
            <td class="application text-primary"><a>{{ a.end_date }}</a></td>
            <td class="application text-primary"><a>{{ a.total_incentive }}</a></td>
            <td class="job-links" style="display: flex; justify-content: center">
              <div class="dropdown">
                <a href="javascript:void(0);">
                  <i class="fa fa-cog"></i>
                </a>
                <div class="dropdown-content">
                  <a href="avascript:void(0);" (click)="editSubmit(a)" data-toggle="modal"
                    data-target="#processIncentiveView">
                    View
                  </a>
                  <a 
                    href="{{ apiUrl }}vcsapi/get/incentive/file/download/excel/{{ a.inc_file_id }}/{{ excelfileName }}"
                    target="_blank" rel="noopener noreferrer" download>
                    Download
                  </a>
                  <a 
                    href="{{ apiUrl }}vcsapi/get/incentive/file/download/employee/excel/{{ a.inc_file_id }}/{{ excelfileIndividualName }}"
                    target="_blank" rel="noopener noreferrer" download>
                    Employee Incentive
                  </a>
                  <a href="avascript:void(0);" (click)="incentiveDelete(a)" data-toggle="modal">
                    Delete
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <pagination-controls *ngIf="incentiveDataListMain.length" class="product-pagination"
          autoHide="false" maxSize="8" (pageChange)="onPageChanged($event)"></pagination-controls>
      </div>

      <!-- Process Incentive Modal -->
      <div class="modal fade modal-bx-info editor" id="processIncentive" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 50% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                Process Incentive
              </h5>
              <button type="button" class="close" data-dismiss="modal" #processIncentiveClose
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label style="font-size: 14px">Start Date</label>
                    <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                      [(ngModel)]="start_date" [config]="datePickerConfig">
                    </dp-date-picker>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label style="font-size: 14px">End Date</label>
                    <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                      [(ngModel)]="end_date" [config]="datePickerConfig">
                    </dp-date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="generateIncentives()">
                Calculate
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Process Incentive View Modal -->
      <div class="modal fade modal-bx-info editor" id="processIncentiveView" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true" *ngIf="showModalBox"
        data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document" style="max-width: 90% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                View Incentive
              </h5>
              <button type="button" class="close" data-dismiss="modal" #processIncentiveViewClose
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="payrollIncentiveView.length !== 0">
              <div class="row">
                <table class="table-job-bx cv-manager company-manage-job d-block overflow-auto">
                  <thead>
                    <tr>
                      <th class="feature"></th>
                      <th>Client Name</th>
                      <th>Worker Name</th>
                      <th>Worker Hours</th>
                      <th>VCS Profit</th>
                      <th>Recruiter Name</th>
                      <th>Recruiter Incentive</th>
                      <th>Team Lead Name</th>
                      <th>Team Lead Incentive</th>
                      <th>Manager Name</th>
                      <th>Manager Incentive</th>
                      <th>Director Name</th>
                      <th>Director Incentive</th>
                      <th>Onboarding Name</th>
                      <th>Onboarding Incentive</th>
                      <th>Total Incentive</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="
                        let a of payrollIncentiveView;
                        let i = index
                      ">
                      <td class="feature"></td>

                      <td class="application text-primary">
                        {{a.client_name}}
                      </td>
                      <td class="application text-primary">
                        {{ a?.emp_name }}
                      </td>
                      <td class="application text-primary">
                        {{ a.total_hr_worked == null ? 0 : a.total_hr_worked  }}
                      </td>
                      <td class="application text-primary">
                        {{ a.profit == null ? 0 : a.profit  }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.recruiter_name }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.recruiter_inc_amt }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.tl_name }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.tl_inc_amount }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.mng_name }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.mng_inc_amount }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.dir_name }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.dir_inc_amount }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.onb_mgr_name }}
                      </td>
                      <td class="application text-primary">
                        {{ a?.onb_inc_amount }}
                      </td>
                      <td class="application text-primary">
                        {{ a.total_employee_incentive }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->
    </div>
  </div>
</app-admin-default-layout>