import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  navbarItems: any = [
    {
      label: 'Home',
      link: '/home',
    },
    {
      label: 'About Us',
      link: '/about-us',
    },
    {
      label: 'Clients & Partners',
      link: '/client-partners',
    },
    {
      label: 'Healthcare Services',
      link: '',
      children: [
        {
          label: 'Our Healthcare Services',
          link: '/healthcare-services',
        },
        {
          label: 'Research',
          link: '/research',
        },
        {
          label: 'FAQ',
          link: '/faq',
        },
        {
          label: 'Skills Checklist',
          link: '/skill-checklist',
        },
      ],
    },
    {
      label: 'Industrial & Commercial Services',
      link: '',
      children: [
        {
          label: 'Industrial & Commercial Services',
          link: '/ia-services',
        },
        {
          label: 'Research',
          link: '/ia-research',
        },
        {
          label: 'FAQ',
          link: '/ia-faq',
        },
        {
          label: 'Blog',
          link: '/ia-blog',
        },
      ],
    },
    {
      label: 'IT Services',
      link: '',
      children: [
        {
          label: 'Our IT Services',
          link: '/it-services',
        },
        {
          label: 'Case Study',
          link: '/case-study',
        },
        // {
        //   label: 'Resources',
        //   link: '/resources',
        // },
        {
          label: 'Blog',
          link: '/it-blog',
        },
      ],
    },
    {
      label: 'Jobs',
      link: '/jobs',
    },
    {
      label: 'Contact Us',
      link: '/contact-us',
    },
  ];

  user_id: any;
  redirectLink: any = {
    link: 'manage-jobs',
    module_id: 1
  };
  user_name: any;
  moduleArray: any;
  dataModuleArray: any;
  designation: string = '';
  adminPanel: boolean = false;
  checkUserType: boolean = false;
  navStatus: boolean = false;
  // checkAdminType: boolean = true;

  constructor(
    public router: Router,
    private breakpointObserver: BreakpointObserver
  ) {}

  changeCss() {
    var classname = document.getElementById('navbarNavDropdown').className;
    ////console.log(classname);
    if (
      classname ===
        'header-nav navbar-collapse justify-content-start collapse show' &&
      this.navStatus === false
    ) {
      //document.getElementById('navbarNavDropdown').className = "header-nav navbar-collapse justify-content-start collapse";
      this.navStatus = true;
      setTimeout(() => {
        document.getElementById('navbarNavDropdown').style.display = 'none';
      }, 100);
    } else {
      this.navStatus = false;
      //document.getElementById('navbarNavDropdown').className = "header-nav navbar-collapse justify-content-start collapse";
      document.getElementById('navbarNavDropdown').style.display = 'block';
    }
  }

  hideMenu() {
    this.navStatus = true;
    document.getElementById('navbarNavDropdown').style.display = 'none';
    document.getElementById('menuBtn').className =
      'navbar-toggler collapsed navicon justify-content-end';
  }

  ngOnInit() {
    this.user_id = sessionStorage.getItem('user_id');
    this.user_name = sessionStorage.getItem('user_name');

    this.designation = sessionStorage.getItem('designation');

    this.functionassignAccess();
    if (sessionStorage.getItem('user_type') === 'recruitee') {
      this.checkUserType = true;
    }
    // if (sessionStorage.getItem('user_type') === 'admin') {
    //   this.checkAdminType = false;
    // }
  }
  
  functionassignAccess() {
    if (sessionStorage.getItem('user_id')) {
      const arr = JSON.parse(sessionStorage.getItem('moduleArray'));
      const ids = arr.map((o) => o.module_id);
      this.moduleArray = arr.filter(
        ({ module_id }, index) => !ids.includes(module_id, index + 1)
      );
      this.dataModuleArray = []
      if(this.moduleArray.length > 0){
        if (this.moduleArray[0].module_name === 'JOBS') {
          this.redirectLink = {
            link: '/manage-jobs',
            module_id: this.moduleArray[0].module_id
          };
        }else if ( this.moduleArray[0].module_name === 'MY JOBS' && sessionStorage.getItem('user_type') === 'recruitee' ) {
          this.redirectLink = {
            link: '/job-applications',
            module_id: this.moduleArray[0].module_id
          };
        }else if (this.moduleArray[0].module_name === 'MY JOBS') {
          this.redirectLink = {
            link: '/job-applications',
            module_id: this.moduleArray[0].module_id
          };
        }else if (this.moduleArray[0].module_name === 'SETUP') {
          this.redirectLink = {
            link: '/company',
            module_id: this.moduleArray[0].module_id
          };
        }else if (this.moduleArray[0].module_name === 'APPLICANT') {
          this.redirectLink = {
            link: '/applicants',
            module_id: this.moduleArray[0].module_id
          };
        }else if (this.moduleArray[0].module_name === 'ENGAGEMENTS') {
          this.redirectLink = {
            link: '/healthcare-engagement',
            module_id: this.moduleArray[0].module_id
          };
        }else if (this.moduleArray[0].module_name === 'FINANCE') {
          this.redirectLink = {
            link: '/payroll-processing',
            module_id: this.moduleArray[0].module_id
          };
        }else if (this.moduleArray[0].module_name === 'ASSIGNMENT') {
          this.redirectLink = {
            link: '/company',
            module_id: this.moduleArray[0].module_id
          };
        }else if (this.moduleArray[0].module_name === 'PROFILE') {
          this.redirectLink = {
            link: '/company',
            module_id: this.moduleArray[0].module_id
          };
        }
      }
      this.moduleArray.forEach((e) => {
        if (e.module_name === 'JOBS' || e.module_name === 'MY JOBS' || e.module_name === 'SETUP' || e.module_name === 'APPLICANT' || e.module_name === 'ENGAGEMENTS' || e.module_name === 'FINANCE') {
          
          this.adminPanel = true   
          return
        }
        // if (e.module_name === 'JOBS') {
        //   e.module_name_lower = 'Jobs';
        //   //e.route = "/job-dashboard";
        //   e.route = '/manage-jobs';
        // }
        // this.dataModuleArray.push(e)
        // if (
        //   e.module_name === 'MY JOBS' &&
        //   sessionStorage.getItem('user_type') === 'recruitee'
        // ) {
        //   e.module_name_lower = 'My Jobs';
        //   //e.route = "/myjobs-dashboard";
        //   e.route = '/job-applications';
        // }
        // if (e.module_name === 'MY JOBS') {
        //   e.module_name_lower = 'My Jobs';
        //   //e.route = "/myjobs-dashboard";
        //   e.route = '/job-applications';
        // }
        // if (e.module_name === 'SETUP') {
        //   e.module_name_lower = 'Setup';
        //   //e.route = "/setup-dashboard";
        //   e.route = '/company';
        // }
        
        // if (e.module_name === 'APPLICANT') {
        //   e.module_name_lower = 'Applicants';
        //   //e.route = "/applicant-dashboard";
        //   e.route = '/applicants';
          
        // }
        // if (e.module_name === 'ENGAGEMENTS') {
        //   e.module_name_lower = 'Engagements';
        //   e.route = '/healthcare-engagement';
        // }
        // if (e.module_name === 'FINANCE') {
        //   e.module_name_lower = 'Finance';
        //   //e.route = "/finance-dashboard";
        //   e.route = '/payroll-processing';
        // }
        
        // if (e.module_name === 'ASSIGNMENT') {
        //   e.module_name_lower = 'Assignment';
        //   e.route = '/company';
        // }
        // if (e.module_name === 'PROFILE') {
        //   e.module_name_lower = 'Profile';
        //   e.route = '/company';
        // }
      });

      const fin = this.moduleArray.map(e => e.module_name).indexOf('FINANCE');
      const eng = this.moduleArray.map(e => e.module_name).indexOf("ENGAGEMENTS");
      
      this.array_move(this.moduleArray, eng, fin)
      //this.moduleArray.sort((a, b)=> a.module_name > b.module_name ? -1 : 1).sort((a, b)=> a.submodule_name > b.submodule_name ? -1 : 1)
    }
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  navigateToRecruitee() {
    this.router.navigate(['current-assignment']);
  }

  navigateToProfile() {
    this.router.navigate(['candi-profile']);
  }

  navigateToAdminPanel() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(this.redirectLink.module_id),
      },
    };
    this.router.navigate([this.redirectLink.link], navigationExtras);
  }

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.route], navigationExtras);
  }

  navigateToReminder() {
    this.router.navigate(['reminders'])
  }
  navigateToIncentiveDetail() {
    this.router.navigate(['incentive-details'])
  }

  navigateToEngagements() {
    this.router.navigate(['engagements'])
  }

  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/');
    this.user_id = null;
  }
}
