<div class="relative bg-white p-4">
  <div class="absolute inset-0">
    <div class="absolute inset-y-0 left-0 w-1/2 bg-sky-600"></div>
  </div>
  <div class="relative mx-auto max-w-[100rem] lg:grid lg:grid-cols-5">
    <div
      class="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12"
    >
      <div class="mx-auto max-w-lg">
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          Quick Contact
        </h2>
        <p class="mt-3 text-lg leading-6 text-gray-500">
          If you have any questions simply use the following contact details.
        </p>
        <dl class="mt-8 text-base text-gray-500">
          <div>
            <dt><strong>Headquarters</strong></dt>
            <dd>
              <p>9655 Granite Ridge Dr, STE 200</p>
              <p>San Diego, CA 92123</p>
            </dd>
          </div>
          <div class="mt-6">
            <dt class="sr-only">Phone number</dt>
            <dd class="flex">
              <!-- Heroicon name: outline/phone -->
              <svg
                class="h-6 w-6 flex-shrink-0 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
              <span class="ml-3">+1 (916) 800-3762</span>
            </dd>
          </div>
          <div class="mt-3">
            <dt class="sr-only">Email</dt>
            <dd class="flex">
              <!-- Heroicon name: outline/envelope -->
              <svg
                class="h-6 w-6 flex-shrink-0 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              <span class="ml-3">info@vishusa.com</span>
            </dd>
          </div>
        </dl>
        <p class="mt-6 text-base text-gray-500">
          Looking for jobs?
          <a routerLink="/jobs" class="font-medium text-gray-700 underline"
            >View all job openings</a
          >.
        </p>
      </div>
    </div>
    <div
      class="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12"
    >
      <div class="mx-auto max-w-lg lg:max-w-none">
        <!-- <form
          method="post"
          class="grid grid-cols-1 gap-y-6"
        > -->
          <input type="hidden" value="Contact" name="dzToDo" />

          <div class="pb-3">
            <label for="full-name" class="sr-only">Your name</label>
            <input
              type="text"
              name="dzName"
              id="full-name"
              autocomplete="name"
              [(ngModel)]="name" 
              [ngModelOptions]="{ standalone: true }"
              class="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Your name"
              required
            />
          </div>
          <div class="pb-3">
            <label for="email" class="sr-only">Email</label>
            <input
              id="email"
              name="dzEmail"
              type="email"
              autocomplete="email"
              [(ngModel)]="email" 
              [ngModelOptions]="{ standalone: true }"
              class="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Email"
            />
          </div>
          <div class="pb-3">
            <label for="message" class="sr-only">Message</label>
            <textarea
              id="message"
              name="dzMessage"
              rows="4"
              [(ngModel)]="message" 
              [ngModelOptions]="{ standalone: true }"
              class="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Message"
            ></textarea>
          </div>
          <!-- <div class="col-lg-12">
            <div class="recaptcha-bx">
              <div class="input-group">
                <div
                  class="g-recaptcha"
                  data-sitekey="Put reCaptcha Site Key"
                  data-callback="verifyRecaptchaCallback"
                  data-expired-callback="expiredRecaptchaCallback"
                ></div>
                <input
                  class="form-control d-none"
                  style="display: none"
                  data-recaptcha="true"
                  required
                  data-error="Please complete the Captcha"
                />
              </div>
            </div>
          </div> -->
          <div>
            <button
              type="button"
              (click)="sendContactMail()"
              type="submit"
              class="relative inline-flex items-center rounded-sm border border-transparent bg-sky-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800 uppercase transition-colors duration-300"
            >
              <span>Submit</span>
            </button>
          </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</div>
<div class="relative bg-white px-4 py-28">
  <div class="mx-auto max-w-2xl lg:mx-0">
    <h2 class="text-3xl font-bold tracking-tight text-gray-900 text-center">Our offices</h2>
  </div>
  <div
    class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4"
  >
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Headquarters
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>9655 Granite Ridge Dr, STE 200</p>
        <p>San Diego, CA 92123</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        New York Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>418 Broadway STE Y</p>
        <p>Albany, NY 12207</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        New Jersey Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>525 Route 73 North STE 104</p>
        <p>Marlton, NJ 08053</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Delaware Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600"
      >
        <p>8 The Green, STE A</p>
        <p>Dover, DE 19901</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Virginia Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600"
      >
        <p>4445 Corporation Lane STE 264</p>
        <p>Virginia Beach, VA 23462</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Maryland Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>5000 Thayer Center Suite C</p>
        <p>Oakland, MD 21550</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Florida Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>7901 4th St N STE 300</p>
        <p>St. Petersburg, FL 33702</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Washington Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>100 N HOWARD ST, STE R</p>
        <p>Spokane , WA 99201</p>
      </address>
    </div>
    
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Texas Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>2702 E Fifth St</p>
        <p>Tyler, TX 75701</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Tennessee Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>658 Lee Victory Pkwy</p>
        <p>Smyrna, TN 37167</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Indiana Office 
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>9465 Counselors Row</p>
        <p>Indianapolis, IN 46240</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        Atlanta GA Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">Vish Consulting Services, Inc</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>8735 Dunwoody Place, STE R</p>
        <p>Atlanta, GA 30350</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        India Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">VCS Staffing Geek Pvt. Ltd</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>Building No. H 47, Sector 63,</p>
        <p>Noida Guatambudh Nagar Gautam Buddha Nagar Uttar Pradesh 201301</p>
      </address>
    </div>
    <div>
      <h3 class="border-l border-indigo-600 pl-6 font-semibold text-gray-900">
        India Office
      </h3>
      <span class="block border-l border-gray-200 pl-6 font-medium text-[14px] text-gray-900">VCS Staffing Geek Pvt. Ltd</span>
      <address
        class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600"
      >
        <p>B- 202, RG Complex , DB Gupta Road,</p>
        <p>Paharganj, Delhi, New Delhi Central,</p>
        <p>DELHI, 110055, India,</p>
      </address>
    </div>
  </div>
</div>
<app-cta></app-cta>
