<app-admin-default-layout>
  <div class="col-xl-10 col-lg-8 m-b30">
    <div class="job-bx submit-resume">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Company Details
        </h5>
        <a
          href="#"
          class="site-button right-arrow button-sm float-right"
          >Back</a
        >
      </div>

      <!-- Content WorK Start -->

      <div class="wordAlign">
        <div style="min-width: 60px; font-weight: 600">Name</div>
        <div style="min-width: 40px; text-align: center">:</div>
        <div>{{ cmpNamecopy }}</div>
      </div>
      <div class="wordAlign" style="margin-top: 5px">
        <div style="min-width: 60px; font-weight: 600">Address</div>
        <div style="min-width: 40px; text-align: center">:</div>
        <div>{{ cmpaddresscopy }}</div>
      </div>
      <div class="wordAlign" style="margin-top: 5px">
        <div style="min-width: 60px; font-weight: 600">Phone</div>
        <div style="min-width: 40px; text-align: center">:</div>
        <div>{{ cmpPhonecopy }}</div>
      </div>
      <div class="wordAlign" style="margin-top: 5px">
        <div style="min-width: 60px; font-weight: 600">Email</div>
        <div style="min-width: 40px; text-align: center">:</div>
        <div>{{ CmpEmailcopy }}</div>
      </div>

      <div
        class="modal-footer"
        style="
          justify-content: flex-start;
          border: none;
          margin-top: 15px;
          padding-left: 0;
        "
      >
        <button
          type="button"
          data-toggle="modal"
          data-target="#companyDetails"
          (click)="setFormData()"
          class="site-button"
        >
          Edit
        </button>
      </div>


      <div class="job-bx-titlde documentUp clearfix" style="font-size: 13px;border-top: 1px solid #e1e7ff;padding: 11px 0px;">
        <h5 class="font-weight-700 pull-left text-uppercase">Documents</h5>
        <div class="float-right" style="display: flex;">
          <button class="site-button m-b30" data-target="#uploadDocuments" data-toggle="modal" href="javascript:void(0);" type="button">Add Document</button>
        </div>
      </div>

      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th>Doc. Name</th>
            <th>Doc. Type</th>
            <th>Expiry Date</th>
            <th>Document</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody *ngIf="companyDocumentData.length == 0">
          <tr><td colspan="7">No record Found</td></tr>
        </tbody>
        <tbody *ngIf="companyDocumentData.length != 0">
          <tr *ngFor="let a of companyDocumentData | sortBy:'doc_name'" class="itemList">
            <td class="job-name">
              <a href="javascript:void(0);" style="text-transform: capitalize; font-size:14px;" class="link" >{{a.doc_name}}</a>
            </td>
            <td class="application text-primary" style="text-transform: capitalize;font-size:14px;"> {{a.doc_type}}</td>
            <td class="job-name">
              <a href="javascript:void(0);" style="text-transform: capitalize;font-size:14px;" class="link2" >{{a.expiry_date}}</a>
            </td>
            <td class="application text-primary" style="text-transform: capitalize;font-size:14px;">
              <a style="text-decoration: none;" href="{{apiUrl}}vcsapi/download/{{a.id}}/{{a.company_id}}" target="_blank" class="site-button">Download</a>
            </td>
            <td class="job-name">
              <a href="javascript:void(0);" data-toggle="modal" data-target="#documentEdit" (click)="setDocumentId(a)">Edit</a>
            </td>
          </tr>
      </tbody>
        
      </table>

        <!-- Upload Document model -->
      <div class="modal fade modal-bx-info editor" id="uploadDocuments" tabindex="-1" role="dialog"
      aria-labelledby="uploadDocumentsModalLongTitle" aria-hidden="true">
      <div class="faltu-wrapper">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="uploadDocumentsdModalLongTitle">Upload Document</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="addDocumentsList">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Name <span style="color: red;">*</span></label>
                    <input type="text" class="form-control" placeholder="Enter Document Name" formControlName="document_name" style="border: 1px solid #ced4da !important;">
                    <div
                        *ngIf="addDocumentsList.controls['document_name'].errors && (addDocumentsList.controls['document_name'].dirty || addDocumentsList.controls['document_name'].touched)"
                        style="color: red;font-size: 15px;margin-top: 5px">Name is invalid.</div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>Expiry date </label>
                    <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                    formControlName="expirDate" [config]="datePickerConfig">
                  </dp-date-picker>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Document <span style="color: red;">*</span></label>
                        <input 
                          formControlName="file"
                          id="file" 
                          type="file" 
                          class="form-control"
                          (change)="onFileChange($event)">
                          <div *ngIf="f['file'].touched && f['file'].invalid" class="alert alert-danger">
                            <div *ngIf="f['file'].errors['required']">File is required.</div>
                          </div>
                    </div>
                  </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="site-button" data-dismiss="modal">Back</button>
            <button type="button" class="site-button" [disabled]="!addDocumentsList.valid"
              (click)="submitDocuments()"
              [ngStyle]="{'cursor': (!addDocumentsList.valid ? 'not-allowed' : 'pointer')}" data-dismiss="modal"
            >
            Submit
            </button>
          </div>

        </div>
      </div>
      </div>
      </div>

      <!-- Edit Document -->
      <div class="modal fade modal-bx-info editor" id="documentEdit" tabindex="-1" role="dialog"
        aria-labelledby="departmentEditModalLongTitle" aria-hidden="true">
        <div class="faltu-wrapper">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="departmentEditModalLongTitle">Edit Department</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="editDocumentsList">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Name <span style="color: red;">*</span></label>
                      <input type="text" class="form-control" placeholder="Enter Name" formControlName="edit_document_name" style="border: 1px solid #ced4da !important;">
                        <div *ngIf="editDocumentsList.controls['edit_document_name'].invalid && (editDocumentsList.controls['edit_document_name'].dirty || editDocumentsList.controls['edit_document_name'].touched)" class="alert alert-danger" style="font-size: 12px;padding: .5rem 1.25rem;">
                          <div *ngIf="editDocumentsList.controls['edit_document_name'].errors?.['required']">
                            Name is required.
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group"> 
                      <label>Expiry date </label>
                      <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                      formControlName="edit_expirDate" [config]="datePickerConfig">
                    </dp-date-picker>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Document <span style="color: red;">*</span></label>
                        <input 
                          formControlName="edit_file"
                          id="file" 
                          type="file" 
                          class="form-control"
                          (change)="onUpdateFileChange($event)">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <a target="_blank" href="{{apiUrl}}vcsapi/download/{{documentId}}/{{orignalFileId}}">View</a>
                    </div>
                  </div>
                </div>


              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">Back</button>
              <button type="button" class="site-button" [disabled]="!editDocumentsList.valid"
                (click)="updateDocument()"
                [ngStyle]="{'cursor': (!editDocumentsList.valid ? 'not-allowed' : 'pointer')}"
                data-dismiss="modal">Submit</button>
            </div>

          </div>
        </div>
        </div>
      </div>



      <div
        class="modal fade modal-bx-info editor"
        id="companyDetails"
        tabindex="-1"
        role="dialog"
        aria-labelledby="companyDetailsModalLongTitle"
        aria-hidden="true"
      >
      <div class="faltu-wrapper">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="companyDetailsModalLongTitle"
              >
                Company Details
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="compantDetailsForm">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Name"
                        formControlName="name"
                      />
                      <div
                        *ngIf="
                          compantDetailsForm.controls['name']
                            .errors &&
                          compantDetailsForm.controls['name'].dirty
                        "
                        style="
                          color: red;
                          font-size: 15px;
                          margin-top: 5px;
                        "
                      >
                        Name is invalid.
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Address"
                        formControlName="address"
                      />
                      <div
                        *ngIf="
                          compantDetailsForm.controls['address']
                            .errors &&
                          compantDetailsForm.controls['address']
                            .dirty
                        "
                        style="
                          color: red;
                          font-size: 15px;
                          margin-top: 5px;
                        "
                      >
                        Address is invalid.
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Phone</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Enter Phone"
                        formControlName="phone"
                      />
                      <div
                        *ngIf="
                          compantDetailsForm.controls['phone']
                            .errors &&
                          compantDetailsForm.controls['phone'].dirty
                        "
                        style="
                          color: red;
                          font-size: 15px;
                          margin-top: 5px;
                        "
                      >
                        Phone is invalid.
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Enter Email"
                        formControlName="email"
                      />
                      <div
                        *ngIf="
                          compantDetailsForm.controls['email']
                            .errors &&
                          compantDetailsForm.controls['email'].dirty
                        "
                        style="
                          color: red;
                          font-size: 15px;
                          margin-top: 5px;
                        "
                      >
                        Email is invalid.
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <!--<button type="button" class="site-button" [disabled]="compantDetailsForm.invalid" >Submit</button>-->
              <button
                type="button"
                class="site-button"
                (click)="submitDetails()"
                [disabled]="!compantDetailsForm.valid"
                [ngStyle]="{
                  cursor: !compantDetailsForm.valid
                    ? 'not-allowed'
                    : 'pointer'
                }"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</app-admin-default-layout>