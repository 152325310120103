<body id="bg">
  <div class="page-wraper">
    <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#4C96D7"
  type="ball-square-clockwise-spin">
      <p style="color: white">Loading...</p>
    </ngx-spinner>
    <!-- Content -->
    <div class="page-content bg-white">
      <!-- contact area -->
      <div class="content-block">
        <!-- Browse Jobs -->
        <div class="section-full bg-white p-t50 p-b20">
          <div class="container">
            <div class="row">
              <div class="col-xl-2 col-lg-2 m-b30">
                <app-side-bar-candidate></app-side-bar-candidate>
              </div>
              <div class="col-xl-10 col-lg-10 m-b30">
                <div class="job-bx clearfix">
                  <div class="job-bx-title clearfix">
                    <h5 class="font-weight-700 pull-left text-uppercase">
                      My Documents
                    </h5>
                    <div class="float-right" style="display: flex">
                      <div class="form-group" style="margin-right: 10px"></div>
                    </div>
                  </div>
                  <div style="margin: 40px 12px; font-size: 18px">
                    <div style="color: red; display: flex" *ngIf="badge > 0">
                      ALERT : New Documents Required.
                      <div
                        type="button"
                        class="site-button"
                        style="margin-left: 5%"
                        data-toggle="modal"
                        data-target="#uploadDoc"
                        (click)="getDocToUpload()"
                      >
                        Upload
                      </div>
                    </div>

                    <div style="color: limegreen" *ngIf="badge === 0">
                      NOTE : No Additional Documents Required.
                    </div>
                  </div>

                  <table class="table-job-bx cv-manager company-manage-job">
                    <thead>
                      <tr>
                        <th class="feature">
                        </th>
                        <th style="text-align: center">Doc. Name</th>
                        <th style="text-align: center">Doc. Type</th>
                        <th style="text-align: center">Expiry Date</th>
                        <th style="text-align: center">Upload Date</th>
                        <th style="text-align: center">Status</th>
                        <th style="text-align: center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let a of documentData" class="itemList">
                        <td class="feature">
                        </td>
                        <td class="job-name">
                          <a
                            href="javascript:void(0);"
                            style="text-transform: capitalize; font-size: 14px"
                            class="link"
                            >{{ a.rec_doc_name }}</a
                          >
                        </td>
                        <td
                          class="application text-primary"
                          style="
                            text-align: center;
                            text-transform: capitalize;
                            font-size: 14px;
                          "
                        >
                          {{ a.rec_doc_type }}
                        </td>
                        <td class="job-name">
                          <a
                            href="javascript:void(0);"
                            style="text-transform: capitalize; font-size: 14px"
                            class="link2"
                            >{{ a.expiry_date }}</a
                          >
                        </td>
                        <td
                          class="application text-primary"
                          style="
                            text-align: center;
                            text-transform: capitalize;
                            font-size: 14px;
                          "
                        >
                          {{ a.update_date_time }}
                        </td>
                        <td
                          class="application text-primary"
                          style="
                            text-align: center;
                            text-transform: capitalize;
                            font-size: 14px;
                          "
                        >
                          <span style="color: limegreen">{{
                            a.rec_doc_status
                          }}</span>
                        </td>
                        <td
                          class="application text-primary"
                          style="
                            text-align: center;
                            text-transform: capitalize;
                            font-size: 14px;
                          "
                        >
                          <a
                            style="text-decoration: none"
                            href="{{ apiUrl }}vcsapi/download/{{ a.rec_doc_id }}/{{ user_id }}/{{ a.rec_doc_name }}"
                            target="_blank"
                            class="site-button"
                            >Download</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--upload doc Modal -->
                  <div
                    class="modal fade modal-bx-info"
                    id="uploadDoc"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="uploadDocTitle"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog"
                      role="document"
                      style="max-width: 60% !important"
                    >
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" *ngIf="details">
                            Upload Documents
                          </h5>

                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            #uploadDocModal
                            data-dismiss="modal"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div
                          class="modal-body"
                          *ngIf="details"
                          style="margin: 2%"
                        >
                          <div *ngIf="standardDoc">
                            <ul *ngIf="standardDoc.length">
                              <div
                                style="
                                  display: flex;
                                  margin-bottom: 3% !important;
                                "
                              >
                                <div class="divider2"></div>
                                <div
                                  style="
                                    width: 32%;
                                    margin-top: 4px;
                                    text-align: center;
                                  "
                                >
                                  <h5 class="font-weight-700 text-uppercase">
                                    Standard Documents
                                  </h5>
                                </div>
                                <div class="divider2"></div>
                              </div>
                              <li class="innerLi" *ngFor="let a of standardDoc">
                                <strong
                                  class="innerStrng"
                                  style="margin-top: 1%"
                                  >{{ a.doc_name }}
                                </strong>

                                <div style="display: flex">
                                  <div class="custom-file" style="width: 40%">
                                    <p
                                      class="m-a0"
                                      *ngIf="!a.file_name"
                                      style="display: block"
                                    >
                                      <i class="fa fa-upload"></i>
                                      Upload File
                                    </p>

                                    <p
                                      class="m-a0"
                                      *ngIf="a.file_name"
                                      style="display: block"
                                    >
                                      {{ a.file_name }}
                                    </p>
                                    <input
                                      type="file"
                                      class="site-button form-control"
                                      id="customFile"
                                      (change)="
                                        handleFileInput($event.target.files, a)
                                      "
                                    />
                                  </div>

                                  <div
                                    *ngIf="a.showProgressBar"
                                    style="margin-top: -20px"
                                  >
                                    <circle-progress
                                      [percent]="a.showPercentage"
                                      [radius]="40"
                                      [outerStrokeWidth]="6"
                                      [innerStrokeWidth]="4"
                                      [outerStrokeColor]="'#4C96D7'"
                                      [innerStrokeColor]="'#a6cbeb'"
                                      [animation]="false"
                                      [animationDuration]="10"
                                      [titleFontSize]="10"
                                      [renderOnClick]="false"
                                    >
                                    </circle-progress>
                                  </div>

                                  <div
                                    class="job-time margin_preview"
                                    *ngIf="a.exist === 'true'"
                                  >
                                    <a
                                      href="{{ apiUrl }}vcsapi/download/{{ a.rec_doc_id }}/{{ user_id }}/{{ a.doc_name }}"
                                      target="_blank"
                                      ><span style="margin: 14% 35%"
                                        >Preview</span
                                      ></a
                                    >
                                  </div>
                                </div>

                                <div
                                  style="
                                    margin-left: 22%;
                                    width: 55%;
                                    margin-top: 2%;
                                  "
                                >
                                  <div
                                    *ngIf="a.viewShow === 'true'"
                                    class="alert alert-success"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div>Document uploaded successfully.</div>
                                  </div>

                                  <div
                                    *ngIf="a.viewShow === 'error'"
                                    class="alert alert-danger"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div
                                      *ngIf="a.viewShow === 'error'"
                                      class=""
                                    >
                                      Document can not be uploaded,please try
                                      again.
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="a.viewShow === 'fileSizeError'"
                                    class="alert alert-danger"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div
                                      *ngIf="a.viewShow === 'fileSizeError'"
                                      class=""
                                    >
                                      File Size cannot exceed the maximum limit
                                      of 25 mb.
                                    </div>
                                  </div>

                                  <div
                                    *ngIf="a.viewShow === 'try'"
                                    class="alert alert-warning"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div *ngIf="a.viewShow === 'try'" class="">
                                      Uploading in process...
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="facilityDoc">
                            <ul *ngIf="facilityDoc.length">
                              <div
                                style="
                                  display: flex;
                                  margin-bottom: 3% !important;
                                "
                              >
                                <div
                                  class="divider2"
                                  style="width: 25%; margin-top: 4%"
                                ></div>
                                <div
                                  style="
                                    width: 46%;
                                    margin-top: 22px;
                                    text-align: center;
                                  "
                                >
                                  <h5 class="font-weight-700 text-uppercase">
                                    Facility Specific Documents
                                  </h5>
                                </div>
                                <div
                                  class="divider2"
                                  style="width: 25%; margin-top: 4%"
                                ></div>
                              </div>
                              <li class="innerLi" *ngFor="let a of facilityDoc">
                                <strong
                                  class="innerStrng"
                                  style="margin-top: 1%"
                                  >{{ a.doc_name }}
                                </strong>

                                <div style="display: flex">
                                  <div class="custom-file" style="width: 40%">
                                    <p
                                      class="m-a0"
                                      *ngIf="!a.file_name"
                                      style="display: block"
                                    >
                                      <i class="fa fa-upload"></i>
                                      Upload File
                                    </p>

                                    <p
                                      class="m-a0"
                                      *ngIf="a.file_name"
                                      style="display: block"
                                    >
                                      {{ a.file_name }}
                                    </p>
                                    <input
                                      type="file"
                                      class="site-button form-control"
                                      id="customFile"
                                      (change)="
                                        handleFileInput($event.target.files, a)
                                      "
                                    />
                                  </div>

                                  <div
                                    *ngIf="a.showProgressBar"
                                    style="margin-top: -20px"
                                  >
                                    <circle-progress
                                      [percent]="a.showPercentage"
                                      [radius]="40"
                                      [outerStrokeWidth]="6"
                                      [innerStrokeWidth]="4"
                                      [outerStrokeColor]="'#4C96D7'"
                                      [innerStrokeColor]="'#a6cbeb'"
                                      [animation]="false"
                                      [animationDuration]="10"
                                      [titleFontSize]="10"
                                      [renderOnClick]="false"
                                    >
                                    </circle-progress>
                                  </div>

                                  <div
                                    class="job-time margin_preview"
                                    *ngIf="a.exist === 'true'"
                                  >
                                    <a
                                      href="{{ apiUrl }}vcsapi/download/{{ a.rec_doc_id }}/{{ user_id }}/{{ a.doc_name }}"
                                      target="_blank"
                                      ><span style="margin: 14% 35%"
                                        >Preview</span
                                      ></a
                                    >
                                  </div>
                                </div>
                                <div
                                  style="
                                    margin-left: 22%;
                                    width: 55%;
                                    margin-top: 2%;
                                  "
                                >
                                  <div
                                    *ngIf="a.viewShow === 'true'"
                                    class="alert alert-success"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div>Document uploaded successfully.</div>
                                  </div>

                                  <div
                                    *ngIf="a.viewShow === 'fileSizeError'"
                                    class="alert alert-danger"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div
                                      *ngIf="a.viewShow === 'fileSizeError'"
                                      class=""
                                    >
                                      File Size cannot exceed the maximum limit
                                      of 25 mb.
                                    </div>
                                  </div>

                                  <div
                                    *ngIf="a.viewShow === 'error'"
                                    class="alert alert-danger"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div
                                      *ngIf="a.viewShow === 'error'"
                                      class=""
                                    >
                                      Document can not be uploaded,please try
                                      again.
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="a.viewShow === 'try'"
                                    class="alert alert-warning"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div *ngIf="a.viewShow === 'try'" class="">
                                      Uploading in process...
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="othersDoc">
                            <ul *ngIf="othersDoc.length">
                              <div
                                style="
                                  display: flex;
                                  margin-bottom: 3% !important;
                                "
                              >
                                <div class="divider2"></div>
                                <div
                                  style="
                                    width: 32%;
                                    margin-top: 4px;
                                    text-align: center;
                                  "
                                >
                                  <h5 class="font-weight-700 text-uppercase">
                                    Other Documents
                                  </h5>
                                </div>
                                <div class="divider2"></div>
                              </div>
                              <li class="innerLi" *ngFor="let a of othersDoc">
                                <strong
                                  class="innerStrng"
                                  style="margin-top: 1%"
                                  >{{ a.doc_name }}
                                </strong>

                                <div style="display: flex">
                                  <div class="custom-file" style="width: 40%">
                                    <p
                                      class="m-a0"
                                      *ngIf="!a.file_name"
                                      style="display: block"
                                    >
                                      <i class="fa fa-upload"></i>
                                      Upload File
                                    </p>

                                    <p
                                      class="m-a0"
                                      *ngIf="a.file_name"
                                      style="display: block"
                                    >
                                      {{ a.file_name }}
                                    </p>
                                    <input
                                      type="file"
                                      class="site-button form-control"
                                      id="customFile"
                                      (change)="
                                        handleFileInput($event.target.files, a)
                                      "
                                    />
                                  </div>

                                  <div
                                    *ngIf="a.showProgressBar"
                                    style="margin-top: -20px"
                                  >
                                    <circle-progress
                                      [percent]="a.showPercentage"
                                      [radius]="40"
                                      [outerStrokeWidth]="6"
                                      [innerStrokeWidth]="4"
                                      [outerStrokeColor]="'#4C96D7'"
                                      [innerStrokeColor]="'#a6cbeb'"
                                      [animation]="false"
                                      [animationDuration]="10"
                                      [titleFontSize]="10"
                                      [renderOnClick]="false"
                                    >
                                    </circle-progress>
                                  </div>

                                  <div
                                    class="job-time margin_preview"
                                    *ngIf="a.exist === 'true'"
                                  >
                                    <a
                                      href="{{ apiUrl }}vcsapi/download/{{ a.rec_doc_id }}/{{ user_id }}/{{ a.doc_name }}"
                                      target="_blank"
                                      ><span style="margin: 14% 35%"
                                        >Preview</span
                                      ></a
                                    >
                                  </div>
                                </div>
                                <div
                                  style="
                                    margin-left: 22%;
                                    width: 55%;
                                    margin-top: 2%;
                                  "
                                >
                                  <div
                                    *ngIf="a.viewShow === 'true'"
                                    class="alert alert-success"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div>Document uploaded successfully.</div>
                                  </div>

                                  <div
                                    *ngIf="a.viewShow === 'fileSizeError'"
                                    class="alert alert-danger"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div
                                      *ngIf="a.viewShow === 'fileSizeError'"
                                      class=""
                                    >
                                      File Size cannot exceed the maximum limit
                                      of 25 mb.
                                    </div>
                                  </div>

                                  <div
                                    *ngIf="a.viewShow === 'error'"
                                    class="alert alert-danger"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div
                                      *ngIf="a.viewShow === 'error'"
                                      class=""
                                    >
                                      Document can not be uploaded,please try
                                      again.
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="a.viewShow === 'try'"
                                    class="alert alert-warning"
                                    style="
                                      font-size: 12px;
                                      padding: 0.5rem 1.25rem;
                                    "
                                  >
                                    <div *ngIf="a.viewShow === 'try'" class="">
                                      Uploading in process...
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Modal End -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Browse Jobs END -->
      </div>
    </div>
    <!-- Content END-->
    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</body>
