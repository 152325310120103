import { Injectable } from "@angular/core";
import { catchError, retry } from "rxjs/operators";
import { throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import Swall from "sweetalert2";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class DropdownServiceService {
  header = new HttpHeaders().set(
    "Authorization",
    `Bearear ${sessionStorage.getItem('user_token')}`  );

  
  get apiUrl(): string {
    return environment.apiUrl;
  }
  constructor(public http: HttpClient) {}

  getApiTypesVendor() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/tbl/types/vendor", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }
  getApiProfession() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/tbl/profession", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getApiSpeciality() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/tbl/speciality", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getApiJobSector() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/tbl/job_sector", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getApiPositionType() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/tbl/position_type", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getApiJobType() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/tbl/job_type", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getApiSystemName() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/tbl/system_name/all", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getApiDesignation() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/api/tbl/designation/all", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getApiStandardDocument() {
    return this.http
      .get(
        this.apiUrl+"vcsapi/get/api/tbl/standard_document/all",
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /// add api

  addApiTypeVendor(details: any) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/add/api/tbl/type/vendor",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  addApiProfession(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/add/api/tbl/profession",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addApiSpeciality(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/add/api/tbl/speciality",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addApiJobSector(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/add/api/tbl/job_sector",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addApiPositionType(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/add/api/tbl/position_type",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addApiJobType(details) {
    return this.http
      .post(this.apiUrl+"vcsapi/add/api/tbl/job_type", details, {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  addApiSystemName(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/add/api/name/tbl/system_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addApiDocumentName(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/add/api/name/tbl/standard_document",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addApiDesignationName(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/add/api/tbl/designation",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /// edit api

  editApiTypeVendor(details: any) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/type/vendor/name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  editApiProfession(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/profession_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  editApiSpeciality(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/speciality_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  editApiJobSector(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/job_sector_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  editApiPositionType(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/position_type_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  editApiJobType(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/job_type_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  editApiSystemName(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/name/tbl/system_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  editApiStandardDocument(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/name/tbl/standard_document",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  editApiDesignation(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/designation_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /// status change

  statusApiTypeVendor(details: any) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/type/vendor/status",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  statusApiProfession(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/profession_status",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  statusApiSpeciality(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/speciality_status",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  statusApiJobSector(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/job_sector_status",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  statusApiPositionType(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/position_type_status",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  statusApiJobType(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/job_type_status",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  statusApiSystemName(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/status/tbl/system_name",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  statusApiStandardDocument(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/status/tbl/standard_document",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  statusApiDesignation(details) {
    return this.http
      .post(
        this.apiUrl+"vcsapi/update/api/designation_status",
        details,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  checkUniqueDesignation(desg) {
    return this.http
      .get(
        `${this.apiUrl}vcsapi/checkIfExists/designation/${desg}`,
        { headers: this.header }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  // delete Profession
  deleteApiTypeVendor(details: any) {
    return this.http.post(this.apiUrl+'vcsapi/type/vendor/delete/byID', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteApiProfession(details) {
    return this.http.post(this.apiUrl+'vcsapi/professionDelete/byID', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

 // delete Speciality

 deleteApiSpeciality(details){
  return this.http.post(this.apiUrl+'vcsapi/specialityDelete/byID', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
 }

  // delete Job Sector
 deleteApiJobSector(details){
  return this.http.post(this.apiUrl+'vcsapi/jobSectorDelete/byID', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
 }

 // delete Position Type
 deleteApiPositionType(details){
  return this.http.post(this.apiUrl+'vcsapi/positionTypeDelete/byID', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
 }
 //delete Job Type
 deleteApiJobType(details){
  return this.http.post(this.apiUrl+'vcsapi/positionJobDelete/byID', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
 }

 //delete System Name
 deleteApiSystemName(details){
  return this.http.post(this.apiUrl+'vcsapi/systemDelete/byID', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
 }

 deleteApiStandardDocument(details){
  return this.http.post(this.apiUrl+'vcsapi/standardDocumentDelete/byID', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );

 }
 deleteApiDesignation(details){
  return this.http.post(this.apiUrl+'vcsapi/designationDelete/byID', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );

 }

  handleError(error: any) {
    let Swal: any = Swall;
    if (error.status == 0) {
      Swal.fire({
        text: "No Internet Connection",
        type: "error",
      }).then(() => {
        location.reload();
      });
    } else if (error.status == 400) {
      Swal.fire({
        text: "Invalid Syntex",
        type: "error",
      });
    } else if (error.status == 403) {
      Swal.fire({
        text: "Unauthorized Access",
        type: "error",
      });
    } else if (error.status == 404) {
      Swal.fire({
        text: "URL Is Not Recognized",
        type: "error",
      });
    } else if (error.status == 500) {
      Swal.fire({
        text: "Internal Server Error",
        type: "error",
      });
    } else if (error.status == 501) {
      Swal.fire({
        text: "Not Implemented",
        type: "error",
      });
    } else if (error.status == 503) {
      Swal.fire({
        text: "Service Unavailable",
        type: "error",
      });
    } else if (error.status == 511) {
      Swal.fire({
        text: "Network Authentication Required",
        type: "error",
      });
    }
    return throwError(error.statusText);
  }
}
