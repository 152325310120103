<app-page-header
  heading="Our Clients"
  subheading="Your trusted partner through every step of the hiring process and staffing engagement."
></app-page-header>

<div class="page-container space-y-8 md:space-y-16 py-8 md:py-16">
  <section class="section-side-image-text-container">
    <div class="text-container left-text">
      <h3 class="typog_h3 text-gray-900">Managed Service Providers</h3>
      <p class="body">
        Collaborating with top MSPs, we cater to hundreds of healthcare facilities across the United States and India by providing Top Medical Talent.
      </p>
    </div>
    <div class="image-container right-image">
      <div class="image">
        <img
          src="../../assets/images/main/supplier-MSP-VMS.jpg"
          alt="White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull."
          class="object-cover object-center"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container right-text">
      <h3 class="typog_h3 text-gray-900">Providing Top Medical Talent</h3>
      <p class="body">
        Our teams are fully versed on the extensive vetting and onboarding
        process involved in successfully staffing medical professionals.
        Submitting fully vetted candidates within 48 hours of receiving a job
        requisition is key in getting positions filled quickly. Our recruiters
        stay close to their candidates to ensure a seamless hiring process.
      </p>
    </div>
    <div class="image-container left-image">
      <div class="image">
        <img
          src="../../assets/images/main/Healthcare.webp"
          alt="Detail of zipper pull with tan leather and silver rivet."
          class="object-cover object-center"
          loading="lazy"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container left-text">
      <h3 class="typog_h3 text-gray-900">
        Assisted Living / Senior Care Facilities
      </h3>
      <p class="body">
        Currently serving over thirty senior care facilities our independent and
        highly experienced per diem nurses have a minimum of 10 years experience
        caring for the elderly.
      </p>
    </div>
    <div class="image-container right-image">
      <div class="image">
        <img
          src="../../assets/images/main/senior-Nursing-Care.jpeg"
          alt="White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull."
          class="object-cover object-top"
          loading="lazy"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container right-text">
      <h3 class="typog_h3 text-gray-900">Tech Partnerships</h3>
      <p class="body">
        Transformational technologies demand equally transformative
        partnerships. We have partnered with major boutique tech firms across
        the country to develop and implement some of the most innovative and
        cutting-edge technologies. All supplied resources are hand-picked from
        the industry with diverse skill sets in Machine Learning, Artificial
        Intelligence, Blockchain, Cybersecurity, Big Data, RDBMS, Cloud
        Computing, Java technologies, .net technologies and Python programming.
      </p>
    </div>
    <div class="image-container left-image">
      <div class="image">
        <img
          src="../../assets/images/main/IT.jpg"
          alt="Detail of zipper pull with tan leather and silver rivet."
          class="object-cover object-center"
          loading="lazy"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container left-text">
      <h3 class="typog_h3 text-gray-900">Implementation</h3>
      <p class="body">
        Our resources will help you develop and enhance the processes of
        initiating, planning, executing, monitoring, managing, and closing a
        project. Placing emphasis on balancing scope, resources, and schedule to
        deliver a project’s requirements on-time and on-budget is key. Our
        combination of training, deployment planning, and consulting services
        has helped organizations completely convert their product development
        approaches to agile, resulting in significant speed to value.
      </p>
    </div>
    <div class="image-container right-image">
      <div class="image">
        <img
          src="../../assets/images/main/Finance.webp"
          alt="White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull."
          class="object-cover object-top"
          loading="lazy"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container right-text">
      <h3 class="typog_h3 text-gray-900">Industry Experience</h3>
      <p class="body">
        Experienced in supporting a variety of verticals providing top talent.
        We have partnered with companies/institutions in the following areas:
      </p>
      <ul class="mt-4 list-disc pl-4 text-gray-900">
        <li>Healthcare</li>
        <li>Pharmaceutical</li>
        <li>Life Science</li>
        <li>Banking</li>
        <li>Retail</li>
        <li>Infrastructure</li>
        <li>Aviation</li>
      </ul>
    </div>
    <div class="image-container left-image">
      <div class="image">
        <img
          src="../../assets/images/main/finance-banking.webp"
          alt="IT Service"
          class="object-cover object-center"
          loading="lazy"
        />
      </div>
    </div>
  </section>
</div>
<app-cta></app-cta>
