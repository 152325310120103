<app-page-header
  heading="Industrial and Professional Services"
  subheading=""
></app-page-header>
<div class="relative overflow-hidden bg-white pt-16 pb-32">
  <div class="relative">
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div
        class="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0"
      >
        <div>
          <div class="mt-2">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">
              Light Industrial Staffing
            </h2>
            <ul class="mt-6 leading-8">
              <li>Assembly  Workers</li>
              <li>Drivers</li>
              <li>Fabricators</li>
              <li>Installers</li>
              <li>General Labor</li>
              <li>Machine Operators</li>
              <li>Mechanics</li>
              <li>Pick & Pack</li>
              <li>Shipping & Receiving</li>
              <li>Warehouse/Forklift</li>
              <li>Welders</li>
              <li>Leadership</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div
          class="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0"
        >
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src="../../assets/web-images/main/ind1.jpg"
            alt="Inbox user interface"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mt-24">
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div
        class="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0"
      >
        <div>
          <div class="mt-6">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">
              Administrative Staffing
            </h2>
            <ul class="mt-6 leading-8">
              <li>Office Manager</li>
              <li>Administrative Assistant</li>
              <li>General Clerical</li>
              <li>Customer Service</li>
              <li>Call Center Representative</li>
              <li>Accounting Clerk</li>
              <li>Human Resources Representative</li>
              <li>Executive Search</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
        <div
          class="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0"
        >
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
            src="../../assets/web-images/main/ind2.jpg"
            alt="Customer profile user interface"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mt-24">
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div
        class="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0"
      >
        <div>
          <div class="mt-2">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">
              Staffing Capabilities
            </h2>
            <ul class="mt-6 leading-8">
              <li>Temporary/Temp to hire</li>
              <li>Permanent Placement</li>
              <li>Seasonal Support</li>
              <li>Short term fill-ins</li>
              <li>Part-time</li>
              <li>Employer of Record (EoR)</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div
          class="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0"
        >
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src="../../assets/web-images/main/ind3.jpg"
            alt="Inbox user interface"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<app-cta></app-cta>
