import { Component, OnInit } from '@angular/core';
import { UserRoleServiceService } from './user-role-service.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss'],
})
export class UserRoleComponent implements OnInit {
  allUserRole: any;
  addUserRoleForm: UntypedFormGroup;
  editUserRoleForm: UntypedFormGroup;
  defaultStatus = 'active';
  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */

  userRoleID: any;

  assignAccessCheckBoxList = [];
  sortedModuleName: any;
  sortedModuleIds: any;

  accessRoleAllData: any = [];
  accessRoleAllDatacopy: any = [];

  defaultAccessShow = [];
  defaultAccessModuleNameShow: any = [];
  defaultAccessModuleIdShow: any;

  role: any;
  incentive_perc: any;

  duplicateCheck: any = false;

  moduleArray: any = [];

  constructor(
    public service: UserRoleServiceService,
    public fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {}

  ngOnInit() {
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
    this.getUserRoleDetailsAll();

    this.addUserRoleForm = this.fb.group({
      role: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      incentive_perc: new UntypedFormControl(),
    });

    this.editUserRoleForm = this.fb.group({
      editRole: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      incentive_perc: new UntypedFormControl(),
    });
    this.getAllModuleSubModule();
  }

  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }
  ///////////////////////

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }
  /////////////

  getUserRoleDetailsAll() {
    this.service.getUserRoleDetails().subscribe((r) => {
      
      this.allUserRole = r;
    });
  }

  insertUserRole() {
    const data = {
      role_name: this.addUserRoleForm.controls['role'].value,
      incentive_perc: 0,
    };
    this.service.addUserRole(data).subscribe((r) => {
      
      if (r === 'success') {
        Swal.fire({
          title: 'User Role Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addUserRoleForm.reset();
            this.getUserRoleDetailsAll();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  setUSerRoleId(value) {
    this.role = null;
    this.userRoleID = value.role_id;
    this.tempRole = value.role_name;
    this.editUserRoleForm.setValue({
      editRole: value.role_name,
      incentive_perc: 0,
    });
    this.defaultStatus = value.role_status;
  }

  resetAddRole() {
    this.addUserRoleForm.reset();
    this.role = null;
  }

  updateUserRole() {
    const data = {
      role_name: this.editUserRoleForm.controls['editRole'].value,
      role_id: this.userRoleID,
      incentive_perc: 0,
    };
    this.service.updateUserRole(data).subscribe((r) => {
      
      if (r === 'success') {
        Swal.fire({
          title: 'User Role Updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editUserRoleForm.reset();
            this.getUserRoleDetailsAll();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  changeStatusUserRole() {
    const data = {
      role_status: this.defaultStatus,
      role_id: this.userRoleID,
    };
    
    this.service.changeStatusUserRole(data).subscribe((r) => {
      
      if (r === 'success') {
        Swal.fire({
          title: 'Department Updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getUserRoleDetailsAll();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  getAllModuleSubModule() {
    this.accessRoleAllData = [];
    this.accessRoleAllDatacopy = [];
    this.sortedModuleName = [];
    this.sortedModuleIds = null;
    this.service.moduleSubModuleShow().subscribe((r) => {
      
      let duplicateModuleName = [];
      let sortModulename: any;
      for (const key in r) {
        r[key].value = false;
        duplicateModuleName.push(r[key].module_id);
        this.accessRoleAllData.push(r[key]);
        this.accessRoleAllDatacopy.push(r[key]);
      }
      this.sortedModuleIds = duplicateModuleName.filter(function (item, index) {
        return duplicateModuleName.indexOf(item) === index;
      });
      for (const key in this.sortedModuleIds) {
        for (const keys in this.accessRoleAllData) {
          if (
            this.sortedModuleIds[key] === this.accessRoleAllData[keys].module_id
          ) {
            this.sortedModuleName.push(
              this.accessRoleAllData[keys].module_name
            );
            break;
          }
        }
      }
    });
  }

  forCheckingNumber = 0;

  getList(ev, details) {
    if (ev.target.checked === true) {
      if (this.assignAccessCheckBoxList.length > 0) {
        for (const i in this.assignAccessCheckBoxList) {
          if (
            details.action_id !== this.assignAccessCheckBoxList[i].action_id
          ) {
            this.forCheckingNumber += 1;
            if (
              this.forCheckingNumber === this.assignAccessCheckBoxList.length
            ) {
              const data = {
                value: true,
                action_id: details.action_id,
              };
              this.assignAccessCheckBoxList.push(data);
              this.forCheckingNumber = 0;
            }
          }
        }
      } else {
        const data = {
          value: true,
          action_id: details.action_id,
        };
        this.assignAccessCheckBoxList.push(data);
      }
    } else if (ev.target.checked === false) {
      for (let i = 0; i < this.assignAccessCheckBoxList.length; i++) {
        if (this.assignAccessCheckBoxList[i].action_id === details.action_id) {
          this.assignAccessCheckBoxList.splice(i, 1);
        }
      }
    }
    
  }

  getDedfaultData(data) {
    this.assignAccessCheckBoxList = [];
    this.assignAccessCheckBoxList = [];
    this.userRoleID = data.role_id;
    this.accessRoleAllData = [];
    const json = {
      role_id: data.role_id,
    };
    
    this.service.getDefaultRoleData(json).subscribe((r: any) => {
      
      for (const key in this.accessRoleAllDatacopy) {
        this.accessRoleAllDatacopy[key].value = false;
        for (const keys in r) {
          if (this.accessRoleAllDatacopy[key].action_id === r[keys].action_id) {
            this.accessRoleAllDatacopy[key].value = true;
            const data = {
              value: true,
              action_id: r[keys].action_id,
            };
            this.assignAccessCheckBoxList.push(data);
            
          }
        }
        this.accessRoleAllData.push(this.accessRoleAllDatacopy[key]);
      }
    });
  }

  getDedfaultData2(data) {
    this.defaultAccessShow = [];
    this.defaultAccessModuleNameShow = [];
    this.defaultAccessModuleIdShow = null;
    const json = {
      role_id: data.role_id,
    };
    this.service.getDefaultRoleData(json).subscribe((r: any) => {
      
      let duplicateModuleId = [];
      for (const key in r) {
        r[key].value = false;
        duplicateModuleId.push(r[key].module_id);
        this.defaultAccessShow.push(r[key]);
      }
      this.defaultAccessModuleIdShow = duplicateModuleId.filter(function (
        item,
        index
      ) {
        return duplicateModuleId.indexOf(item) === index;
      });

      for (const key in this.defaultAccessModuleIdShow) {
        for (const keys in this.defaultAccessShow) {
          if (
            this.defaultAccessModuleIdShow[key] ===
            this.defaultAccessShow[keys].module_id
          ) {
            this.defaultAccessModuleNameShow.push(
              this.defaultAccessShow[keys].module_name
            );
            break;
          }
        }
      }
    });
  }

  insertRoleData() {
    const data = {
      role_id: this.userRoleID,
      data: this.assignAccessCheckBoxList,
    };
    
    this.service.postAccessRole(data).subscribe((r) => {
      
      if (r === 'check_value') {
        Swal.fire({
          title: 'Assign Access Updated Successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  tempRole: any;

  roleDuplicateCheck(value) {
    if (value != null) {
      const data = {
        role_name: value.toLowerCase().trim(),
      };
      this.service.duplicateRoleCheck(data).subscribe((r: any) => {
        if (r.length > 0 && this.tempRole !== value) {
          this.duplicateCheck = true;
        } else {
          this.duplicateCheck = false;
        }
      });
    }
  }
  deleteRole(value){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deleteRoleData(value.role_id).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg("User role deteted successfully.");
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
        }, err => {
          
          this.errorMsg("Something went wrong,please try again!");
        })
      }
    });

  }

  errorMsg(msg) {
    Swal.fire({
      title: msg,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
  }

  successMsg(msg) {
    Swal.fire({
      title: msg,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        //window.location.reload();
        this.getUserRoleDetailsAll();
      }
    })
  }
}
