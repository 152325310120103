<app-page-header heading="Jobs" subheading=""></app-page-header>
<section class="section-container">
  <form class="dezPlaceAni">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="form-group">
          <!-- <label>Job Title, Keywords, or Phrase</label> -->
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Job Title, Keywords, or Phrase"
              [(ngModel)]="search1"
              [ngModelOptions]="{ standalone: true }"
            />
            <div class="input-group-append">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="form-group">
          <!-- <label>City, State or Country</label> -->
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="City, State or Country"
              [(ngModel)]="search2"
              [ngModelOptions]="{ standalone: true }"
            />
            <div class="input-group-append">
              <span class="input-group-text"
                ><i class="fa fa-map-marker"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="form-group" style="display: grid">
          <select
            class="form-control"
            [(ngModel)]="search3"
            [ngModelOptions]="{ standalone: true }"
          >
            <option value="" selected style="color: gray !important">
              Select Position Type
            </option>

            <option *ngFor="let i of positiontype" [value]="i.position_type_id">
              {{ i.position_type_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-2 col-md-6">
        <button
          type="button"
          type="submit"
          (click)="getAllJobs()"
          class="relative inline-flex items-center rounded-sm border border-transparent bg-sky-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800 uppercase transition-colors duration-300"
        >
          <span>Find Job</span>
        </button>
      </div>
    </div>
  </form>
</section>
<section class="relative px-6 md:px-8 pb-16">
  <div class="content-block">
    <!-- Filters Search -->
    <div class="section-full browse-job-find">
      <div class="container">
        <div class="find-job-bx"></div>
      </div>
    </div>

    <!-- Filters Search END -->
    <div class="section-full bg-white p-b50">
      <div class="container">
        <div class="job-bx-title clearfix">
          <h5 class="font-weight-700 pull-left text-uppercase">
            {{ allJobs.length }} Jobs Found
          </h5>
        </div>
        <ul class="post-job-bx browse-job-grid row">
          <li
            *ngFor="let data of allJobs; index as i"
            class="col-lg-4 col-md-6"
          >
            <div class="post-bx">
              <div class="d-flex m-b30">
                <div class="job-post-info">
                  <h5>
                    <a
                      style="cursor: pointer"
                      href="/job-details?special={{ data.job_id }}"
                      (click)="goTo(data)"
                      >{{ data.job_title }}</a
                    >
                  </h5>
                  <ul>
                    <li style="display: block">
                      <i class="fa fa-map-marker"></i> {{ data.city }},
                      {{ data.state }}
                    </li>
                    <li style="display: block" *ngIf="data.position_type_name">
                      <i class="fa fa-bookmark-o"></i>
                      {{ data.position_type_name }}
                    </li>
                    <li style="display: block">
                      <i class="fa fa-clock-o"></i> Published
                      {{ data.job_post_date }}
                    </li>
                    <li style="display: block" *ngIf="!data.position_type_name">
                      <span>&nbsp;&nbsp;</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex">
                <div class="job-time mr-auto" *ngIf="data.job_type_name">
                  <a style="cursor: pointer"
                    ><span>{{ data.job_type_name }}</span></a
                  >
                </div>
                <div style="margin-right: 34%" *ngIf="!data.job_type_name">
                  <span></span>
                </div>

                <div class="job-time mr-auto">
                  <!-- <h5>${{ data.regular_pay }} - ${{ data.blended_pay }} /hr</h5> -->
                  <h5>
                    ${{ data.minimum_rate }} - ${{ data.maximum_rate }} 
                    {{data.rate === 'per hour' ? '/hr' : null}}
                    {{data.rate === 'per week' ? '/week' : null}}
                    {{data.rate === 'per month' ? '/mn' : null}}
                    {{data.rate === 'per year' ? '/yr' : null}}
                  </h5>
                </div>
                <div class="salary-bx" *ngIf="checkLoggedIn && checkUserType">
                  <span
                    ><button
                      type="submit"
                      class="site-button btn-block buttonApply"
                      (click)="jobApply(data, i)"
                    >
                      APPLY
                    </button></span
                  >
                </div>
                <div class="salary-bx" *ngIf="checkLoggedIn && !checkUserType">
                  <span
                    ><button
                      type="submit"
                      class="site-button btn-block buttonApply"
                      (click)="createAccountCheck(data)"
                    >
                      APPLY
                    </button></span
                  >
                </div>
                <div class="salary-bx" *ngIf="!checkLoggedIn">
                  <span
                    ><button
                      type="submit"
                      class="site-button btn-block buttonApply"
                      (click)="loginGuest(data)"
                    >
                      APPLY
                    </button></span
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Browse Jobs END -->
  </div>
</section>
<!-- Modal Box -->
<div class="modal fade lead-form-modal" id="login" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        #closeModal
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body row m-a0 clearfix">
        <div
          class="col-lg-6 col-md-6 overlay-primary-dark d-flex p-a0"
          style="
            background-image: url(../assets/images/background/login_2.jpg);
            background-position: center;
            background-size: cover;
          "
        >
          <div class="form-info text-white align-self-center">
            <h3 class="m-b15">Login To Apply</h3>
            <p class="m-b15">Welcome to Vish Consulting Services, USA.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 p-a0">
          <div class="lead-form browse-job text-left">
            <form [formGroup]="loginForm">
              <h3 class="m-t0">Login</h3>
              <div class="form-group">
                <input
                  value=""
                  class="form-control"
                  placeholder="E-Mail Address"
                  formControlName="email"
                />
                <div
                  *ngIf="
                    loginForm.controls['email'].invalid &&
                    (loginForm.controls['email'].dirty ||
                      loginForm.controls['email'].touched)
                  "
                  class="alert alert-danger"
                  style="font-size: 12px; padding: 0.5rem 1.25rem"
                >
                  <div *ngIf="loginForm.controls['email'].errors?.['required']">
                    Email is required.
                  </div>
                  <div *ngIf="loginForm.controls['email'].invalid">
                    Email is invalid.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <input
                  value=""
                  class="form-control"
                  placeholder="Password"
                  type="password"
                  formControlName="password"
                />
                <div
                  *ngIf="
                    loginForm.controls['password'].invalid &&
                    (loginForm.controls['password'].dirty ||
                      loginForm.controls['password'].touched)
                  "
                  class="alert alert-danger"
                  style="font-size: 12px; padding: 0.5rem 1.25rem"
                >
                  <div *ngIf="loginForm.controls['password'].errors?.['required']">
                    Password is required.
                  </div>
                </div>
              </div>
              <div class="clearfix">
                <button
                  type="button"
                  class="btn-primary site-button btn-block"
                  [disabled]="loginForm.invalid"
                  [ngStyle]="{
                    cursor: !loginForm.valid ? 'not-allowed' : 'pointer'
                  }"
                  (click)="login()"
                >
                  Login
                </button>
              </div>
              <div class="clearfix">
                <button
                  type="button"
                  class="btn-primary site-button btn-block"
                  style="margin-top: 1%"
                  (click)="loginGuest2()"
                >
                  Apply without Login
                </button>
              </div>
              <div style="text-align: center; margin-top: 2%">
                <small class="clearfix"
                  >Not Registered Yet?
                  <a href="javascript:void(0);" (click)="createAccountCheck2()"
                    >Register here</a
                  >
                </small>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Box End -->
