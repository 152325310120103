<app-admin-default-layout>
    <div class="col-xl-10 col-lg-10 m-b30">
        <div class="job-bx submit-resume">
            <div class="job-bx-title clearfix">
                <h5 class="font-weight-700 pull-left text-uppercase">Company Alert</h5>
                <table class="table-job-bx cv-manager company-manage-job">
                    <thead>
                        <tr>
                        <th>Doc. Name</th>
                        <th>Doc. Type</th>
                        <th>Expiry Date</th>
                        <th>Document</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="companyDocumentAlert.length == 0">
                        <tr><td colspan="7">No record found</td></tr>
                    </tbody>
                    <tbody *ngIf="companyDocumentAlert.length != 0">
                        <tr *ngFor="let a of companyDocumentAlert" class="itemList">
                            <td class="job-name">
                                <a href="javascript:void(0);" style="text-transform: capitalize; font-size:14px;" class="link">{{a.doc_name}}</a>
                            </td>
                            <td class="application text-primary" style="text-transform: capitalize;font-size:14px;">
                                {{a.doc_type}}</td>
                            <td class="job-name">
                                <a href="javascript:void(0);" style="text-transform: capitalize;font-size:14px;" class="link2" >{{a.expiry_date}}</a>
                            </td>
                            <td class="application text-primary" style="text-transform: capitalize;font-size:14px;">
                                <a style="text-decoration: none;" href="{{apiUrl}}vcsapi/download/{{a.id}}/{{a.company_id}}" target="_blank" class="site-button">Download</a>
                            </td>
                            <td class="job-name">
                                <a href="javascript:void(0);" (click)="markToRead(a)">Mark to history</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</app-admin-default-layout>