<section class="page-header-container">
  <div
    class="absolute top-1/2 left-20 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2"
  >
    <svg
      viewBox="0 0 558 558"
      width="558"
      height="558"
      fill="none"
      aria-hidden="true"
      class="animate-spin-slower"
    >
      <defs>
        <linearGradient
          id=":R1hm:"
          x1="79"
          y1="16"
          x2="105"
          y2="237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#fff"></stop>
          <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
        </linearGradient>
      </defs>
      <path
        opacity=".2"
        d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
        stroke="#fff"
      ></path>
      <path
        d="M1 279C1 125.465 125.465 1 279 1"
        stroke="url(#:R1hm:)"
        stroke-linecap="round"
      ></path>
    </svg>
  </div>
  <div class="text-container">
    <h2 class="title">
      {{ heading }}
    </h2>
    <p class="subtitle">
      {{ subheading }}
    </p>
  </div>
</section>
