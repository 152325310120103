<button
  type="button"
  class="all-buttons"
  [ngClass]="{
    'size-normal': size === 'normal',
    'size-lg': size === 'lg',
    'button-primary': buttonType === 'primary',
    'button-secondary': buttonType === 'secondary',
    'button-transparent': buttonType === 'transparent'
  }"
>
  <mat-icon
    class="-ml-1 mr-2 text-base"
    *ngIf="hasIcon && iconLocation === 'left'"
    >{{ iconType }}</mat-icon
  >

  <span>{{ label }}</span>
  <mat-icon
    class="-mr-1 ml-2 text-base"
    *ngIf="hasIcon && iconLocation === 'right'"
    >{{ iconType }}</mat-icon
  >
</button>
