import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';
import { Country, State, City } from 'country-state-city';

@Component({
  selector: 'app-post-job',
  templateUrl: './post-job.component.html',
  styleUrls: ['./post-job.component.scss'],
})
export class PostJobComponent implements OnInit {
  filteredCity: any = [];
  postJobForm: UntypedFormGroup;
  blended_pay: any;
  regular_pay: any;
  moduleArray: any[];
  jobType: any = [];
  positiontype: any = [];
  jobSector: any = [];
  clientList: any = [];
  systemList: any = [];
  shit_details: any = ['Day'];
  ot_holiday_pay_rate_traveller: any;
  ot_holiday_pay_rate_local: any;
  states: any = [];
  stateList: boolean = false;
  filterArrayState: any = [];
  cityList: boolean = false;
  filterArrayCity: any = [];

  state = new UntypedFormControl(null, [Validators.required])
  city = new UntypedFormControl(null, [Validators.required])
  constructor(
    public fb: UntypedFormBuilder,
    public http: AdminService,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.postJobForm = this.fb.group({
      job_id: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      state: this.state,
      city: this.city,
      job_title: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      job_desc: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(1000),
      ]),
      minimum_rate: new UntypedFormControl(null, [
        Validators.required,
        Validators.max(999999999999999999999999999999),
      ]),
      maximum_rate: new UntypedFormControl(null, [
        Validators.required,
        Validators.max(999999999999999999999999999999),
      ]),
      rate: new UntypedFormControl(null, [ Validators.required]),

      position: new UntypedFormControl(null, [Validators.required]),
      job_type: new UntypedFormControl(null),
      job_sector: new UntypedFormControl(null, [Validators.required]),
      system_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(200),
      ]),
      client_name: new UntypedFormControl(null, [Validators.required]),
      req_inf: new UntypedFormControl(null, Validators.maxLength(1000)),
      job_duration: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(50),
      ]),
    });
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
    this.states = State.getStatesOfCountry('US');
    this.filterArrayState = State.getStatesOfCountry('US');

    this.getAllManageDetails();

    this.state.enable();
    this.states = State.getStatesOfCountry("US");
    this.state.valueChanges.subscribe((state) => {
      this.city.reset();
      this.city.disable();
      if (state) {
        this.filteredCity = City.getCitiesOfState('US', state);
        this.city.enable();
      }
    });
  }

  getAllManageDetails() {
    this.jobType = [];
    this.positiontype = [];
    this.jobSector = [];
    this.clientList = [];
    this.systemList = [];

    this.http.getAllManageJobDetails().subscribe((res: any) => {
      if(res.success){
        this.jobType = res.data.job_type;
        this.positiontype = res.data.position_type;
        this.jobSector = res.data.job_sector;
        this.clientList = res.data.client;
        this.systemList = res.data.jobclients;
      }
    });
  }

  verifyJobId() {
    let job_id = `VCS101${this.postJobForm.controls['job_id'].value}`
    this.http.verifyJobId(job_id).subscribe(
      (res: any) => {
        if (res === 'already exists') {
          this.errMsg('This job ID already exists.');
        } else if (res === 'not exists') {
        }
      },
      (err) => {
        this.errMsg('Something went wrong,please try again.');
      }
    );
  }

  changeOTRate(e) {
    this.ot_holiday_pay_rate_traveller = (
      Number(this.postJobForm.controls['ot_holiday_rate'].value) / 1.28
    ).toFixed(2);
    this.ot_holiday_pay_rate_local = (
      Number(this.postJobForm.controls['ot_holiday_rate'].value) / 1.37
    ).toFixed(2);
    var t = e.target.value;
    e.target.value =
      t.indexOf('.') >= 0
        ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3)
        : t;

    ///// Restrict negative by typing
    var key = !isNaN(e.charCode) ? e.charCode : e.keyCode;
    function keyAllowed() {
      var keys = [
        8, 9, 13, 16, 17, 18, 19, 20, 27, 46, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 91, 92, 93,
      ];
      if (key && keys.indexOf(key) === -1) return false;
      else return true;
    }
    if (!keyAllowed()) e.preventDefault();
  }

  checkBoxSelect(value) {
    let index = this.shit_details.indexOf(value);
    if (index > -1) {
      this.shit_details.splice(index, 1);
    } else {
      this.shit_details.push(value);
    }
  }

  submit() {
    this.http.spinnerShow();
    let date = new Date();
    let selectedState = this.states.filter(el => el.isoCode == this.postJobForm.controls['state'].value)

    let strTime = date.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    });

    if(this.postJobForm.valid){
      let data = {
        position_type: this.postJobForm.controls['position'].value,
        client_id: this.postJobForm.controls['client_name'].value,
        job_no: `VCS101${this.postJobForm.controls['job_id'].value}`,
        job_title: this.postJobForm.controls['job_title'].value,
        job_type: this.postJobForm.controls['job_type'].value,
        country: 'USA',
        state: selectedState && selectedState.length > 0 && selectedState[0].name,
        city: this.postJobForm.controls['city'].value,

        minimum_rate: this.postJobForm.controls['minimum_rate'].value,
        maximum_rate: this.postJobForm.controls['maximum_rate'].value,
        rate: this.postJobForm.controls['rate'].value,

        job_description: this.postJobForm.controls['job_desc'].value,
        job_post_by: sessionStorage.getItem('user_id'),
        job_post_date: moment(strTime).format('MM/DD/YYYY'),
        req_information: this.postJobForm.controls['req_inf'].value,
        system_name: this.postJobForm.controls['system_name'].value,
        job_sector: this.postJobForm.controls['job_sector'].value,
        duration: this.postJobForm.controls['job_duration'].value,
        shift: this.shit_details.join(','),
      };
      this.http.postJob(data).subscribe(
        (res) => {
          if (res === 'success') {
            this.http.spinnerHide();
            Swal.fire({
              title: 'Job posted successfully.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.postJobForm.reset();
              }
            });
          } else {
            this.http.spinnerHide();
            Swal.fire({
              title: 'Something went wrong,please try again.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
          }
        },
        (err) => {
          this.http.spinnerHide();
          Swal.fire({
            title: 'Something went wrong,please try again.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      );
    }else{
      this.http.spinnerHide();
    }
  }

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  /////////////////

  errMsg(msg) {
    Swal.fire({
      title: msg,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.postJobForm.get('job_id').reset();
      }
    });
  }
}
