<main class="bg-white">
  <section class="section-hero">
    <div class="image-container">
      <img
        class="image"
        src="../../assets/images/main/vcs_main_image_5.webp"
        alt="Elite Mente"
        width="1903"
        height="740"
      />
    </div>
    <div class="text-container">
      <div class="tyopg_h1 flex flex-column justify-center items-center">
        <h1 class="title">Vish Consulting Services</h1>
        <span class="subtitle">Leading Provider of Staffing and Recruitment Services</span>
        <a class="seal" target="_blank" href="https://www.jointcommission.org/"><img src="../../assets/images/logo/GoldSeal.png" width="170" class="m-b15 goldlog" alt="Joint Commission Certified" loading="lazy"></a>
       <span  style="filter: brightness(200%);" class="sub-text subtitle" >The Joint Commission Certified Company</span>
      </div>
      
      <div class="action-button">
        <app-buttons
          label="View All Jobs"
          [hasIcon]="false"
          buttonType="secondary"
          routerLink="/jobs"
          size="lg"
        ></app-buttons>
      </div>
    </div>
  </section>
  <section class="section-container">
    <div class="relative mx-auto">
      <div class="cards-grid-3-container">
        <div class="image-card">
          <div class="flex-shrink-0">
            <img
              class="image"
              src="../../assets/images/main/senior-Nursing-Care.jpeg"
              alt=""
              loading="lazy"
              width="520"
              height="192"
            />
          </div>
          <div class="body">
            <div class="flex-1">
              <p class="title">OUR HEALTHCARE SERVICES</p>
              <p class="content">
                Providing outstanding staffing services to our healthcare
                clients is our top priority. Treating our healthcare
                professionals like the heroes they are, is an honor.
              </p>
            </div>
          </div>
        </div>

        <div class="image-card">
          <div class="flex-shrink-0">
            <img
              class="image"
              src="../../assets/images/main/business_main_page.jpg"
              alt=""
              width="520"
              loading="lazy"
              height="192"
            />
          </div>
          <div class="body">
            <div class="flex-1">
              <p class="title">CLIENTS &amp; PARTNERS</p>
              <p class="content">
                By offering an array of services working within our clients
                budgets, we are able to provide top candidates and incredible
                business solutions to meet our clients needs.
              </p>
            </div>
          </div>
        </div>

        <div class="image-card">
          <div class="flex-shrink-0">
            <img
              class="image"
              src="../../assets/images/main/job-seeker.jpg"
              alt=""
              width="520"
              loading="lazy"
              height="192"
            />
          </div>
          <div class="body">
            <div class="flex-1">
              <p class="title">Jobs</p>
              <p class="content">
                Enhance your career with Vish! Check out our latest jobs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="relative px-6 md:px-8 py-2">
    <div class="relative mx-auto">
      <div class="md:flex md:items-center md:justify-between pb-4">
        <div class="min-w-0 flex-1">
          <h2 class="typog_h3">Recent Jobs</h2>
        </div>
        <div class="mt-4 flex md:mt-0 md:ml-4">
          <app-buttons
            label="View all jobs"
            iconLocation="right"
            [hasIcon]="true"
            size="normal"
            buttonType="transparent"
            iconType="keyboard_arrow_right"
            routerLink="/jobs"
          ></app-buttons>
        </div>
      </div>

      <div class="overflow-hidden bg-gray-100 shadow sm:rounded-md">
        <ul role="list" class="divide-y divide-gray-300">
          <li *ngFor="let job of allJobs.slice(0, 5)">
            <a href="#" class="block hover:bg-gray-50" (click)="disableLink($event)">
              <div class="px-4 py-4 sm:px-6">
                <div class="flex items-center justify-between">
                  <p class="truncate text-base font-bold text-gray-800">
                    {{ job.job_title }}
                  </p>
                  <div class="ml-2 flex flex-shrink-0">
                    <p
                      class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                    >
                      {{ job.job_type_name }}
                    </p>
                  </div>
                </div>
                <div class="mt-2 sm:flex sm:justify-between">
                  <div class="sm:flex">
                    <p class="flex items-center text-sm text-gray-500">
                      <mat-icon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        >work</mat-icon
                      >
                      {{ job.position_type_name }}
                    </p>
                    <p
                      class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
                    >
                      <mat-icon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      >
                        location_on</mat-icon
                      >

                      {{ job.city + ", " ?? "" }} {{ job.state ?? "" }}
                    </p>
                  </div>
                  <div
                    class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
                  >
                    <mat-icon class="h-5 w-5 flex-shrink-0 text-gray-400">
                      attach_money</mat-icon
                    >
                    <p>
                      ${{ job.minimum_rate }} - ${{ job.maximum_rate }} 
                      {{job.rate === 'per hour' ? '/hr' : null}}
                      {{job.rate === 'per week' ? '/week' : null}}
                      {{job.rate === 'per month' ? '/mn' : null}}
                      {{job.rate === 'per year' ? '/yr' : null}}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section class="section-container-min">&nbsp;</section>
  <app-cta></app-cta>
</main>
