import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/services/jobs.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  allJobs: any = [];
  search1: any = '';
  search2: any = '';
  search3: any = '';

  constructor(private _jobsService: JobsService) {}

  ngOnInit(): void {
    this.getAllJobs();
  }
  disableLink(event: Event): void {
    event.preventDefault();
    // Optionally, you can add additional logic here if needed
  }
  getAllJobs() {
    this.allJobs = [];
    let jobsObj = {
      s1: this.search1,
      s2: this.search2,
      s3: this.search3,
    };

    this._jobsService.fetchJobsTop5(jobsObj).subscribe({
      next: (res: any) => {
        this.allJobs = res;
      },
      error: (error: any) => {
      },
    });
  }
}
