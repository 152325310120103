<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Non IT Engagements
        </h5>
        <div class="float-right" style="display: flex;align-items:center;">
          <div class="form-group" style="margin-right: 10px">
            <input type="text" class="form-control2" [(ngModel)]="searchData" placeholder="Search" />
          </div>
          <div class="col-lg-2 col-md-2 addEng">
            <button type="button" class="site-button" data-toggle="modal" data-target="#addEngagement">
              Create Non IT engagement
            </button>
          </div>
        </div>
      </div>

      <div class="job-bx-title clearfix">
        <div class="form-group">
          <div class="row">
            <form [formGroup]="searchEngagements" style="display: inline-flex;">
              <div class="col-lg-2 col-md-2" style="margin-left: 12px">
                <label style="font-size: 14px">Eng Status</label>
                <select class="form-control" formControlName="eng_status" style="height: 38px !important">
                  <option value="All" selected>All</option>
                  <option *ngFor="let statsEng of statusEng" [value]="statsEng"
                    style="text-transform: capitalize">
                    {{ statsEng }}
                  </option>
                </select>

              </div>

              <div class="col-lg-2 col-md-2" style="margin-left: 12px">
                <label style="font-size: 14px">Client</label>
                <select class="form-control" formControlName="client_id" style="height: 38px !important">
                  <option value="ALL" selected>All</option>
                  <option *ngFor="let data of allClients | sortBy:'client_name'" [value]="data.client_id"
                    style="text-transform: capitalize">
                    {{ data.client_name }}
                  </option>
                </select>
              </div>

              <div class="col-lg-3 col-md-3" style="margin-left: 12px">
                <label style="font-size: 14px">State</label>

                <select class="form-control" formControlName="state" style="height: 38px !important">
                  <option value="ALL" selected>All</option>
                  <option *ngFor="let state of states" [value]="state.isoCode">
                    {{ state.name }} ({{ state.isoCode }})
                  </option>
                </select>
              </div>

              <div class="col-lg-3 col-md-3" style="margin-left: 12px">
                <label style="width: 100%; font-size: 14px;">City</label>
                <select formControlName="city" style="height: 38px !important; width: 100%;">
                  <option value="ALL" selected>All</option>
                  <option *ngFor="let city of cities" [value]="city.name"
                    style="text-transform: capitalize">
                    {{ city.name }}
                  </option>
                </select>
              </div>

              <div>
                <div style="display: inline-block; margin-bottom: 0.5rem"></div>
                <div type="button" class="site-button2" (click)="searchEngagement()">
                  Search
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <button (click)="exportexcel()" type="button" class="site-button"
        style="float: right; margin-bottom: 10px;">Export to Excel</button>
      <table id="excel-table" class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th>Eng#</th>
            <th>Status</th>
            <th>Employee Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>State</th>
            <th hidden>City</th>
            <th>Client</th>
            <th hidden>Facility</th>
            <th hidden>Role</th>
            <th hidden>Email ID</th>
            <th hidden>Phone</th>
            <th hidden>Shift</th>
            <th hidden>OT Starts After (Hr/Weekly)</th>

            <th hidden>Day- Bill Rate (Reg)</th>
            <th hidden>Day- Pay Rate (Reg)</th>
            <th hidden>Day- Bill Rate (OT)</th>
            <th hidden>Day- Pay Rate (OT)</th>
            <th hidden>Day- Bill Rate (DT)</th>
            <th hidden>Day- Pay Rate (DT)</th>

            <th hidden>Evening- Bill Rate (Reg)</th>
            <th hidden>Evening- Pay Rate (Reg)</th>
            <th hidden>Evening- Bill Rate (OT)</th>
            <th hidden>Evening- Pay Rate (OT)</th>
            <th hidden>Evening- Bill Rate (DT)</th>
            <th hidden>Evening- Pay Rate (DT) </th>


            <th hidden>Night- Bill Rate (Reg) </th>
            <th hidden>Night- Pay Rate (Reg)</th>
            <th hidden>Night- Bill Rate (OT)</th>
            <th hidden>Night- Pay Rate (OT)</th>
            <th hidden>Night- Bill Rate (DT) </th>
            <th hidden>Night- Pay Rate (DT)</th>

            <th hidden>Non Taxable (Weekly)</th>
            <th hidden>Non Taxable (1 Time Only)</th>
            <th hidden>Notes</th>

            <th hidden>Recruiter</th>
            <th hidden>R-Lead</th>
            <th hidden>R-Manager</th>
            <th hidden>Onboarding</th>
            <th hidden>Director</th>

            <th>Action</th>
          </tr>
        </thead>
        <tbody *ngIf="healthcareEnagementData.length == 0">
          <tr>
            <td colspan="7">No Record Found</td>
          </tr>
        </tbody>
        <tbody *ngIf="healthcareEnagementData.length != 0">
          <tr *ngFor="let a of healthcareEnagementData" class="itemList">
            <td class="job-name application text-primary"> {{a.emp_no || 'N/A'}} </td>
            <td class="job-name application text-primary"> {{a.status}} </td>
            <td class="application text-primary">{{a.emp_name}} </td>
            <td class="job-name application text-primary">{{a.start_date}} </td>
            <td class="job-name application text-primary">{{a.end_date}}</td>
            <td class="job-name application text-primary">{{a.work_state}}</td>
            <td class="application text-primary hidden">{{a.city}}</td>
            <td>{{a.client_name}}</td>
            <td class="hidden">{{a.facility}}</td>
            <td class="hidden">{{a.role}}</td>
            <td class="job-name application text-primary hidden">{{a.email}}</td>
            <td class="job-name application text-primary hidden">{{a.phone}}</td>
            <td class="hidden">{{a.shift}}</td>
            <td class="hidden">{{a.ot_starts_after}}</td>
            <td class="hidden">{{a.eng_billrateReg_day}}</td>
            <td class="hidden">{{a.eng_payrateReg_day}}</td>
            <td class="hidden">{{a.eng_billrateOt_day}}</td>
            <td class="hidden">{{a.eng_payrateOt_day}}</td>
            <td class="hidden">{{a.eng_billrateDt_day}}</td>
            <td class="hidden">{{a.eng_payrateDt_day}}</td>
            <td class="hidden">{{a.eng_billrateReg_evn}}</td>
            <td class="hidden">{{a.eng_payrateReg_evn}}</td>
            <td class="hidden">{{a.eng_billrateOt_evn}}</td>
            <td class="hidden">{{a.eng_payrateOt_evn}}</td>
            <td class="hidden">{{a.eng_billrateDt_evn}}</td>
            <td class="hidden">{{a.eng_payrateDt_evn}}</td>
            <td class="hidden">{{a.eng_billrateReg_ngt}}</td>
            <td class="hidden">{{a.eng_payrateReg_ngt}}</td>
            <td class="hidden">{{a.eng_billrateOt_ngt}}</td>
            <td class="hidden">{{a.eng_payrateOt_ngt}}</td>
            <td class="hidden">{{a.eng_billrateDt_ngt}}</td>
            <td class="hidden">{{a.eng_payrateDt_ngt}}</td>
            <td class="hidden">{{a.nonTaxable_weekly}}</td>
            <td class="hidden">{{a.nonTaxable_1timeOnly}}</td>

            <td class="hidden">{{a.notes}}</td>

            <td class="job-name application text-primary hidden">{{a.eng_recruiter}} </td>
            <td class="job-name application text-primary hidden">{{a.eng_rlead}} </td>
            <td class="job-name application text-primary hidden">{{a.eng_rmanager}} </td>
            <td class="job-name application text-primary hidden">{{a.eng_onboarding}} </td>
            <td class="job-name application text-primary hidden">{{a.eng_director}} </td>
            <td class="job-links">
              <div class="dropdown">
                <a href="javascript:void(0);">
                  <i class="fa fa-cog"></i></a>
                <div class="dropdown-content">
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#editEngagement"
                    (click)="setEngagementId(a)">Edit</a>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#healthEnga"
                    *ngIf="user_type === 'admin'" (click)="editHealthEngagementId(a.id,3)">Edit History</a>
                  <a href="javascript:void(0);" (click)="deleteEngagement(a)">Delete</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

      </table>

      <div class="pagination">
        <pagination-controls *ngIf="engagementDataListMain.length" class="product-pagination"
          autoHide="false" maxSize="8" (pageChange)="onPageChanged($event)"></pagination-controls>
      </div>
      <!-- Add engagement Modal -->
      <div class="modal fade modal-bx-info editor" id="addEngagement" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true">
        <div class="faltu-wrapper">
          <div class="modal-dialog" role="document" style="max-width: 950px !important;">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="EducationModalLongTitle">
                  Create Non IT Engagement
                </h5>
                <button type="button" class="close" data-dismiss="modal" #process1Close aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form [formGroup]="addEngagementsList">
                  <div class="row">
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Status</label>
                        <select class="form-control" formControlName="eng_statusadd">
                          <option [value]="open" style="text-transform: capitalize">Open</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Start Date</label>
                        <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY"
                          required="true" formControlName="startDate" [config]="datePickerConfig">
                        </dp-date-picker>
                        <div
                          *ngIf="addEngagementsList.controls['startDate'] .errors && (addEngagementsList.controls['startDate'].dirty || addEngagementsList.controls['startDate'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">Start date is required.</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">End Date</label>
                        <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY"
                          required="true" formControlName="endDate" [config]="datePickerConfig">
                        </dp-date-picker>
                        <div
                          *ngIf="addEngagementsList.controls['endDate'] .errors && (addEngagementsList.controls['endDate'].dirty || addEngagementsList.controls['endDate'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">End date is required.</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Employee Name <span
                            style="color: red">*</span></label>
                        <input type="text" class="form-control" placeholder="Enter Employee Name"
                          formControlName="emp_name">
                        <div
                          *ngIf="addEngagementsList.controls['emp_name'] .errors && (addEngagementsList.controls['emp_name'].dirty || addEngagementsList.controls['emp_name'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">Employee name is required.
                        </div>

                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Email ID</label>
                        <input type="text" class="form-control" placeholder="Enter Email Name"
                          formControlName="emp_email">
                        <div
                          *ngIf="addEngagementsList.controls['emp_email'] .errors && (addEngagementsList.controls['emp_email'].dirty || addEngagementsList.controls['emp_email'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">Email is required.</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Phone</label>
                        <input type="text" class="form-control" placeholder="Enter Phone"
                          formControlName="eng_phone">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">State</label>
                        <select class="form-control" formControlName="state"
                          style="height: 38px !important">
                          <option>Select state</option>
                          <option *ngFor="let state of states" [value]="state.isoCode">
                            {{ state.name }} ({{ state.isoCode }})
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">City</label>
                        <select formControlName="city" style="height: 38px !important; width: 100%;">
                          <option>Select city</option>
                          <option *ngFor="let city of cities" [value]="city.name"
                            style="text-transform: capitalize">
                            {{ city.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Client Name</label>
                        <select class="form-control" formControlName="eng_client">
                          <option *ngFor="let data of allClients | sortBy:'client_name'"
                            [value]="data.client_id" style="text-transform: capitalize">
                            {{ data.client_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Facility</label>
                        <input type="text" class="form-control" placeholder="Enter Facility"
                          formControlName="eng_facility">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Role</label>
                        <input type="text" class="form-control" placeholder="Enter Role"
                          formControlName="eng_role">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Shift</label>
                        <select class="form-control" formControlName="eng_shift">

                          <option *ngFor="let sdata of allshift" [value]="sdata"
                            style="text-transform: capitalize">
                            {{ sdata }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- a. Recruiter -->

                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">

                        <label style="font-size: 14px">Recruiter</label>
                        <ng-select class="form-control" formControlName="eng_recruiter">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>
                        <!-- <div *ngIf="addEngagementsList.controls['eng_team'] .errors && (addEngagementsList.controls.eng_team.dirty || addEngagementsList.controls.eng_team.touched)" style="color: red;font-size: 15px;margin-top: 5px">Team is required.</div> -->
                      </div>
                    </div>
                    <!-- b. R- Lead -->
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">R- Lead</label>
                        <ng-select class="form-control" formControlName="eng_rlead">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>

                      </div>
                    </div>
                    <!-- c. R- Manager -->
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">R- Manager</label>
                        <ng-select class="form-control" formControlName="eng_rmanager">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>

                      </div>
                    </div>
                    <!-- d. Director -->
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Director</label>
                        <ng-select class="form-control" formControlName="eng_director">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>

                      </div>
                    </div>
                    <!-- e. Onboarding -->
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Onboarding</label>
                        <ng-select class="form-control" formControlName="eng_onboarding">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>

                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">OT Starts After (Hr/Weekly)</label>
                        <input type="number" class="form-control" placeholder="OT Starts After (Hr/Weekly)"
                          formControlName="eng_otstart">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Non Taxable (Weekly)</label>
                        <input type="number" class="form-control" placeholder="Non Taxable (Weekly)"
                          formControlName="nonTaxable_weekly">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Non Taxable (1 Time Only)</label>
                        <input type="number" class="form-control" placeholder="Non Taxable (1 Time Only)"
                          formControlName="nonTaxable_1timeOnly">
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label style="font-size: 14px">Notes</label>
                        <textarea style="height: 100%" class="form-control" ng-maxlength="200" rows="3"
                          cols="50" formControlName="notes"></textarea>
                        <span>{{200 - (addEngagementsList.controls['notes'].value &&
                          addEngagementsList.controls['notes'].value != null ?
                          addEngagementsList.controls['notes'].value.length : 0)}} characters
                          remaining</span>
                        <div
                          *ngIf="addEngagementsList.controls['notes'].errors && (addEngagementsList.controls['notes'].dirty || addEngagementsList.controls['notes'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">Only 200 characters allowed!
                        </div>
                      </div>
                    </div>
                    <hr style="background-color: #9da1b1;
                        border: 1px solid;
                        width: 94%;
                        margin-left: 12px;
                        margin-bottom: 12px;
                      ">
                    <h2 style="margin-bottom: 19px; font-size: 19px;">Day</h2>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (Reg)"
                          formControlName="eng_billrateReg_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px"> Pay Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (Reg)"
                          formControlName="eng_payrateReg_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (OT/H)"
                          formControlName="eng_billrateOt_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (OT/H)"
                          formControlName="eng_payrateOt_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (DT/H)"
                          formControlName="eng_billrateDt_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (DT/H)"
                          formControlName="eng_payrateDt_day">
                      </div>
                    </div>
                    <hr style="background-color: #9da1b1;
                        border: 1px solid;
                        width: 94%;
                        margin-left: 12px;
                        margin-bottom: 12px;
                      ">
                    <h2 style="margin-bottom: 19px; font-size: 19px;">Evening</h2>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (Reg)"
                          formControlName="eng_billrateReg_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px"> Pay Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (Reg)"
                          formControlName="eng_payrateReg_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (OT/H)"
                          formControlName="eng_billrateOt_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (OT/H)"
                          formControlName="eng_payrateOt_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (DT/H)"
                          formControlName="eng_billrateDt_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (DT/H)"
                          formControlName="eng_payrateDt_evn">
                      </div>
                    </div>
                    <hr style="background-color: #9da1b1;
                        border: 1px solid;
                        width: 94%;
                        margin-left: 12px;
                        margin-bottom: 12px;
                      ">
                    <h2 style="margin-bottom: 19px; font-size: 19px;">Night</h2>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (Reg)"
                          formControlName="eng_billrateReg_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px"> Pay Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (Reg)"
                          formControlName="eng_payrateReg_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (OT/H)"
                          formControlName="eng_billrateOt_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (OT/H)"
                          formControlName="eng_payrateOt_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (DT/H)"
                          formControlName="eng_billrateDt_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (DT/H)"
                          formControlName="eng_payrateDt_ngt">
                      </div>
                    </div>

                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="site-button" data-dismiss="modal">
                  Back
                </button>
                <button type="button" class="site-button" (click)="addEngagement()"
                  [disabled]="!addEngagementsList.valid" [ngStyle]="{
                      cursor: !addEngagementsList.valid
                        ? 'not-allowed'
                        : 'pointer'
                    }" data-dismiss="modal">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->


      <!-- Edit engagement Modal -->
      <div class="modal fade modal-bx-info editor" id="editEngagement" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true">
        <div class="faltu-wrapper">
          <div class="modal-dialog" role="document" style="max-width: 950px !important;">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="EducationModalLongTitle">
                  Edit Non IT Engagement
                </h5>
                <button type="button" class="close" data-dismiss="modal" #process1Close aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form [formGroup]="editEngagementsList">
                  <input type="hidden" formControlName="edit_id">
                  <div class="row">
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Status</label>
                        <select class="form-control" formControlName="edit_eng_statusadd"
                          (change)="selectChangeHandler($event)">
                          <option *ngFor="let stdata of statusEng" [value]="stdata"
                            style="text-transform: capitalize">
                            {{ stdata }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3" *ngIf="selectedStatus == 'Closed'">
                      <div class="form-group">
                        <label style="font-size: 14px">Closed Reason</label>
                        <select class="form-control" id="edit_closed_reason"
                          formControlName="edit_closed_reason">
                          <option *ngFor="let rdata of closeReason" [value]="rdata"
                            style="text-transform: capitalize">
                            {{ rdata }}
                          </option>
                        </select>
                        <div
                          *ngIf="editEngagementsList.controls['edit_closed_reason'] .errors && (editEngagementsList.controls['edit_closed_reason'].dirty || editEngagementsList.controls['edit_closed_reason'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">close reason is required.</div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Start Date</label>
                        <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY"
                          required="true" formControlName="edit_startDate" [config]="datePickerConfig">
                        </dp-date-picker>
                        <div
                          *ngIf="editEngagementsList.controls['edit_startDate'] .errors && (editEngagementsList.controls['edit_startDate'].dirty || editEngagementsList.controls['edit_startDate'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">Start date is required.</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">End Date</label>
                        <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY"
                          required="true" formControlName="edit_endDate" [config]="datePickerConfig">
                        </dp-date-picker>
                        <div
                          *ngIf="editEngagementsList.controls['edit_endDate'] .errors && (editEngagementsList.controls['edit_endDate'].dirty || editEngagementsList.controls['edit_endDate'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">End date is required.</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Employee Name</label>
                        <input type="text" class="form-control" placeholder="Enter Employee Name"
                          formControlName="edit_emp_name">
                        <div
                          *ngIf="editEngagementsList.controls['edit_emp_name'] .errors && (editEngagementsList.controls['edit_emp_name'].dirty || editEngagementsList.controls['edit_emp_name'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">Employee name is required.
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Email ID</label>
                        <input type="text" class="form-control" placeholder="Enter Email Name"
                          formControlName="edit_emp_email">
                        <div
                          *ngIf="editEngagementsList.controls['edit_emp_email'] .errors && (editEngagementsList.controls['edit_emp_email'].dirty || editEngagementsList.controls['edit_emp_email'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">Email is required.</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Phone</label>
                        <input type="text" class="form-control" placeholder="Enter Phone"
                          formControlName="edit_eng_phone">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">State</label>
                        <select class="form-control" formControlName="edit_state"
                          style="height: 38px !important">
                          <option>Please select state</option>
                          <option *ngFor="let state of states" [value]="state.isoCode">
                            {{ state.name }} ({{ state.isoCode }})
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px; width: 100%;">City</label>
                        <select formControlName="edit_city" style="height: 38px !important; width: 100%;">
                          <option>Please select city</option>
                          <option *ngFor="let city of cities" [value]="city.name"
                            style="text-transform: capitalize">
                            {{ city.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Client Name</label>
                        <select class="form-control" formControlName="edit_eng_client">
                          <option *ngFor="let data of allClients | sortBy:'client_name'"
                            [value]="data.client_id" style="text-transform: capitalize">
                            {{ data.client_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Facility</label>
                        <input type="text" class="form-control" placeholder="Enter Facility"
                          formControlName="edit_eng_facility">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Role</label>
                        <input type="text" class="form-control" placeholder="Enter Role"
                          formControlName="edit_eng_role">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Shift</label>
                        <select class="form-control" formControlName="edit_eng_shift">

                          <option *ngFor="let sdata of allshift" [value]="sdata"
                            style="text-transform: capitalize">
                            {{ sdata }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- a. Recruiter -->
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Recruiter</label>
                        <ng-select class="form-control" formControlName="edit_eng_recruiter">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">R- Lead</label>
                        <ng-select class="form-control" formControlName="edit_eng_rlead">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <!-- c. R- Manager -->
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">R- Manager</label>
                        <ng-select class="form-control" formControlName="edit_eng_rmanager">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>

                      </div>
                    </div>
                    <!-- d. Director -->
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Director</label>
                        <ng-select class="form-control" formControlName="edit_eng_director">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>

                      </div>
                    </div>
                    <!-- e. Onboarding -->
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Onboarding</label>
                        <ng-select class="form-control" formControlName="edit_eng_onboarding">
                          <ng-option *ngFor="let item of userList | sortBy:'EmployeeName'"
                            [value]="item.user_id">{{item.EmployeeName}}</ng-option>
                        </ng-select>

                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">OT Starts After (Hr/Weekly)</label>
                        <input type="number" class="form-control" placeholder="OT Starts After (Hr/Weekly)"
                          formControlName="edit_eng_otstart">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Non Taxable (Weekly)</label>
                        <input type="number" class="form-control" placeholder="Non Taxable (Weekly)"
                          formControlName="edit_nonTaxable_weekly">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Non Taxable (1 Time Only)</label>
                        <input type="number" class="form-control" placeholder="Non Taxable (1 Time Only)"
                          formControlName="edit_nonTaxable_1timeOnly">
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label style="font-size: 14px">Notes</label>
                        <textarea style="height: 100%" class="form-control" ng-maxlength="200" rows="3"
                          cols="50" formControlName="edit_notes"></textarea>
                        <span>{{200 - (editEngagementsList.controls['edit_notes'].value &&
                          editEngagementsList.controls['edit_notes'].value != null ?
                          editEngagementsList.controls['edit_notes'].value.length : 0)}} characters
                          remaining</span>
                        <div
                          *ngIf="editEngagementsList.controls['edit_notes'].errors && (editEngagementsList.controls['edit_notes'].dirty || editEngagementsList.controls['edit_notes'].touched)"
                          style="color: red;font-size: 15px;margin-top: 5px">Only 200 characters allowed!
                        </div>
                      </div>
                    </div>
                    <hr style="background-color: #9da1b1;
                        border: 1px solid;
                        width: 94%;
                        margin-left: 12px;
                        margin-bottom: 12px;
                    ">
                    <h2 style="margin-bottom: 19px; font-size: 19px;">Day</h2>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (Reg)"
                          formControlName="edit_eng_billrateReg_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px"> Pay Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (Reg)"
                          formControlName="edit_eng_payrateReg_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (OT/H)"
                          formControlName="edit_eng_billrateOt_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (OT/H)"
                          formControlName="edit_eng_payrateOt_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (DT/H)"
                          formControlName="edit_eng_billrateDt_day">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (DT/H)"
                          formControlName="edit_eng_payrateDt_day">
                      </div>
                    </div>
                    <hr style="background-color: #9da1b1;
                      border: 1px solid;
                      width: 94%;
                      margin-left: 12px;
                      margin-bottom: 12px;
                    ">
                    <h2 style="margin-bottom: 19px; font-size: 19px;">Evening</h2>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (Reg)"
                          formControlName="edit_eng_billrateReg_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px"> Pay Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (Reg)"
                          formControlName="edit_eng_payrateReg_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (OT/H)"
                          formControlName="edit_eng_billrateOt_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (OT/H)"
                          formControlName="edit_eng_payrateOt_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (DT/H)"
                          formControlName="edit_eng_billrateDt_evn">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (DT/H)"
                          formControlName="edit_eng_payrateDt_evn">
                      </div>
                    </div>
                    <hr style="background-color: #9da1b1;
                      border: 1px solid;
                      width: 94%;
                      margin-left: 12px;
                      margin-bottom: 12px;
                    ">
                    <h2 style="margin-bottom: 19px; font-size: 19px;">Night</h2>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (Reg)"
                          formControlName="edit_eng_billrateReg_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px"> Pay Rate (Reg)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (Reg)"
                          formControlName="edit_eng_payrateReg_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (OT/H)"
                          formControlName="edit_eng_billrateOt_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (OT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (OT/H)"
                          formControlName="edit_eng_payrateOt_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Bill Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Bill Rate (DT/H)"
                          formControlName="edit_eng_billrateDt_ngt">
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <div class="form-group">
                        <label style="font-size: 14px">Pay Rate (DT/H)</label>
                        <input type="number" class="form-control" placeholder="Enter Pay Rate (DT/H)"
                          formControlName="edit_eng_payrateDt_ngt">
                      </div>
                    </div>

                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="site-button" data-dismiss="modal">
                  Back
                </button>
                <button type="button" class="site-button" (click)="updateEngagement()"
                  [disabled]="!editEngagementsList.valid" [ngStyle]="{
                      cursor: !editEngagementsList.valid
                        ? 'not-allowed'
                        : 'pointer'
                    }" data-dismiss="modal">
                  Update
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->
      <!--Edit History List-->
      <div class="modal fade modal-bx-info editor" id="healthEnga" tabindex="-1" role="dialog"
        aria-labelledby="healthEngaModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="healthEngaModalLongTitle">
                View Edit History Non IT Engagement
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="padding: 0">
              <table class="table-job-bx">
                <thead>
                  <tr>
                    <th class="">S.No</th>
                    <th>Edit User</th>
                    <th>Date</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody *ngIf="editGethealthCareHistory.length == 0">
                  <tr>
                    <td colspan="7">No Record Found</td>
                  </tr>
                </tbody>
                <tbody *ngIf="editGethealthCareHistory.length != 0">
                  <tr style="background: transparent"
                    *ngFor="let healthcare of editGethealthCareHistory ; let i = index">

                    <td class="">{{i+1}}</td>
                    <td class="job-name">{{healthcare.user_first_name}} {{healthcare.user_last_name}}</td>
                    <td>{{healthcare.created_at | date: 'MM/dd/yyyy'}} </td>
                    <td>
                      <button type="button" data-toggle="modal" data-target="#viewhealthEnga"
                        (click)="viewHealthCareHistor(healthcare.id)" class="site-button">
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
      </div>
      <!--End History List-->

      <!--Edit History View-->
      <div class="modal fade modal-bx-info editor" id="viewhealthEnga" tabindex="-1" role="dialog"
        aria-labelledby="viewhealthEngaModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 83%;">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="healthEngaModalLongTitle">
                Details Edit History Non IT Engagement
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="padding: 0">
              <table class="table-job-bx viewData">
                <tbody>
                  <tr>
                    <th>Status</th>
                    <th></th>
                    <th>Start Date
                    </th>
                    <th></th>
                    <th>End Date</th>
                    <th></th>
                    <th>Employee Name</th>
                    <th></th>
                    <th>Phone</th>
                    <th></th>
                    <th>State</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['status']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['start_date']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['end_date']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['emp_name']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['phone']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['work_state']}}</td>
                  </tr>
                  <tr>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['status']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['start_date']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['end_date']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['emp_name']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['phone']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['work_state']}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table-job-bx viewData">
                <tbody>
                  <tr>
                    <th>City</th>
                    <th></th>
                    <th>closed Reason</th>
                    <th></th>
                    <th>Facility</th>
                    <th></th>
                    <th>Role</th>
                    <th></th>
                    <th>Shift</th>
                    <th></th>
                    <th>Recruiter</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['city']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['closed_reason']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['facility']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['role']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['shift']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_recruiter']}}</td>
                  </tr>
                  <tr>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['city']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['closed_reason']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['facility']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['role']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['shift']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_recruiter']}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table-job-bx viewData">
                <tbody>
                  <tr>
                    <th>R- Lead</th>
                    <th></th>
                    <th>R- Manager</th>
                    <th></th>
                    <th>Director</th>
                    <th></th>
                    <th>Onboarding</th>
                    <th></th>
                    <th>OT Starts After (Hr/Weekly)</th>
                    <th></th>
                    <th>Non Taxable (Weekly)</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_rlead']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_rmanager']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_director']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_onboarding']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['ot_starts_after']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['nonTaxable_weekly']}}</td>
                  </tr>
                  <tr>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_rlead']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_rmanager']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_director']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_onboarding']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['ot_starts_after']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['nonTaxable_weekly']}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table-job-bx viewData">
                <tbody>
                  <tr>
                    <th>Notes</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['notes']}}</td>
                  </tr>
                  <tr>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['notes']}}</td>
                  </tr>
                </tbody>
              </table>
              <h4>Day</h4>
              <table class="table-job-bx viewData">
                <tbody>
                  <tr>
                    <th>Bill Rate (Reg)</th>
                    <th></th>
                    <th>Pay Rate (Reg)</th>
                    <th></th>
                    <th>Bill Rate (OT/H)</th>
                    <th></th>
                    <th>Pay Rate (OT/H)</th>
                    <th></th>
                    <th>Bill Rate (DT/H)</th>
                    <th></th>
                    <th>Pay Rate (DT/H)</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_billrateReg_day']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_payrateReg_day']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_billrateOt_day']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_payrateOt_day']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_billrateDt_day']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_payrateDt_day']}}</td>

                  </tr>
                  <tr>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_billrateReg_day']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_payrateReg_day']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_billrateOt_day']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_payrateOt_day']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_billrateDt_day']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_payrateDt_day']}}</td>

                  </tr>
                </tbody>
              </table>
              <h4>Evening</h4>
              <table class="table-job-bx viewData">
                <tbody>
                  <tr>
                    <th>Bill Rate (Reg)</th>
                    <th></th>
                    <th>Pay Rate (Reg)</th>
                    <th></th>
                    <th>Bill Rate (OT/H)</th>
                    <th></th>
                    <th>Pay Rate (OT/H)</th>
                    <th></th>
                    <th>Bill Rate (DT/H)</th>
                    <th></th>
                    <th>Pay Rate (DT/H)</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_billrateReg_evn']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_payrateReg_evn']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_billrateOt_evn']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_payrateOt_evn']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_billrateDt_evn']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_payrateDt_evn']}}</td>

                  </tr>
                  <tr>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_billrateReg_evn']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_payrateReg_evn']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_billrateOt_evn']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_payrateOt_evn']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_billrateDt_evn']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_payrateDt_evn']}}</td>

                  </tr>
                </tbody>
              </table>
              <h4>Night</h4>
              <table class="table-job-bx viewData">
                <tbody>
                  <tr>
                    <th>Bill Rate (Reg)</th>
                    <th></th>
                    <th>Pay Rate (Reg)</th>
                    <th></th>
                    <th>Bill Rate (OT/H)</th>
                    <th></th>
                    <th>Pay Rate (OT/H)</th>
                    <th></th>
                    <th>Bill Rate (DT/H)</th>
                    <th></th>
                    <th>Pay Rate (DT/H)</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_billrateReg_ngt']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_payrateReg_ngt']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_billrateOt_ngt']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_payrateOt_ngt']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_billrateDt_ngt']}}</td>
                    <th>Old Value:</th>
                    <td>{{viewhealthCareHistoryOld['eng_payrateDt_ngt']}}</td>

                  </tr>
                  <tr>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_billrateReg_ngt']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_payrateReg_ngt']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_billrateOt_ngt']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_payrateOt_ngt']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_billrateDt_ngt']}}</td>
                    <th>New Value:</th>
                    <td>{{viewhealthCareHistoryNew['eng_payrateDt_ngt']}}</td>

                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
      </div>
      <!--End History View-->

    </div>
  </div>
</app-admin-default-layout>