<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Manage jobs
        </h5>
        <div class="float-right" style="display: flex">
          <div class="form-group" style="margin-right: 10px">
            <input type="text" class="form-control2" [(ngModel)]="searchData" placeholder="Search Job" />
          </div>
          <a type="button" class="site-button m-b30" *ngIf="user_type === 'admin'" href="{{apiUrl}}vcsapi/api/generate/excel/manage/jobPost/{{
            user_id
          }}/{{ excelfileName }}" target="_blank" rel="noopener noreferrer" download>Report</a>
        </div>
      </div>

      <div class="job-bx-title clearfix">
        <div class="float-left form-group" style="display: flex">
          <div>
            <label style="font-size: 14px">Job Status</label>
            <select class="form-control" #myjobValue (change)="onJobSelected(myjobValue.value)"
              [(ngModel)]="job_status_select">
              <option value="ALL">All</option>
              <option value="closed">Closed</option>
              <option value="on hold">On-Hold</option>
              <option value="open" selected>Open</option>
            </select>
          </div>
          <div style="margin-left: 12px">
            <div>
              <label style="font-size: 14px">Job ID</label>
              <input type="text" class="form-control" [(ngModel)]="jobID" placeholder="Enter Job ID" />
            </div>
          </div>

          <div style="margin-left: 12px">
            <div>
              <label style="font-size: 14px">Posted By</label>
              <input type="text" class="form-control" [(ngModel)]="job_posted_by" placeholder="Posted By Name" />
            </div>
          </div>

          <div style="margin-left: 12px">
            <div>
              <label style="font-size: 14px">Position Type</label>
              <input type="text" class="form-control" [(ngModel)]="position_type_name" placeholder="Position Type" />
            </div>
          </div>
        </div>
        <span style="float: right; margin-top: 40px" *ngIf="user_type === 'admin'">
          <a type="button" class="site-button m-b30" style="
            color: white;
            background: red;
            margin-bottom: 0px;
          " data-toggle="modal" data-target="#deleteJobs">Delete Jobs</a>
        </span>
      </div>

      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th class="feature">
            </th>
            <th>Job Title</th>
            <th>Job ID</th>
            <th>Posted By</th>
            <th style="text-align: center">Applications</th>
            <th>Status</th>
            <th style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
            let a of jobList
              | paginate
                : { itemsPerPage: count, currentPage: page }
          " class="itemList">
            <td class="feature">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="check{{ a.job_id }}"
                  name="example{{ a.job_id }}" (click)="selectJobsToDelete(a.job_id)" />
                <label class="custom-control-label" for="check{{ a.job_id }}"></label>
              </div>
            </td>
            <td class="job-name">
              <a href="javascript:void(0);" data-toggle="modal" data-target="#exampleModalLong"
                style="text-transform: capitalize" class="link2" (click)="viewJob(a)">{{ a.job_title }}</a>
              <ul class="job-post-info">
                <li>
                  <i class="fa fa-map-marker"></i>{{ a.city }},
                  {{ a.state }}
                </li>
                <li>
                  <i class="fa fa-bookmark-o"></i>
                  {{ a.job_type_name }}
                </li>
                <li>
                  <i class="fa fa-filter"></i>
                  {{ a.position_type_name }}
                </li>
              </ul>
            </td>
            <td class="application text-primary">
              {{ a.job_no }}
            </td>
            <td class="application text-primary">
              {{ a.job_post_by_first_name }}
              {{ a.job_post_by_middle_name }}
              {{ a.job_post_by_last_name }}
            </td>

            <td class="application text-primary" style="text-align: center">
              {{ a.count }}
            </td>
            <td class="expired pending" *ngIf="a.job_status === 'closed'" style="text-transform: capitalize">
              closed
            </td>
            <td class="expired success" *ngIf="a.job_status === 'open'" style="text-transform: capitalize">
              open
            </td>
            <td class="expired success" *ngIf="a.job_status === 'on hold'"
              style="text-transform: capitalize; color: blue">
              on hold
            </td>
            <td class="job-links">
              <div class="dropdown">
                <a href="javascript:void(0);">
                  <i class="fa fa-cog"></i></a>
                <div class="dropdown-content">
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#editDetails"
                    (click)="EditJob(a)">Edit</a>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#changeStatus"
                    (click)="EditJob(a)">Change Status</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <pagination-controls *ngIf="jobList.length" class="product-pagination" autoHide="false" maxSize="8"
          (pageChange)="onPageChanged($event)">
        </pagination-controls>
      </div>

      <!--View Modal -->
      <div class="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 60% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" *ngIf="details">
                {{ details.job_title }}
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <ul>
                <li>
                  <strong>Job Title :</strong>
                  <p>{{ details.job_title }}</p>
                </li>
                <li>
                  <strong>Job ID :</strong>
                  <p>{{ details.job_no }}</p>
                </li>
                <li>
                  <strong>Job Location :</strong>
                  <p>
                    {{ details.city }}, {{ details.state }},
                    {{ details.country }}
                  </p>
                </li>
                <li>
                  <strong>Job Type :</strong>
                  <p>{{ details.job_type_name }}</p>
                </li>
                <li>
                  <strong>Minimum :</strong>
                  <p>$ {{ details.minimum_rate }}</p>
                </li>
                <li>
                  <strong>Maximum :</strong>
                  <p>$ {{ details.maximum_rate }}</p>
                </li>
                <li>
                  <strong>Rate :</strong>
                  <p>{{ details.rate }}</p>
                </li>
                <li>
                  <strong>Job Duration :</strong>
                  <p>{{ details.duration }}</p>
                </li>
                <li>
                  <strong>Shift :</strong>
                  <p>{{ details.shift }}</p>
                </li>
                <li>
                  <strong>Position Type :</strong>
                  <p>{{ details.position_type_name }}</p>
                </li>
                <li>
                  <strong>System Name :</strong>
                  <p>{{ details.system_name_name }}</p>
                </li>
                <li>
                  <strong>Description :</strong>
                  <p style="word-break: break-all">
                    {{ details.job_description }}
                  </p>
                </li>
                <li>
                  <strong>Required Informations :</strong>
                  <p style="word-break: break-all">
                    {{ details.req_information }}
                  </p>
                </li>
                <li>
                  <strong>Date of Posting :</strong>
                  <p>
                    {{
                    details.job_post_date | date : "MM/dd/yyyy"
                    }}
                  </p>
                </li>
                <li>
                  <strong>Posted By :</strong>
                  <p>
                    {{ details.job_post_by_first_name }}
                    {{ details.job_post_by_middle_name }}
                    {{ details.job_post_by_last_name }}
                  </p>
                </li>
                <li>
                  <strong>Date of Editing :</strong>
                  <p>
                    {{
                    details.job_post_edit_date | date : "MM/dd/yyyy"
                    }}
                  </p>
                </li>
                <li>
                  <strong>Edited By :</strong>
                  <p>
                    {{ details.job_post_edit_by_first_name }}
                    {{ details.job_post_edit_by_middle_name }}
                    {{ details.job_post_edit_by_last_name }}
                  </p>
                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Edit Modal -->
      <div class="modal fade modal-bx-info editor" id="editDetails" tabindex="-1" role="dialog"
        aria-labelledby="EducationModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 60% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                Edit Job
              </h5>
              <button type="button" class="close" data-dismiss="modal" #closeEdit aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <form [formGroup]="EditJobForm">
                <div class="row">
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>Job ID<span class="spanStar">*</span></label>
                      <input type="text" class="form-control" placeholder="Enter Job ID" [(ngModel)]="job_No"
                        formControlName="job_id" readonly />

                      <div *ngIf="
                        EditJobForm.controls['job_id'].invalid &&
                        (EditJobForm.controls['job_id'].dirty ||
                          EditJobForm.controls['job_id'].touched)
                      " class="alert alert-danger" style="
                        font-size: 12px;
                        padding: 0.5rem 1.25rem;
                      ">
                        <div *ngIf=" EditJobForm.controls['job_id'].errors?.['required'] ">
                          Job ID is required.
                        </div>
                        <div *ngIf=" EditJobForm.controls['job_id'].errors?.['maxlength'] ">
                          Job ID must be at-most 20 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group" style="display: grid">
                      <label>State<span class="spanStar">*</span></label>
                      <select class="form-control" formControlName="state" style="height: 38px !important">
                        <option>Select state</option>
                        <option *ngFor="let state of states" [value]="state.isoCode">
                          {{ state.name }} ({{ state.isoCode }})
                        </option>
                      </select>

                      <div *ngIf="
                        EditJobForm.controls['state'].invalid &&
                        (EditJobForm.controls['state'].dirty ||
                          EditJobForm.controls['state'].touched)
                      " class="alert alert-danger" style="
                        font-size: 12px;
                        padding: 0.5rem 1.25rem;
                      ">
                        <div *ngIf="
                          EditJobForm.controls['state'].errors
                            ?.['required']
                        ">
                          State is required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group" style="display: grid">
                      <label>City<span class="spanStar">*</span></label>
                      <select class="form-control" formControlName="city" style="height: 38px !important; width: 100%;">
                        <option>Select city</option>
                        <option *ngFor="let city of filteredCity" [value]="city.name"
                          style="text-transform: capitalize">
                          {{ city.name }}
                        </option>
                      </select>

                      <div *ngIf="
                        EditJobForm.controls['city'].invalid &&
                        (EditJobForm.controls['city'].dirty ||
                          EditJobForm.controls['city'].touched)
                      " class="alert alert-danger" style="
                        font-size: 12px;
                        padding: 0.5rem 1.25rem;
                      ">
                        <div *ngIf="
                          EditJobForm.controls['city'].errors
                            ?.['required']
                        ">
                          City is required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Job Title<span class="spanStar">*</span></label>
                      <input type="text" class="form-control" placeholder="Enter Job Title" formControlName="job_title"
                        [(ngModel)]="job_title" />

                      <div *ngIf=" EditJobForm.controls['job_title'].invalid && (EditJobForm.controls['job_title'].dirty || EditJobForm.controls['job_title'].touched) " class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                        <div *ngIf=" EditJobForm.controls['job_title'].errors?.['required'] ">
                          Job title is required.
                        </div>
                        <div *ngIf=" EditJobForm.controls['job_title'].errors?.['maxlength'] ">
                          Job title must be at-most 100 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3">
                    <div class="form-group">
                      <label>Minimum
                        <span class="spanStar">*</span>
                      </label>
                      <div class="input-group custom-input-group">
                        <div class="input-group-prepend h-100">
                          <span class="input-group-text dollar-sign bg-white">$</span>
                        </div>
                        <input type="number" class="custom-edit-control" placeholder="Enter minimum value"
                          formControlName="minimum_rate" step="0.01" min="0" [(ngModel)]="minimum_rate"
                          id="minimumRate" />
                      </div>

                      <div
                        *ngIf="EditJobForm.controls['minimum_rate'].invalid && (EditJobForm.controls['minimum_rate'].dirty || EditJobForm.controls['minimum_rate'].touched)"
                        class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                        <div *ngIf="EditJobForm.controls['minimum_rate'].errors?.['required']">
                          Minimum rate is required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-1 col-md-1">
                    <div class="form-group">
                      <label></label>
                      <br />
                      <label class="to-label">to</label>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3">
                    <div class="form-group">
                      <label>Maximum
                        <span class="spanStar">*</span>
                      </label>
                      <div class="input-group custom-input-group">
                        <div class="input-group-prepend h-100">
                          <span class="input-group-text dollar-sign bg-white">$</span>
                        </div>
                        <input type="number" class="custom-edit-control" placeholder="Enter maximum value"
                          formControlName="maximum_rate" [(ngModel)]="maximum_rate" step="0.01" min="0"
                          id="maximumRate" />
                      </div>

                      <div
                        *ngIf="EditJobForm.controls['maximum_rate'].invalid && (EditJobForm.controls['maximum_rate'].dirty || EditJobForm.controls['maximum_rate'].touched)"
                        class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                        <div *ngIf="EditJobForm.controls['maximum_rate'].errors?.['required']">
                          Maximum rate is required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-5">
                    <div class="form-group" style="display: grid">
                      <label>Rate
                        <span class="spanStar">*</span>
                      </label>
                      <select class="form-control" formControlName="rate" [(ngModel)]="rate">
                        <option></option>
                        <option>per hour</option>
                        <option>per week</option>
                        <option>per month</option>
                        <option>per year</option>
                      </select>
                      <div
                        *ngIf="EditJobForm.controls['rate'].invalid && (EditJobForm.controls['rate'].dirty || EditJobForm.controls['rate'].touched)"
                        class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                        <div *ngIf="EditJobForm.controls['rate'].errors?.['required']">
                          Rate is required.
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-lg-4 col-md-4">
                    <div class="form-group" style="display: grid">
                      <label>Position Type</label>
                      <select class="form-control" formControlName="position" [(ngModel)]="position_type">
                        <option selected></option>
                        <option *ngFor="let data of positiontype | sortBy:'position_type_name'"
                          [value]="data.position_type_id" style="text-transform: capitalize">
                          {{ data.position_type_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <div class="form-group" style="display: grid">
                        <label>Job Type</label>
                        <select class="form-control" formControlName="job_type" [(ngModel)]="job_type">
                          <option selected></option>
                          <option *ngFor="let data of jobType | sortBy:'job_type_name'" [value]="data.job_type_id"
                            style="text-transform: capitalize">
                            {{ data.job_type_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <div class="form-group" style="display: grid">
                        <label>Job Sector<span class="spanStar">*</span></label>
                        <select class="form-control" formControlName="job_sector" [(ngModel)]="job_sector">
                          <option selected></option>
                          <option *ngFor="let data of jobSector | sortBy:'job_sector_name'" [value]="data.job_sector_id"
                            style="text-transform: capitalize">
                            {{ data.job_sector_name }}
                          </option>
                        </select>
                        <div *ngIf=" EditJobForm.controls['job_sector'] .invalid && (EditJobForm.controls['job_sector'] .dirty || EditJobForm.controls['job_sector'] .touched) " class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                          <div *ngIf=" EditJobForm.controls['job_sector'].errors?.['required'] ">
                            Job sector is required.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <div class="form-group" style="display: grid">
                        <label>Client Name<span class="spanStar">*</span></label>
                        <select class="form-control" formControlName="client_name" [(ngModel)]="client_id">
                          <option selected></option>
                          <option *ngFor="let data of clientList | sortBy:'client_name'" [value]="data.client_id"
                            style="text-transform: capitalize">
                            {{ data.client_name }}
                          </option>
                        </select>
                        <div *ngIf=" EditJobForm.controls['client_name'] .invalid && (EditJobForm.controls['client_name'] .dirty || EditJobForm.controls['client_name'] .touched) " class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                          <div *ngIf=" EditJobForm.controls['client_name'] .errors?.['required'] ">
                            Client name rate is required.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>System Name<span class="spanStar">*</span></label>
                      <select class="form-control" formControlName="system_name" [(ngModel)]="system_name">
                        <option selected></option>
                        <option *ngFor="let data of systemList | sortBy:'system_name'" [value]="data.system_name_id"
                          style="text-transform: capitalize">
                          {{ data.system_name }}
                        </option>
                      </select>

                      <div *ngIf=" EditJobForm.controls['system_name'] .invalid && (EditJobForm.controls['system_name'].dirty || EditJobForm.controls['system_name'] .touched) " class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                        <div *ngIf=" EditJobForm.controls['system_name'].errors?.['required'] ">
                          System name rate is required.
                        </div>
                        <div *ngIf=" EditJobForm.controls['system_name'].errors?.['maxlength'] ">
                          System name rate must be at-most 200
                          characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>Job Duration<span class="spanStar">*</span></label>
                      <input type="text" class="form-control" placeholder="Enter Job Duration"
                        formControlName="job_duration" [(ngModel)]="job_duration" />

                      <div *ngIf=" EditJobForm.controls['job_duration'] .invalid && (EditJobForm.controls['job_duration'] .dirty || EditJobForm.controls['job_duration'] .touched) " class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; ">
                        <div *ngIf=" EditJobForm.controls['job_duration'].errors ?.['required'] "> Job duration is required. </div>
                        <div *ngIf=" EditJobForm.controls['job_duration'].errors ?.['maxlength'] "> Job duration must be at-most 50 characters long. </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Shift<span class="spanStar">*</span></label>
                      <div style="display: flex; padding: 10px">
                        <div *ngFor="let a of shiftArry; let i = index"
                          class="custom-control custom-checkbox checkbox-margin" style="margin: 10px">
                          <input type="checkbox" class="custom-control-input" id="Day{{ i }}" name="Day{{ i }}"
                            [checked]="a.value" (click)="checkBoxSelect(a.shift)" />
                          <label class="custom-control-label font-normal" for="Day{{ i }}">{{ a.shift }}</label>
                        </div>
                      </div>
                      <div *ngIf="shit_details.length === 0" class="alert alert-danger" style="
                        font-size: 12px;
                        padding: 0.5rem 1.25rem;
                      ">
                        <div>Shift is required.</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Job Description<span class="spanStar">*</span></label>
                      <textarea type="text" class="form-control" [(ngModel)]="job_desc"
                        formControlName="job_desc"></textarea>
                      <div *ngIf="
                        EditJobForm.controls['job_desc'].invalid &&
                        (EditJobForm.controls['job_desc'].dirty ||
                          EditJobForm.controls['job_desc'].touched)
                      " class="alert alert-danger" style="
                        font-size: 12px;
                        padding: 0.5rem 1.25rem;
                      ">
                        <div *ngIf="
                          EditJobForm.controls['job_desc'].errors
                            ?.['required']
                        ">
                          Job description is required.
                        </div>
                        <div *ngIf="
                          EditJobForm.controls['job_desc'].errors
                            ?.['maxlength']
                        ">
                          Job description must be at-most 1000
                          characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Required Information</label>
                      <textarea type="text" class="form-control" [(ngModel)]="req_info"
                        formControlName="req_info"></textarea>

                      <div *ngIf="
                        EditJobForm.controls['req_info'].invalid &&
                        (EditJobForm.controls['req_info'].dirty ||
                          EditJobForm.controls['req_info'].touched)
                      " class="alert alert-danger" style="
                        font-size: 12px;
                        padding: 0.5rem 1.25rem;
                      ">
                        <div *ngIf="
                          EditJobForm.controls['req_info'].errors
                            ?.['maxlength']
                        ">
                          Required Information must be at-most 1000
                          characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="updateDetails()" [disabled]="
                !EditJobForm.valid || shit_details.length === 0
              " [ngStyle]="{
                cursor:
                  !EditJobForm.valid || shit_details.length === 0
                    ? 'not-allowed'
                    : 'pointer'
              }">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--Change Status Modal -->
      <div class="modal fade modal-bx-info editor" id="changeStatus" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle">
                Change Status
              </h5>
              <button type="button" class="close" data-dismiss="modal" #closeStatusModal aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Status</label>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="open" name="open1"
                              [(ngModel)]="job_status" value="open" />
                            <label class="custom-control-label" for="open">Open</label>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="closed" name="closed1"
                              [(ngModel)]="job_status" value="closed" />
                            <label class="custom-control-label" for="closed">Close</label>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="on_hold" name="on_hold1"
                              [(ngModel)]="job_status" value="on hold" />
                            <label class="custom-control-label" for="on_hold">On Hold</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="changeStatus()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--delete Jobs Modal -->
      <div class="modal fade modal-bx-info editor" id="deleteJobs" tabindex="-1" role="dialog"
        aria-labelledby="EmploymentModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EmploymentModalLongTitle">
                Delete Options
              </h5>
              <button type="button" class="close" data-dismiss="modal" #closeDeleteModal aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="height: 400px">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <!-- <label>Status</label> -->
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="delete_by_id" name="delete_by_id1"
                              [(ngModel)]="delete_type" [ngModelOptions]="{
                              standalone: true
                            }" value="delete_by_id" />
                            <label class="custom-control-label" for="delete_by_id">Delete Selected Jobs</label>
                          </div>
                        </div>
                      </div>

                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="delete_by_date" name="delete_by_date1"
                              [(ngModel)]="delete_type" [ngModelOptions]="{
                              standalone: true
                            }" value="delete_by_date" />
                            <label class="custom-control-label" for="delete_by_date">Delete Jobs before</label>
                          </div>
                          <div style="
                            margin-top: 15px;
                            margin-left: 30px;
                          ">
                            <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                              [(ngModel)]="delete_date" [ngModelOptions]="{
                              standalone: true
                            }" [config]="datePickerConfig">
                            </dp-date-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="site-button" data-dismiss="modal">
                Back
              </button>
              <button type="button" class="site-button" (click)="deleteJobs()">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->
    </div>
  </div>
</app-admin-default-layout>