import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AdminService } from "src/app/services/admin.service";
import Swal from "sweetalert2";
import * as moment from "moment";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-payroll-approval",
  templateUrl: "./payroll-approval.component.html",
  styleUrls: ["./payroll-approval.component.scss"],
})
export class PayrollApprovalComponent implements OnInit {
  @ViewChild("processSecondClose", { static: false })
  private processSecondClose: ElementRef;
  @ViewChild("calculatePayroleClose", { static: false })
  private calculatePayroleClose: ElementRef;

  moduleArray: any = [];

  client_id1: any = "ALL";
  year1: any = "ALL";
  month1: any = "ALL";
  week1: any = "ALL";
  yearList2: any;
  clientList1: any;
  monthList2: any;
  weekList2: any;

  payrollDataList: any = [];
  showModalBox: boolean = false;
  details: any;
  data: any;

  ///////////////
  weekly_per_diem: any;
  regular_hr: any;
  ot_hr: any;
  holiday_hr: any;
  bonus_amount: any;
  total_hr_worked: any;
  taxable_income: any;
  non_taxable_income: any;
  total_gross_income: any;
  comment: any;
  invoice_amount: any;
  deduction_rate: any = "4.5";
  invoice_after_deduction: any;
  profit: any;
  payrollDataListMain: any = [];
  acc_file_id: any;
  excelfileName: any;
  user_id: any;
  misc_exp_amt: any;
  submitErr: any = "";
  workHour: any = [];
  rec_work_hr_id: any;

  // new fields
  regular_hr_day: any;
  regular_hr_evening: any;
  regular_hr_night: any;
  onb_ot_bill_rate: any;
  dth_bill_rate: any;
  wk_non_taxable: any;
  non_taxable: any;
  dth_hr: any;
  total_pay: any;
  taxable_pay: any;
  taxable_w2_pay: any;
  invoice_income: any;
  invoice_after_msp_fee: any;
  onb_ot_pay_rate: any;
  dth_pay_rate: any;

  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */

  get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {
    this.user_id = sessionStorage.getItem("user_id");
    this.excelfileName =
      "payroll_approve(" + moment(new Date()).format("MM-DD-YYYY") + ")";
  }

  ngOnInit() {
    this.getClients();
    if (
      this.client_id1 === "ALL" &&
      this.year1 === "ALL" &&
      this.month1 === "ALL" &&
      this.week1 === "ALL"
    ) {
      this.searchList();
    }
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 400);
  }
  ////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  ////////////////////////////

  getClients() {
    this.http.getClientHired().subscribe((res: any) => {
      this.clientList1 = res;
    });
  }

  onOptionsSelected(val) {
    this.yearList2 = [];
    this.monthList2 = [];
    this.weekList2 = [];
    if (val !== "ALL") {
      this.month1 = "ALL";
      this.week1 = "ALL";
      this.year1 = "ALL";
      this.http.getYearFromClientID(val, 'submitted').subscribe((res: any) => {
        this.yearList2 = res;
      });
    }else{
      this.month1 = "ALL";
      this.week1 = "ALL";
      this.year1 = "ALL";
    }
  }
  onYearSelected2(val) {
    this.monthList2 = [];
    this.weekList2 = [];
    if (val !== "ALL") {
      this.month1 = "";
      this.week1 = "";
      this.http
        .getMonthFromClientIDandYr(this.client_id1, val, 'submitted')
        .subscribe((res: any) => {
          this.monthList2 = res;
        });
    }else{
      this.month1 = "";
      this.week1 = "";
    }
  }

  onMonthSelected2(val) {
    if (val !== "") {
      this.week1 = "ALL";
      this.http.getWeekByMonth(this.client_id1, this.year1, val, 'submitted').subscribe((res: any) => {
        this.weekList2 = res;
      });
    }
  }

  searchList() {
    this.http.spinnerShow();
    this.payrollDataListMain = [];
    let body = {
      client_id: this.client_id1,
      year: this.year1,
      month: this.month1,
      week_id: this.week1,
    };
    this.http.getPayrollAllDataSubmitted(body).subscribe((res: any) => {
      this.payrollDataListMain = res;
      this.http.spinnerHide();
      this.payrollDataList.forEach((el) => {
        if (el.payroll_status !== "submitted") {
          this.submitErr = "true";
        } else if (el.payroll_status === "submitted") {
          this.submitErr = "";
        }
      });
    });
  }

  editSubmit(val) {
    this.showModalBox = true;
    this.payrollDataList = [];
    this.acc_file_id = val.acc_file_id;
    let body = {
      client_id: val.client_id,
      // year: val.year,
      // month: val.month,
      // week_id: val.week_id,
      start_date: val.start_date,
      end_date: val.end_date,
      acc_file_id: val.acc_file_id,
    };
    this.http.getDataByFileID(body).subscribe((res: any) => {
      this.payrollDataList = res;
      this.payrollDataList.forEach((el) => {
        if (el.payroll_status !== "submitted") {
          this.submitErr = "true";
        } else if (el.payroll_status === "submitted") {
          this.submitErr = "";
        }
      });
    });
  }

  refreshSecondModal() {
    this.showModalBox = true;
    this.payrollDataList = [];
    let body = {
      client_id: this.details.client,
      start_date: this.details.acc_start_date,
      end_date: this.details.acc_end_date,
      acc_file_id: this.details.account_file_id
    };
    this.http.getDataByFileID(body).subscribe((res: any) => {
      this.payrollDataList = res;
      this.acc_file_id = this.details.account_file_id;
      this.payrollDataList.forEach((el) => {
        if (el.payroll_status !== "submitted") {
          this.submitErr = "true";
        } else if (el.payroll_status === "submitted") {
          this.submitErr = "";
        }
      });
    });
  }

  openCalculation(val) {
    this.details = "";
    this.data = "";
    this.details = val;
    let body1 = {
      engagement_id: this.details.engagement_id,
      rec_payroll_id: this.details.rec_payroll_id,
      start_date: this.details.start_date,
      end_date: this.details.end_date,
    };
    this.getWorkHour(body1);
    let body = {
      engagement_id: this.details.engagement_id,
      start_date: this.details.start_date,
      end_date: this.details.end_date,
      rec_payroll_id: this.details.rec_payroll_id,
      week_id: `${this.details.start_date} - ${this.details.end_date}`,
    };
    this.http.getPayrollDataByAssignID(body).subscribe(
      (res: any) => {
        this.data = []
        if(res && res.length > 0){
          this.data = res[0];
          
          if (
            this.workHour.length !== 0 && (
              res[0].regular_hr_day === null ||
              res[0].regular_hr_evening === null ||
              res[0].regular_hr_night === null ||
              res[0].ot_hr === null ||
              res[0].dth_hr === null ||
              res[0].bonus_amount === null
            )
          ) {
            this.regular_hr_day = this.workHour[0]?.rec_reg_hr_day;
            this.regular_hr_evening = this.workHour[0]?.rec_reg_hr_evening;
            this.regular_hr_night = this.workHour[0]?.rec_reg_hr_night;
            this.ot_hr = this.workHour[0]?.rec_ot_hr;
            this.dth_hr = this.workHour[0]?.rec_dth_hr;
            this.bonus_amount = 0;
            this.misc_exp_amt = 0;
            this.rec_work_hr_id = this.workHour[0].rec_work_hr_id;
          } else if (
            this.workHour.length === 0 && (
              res[0].regular_hr_day === null ||
              res[0].regular_hr_evening === null ||
              res[0].regular_hr_night === null ||
              res[0].ot_hr === null ||
              res[0].dth_hr === null ||
              res[0].bonus_amount === null
            )
          ) {
            this.regular_hr_day = 0;
            this.regular_hr_evening = 0;
            this.regular_hr_night = 0;
            this.ot_hr = 0;
            this.dth_hr = 0;
            this.bonus_amount = 0;
            this.misc_exp_amt = 0;
            this.rec_work_hr_id = 0;
          } else {
            this.regular_hr_day = res[0].regular_hr_day;
            this.regular_hr_evening = res[0].regular_hr_evening;
            this.regular_hr_night = res[0].regular_hr_night;
            this.ot_hr = res[0].ot_hr;
            this.dth_hr = res[0].dth_hr;
            this.bonus_amount = res[0].bonus_amount;
            this.misc_exp_amt = res[0].misc_exp_amt;
            this.rec_work_hr_id = this.workHour && this.workHour.length > 0 && this.workHour[0].rec_work_hr_id;
          }
  
          if(res[0].shift == "Day"){
            this.onb_ot_bill_rate = res[0].eng_billrateOt_day
            this.dth_bill_rate = res[0].eng_billrateDt_day
            this.onb_ot_pay_rate = res[0].eng_payrateOt_day
            this.dth_pay_rate = res[0].eng_payrateDt_day
          }else if(res[0].shift == "Evening"){
            this.onb_ot_bill_rate = res[0].eng_billrateOt_evn
            this.dth_bill_rate = res[0].eng_billrateDt_evn
            this.onb_ot_pay_rate = res[0].eng_payrateOt_evn
            this.dth_pay_rate = res[0].eng_payrateDt_evn
          }else if(res[0].shift == "Night"){
            this.onb_ot_bill_rate = res[0].eng_billrateOt_ngt
            this.dth_bill_rate = res[0].eng_billrateDt_ngt
            this.onb_ot_pay_rate = res[0].eng_payrateOt_ngt
            this.dth_pay_rate = res[0].eng_payrateDt_ngt
          }
          this.total_hr_worked = res[0].total_hr_worked !== null ? res[0].total_hr_worked : Number(this.regular_hr_day) + Number(this.regular_hr_evening) + Number(this.regular_hr_night) + Number(this.ot_hr) + Number(this.dth_hr);
          
          this.wk_non_taxable = res[0].wk_non_taxable !== null ? res[0].wk_non_taxable : 0;

          this.non_taxable = res[0].non_taxable !== null ? res[0].non_taxable : (Number(this.wk_non_taxable) + Number(this.misc_exp_amt)).toFixed(2);

          this.total_pay = res[0].total_pay !== null ? res[0].total_pay : (
            (Number(this.regular_hr_day) * Number(this.data.eng_payrateReg_day)) +
            (Number(this.regular_hr_evening) * Number(this.data.eng_payrateReg_evn)) +
            (Number(this.regular_hr_night) * Number(this.data.eng_payrateReg_ngt)) +
            (Number(this.ot_hr) * Number(this.onb_ot_pay_rate)) +
            (Number(this.dth_hr) * Number(this.dth_pay_rate)) +
            Number(this.bonus_amount) +
            Number(this.misc_exp_amt)
          ).toFixed(2);

          this.taxable_pay = res[0].taxable_pay !== null ? res[0].taxable_pay : (
            Number(this.total_pay) - Number(this.non_taxable)
          ).toFixed(2);
          this.taxable_w2_pay = res[0].taxable_w2_pay !== null ? res[0].taxable_w2_pay : (
            Number(this.taxable_pay) - Number(this.bonus_amount)
          ).toFixed(2);
          this.comment = res[0].comments;
          this.invoice_after_msp_fee = res[0].invoice_after_msp_fee;
          this.invoice_income = res[0].invoice_income;

          this.profit = res[0].profit;
        }
      },
      (err) => {}
    );
  }

  getWorkHour(data) {
    this.http.getWorkHour(data).subscribe(
      (res) => {
        this.workHour = res;
      },
      (err) => {}
    );
  }

  changetotalHr(e) {
    this.total_hr_worked =
    Number(this.regular_hr_day) + Number(this.regular_hr_evening) + Number(this.regular_hr_night) + Number(this.ot_hr) + Number(this.dth_hr);
    
    this.total_pay = (
      (Number(this.regular_hr_day) * Number(this.data.eng_payrateReg_day)) +
      (Number(this.regular_hr_evening) * Number(this.data.eng_payrateReg_evn)) +
      (Number(this.regular_hr_night) * Number(this.data.eng_payrateReg_ngt)) +
      (Number(this.ot_hr) * Number(this.onb_ot_pay_rate)) +
      (Number(this.dth_hr) * Number(this.dth_pay_rate)) +
      Number(this.bonus_amount) +
      Number(this.misc_exp_amt)
    ).toFixed(2);
    
    this.non_taxable = (
      Number(this.wk_non_taxable) + Number(this.misc_exp_amt)
    ).toFixed(2);

    this.taxable_pay = (
      Number(this.total_pay) - Number(this.non_taxable)
    ).toFixed(2);

    this.invoice_income = (
      (Number(this.regular_hr_day) * Number(this.data.eng_billrateReg_day)) +
      (Number(this.regular_hr_evening) * Number(this.data.eng_billrateReg_evn)) +
      (Number(this.regular_hr_night) * Number(this.data.eng_billrateReg_ngt)) +
      (Number(this.ot_hr) * Number(this.onb_ot_bill_rate)) +
      (Number(this.dth_hr) * Number(this.dth_bill_rate)) 
    ).toFixed(2);
      
    this.taxable_w2_pay = (Number(this.taxable_pay) - Number(this.bonus_amount)).toFixed(2);

    this.invoice_after_msp_fee = (
      Number(this.invoice_income) - (Number(this.invoice_income) * Number(this.data.msp_fee) / 100)
    ).toFixed(2);

    this.profit = (
      (Number(this.invoice_after_msp_fee) - Number(this.total_pay)) - (Number(this.taxable_pay) * Number(this.data.payroll_expenses) / 100)
    ).toFixed(2);

    // Restrict negative by typing
    var key = !isNaN(e.charCode) ? e.charCode : e.keyCode;
    function keyAllowed() {
      var keys = [
        8, 9, 13, 16, 17, 18, 19, 20, 27, 46, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 91, 92, 93,
      ];
      if (key && keys.indexOf(key) === -1) return false;
      else return true;
    }
    if (!keyAllowed()) e.preventDefault();
  }

  changeDeduction(e) {
    this.invoice_after_deduction = (
      Number(this.invoice_amount) -
      (Number(this.invoice_amount) * Number(this.deduction_rate)) / 100
    ).toFixed(2);

    this.profit = (
      Number(this.invoice_after_deduction) -
      Number(this.total_gross_income) -
      Number(this.taxable_income) * 0.12
    ).toFixed(2);

    // var t = e.target.value;
    // e.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;

    ///// Restrict negative by typing

    var key = !isNaN(e.charCode) ? e.charCode : e.keyCode;
    function keyAllowed() {
      var keys = [
        8, 9, 13, 16, 17, 18, 19, 20, 27, 46, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 91, 92, 93,
      ];
      if (key && keys.indexOf(key) === -1) return false;
      else return true;
    }
    if (!keyAllowed()) e.preventDefault();
  }

  submitPayroll() {
    let insertJson = {
      rec_payroll_id: this.data.rec_payroll_id,
      engagement_id: this.details.engagement_id,
      start_date: moment(new Date(this.details.start_date)).format( "MM/DD/YYYY" ),
      end_date: moment(new Date(this.details.end_date)).format( "MM/DD/YYYY" ),
      acc_file_id: this.details.account_file_id,
      bonus_amount: Number(this.bonus_amount).toFixed(2),
      comments: this.comment,
      payroll_status: "submitted",
      misc_exp_amt: Number(this.misc_exp_amt).toFixed(2),
      rec_work_hr_id: this.rec_work_hr_id,
      
      // new fields 
      wk_non_taxable: Number(this.wk_non_taxable).toFixed(2),
      non_taxable: Number(this.non_taxable),
      regular_hr_day: Number(this.regular_hr_day).toFixed(2),
      regular_hr_evening: Number(this.regular_hr_evening).toFixed(2),
      regular_hr_night: Number(this.regular_hr_night).toFixed(2),
      ot_hr: Number(this.ot_hr).toFixed(2),
      dth_hr: Number(this.dth_hr).toFixed(2),

      total_hr_worked: Number(this.total_hr_worked),
      total_pay: Number(this.total_pay),
      taxable_pay: Number(this.taxable_pay),
      taxable_w2_pay: Number(this.taxable_w2_pay),
      invoice_income: Number(this.invoice_income),
      invoice_after_msp_fee: Number(this.invoice_after_msp_fee),
      profit: Number(this.profit),
    };

    this.http.insertPayrollData(insertJson).subscribe(
      (res: any) => {
        if (res === "success") {
          this.successMsg("Payroll calculated successfully.");
        } else {
          this.errorMsg("Something went wrong. Please Try Again.");
        }
      },
      (err) => {
        this.errorMsg("Something went wrong. Please Try Again.");
      }
    );
  }

  approvePayroll() {
    let data = {
      acc_file_id: this.acc_file_id,
    };
    this.http.approvePayroll(data).subscribe(
      (res: any) => {
        if (res === "success") {
          this.successMsg2("Payroll file approved successfully.");
          this.processSecondClose.nativeElement.click();
          this.searchList();
        } else {
          this.errorMsg("Something went wrong. Please Try Again.");
        }
      },
      (err) => {
        this.errorMsg("Something went wrong. Please Try Again.");
      }
    );
  }

  errorMsg(msg) {
    Swal.fire({
      title: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  successMsg(msg) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.calculatePayroleClose.nativeElement.click();
        this.refreshSecondModal();
      }
    });
  }

  successMsg2(msg) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }
}
