<body id="bg">
  <div class="page-wraper">
    <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#4C96D7"
  type="ball-square-clockwise-spin">
      <p style="color: white">Loading...</p>
    </ngx-spinner>

    <!-- Content -->
    <div class="page-content bg-white">
      <!-- contact area -->
      <div class="content-block">
        <!-- Browse Jobs -->
        <div class="section-full bg-white p-t50 p-b20">
          <div class="container">
            <div class="row">
              <div class="col-xl-2 col-lg-2 m-b30">
                <app-side-bar-candidate></app-side-bar-candidate>
              </div>

              <div class="col-xl-10 col-lg-10 m-b30">
                <div class="job-bx submit-resume">
                  <div class="job-bx-title clearfix">
                    <h5 class="font-weight-700 pull-left text-uppercase">
                      Change password (web)
                    </h5>
                  </div>

                  <form
                    class="job-alert-bx"
                    [formGroup]="passwordForm"
                    style="
                      width: 50%;
                      margin: 0 auto;
                      box-shadow: 0px 1px 2px 0px grey;
                      margin-bottom: 3%;
                      padding: 3%;
                    "
                  >
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Old Password<span class="requiredField"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control"
                          formControlName="old_password"
                          type="password"
                          (input)="checkOldPassword()"
                        />
                        <div
                          *ngIf="
                            passwordForm.controls['old_password'].invalid &&
                            (passwordForm.controls['old_password'].dirty ||
                              passwordForm.controls['old_password'].touched)
                          "
                          class="alert alert-danger"
                          style="
                            font-size: 12px;
                            padding: 0.5rem 1.25rem;
                            margin-bottom: 0px;
                          "
                        >
                          <div
                            *ngIf="
                              passwordForm.controls['old_password'].errors
                                ?.['required']
                            "
                          >
                            Old Password is required.
                          </div>
                        </div>
                        <div
                          *ngIf="
                            (passwordForm.controls['old_password'].dirty ||
                              passwordForm.controls['old_password'].touched) &&
                            !old_password
                          "
                          class="alert alert-danger"
                          style="font-size: 12px; padding: 0.5rem 1.25rem"
                        >
                          <div *ngIf="!old_password">
                            Old Password does not match.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Password<span class="requiredField">*</span></label
                        >
                        <input
                          class="form-control"
                          formControlName="password"
                          (input)="reset_retype_pass()"
                          type="password"
                        />
                        <div
                          *ngIf="
                            passwordForm.controls['password'].invalid &&
                            (passwordForm.controls['password'].dirty ||
                              passwordForm.controls['password'].touched)
                          "
                          class="alert alert-danger"
                          style="font-size: 12px; padding: 0.5rem 1.25rem"
                        >
                          <div
                            *ngIf="
                              passwordForm.controls['password'].errors?.['required']
                            "
                          >
                            Password is required.
                          </div>
                          <div
                            *ngIf="
                              passwordForm.controls['password'].errors?.['pattern']
                            "
                          >
                            Password must has at least 8 characters that include
                            atleast 1 uppercase character, 1 lowercase
                            character, 1 number, and 1 special character
                            in(#?!@$%^&*-).
                          </div>
                          <div
                            *ngIf="
                              passwordForm.controls['password'].errors?.['maxlength']
                            "
                          >
                            Password must be at most 30 characters long.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Confirm Password<span class="requiredField"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control"
                          formControlName="retype_password"
                          type="password"
                        />

                        <div
                          *ngIf="
                            passwordForm.controls['retype_password'].invalid &&
                            (passwordForm.controls['retype_password'].dirty ||
                              passwordForm.controls['retype_password'].touched)
                          "
                          class="alert alert-danger"
                          style="
                            font-size: 12px;
                            padding: 0.5rem 1.25rem;
                            margin-bottom: 0px;
                          "
                        >
                          <div
                            *ngIf="
                              passwordForm.controls['retype_password'].errors
                                ?.['required']
                            "
                          >
                            Confirm Password is required.
                          </div>
                        </div>
                        <div
                          *ngIf="
                            passwordForm.controls['retype_password'].value !==
                              passwordForm.controls['password'].value &&
                            (passwordForm.controls['retype_password'].dirty ||
                              passwordForm.controls['retype_password'].touched)
                          "
                          class="alert alert-danger"
                          style="
                            font-size: 12px;
                            padding: 0.5rem 1.25rem;
                            margin-bottom: 0px;
                          "
                        >
                          <div
                            *ngIf="
                              passwordForm.controls['retype_password'].value !==
                              passwordForm.controls['password'].value
                            "
                          >
                            Confirm Password does not match.
                          </div>
                        </div>

                        <div
                          *ngIf="
                            !passwordForm.controls['password'].value &&
                            (passwordForm.controls['retype_password'].dirty ||
                              passwordForm.controls['retype_password'].touched)
                          "
                          class="alert alert-danger"
                          style="font-size: 12px; padding: 0.5rem 1.25rem"
                        >
                          <div *ngIf="!passwordForm.controls['password'].value">
                            Password is Empty.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 text-left" style="margin-top: 3%">
                      <button
                        class="site-button"
                        style="margin-left: 1%"
                        [disabled]="passwordForm.invalid"
                        [ngStyle]="{
                          cursor: !passwordForm.valid
                            ? 'not-allowed'
                            : 'pointer'
                        }"
                        (click)="changePassword()"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Browse Jobs END -->
      </div>
    </div>
    <!-- Content END-->

    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</body>
