<body id="bg">
  <div class="page-wraper">
    <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#4C96D7"
  type="ball-square-clockwise-spin">
      <p style="color: white">Loading...</p>
    </ngx-spinner>

    <!-- Content -->
    <div class="page-content bg-white">
      <!-- contact area -->
      <div class="content-block">
        <!-- Browse Jobs -->
        <div class="section-full bg-white p-t50 p-b20">
          <div class="container">
            <div class="row">
              <div class="col-xl-2 col-lg-2 m-b30">
                <div class="sticky-top">
                  <div class="candidate-info company-info">
                    <div class="candidate-detail text-center">
                      <div class="canditate-des">
                        <a href="javascript:void(0);">
                          <img alt="" src="../assets/images/logo/logo.png" />
                        </a>
                      </div>
                      <div class="candidate-title">
                        <h4 class="m-b5">
                          <a href="javascript:void(0);">JOBS</a>
                        </h4>
                      </div>
                    </div>
                    <ul>
                      <li *ngFor="let a of moduleArray; let i = index">
                        <a
                          (click)="navigateTo(a)"
                          class="oldclass"
                          id="clsActive{{ a.submodule_id }}"
                          style="text-decoration: none"
                        >
                          <i class="fa fa-briefcase" aria-hidden="true"></i>
                          <span>{{ a.submodule_name_lower }}</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-xl-10 col-lg-10 m-b30">
                <div class="job-bx submit-resume">
                  <div class="job-bx-title clearfix">
                    <h5 class="font-weight-700 pull-left text-uppercase">
                      My Jobs Dashboard
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Browse Jobs END -->
      </div>
    </div>
    <!-- Content END-->

    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</body>
