  import {MatFormFieldModule} from '@angular/material/form-field';
  import {MatAutocompleteModule} from '@angular/material/autocomplete';
  import {MatInputModule} from '@angular/material/input';
  import {MatSlideToggleModule} from '@angular/material/slide-toggle';
  import {MatTooltipModule} from '@angular/material/tooltip';
  import {MatBadgeModule} from '@angular/material/badge';

  import { NgModule } from '@angular/core';
  @NgModule({
    exports: [ 
      MatAutocompleteModule,
      MatFormFieldModule,
      MatInputModule,
      MatSlideToggleModule,
      MatTooltipModule,
      MatBadgeModule
    ]
    
  }) 
  
  export class MaterialModule {}