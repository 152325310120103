<app-page-header
  heading="Clients &amp; Partners"
  subheading="Assisting you in every step of the hiring process"
></app-page-header>

<div class="page-container space-y-8 md:space-y-16 py-8 md:py-16">
  <section class="section-side-image-text-container">
    <div class="text-container left-text">
      <h3 class="typog_h3 text-gray-900">Managed Service Provider</h3>
      <p class="body">
        Collaborating with top MSPs, we cater to companies across the United
        States by providing Top Talent. Submitting fully vetted candidates
        within 48 hours of receiving a job requisition is key in getting
        positions filled quickly. Our recruiters stay close to their candidates
        to ensure a seamless hiring process. We strive to become your trusted
        partner in staffing.
      </p>
    </div>
    <div class="image-container right-image">
      <div class="image">
        <img
          src="../../assets/web-images/main/supplier-MSP-VMS.jpg"
          alt="White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull."
          class="object-cover object-center"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container right-text">
      <h3 class="typog_h3 text-gray-900">Light Industrial</h3>
      <p class="body">
        When it comes to manufacturing and production it is our goal to be a
        one-stop-shop for your contingent workforce. Our LI recruitment teams
        are committed to becoming experts in the roles that they staff. From
        skilled trades to fulfillment, we ensure that the candidates we submit
        have the right experience for the role.
      </p>
    </div>
    <div class="image-container left-image">
      <div class="image">
        <img
          src="../../assets/web-images/main/machines.jpg"
          alt="Detail of zipper pull with tan leather and silver rivet."
          class="object-cover object-center"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container left-text">
      <h3 class="typog_h3 text-gray-900">Office &amp; Administrative</h3>
      <p class="body">
        Staffing for mid to large sized organizations, our pool of candidates
        have experience ranging from management to clerical/customer service
        roles. Positions we staff on a regular basis include, office management,
        a variety of administrative roles within accounting, marketing &amp;
        human resources departments, and customer service/call center
        representatives.
      </p>
    </div>
    <div class="image-container right-image">
      <div class="image">
        <img
          src="../../assets/web-images/main/clients_work.jpg"
          alt="White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull."
          class="object-cover object-top"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container right-text">
      <h3 class="typog_h3 text-gray-900">Tech Firms</h3>
      <p class="body">
        Transformational technologies demand equally transformative
        partnerships. We have partnered with major boutique tech firms across
        the country to develop and implement some of the most innovative and
        cutting-edge technologies. All supplied resources are hand-picked from
        the industry with diverse skill sets in machine learning, Artificial
        Intelligence, Blockchain, Cybersecurity, Big data, RDBMS, Cloud
        computing, Java technologies, .net technologies and Python programming.
      </p>
    </div>
    <div class="image-container left-image">
      <div class="image">
        <img
          src="../../assets/web-images/main/IT.jpg"
          alt="Detail of zipper pull with tan leather and silver rivet."
          class="object-cover object-center"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container left-text">
      <h3 class="typog_h3 text-gray-900">Implementation Partners</h3>
      <p class="body">
        Our resources will help you develop and enhance the processes of
        initiating, planning, executing, monitoring, managing, and closing a
        project.  Placing emphasis on balancing scope, resources, and schedule
        to deliver a project’s requirements on-time and on- budget is key. Our
        combination of training, deployment planning, and consulting services
        has helped organizations completely convert their product development
        approaches to agile, resulting in significant speed to value.
      </p>
    </div>
    <div class="image-container right-image">
      <div class="image">
        <img
          src="../../assets/web-images/main/Finance.jpg"
          alt="White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull."
          class="object-cover object-top"
        />
      </div>
    </div>
  </section>

  <section class="section-side-image-text-container">
    <div class="text-container right-text">
      <h3 class="typog_h3 text-gray-900">Industry Experience</h3>
      <p class="body">
        Experienced in supporting a variety of verticals providing top talent.
        We have partnered with companies/organizations in the following areas:
      </p>
      <ul class="mt-4 list-disc pl-4 text-gray-900">
        <li>Manufacturing/Skilled Trades</li>
        <li>Distribution</li>
        <li>Aviation</li>
        <li>Medical Device</li>
        <li>Healthcare</li>
        <li>Banking</li>
        <li>Insurance</li>
        <li>Food Service</li>
        <li>Corporate Retail</li>
      </ul>
    </div>
    <div class="image-container left-image">
      <div class="image">
        <img
          src="../../assets/web-images/main/itservice5.png"
          alt="IT Service"
          class="object-cover object-center"
        />
      </div>
    </div>
  </section>
</div>
<app-cta></app-cta>
