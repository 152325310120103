<body id="bg">
  <div class="page-wraper">
    <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#4C96D7"
  type="ball-square-clockwise-spin">
      <p style="color: white">Loading...</p>
    </ngx-spinner>

    <!-- Content -->
    <div class="page-content bg-white">
      <!-- contact area -->
      <div class="content-block">
        <!-- Browse Jobs -->
        <div class="section-full bg-white p-t50 p-b20">
          <div class="container">
            <div class="row">
              <div class="col-xl-2 col-lg-2 m-b30">
                <app-side-bar-candidate></app-side-bar-candidate>
              </div>

              <div class="col-xl-10 col-lg-10 m-b30">
                <div class="job-bx submit-resume">
                  <div class="job-bx-title clearfix">
                    <h5 class="font-weight-700 pull-left text-uppercase">
                      Change passcode (App)
                    </h5>
                  </div>

                  <form
                    class="job-alert-bx"
                    [formGroup]="passcodeForm"
                    style="
                      width: 50%;
                      margin: 0 auto;
                      box-shadow: 0px 1px 2px 0px grey;
                      margin-top: 3%;
                      padding: 3%;
                    "
                  >
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Old Passcode<span class="requiredField"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control"
                          formControlName="old_passcode"
                          type="password"
                          (input)="checkOldPasscode()"
                        />
                        <div
                          *ngIf="
                            passcodeForm.controls['old_passcode'].invalid &&
                            (passcodeForm.controls['old_passcode'].dirty ||
                              passcodeForm.controls['old_passcode'].touched)
                          "
                          class="alert alert-danger"
                          style="
                            font-size: 12px;
                            padding: 0.5rem 1.25rem;
                            margin-bottom: 0px;
                          "
                        >
                          <div
                            *ngIf="
                              passcodeForm.controls['old_passcode'].errors
                                ?.['required']
                            "
                          >
                            Old Passcode is required.
                          </div>
                        </div>
                        <div
                          *ngIf="
                            (passcodeForm.controls['old_passcode'].dirty ||
                              passcodeForm.controls['old_passcode'].touched) &&
                            !old_passcode
                          "
                          class="alert alert-danger"
                          style="font-size: 12px; padding: 0.5rem 1.25rem"
                        >
                          <div *ngIf="!old_passcode">
                            Old Passcode does not match.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Passcode<span class="requiredField">*</span></label
                        >
                        <input
                          class="form-control"
                          formControlName="passcode"
                          (input)="reset_retype_passcode()"
                          type="password"
                        />
                        <div
                          *ngIf="
                            passcodeForm.controls['passcode'].invalid &&
                            (passcodeForm.controls['passcode'].dirty ||
                              passcodeForm.controls['passcode'].touched)
                          "
                          class="alert alert-danger"
                          style="
                            font-size: 12px;
                            padding: 0.5rem 1.25rem;
                            margin-bottom: 0px;
                          "
                        >
                          <div
                            *ngIf="
                              passcodeForm.controls['passcode'].errors?.['required']
                            "
                          >
                            Passcode is required.
                          </div>
                          <div *ngIf="passcodeForm.controls['passcode'].invalid">
                            Passcode must be 4 digits (0-9).
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Confirm Passcode<span class="requiredField"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control"
                          formControlName="retype_passcode"
                          type="password"
                        />

                        <div
                          *ngIf="
                            passcodeForm.controls['retype_passcode'].invalid &&
                            (passcodeForm.controls['retype_passcode'].dirty ||
                              passcodeForm.controls['retype_passcode'].touched)
                          "
                          class="alert alert-danger"
                          style="
                            font-size: 12px;
                            padding: 0.5rem 1.25rem;
                            margin-bottom: 0px;
                          "
                        >
                          <div
                            *ngIf="
                              passcodeForm.controls['retype_passcode'].errors
                                ?.['required']
                            "
                          >
                            Confirm Passcode is required.
                          </div>
                        </div>
                        <div
                          *ngIf="
                            passcodeForm.controls['retype_passcode'].value !==
                              passcodeForm.controls['passcode'].value &&
                            (passcodeForm.controls['retype_passcode'].dirty ||
                              passcodeForm.controls['retype_passcode'].touched)
                          "
                          class="alert alert-danger"
                          style="
                            font-size: 12px;
                            padding: 0.5rem 1.25rem;
                            margin-bottom: 0px;
                          "
                        >
                          <div
                            *ngIf="
                              passcodeForm.controls['retype_passcode'].value !==
                              passcodeForm.controls['passcode'].value
                            "
                          >
                            Confirm Passcode does not match.
                          </div>
                        </div>

                        <div
                          *ngIf="
                            !passcodeForm.controls['passcode'].value &&
                            (passcodeForm.controls['retype_passcode'].dirty ||
                              passcodeForm.controls['retype_passcode'].touched)
                          "
                          class="alert alert-danger"
                          style="font-size: 12px; padding: 0.5rem 1.25rem"
                        >
                          <div *ngIf="!passcodeForm.controls['passcode'].value">
                            Passcode is Empty.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 text-left" style="margin-top: 3%">
                      <button
                        class="site-button"
                        style="margin-left: 1%"
                        [disabled]="
                          passcodeForm.invalid &&
                          passcodeForm.controls['retype_passcode'].value !==
                            passcodeForm.controls['passcode'].value
                        "
                        [ngStyle]="{
                          cursor: !passcodeForm.valid
                            ? 'not-allowed'
                            : 'pointer'
                        }"
                        (click)="changePasscode()"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Browse Jobs END -->
      </div>
    </div>
    <!-- Content END-->

    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</body>
