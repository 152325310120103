<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Payroll Approval
        </h5>
      </div>

      <div class="job-bx-title clearfix">
        <div class="form-group" style="display: flex">
          <div>
            <label style="font-size: 14px">Client</label>
            <select
              class="form-control"
              [(ngModel)]="client_id1"
              #myClient1
              (change)="onOptionsSelected(myClient1.value)"
            >
              <option value="ALL" selected>All</option>
              <option
                *ngFor="let data of clientList1 | sortBy:'client_name'"
                [value]="data.client_id"
                style="text-transform: capitalize"
              >
                {{ data.client_name }}
              </option>
            </select>
          </div>

          <div style="margin-left: 12px">
            <label style="font-size: 14px">Year</label>
            <select [style.min-width]="'75px'" class="form-control" [(ngModel)]="year1" #myyear2
              (change)="onYearSelected2(myyear2.value)">
              <option value="ALL" selected>All</option>
              <option *ngFor="let data of yearList2" [value]="data" style="text-transform: capitalize">
                {{ data }}
              </option>
            </select>
          </div>

          <div style="margin-left: 12px">
            <label style="font-size: 14px">Month</label>
            <select [style.min-width]="'75px'" class="form-control" [(ngModel)]="month1" #myMonth2
              (change)="onMonthSelected2(myMonth2.value)">
              <option value="ALL" selected>All</option>
              <option *ngFor="let data of monthList2" [value]="data.month_no"
                style="text-transform: capitalize">
                {{ data.month_name }}
              </option>
            </select>
          </div>

          <div style="margin-left: 12px">
            <label style="font-size: 14px">Week</label>
            <select [style.min-width]="'75px'" class="form-control" [(ngModel)]="week1">
              <option value="ALL" selected>All</option>
              <option *ngFor="let data of weekList2 | sortBy:'start_date'" [value]="data.start_date + '-' + data.end_date"
                style="text-transform: capitalize">
                {{ data.start_date }} - {{ data.end_date }}
              </option>
            </select>
          </div>

          <div>
            <div
              style="display: inline-block; margin-bottom: 0.5rem"
            ></div>
            <div
              type="button"
              class="site-button2"
              (click)="searchList()"
            >
              View
            </div>
          </div>
        </div>
      </div>

      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th class="feature"></th>
            <th>Payroll File no.</th>
            <th>Client</th>
            <th>Year</th>
            <th>Month</th>
            <th>Week</th>
            <th>Status</th>
            <th style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let a of payrollDataListMain
                | paginate
                  : { itemsPerPage: count, currentPage: page }
            "
          >
            <td class="feature"></td>
            <td class="application text-primary">
              <a>{{ a.file_no }}</a>
            </td>
            <td class="application text-primary">
              <a>{{ a.client_name }}</a>
            </td>
            <td class="application text-primary">{{ a.year && a.year != null ? a.year : (a.end_date | date:'YYY') }}</td>
            <td class="application text-primary">
              <a>{{ a.month && a.month != null ? a.month : (a.end_date | date:'MMM') }}</a>
            </td>
            <td class="application text-primary">
              <a>{{ a.start_date }} - {{ a.end_date }}</a>
            </td>
            <td
              class="expired pending"
              style="text-transform: capitalize"
              *ngIf="a.approval_status === 'not_processed'"
            >
              <a>Not Processed</a>
            </td>
            <td
              class="expired success"
              style="text-transform: capitalize"
              *ngIf="a.approval_status === 'approved'"
            >
              <a>Approved</a>
            </td>

            <td class="job-links">
              <div class="dropdown">
                <a href="javascript:void(0);">
                  <i class="fa fa-cog"></i>
                </a>
                <div class="dropdown-content">
                  <a
                    href="avascript:void(0);"
                    (click)="editSubmit(a)"
                    data-toggle="modal"
                    data-target="#processPayrollSecond"
                    >
                    <!-- *ngIf="a.approval_status !== 'approved'" -->
                    Edit & Approve
                  </a>
                  <a
                    href="avascript:void(0);"
                    href="{{ apiUrl }}vcsapi/get/api/approval_status/approved/accountfiledata/download/excelfile/{{ a.acc_file_id }}/{{ excelfileName }}"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    >Download
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <pagination-controls
          *ngIf="payrollDataListMain.length"
          class="product-pagination"
          autoHide="false"
          maxSize="8"
          (pageChange)="onPageChanged($event)"
        ></pagination-controls>
      </div>
      <!-- Edit and Approve Modal -->
      <div
        class="modal fade modal-bx-info editor"
        id="processPayrollSecond"
        tabindex="-1"
        role="dialog"
        aria-labelledby="EducationModalLongTitle"
        aria-hidden="true"
        *ngIf="showModalBox"
      >
        <div
          class="modal-dialog"
          role="document"
          style="max-width: 80% !important"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                Edit and Approve
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                #processSecondClose
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              class="modal-body"
              *ngIf="payrollDataList.length !== 0"
            >
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Client :&nbsp;&nbsp;</label>
                    <span
                      class="clearfix font-13"
                      style="margin-top: 3px"
                      >{{ payrollDataList[0].client_name }}
                    </span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Start Date :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">{{ payrollDataList[0].acc_start_date }}</span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">End Date :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">{{ payrollDataList[0].acc_end_date }}</span>
                  </div>
                </div>
                <div class="max-h-[600px] overflow-auto">
                  <table class="table-job-bx cv-manager company-manage-job">
                    <thead>
                      <tr>
                        <th class="feature"></th>
                        <th>Employee Name</th>
                        <!-- <th>Job ID</th> -->
                        <!-- <th>Reg. Hr.</th> -->
                        <!-- <th>OT Hr.</th> -->
                        <th>Total Hours Worked</th>
                        <th>Non Taxable Pay</th>
                        <th>Total Taxable Pay</th>
                        <th>Total Earnings</th>
                        <th>Invoice Amount</th>
                        <th>Invoice After MSP fee</th>
                        <th>VCS profit</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let a of payrollDataList;
                          let i = index
                        ">
                        <td class="feature"></td>

                        <td class="application text-primary" *ngIf="a.payroll_status === 'inprocess'"
                          style="color: red !important">
                          {{a.emp_name}}
                        </td>
                        <td class="application text-primary" *ngIf="a.payroll_status === 'submitted'"
                          style="color: blue !important">
                          {{a.emp_name}}
                        </td>
                        <td class="application text-primary" *ngIf="a.payroll_status === 'approved'">
                          {{a.emp_name}}
                        </td>
                        <td class="application text-primary">
                          {{ a?.total_hr_worked }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.non_taxable }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.taxable_pay }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.total_pay }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.invoice_income }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.invoice_after_msp_fee }}
                        </td>
                        <td class="application text-primary">
                          {{ a?.profit }}
                        </td>
                        <td class="application text-primary">
                          <div class="appCount" style="
                              background-color: #563a10;
                              width: 72px;
                              margin-left: -10px;
                            " (click)="openCalculation(a)" data-toggle="modal"
                            data-target="#calculatePayrole">
                            Edit
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                (click)="approvePayroll()"
                [disabled]="submitErr === 'true'"
                [ngStyle]="{
                  cursor:
                    submitErr === 'true' ? 'not-allowed' : 'pointer'
                }"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

    <!-- Calculate Payrole Modal -->
    <div class="modal fade modal-bx-info editor" id="calculatePayrole" tabindex="-1" role="dialog"
      aria-labelledby="EducationModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document" style="max-width: 60% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="EducationModalLongTitle">
              Calculate Weekly Pay
            </h5>
            <button type="button" class="close" data-dismiss="modal" #calculatePayroleClose
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" *ngIf="details && data">
            <form>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Applicant Name :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      {{ details.emp_name }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Client :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">{{ details.client_name }}</span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Start Date:&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">{{ details.start_date }}</span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">End Date:&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">{{ details.end_date }}</span>
                  </div>
                </div>
                <div class="divider"></div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Reg Bill Rate - Day :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ data.eng_billrateReg_day }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Reg Bill Rate - Even :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ data.eng_billrateReg_evn }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Reg Bill Rate - Night :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ data.eng_billrateReg_ngt }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">OT Bill Rate :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ onb_ot_bill_rate }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">DT/H Bill rate :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ dth_bill_rate }}
                    </span>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Reg Pay Rate - Day :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ data.eng_payrateReg_day }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Reg Pay Rate - Even :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ data.eng_payrateReg_evn }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Reg Pay Rate - Night :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ data.eng_payrateReg_ngt }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">OT Pay Rate :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ onb_ot_pay_rate }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">DT/H Pay rate :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      $ {{ dth_pay_rate }}
                    </span>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">OT Starts After (Hr/Weekly) :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      {{ data.ot_starts_after }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Non Taxable (Weekly) :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      {{ data.nonTaxable_weekly }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">MSP Fee % :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      {{ data.msp_fee !== null ? data.msp_fee : 0  }}
                    </span>
                  </div>
                  <div class="clearfix m-b20" style="display: flex">
                    <label class="m-b0">Payroll Expense % :&nbsp;&nbsp;</label>
                    <span class="clearfix font-13" style="margin-top: 3px">
                      {{ data.payroll_expenses !== null ? data.payroll_expenses : 0 }}
                    </span>
                  </div>
                </div>
                <div class="divider"></div>

                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <label>Regular Hours - Day<span style="color: red">*</span></label>
                    <input type="number" class="form-control" placeholder="Enter Hour"
                      [(ngModel)]="regular_hr_day" [ngModelOptions]="{ standalone: true }"
                      (input)="changetotalHr($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <label>Regular Hours - Evening<span style="color: red">*</span></label>
                    <input type="number" class="form-control" placeholder="Enter Hour"
                      [(ngModel)]="regular_hr_evening" [ngModelOptions]="{ standalone: true }"
                      (input)="changetotalHr($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <label>Regular Hours - Night<span style="color: red">*</span></label>
                    <input type="number" class="form-control" placeholder="Enter Hour"
                      [(ngModel)]="regular_hr_night" [ngModelOptions]="{ standalone: true }"
                      (input)="changetotalHr($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <label>OT Hour<span style="color: red">*</span></label>
                    <input type="number" class="form-control" placeholder="Enter Hour" [(ngModel)]="ot_hr"
                      [ngModelOptions]="{ standalone: true }" (input)="changetotalHr($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <label>DT/H Hours<span style="color: red">*</span></label>
                    <input type="number" class="form-control" placeholder="Enter Hour"
                      [(ngModel)]="dth_hr" [ngModelOptions]="{ standalone: true }"
                      (input)="changetotalHr($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <label>Miscellaneous Compensation<span style="color: red">*</span></label>
                    <input type="number" class="form-control" placeholder="Enter Amount"
                      [(ngModel)]="misc_exp_amt" [ngModelOptions]="{ standalone: true }" step="0.01"
                      min="0" id="miscAmt" oninput="miscAmt.value>=0||(value='');"
                      (input)="changetotalHr($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <label>Bonus Amount ($/Week)<span style="color: red">*</span></label>
                    <input type="number" class="form-control" placeholder="Enter Amount"
                      [(ngModel)]="bonus_amount" [ngModelOptions]="{ standalone: true }" step="0.01"
                      min="0" id="RpayRate" oninput="RpayRate.value>=0||(value='');"
                      (input)="changetotalHr($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="form-group">
                    <label>Weekly Non-Taxable<span style="color: red">*</span></label>
                    <input type="number" class="form-control" placeholder="Enter non-taxable"
                      [(ngModel)]="wk_non_taxable" [ngModelOptions]="{ standalone: true }"
                      (input)="changetotalHr($event)" />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12" style="margin-top: 10px">
                  <div class="form-group">
                    <label>Comments</label>
                    <textarea type="text" class="form-control" rows="2" cols="50"
                      placeholder="Enter Comment" [(ngModel)]="comment"
                      [ngModelOptions]="{ standalone: true }"></textarea>
                  </div>
                  <div class="divider"></div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Total hours Worked :&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{total_hr_worked}}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Total Earnings ($):&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{total_pay}}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Non Taxable Pay ($):&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{ non_taxable }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Total Taxable Pay ($):&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{taxable_pay}}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Taxable W2 Pay ($):&nbsp;&nbsp;</label>
                      <span class="clearfix font-13" style="margin-top: 3px">
                        $ {{taxable_w2_pay}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="site-button" data-dismiss="modal">
              Back
            </button>
            <button type="button" class="site-button" (click)="submitPayroll()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal End -->
    </div>
  </div>
</app-admin-default-layout>