import { Component, OnInit } from '@angular/core';
import { DepartmentServiceService } from './department-service.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss'],
})
export class DepartmentComponent implements OnInit {
  addDeptForm: UntypedFormGroup;
  editDeptForm: UntypedFormGroup;
  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */
  defaultStatus = 'active';

  allDepartment: any;
  deptId: any;
  moduleArray: any = [];

  constructor(
    public service: DepartmentServiceService,
    public fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {}

  ngOnInit() {
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
    this.getDepartmentDetailsAll();

    this.addDeptForm = this.fb.group({
      dept: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
    });

    this.editDeptForm = this.fb.group({
      editDept: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
    });
  }

  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }
  ///////////////////////

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  /////////////

  getDepartmentDetailsAll() {
    this.service.getDepartmentDetails().subscribe((r) => {
      this.allDepartment = r;
    });
  }

  insertDept() {
    const data = {
      dept_name: this.addDeptForm.controls['dept'].value,
    };
    this.service.addDepartment(data).subscribe((r) => {
      if (r === 'success') {
        Swal.fire({
          title: 'Department Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDeptForm.reset();
            this.getDepartmentDetailsAll();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  setDeptId(value) {
    this.deptId = value.dept_id;
    this.editDeptForm.setValue({
      editDept: value.dept_name,
    });
    this.defaultStatus = value.dept_status;
  }

  updateDept() {
    const data = {
      dept_name: this.editDeptForm.controls['editDept'].value,
      dept_id: this.deptId,
    };
    this.service.updateDepartment(data).subscribe((r) => {
      if (r === 'success') {
        Swal.fire({
          title: 'Department Updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDeptForm.reset();
            this.getDepartmentDetailsAll();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  changeStatusDept() {
    const data = {
      dept_status: this.defaultStatus,
      dept_id: this.deptId,
    };
    this.service.changeStatusDept(data).subscribe((r) => {
      if (r === 'success') {
        Swal.fire({
          title: 'Status Change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDepartmentDetailsAll();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }
}
