import {Injectable} from '@angular/core';
import {catchError, retry} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import Swall from 'sweetalert2';
import * as countrycitystatejson from 'countrycitystatejson';
import { environment } from 'src/environments/environment';

@Injectable({ 
  providedIn: 'root'
})
export class EngagementServiceService {
  header = new HttpHeaders().set('Authorization', `Bearear ${sessionStorage.getItem('user_token')}`);
  get apiUrl(): string {
    return environment.apiUrl;
  }
  constructor(public http: HttpClient) {
  }

  private countryData = countrycitystatejson;

  getCountries() {
    return this.countryData.getCountries();
  }

  getStatesByCountry(countryShotName: string) {
    
    return this.countryData.getStatesByShort(countryShotName);
  }

  getCitiesByState(country: string, state: string) {
    return this.countryData.getCities(country, state);
  }


  addApiEngagement(details) {
    return this.http.post(this.apiUrl+'vcsapi/add-enagegment', details, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  
  getHealthcareEnagement(){
    return this.http.get(this.apiUrl+'vcsapi/getHealthcareEnagement', { headers: this.header })
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getClientsDetails() {
    return this.http
      .get(this.apiUrl+"vcsapi/get/all/clients", {
        headers: this.header,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  updateApiEngagement(details){

    return this.http.post(this.apiUrl+'vcsapi/update-engagement', details, { headers: this.header })
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteApiEngagement(details){
    return this.http.post(this.apiUrl+'vcsapi/engagementDelete/byID', details, { headers: this.header })
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
  
   }


  enggagementResults(details){
    return this.http.post(this.apiUrl+'vcsapi/api/get/enggagementResults', details, { headers: this.header })
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllEmployee(){
    return this.http.get(this.apiUrl+'vcsapi/get/all/employee/details', { headers: this.header })
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  

  getEditHistoryAllData(engagement_id,formId) {
    return this.http.get(`${this.apiUrl}vcsapi/gethealthcareeditdatabyEnagementId/${engagement_id}/${formId}`, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }


  getEditHistoryDataById(id) {
    return this.http.get(`${this.apiUrl}vcsapi/gethealthcareeditdatabyId/${id}`, { headers: this.header })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }



  handleError(error: any) {
    let Swal: any = Swall;
    if (error.status == 0) {
      Swal.fire({
        text: 'No Internet Connection',
        type: 'error'
      }).then(() => {
        location.reload();
      })
    }
    else if (error.status == 400) {
      Swal.fire({
        text: 'Invalid Syntex',
        type: 'error'
      })
    }
    else if (error.status == 403) {
      Swal.fire({
        text: 'Unauthorized Access',
        type: 'error'
      })
    }
    else if (error.status == 404) {
      Swal.fire({
        text: 'URL Is Not Recognized',
        type: 'error'
      })
    }
    else if (error.status == 500) {
      Swal.fire({
        text: 'Internal Server Error',
        type: 'error'
      })
    }
    else if (error.status == 501) {
      Swal.fire({
        text: 'Not Implemented',
        type: 'error'
      })
    }
    else if (error.status == 503) {
      Swal.fire({
        text: 'Service Unavailable',
        type: 'error'
      })
    }
    else if (error.status == 511) {
      Swal.fire({
        text: 'Network Authentication Required',
        type: 'error'
      })
    }
    return throwError(error.statusText);
  }


}
