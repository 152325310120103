import { Component, ElementRef, ViewChild } from '@angular/core';
import Swal from "sweetalert2";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import * as moment from "moment";
import { IDayCalendarConfig } from 'ng2-date-picker';
import { environment } from 'src/environments/environment';
import { AdminService } from "src/app/services/admin.service";

@Component({
  selector: 'app-profit-calculation',
  templateUrl: './profit-calculation.component.html',
  styleUrls: ['./profit-calculation.component.scss']
})
export class ProfitCalculationComponent {
  @ViewChild("processProfitClose", { static: false })
  private processProfitClose: ElementRef;
  inputStyles = {
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    height: '50px',
    width: '100%'
  };
  moduleArray: any = [];
  type_vendor: any = [];
  profitCalculation: any = [];

  public count: any = 20;
  public page: any;

  selected_income_expense: any = "";
  selected_type: any = "";
  selected_amount: any = 0.00;
  selected_vendor: any = "";
  comment: any = "";
  start_date: any;
  end_date: any;

  ///////////////
  loading: boolean = false;
  excelfileName: any;
  user_id: any;

  datePickerConfig = <IDayCalendarConfig>{
    drops: 'down',
    weekDayFormat: 'dd',
    dayBtnFormat: 'D',
    format: 'MM/DD/YYYY',
  } 

  get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {
    this.user_id = sessionStorage.getItem("user_id");
    this.excelfileName =
      "profit_calculation(" + moment(new Date()).format("MM-DD-YYYY") + ")";
  }

  ngOnInit() {
    this.searchList();
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 400);
  }

  getDataTypesVendor() {
    this.http.getApiTypesVendorActive().subscribe((res: any) => {
      this.type_vendor = res;
    });
  }

  ////////////////////////////
  public onPageChanged(event: any) {
    this.page = event;
    window.scrollTo(0, 0);
  }
  /////////////////////////

  navigateTo(val: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  searchList() {
    this.http.spinnerShow();
    this.profitCalculation = [];
    this.http.getProfitData().subscribe(
      (res: any) => {
        this.profitCalculation = res;
        this.http.spinnerHide();
      },
      (err) => {
        this.errorMsg("Something went wrong,please try again!");
      }
    );
  }

  //// Delete Profit Calculation
  profitCalculationDelete(value: any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          profit_file_id: value.profit_file_id
        }
        this.http.deleteProfitCalculation(data).subscribe(res => {
          if (res["message"] === 'OK') {
            this.successMsg("Profit calculation file has been deteted successfully.");
            this.searchList();
          }
          else {
            this.errorMsg("Something went wrong,please try again!");
          }
        }, err => {
          this.errorMsg("Something went wrong,please try again!");
        })
      }
    });
  }

  generateProfit() {
    const {
      start_date,
      end_date
    } = this
    if(start_date == '' || typeof start_date == 'undefined' || end_date == '' || typeof end_date == 'undefined') return
    const check_startDate = new Date(start_date);
    const check_end_date = new Date(end_date);

    // Compare dates
    if (check_end_date < check_startDate) return 
    this.loading = true
    let data = {
      start_date: moment(new Date(start_date)).format( "MM/DD/YYYY" ),
      end_date: moment(new Date(end_date)).format( "MM/DD/YYYY" ),
    }
    this.http.generateProfitCalculation(data).subscribe(
      (res: any) => {
        if(res === "already exists") return this.errorMsg("Already Exist!");
        if (res === "success") {
          this.loading = false
          this.successMsg("Profit processed successfully.");
          this.processProfitClose.nativeElement.click();
          this.start_date = ''
          this.end_date = ''
          this.searchList();
        } else if (res === "No data to be inserted.") {
          this.loading = false
          this.errorMsg("No data to be inserted!");
        } else {
          this.loading = false
          this.errorMsg("Something went wrong,please try again!");
        }
      },
      (err) => {
        this.loading = false
        this.errorMsg("Something went wrong. Please Try Again.");
      }
    );
  }
  errorMsg(msg: string) {
    Swal.fire({
      title: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }
  
  successMsg(msg: string) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.selected_income_expense = "";
        this.selected_type = "";
        this.selected_amount = 0;
        this.selected_vendor = "";
        this.comment = "";
      }
    });
  }

  successMsg2(msg: string) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }
}
