<app-page-header heading="Terms of Service" subheading=""></app-page-header>
<div class="w-full py-10 mx-auto max-w-[100rem] px-4 sm:px-6 lg:px-8">
  <p class="mt-4 text-base text-gray-600">
    By this electronic signature, I hereby agree to the following Vish
    Consulting Services, Inc. (“Company”) Terms and Conditions: I hereby certify
    that the information I have provided in my application is true, accurate,
    and complete, to the best of my knowledge, that I am the individual who has
    completed the form, and that I am solely responsible for the form, content,
    and accuracy of any material contained therein. I understand that providing
    false, inaccurate, or incomplete information may be a violation of state or
    federal laws that could result in civil penalties. I agree to promptly
    notify the Company of information that would make the contents of my
    application inaccurate, untrue, or misleading. I assume all responsibility
    and risk for my use of the Company site and services, and the documents or
    materials that I provide and for my conduct on and off the site.
  </p>
  <p class="mt-4 text-base text-gray-600">
    I hereby expressly authorize Company to submit my application and all
    information provided by me to agencies selected by Company for consideration
    of employment with those agencies. I understand that the Company makes no
    representations, warranties, or guarantees regarding the effectiveness of
    its services or the timeliness of the services in meeting my employment
    objectives. Company does not guarantee that the services will result in me
    being hired, and is not responsible or liable for any business, employment,
    hiring and/or salary decisions, for whatever reasons made, by me.
  </p>
  <h2 class="mt-6 text-xl font-bold tracking-tight text-gray-900">Release</h2>
  <p class="mt-4 text-base text-gray-600">
    To the fullest extent allowable by law, I release Company from claims,
    demands, and damages (actual and consequential and direct or indirect) of
    every kind and nature, known and unknown, suspected or unsuspected,
    disclosed and undisclosed arising out of or in any way connected with the
    submission of my application, the information provided therein, or my use of
    the site and services.
  </p>
  <h2 class="mt-6 text-xl font-bold tracking-tight text-gray-900">Indemnity</h2>
  <p class="mt-4 text-base text-gray-600">
    I AGREE TO INDEMNIFY, HOLD HARMLESS, AND RELEASE COMPANY (AND ITS
    SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS) FROM
    AND AGAINST ANY THIRD PARTY CLAIMS, ACTIONS, OR DEMANDS (INCLUDING, WITHOUT
    LIMITATION, COSTS, DAMAGES AND REASONABLE LEGAL AND ACCOUNTING FEES)
    ALLEGING OR RESULTING FROM OR IN CONNECTION WITH THE INFORMATION PROVIDED BY
    ME IN MY APPLICATION, DOCUMENTS PROVIDED BY ME, USE OF THE SERVICES, OR
    BREACH OF THIS AGREEMENT. IN ADDITION TO MONETARY DAMAGES, I AGREE THAT
    BREACH OF THIS AGREEMENT WILL ENTITLE Company TO EQUITABLE RELIEF AND
    RECOVERY OF ITS ATTORNEYS FEES AND COSTS.
  </p>
  <h2 class="mt-6 text-xl font-bold tracking-tight text-gray-900">
    Limitation on Damages
  </h2>
  <p class="mt-4 text-base text-gray-600">
    TO THE FULLEST EXTENT ALLOWABLE BY LAW, IN NO EVENT SHALL COMPANY (OR ANY OF
    ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUBSIDIARIES, AFFILIATES,
    AGENTS, OR ADVERTISERS), BE LIABLE FOR ANY NON-DIRECT DAMAGES WHATSOEVER
    (INCLUDING, WITHOUT LIMITATION, INCIDENTAL OR CONSEQUENTIAL DAMAGES, LOST
    PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR LOST EMPLOYMENT
    OPPORTUNITY)) RESULTING FROM OR ARISING UNDER OR IN CONNECTION WITH THE
    SERVICES OR THE USE OR ACCESS TO THE SITE AND SERVICES OF Company, WHETHER
    BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR
    NOT COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL
    COMPANY (OR ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUBSIDIARIES,
    AFFILIATES, AGENTS OR ADVERTISERS) BE LIABLE FOR ANY DIRECT DAMAGES IN
    EXCESS OF THE AGGREGATE AMOUNT OF $100.00.
  </p>
  <h2 class="mt-6 text-xl font-bold tracking-tight text-gray-900">
    Warranty Disclaimers
  </h2>
  <p class="mt-4 text-base text-gray-600">
    THIS SITE AND THE SERVICES PROVIDED ARE PROVIDED ON AN "AS IS" BASIS WITHOUT
    ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
    ALLOWABLE BY LAW, ALL WARRANTIES ARE DISCLAIMED, INCLUDING BUT NOT LIMITED
    TO, THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
  </p>
  <h2 class="mt-6 text-xl font-bold tracking-tight text-gray-900">
    Applicable Law and Venue
  </h2>
  <p class="mt-4 text-base text-gray-600">
    This agreement shall be construed and enforceable according to the laws of
    the State in which Company is headquartered, and I hereby submit to the
    exclusive jurisdiction of those courts. The sole and exclusive venue for
    disputes arising from this agreement or services related thereto shall be in
    the county in which Company is headquartered.
  </p>
</div>
