import { Component } from '@angular/core';
import { RecruiteeService } from 'src/app/services/recruitee.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-side-bar-candidate',
  templateUrl: './side-bar-candidate.component.html',
  styleUrls: ['./side-bar-candidate.component.scss']
})
export class SideBarCandidateComponent {
  checkUserType: boolean = false;
  currentRoute: string;
  badge: Number;

  constructor(public service: RecruiteeService, public router: Router) {
    this.currentRoute = router.url
  }

  ngOnInit() {
    this.service.spinnerShow();
    setTimeout(() => {
      this.service.spinnerHide();
    }, 900);

    if (sessionStorage.getItem('user_type') === 'recruitee') {
      this.checkUserType = true;
    }
  }

  getBadge() {
    this.badge = 0;
    this.service
      .getCurrentReqDocs(sessionStorage.getItem('user_id'))
      .subscribe((res) => {
        //// //console.log(res);
        let result: any = res;
        if (result.length) {
          this.badge = result.length;
        }
      });
  }
}
