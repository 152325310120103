import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';
import { ClientServiceService } from './client-service.service';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {
  addClientForm: UntypedFormGroup;
  editClientForm: UntypedFormGroup;

  get apiUrl(): string {
    return environment.apiUrl;
  }
  
  defaultStatus = 'active';
  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */

  allClients: any;
  clientId: any;
  edit_msp_fee_old: any = '';
  edit_payroll_expenses_old: any = '';
  moduleArray: any = [];
  clientDocumentList: any = [];
  companyDocumentData: any = [];

  addDocumentsList: UntypedFormGroup;
  editDocumentsList: UntypedFormGroup;
  orignalFileName: string = "";
  documentId: any;
  datePickerConfig = <IDayCalendarConfig>{
    drops: 'up',
    format: 'MM/DD/YYYY'
  }
  data: any;

  docuList = true;
  docuAdd = false;
  docuEdit = false;
  orignalFileId:string = "";

  constructor(
    public service: ClientServiceService,
    public fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {}

  ngOnInit() {
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
    this.getClientDetailsAll();
    // this.documentData();

    this.addClientForm = this.fb.group({
      client_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(80),
      ]),
      msp_fee: new UntypedFormControl(null, [Validators.required , Validators.max(99)]),
      payroll_expenses: new UntypedFormControl(null, [Validators.required , Validators.max(99)]),
    });

    this.editClientForm = this.fb.group({
      edit_client: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(80),
      ]),
      edit_msp_fee: new UntypedFormControl(null, [Validators.required , Validators.max(99)]),
      edit_payroll_expenses: new UntypedFormControl(null, [Validators.required , Validators.max(99)]),
    });

    this.addDocumentsList = this.fb.group({
      document_name: new UntypedFormControl(null, [Validators.required,Validators.maxLength(80)]),
      expirDate: new UntypedFormControl(),
      file: new UntypedFormControl(null, []),
      fileSource: new UntypedFormControl('', [Validators.required])
    });

    this.editDocumentsList = this.fb.group({
      edit_document_name: new UntypedFormControl(null, [Validators.required,Validators.maxLength(80)]),
      edit_expirDate: new UntypedFormControl(),
      edit_file: new UntypedFormControl(null, []),
      edit_fileSource: new UntypedFormControl(),
    });
  }
  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  /////////////

  getClientDetailsAll() {
    this.service.getClientsDetails().subscribe((r) => {
      this.allClients = r;
    });
  }

  insertClient() {
    const data = {
      client_name: this.addClientForm.controls['client_name'].value,
      msp_fee: this.addClientForm.controls['msp_fee'].value,
      payroll_expenses: this.addClientForm.controls['payroll_expenses'].value,
    };
    this.service.addClient(data).subscribe((r) => {
      if (r === 'success') {
        Swal.fire({
          title: 'Client Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addClientForm.reset();
            this.getClientDetailsAll();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  setClientId(value) {
    this.clientId = value.client_id;
    this.edit_msp_fee_old = value.msp_fee;
    this.edit_payroll_expenses_old = value.payroll_expenses;
    this.editClientForm.setValue({
      edit_client: value.client_name,
      edit_msp_fee: value.msp_fee,
      edit_payroll_expenses: value.payroll_expenses,
    });
    this.defaultStatus = value.client_status;
  }

  updateClient() {
    const data = {
      client_name: this.editClientForm.controls['edit_client'].value,
      client_id: this.clientId,
      msp_fee: this.editClientForm.controls['edit_msp_fee'].value,
      payroll_expenses: this.editClientForm.controls['edit_payroll_expenses'].value,
      payroll_expenses_old: this.edit_payroll_expenses_old,
      msp_fee_old: this.edit_msp_fee_old
    };
    this.service.updateClient(data).subscribe((r) => {
      if (r === 'success') {
        Swal.fire({
          title: 'Client Updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.edit_payroll_expenses_old = ''
            this.edit_msp_fee_old = ''
            this.editClientForm.reset();
            this.getClientDetailsAll();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.edit_payroll_expenses_old = ''
            this.edit_msp_fee_old = ''
          }
        });
      }
    });
  }

  changeStatusClient() {
    const data = {
      client_status: this.defaultStatus,
      client_id: this.clientId,
    };
    this.service.changeStatusClient(data).subscribe((r) => {
      if (r === 'success') {
        Swal.fire({
          title: 'Status change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getClientDetailsAll();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  enableDocumentForm(){
    this.docuList = false;
    this.docuAdd = true;
    this.docuEdit = false;
  }

  enableDocumentList(){
    this.docuList = true;
    this.docuAdd = false;
    this.docuEdit = false;

  }

  editDocumentList(value){
    this.docuList = false;
    this.docuAdd = false;
    this.docuEdit = true;
    this.documentId = value.id;
    this.clientId = value.client_id;
    this.orignalFileName =value.doc_name;
    this.orignalFileId =value.client_id;
    this.editDocumentsList.setValue({
      edit_document_name: value.doc_name,
      edit_expirDate: value.expiry_date,
      edit_file: '',
      edit_fileSource:'',
    });

  }

  get f(){
    return this.addDocumentsList.controls;
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addDocumentsList.patchValue({
        fileSource: file
      });
    }
  }

  onUpdateFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      debugger;
      this.editDocumentsList.patchValue({
        edit_fileSource: file
      });
    }
  }
 
  submitDocuments() {
    var document_name = this.addDocumentsList.controls['document_name'].value;
    var expirDate = this.addDocumentsList.controls['expirDate'].value;
    
    // Create form data
    const formData = new FormData(); 
    formData.append('file', this.addDocumentsList.get('fileSource').value);
    formData.append("document_name", document_name); // same as above
    formData.append("expirDate", expirDate); // same as above
    formData.append("user_id", this.clientId);
    this.service.uploadApiDocument(formData).subscribe(r => {
      if (r === "success") {
        Swal.fire({
          title: 'Document uploaded successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDocumentsList.reset();
            this.documentData();
            this.docuList = true;
            this.docuAdd = false;
            this.docuEdit = false;
          }
        });
      }else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.docuList = true;
            this.docuAdd = false;
            this.docuEdit = false;

          }
        })
      }

    });
  }

  getDocumentClientId(value){
    this.clientId = value.client_id;
    this.service.getApiAllDocumentClient(this.clientId).subscribe(r => {
      this.companyDocumentData = r;
     });
    
  }

  documentData() {
    this.service.getApiAllDocumentClient(this.clientId).subscribe(r => {
     this.companyDocumentData = r;
    });
  }

  updateDocument() {
    var doc_name = this.editDocumentsList.controls['edit_document_name'].value;
    var expirDate = this.editDocumentsList.controls['edit_expirDate'].value;
    var documentId = this.documentId;
    const formData = new FormData(); 
    formData.append('file', this.editDocumentsList.get('edit_fileSource').value);
    formData.append("document_name", doc_name); // same as above
    formData.append("expirDate", expirDate); // same as above
    formData.append("user_id", this.clientId);
    formData.append("id", documentId);

    this.service.updateDocuments(formData).subscribe(rep => {
      if (rep === 'success') {
        Swal.fire({
          title: 'Document Updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDocumentsList.reset();
            this.documentData();
            this.docuList = true;
            this.docuAdd = false;
            this.docuEdit = false;
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.docuList = true;
            this.docuAdd = false;
            this.docuEdit = false;

          }
        })
      }

    });
  }
}
