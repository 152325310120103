<div
  class="mx-auto max-w-full overflow-hidden bg-sky-600 py-8 shadow-xl md:py-20 relative px-6 md:px-0"
>
  <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
    <svg
      class="absolute inset-0 h-full w-full"
      preserveAspectRatio="xMidYMid slice"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1463 360"
    >
      <path
        class="text-sky-500 text-opacity-40"
        fill="currentColor"
        d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
      />
      <path
        class="text-sky-700 text-opacity-40"
        fill="currentColor"
        d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
      />
    </svg>
  </div>
  <div class="relative text-center">
    <div>
      <h2 class="typog_h2 text-white">Create your Vish Account</h2>
      <p class="mx-auto mt-2 max-w-2xl text-lg text-gray-300">
        Creating a profile ensures quicker submittals, resulting in quicker
        offers.
      </p>
    </div>
    <div class="mt-10">
      <a
        class="all-buttons button-secondary py-3 px-6"
        [routerLink]="'/register'"
        size="lg"
        >Create an account</a
      >
    </div>
  </div>
</div>
