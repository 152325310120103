<app-page-header heading="Blog" subheading=""></app-page-header>
<div class="bg-white">
  <div
    class="mx-auto max-w-[100rem] py-8 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-20 lg:px-8"
  >
    <div>
      <h2 class="mb-4 text-lg text-gray-900 font-bold">Recent Posts</h2>
      <ul role="list" class="divide-y divide-gray-200">
        <li
          class="relative py-5 px-4 focus-within:ring-2 focus-within:ring-inset bg-gray-100"
        >
          <div class="flex justify-between space-x-3">
            <div class="min-w-0 flex-1">
              <span class="absolute inset-0" aria-hidden="true"></span>
              <p class="truncate text-sm font-medium text-gray-900">
                Light Industrial job market
              </p>
              <p class="truncate text-sm text-gray-500">November 11, 2022</p>
            </div>
          </div>
        </li>

        <li
          class="relative py-5 px-4 focus-within:ring-2 focus-within:ring-inset bg-gray-100"
        >
          <div class="flex justify-between space-x-3">
            <div class="min-w-0 flex-1">
              <span class="absolute inset-0" aria-hidden="true"></span>
              <p class="truncate text-sm font-medium text-gray-900">
                Diversity in Light Industrial Sector
              </p>
              <p class="truncate text-sm text-gray-500">November 8, 2022</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="mt-12 lg:col-span-2 lg:mt-0">
      <dl class="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-1">
        <div class="relative">
          <dt>
            <div>
              <h2 class="text-base font-semibold text-sky-600">
                November 11, 2022
              </h2>
              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900">
                Light Industrial job market
              </p>
              <p class="mt-4 text-lg text-gray-600">
                Establishments in the Manufacturing sector are often described
                as plants, factories, or mills and characteristically use
                power-driven machines and materials-handling equipment. One of
                the biggest fallacies about working in an industrial and
                manufacturing environment is - it involves working with massive
                pieces of complex machinery and requires extensive skills and
                can be done only by experienced labor; however, this could not
                be further from the truth. Although, light industrial &
                manufacturing jobs can involve the use of machinery, but on a
                much smaller scale — only requiring moderate training. Most of
                the positions in these industries include assembly & production
                lines, machine operators & fabricators, inventory clerks &
                material handlers, and quality control inspectors. If you are
                interested in a manufacturing job, but on a smaller scale,
                consider your options in the light industry.
              </p>
            </div>
            <div class="mt-10">
              <h2 class="text-base font-semibold text-sky-600">
                November 8, 2022
              </h2>
              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900">
                Diversity in Light Industrial Sector
              </p>
              <p class="mt-4 text-lg text-gray-600">
                Women continue to explore new opportunities and make inroads
                into industries once almost exclusively male, such as
                manufacturing. The evidence shows that attitudes both in the
                industry and among women are changing. The industry is seeing
                the inherent value of an integrated and inclusive workplace
                where a diversity of viewpoints and ideas are represented.
                Manufacturers have come to see that diversity boosts the bottom
                line, fosters a more dynamic and creative environment, and
                improves employee morale and retention. This has led to a
                concerted effort to draw women into the manufacturing sector,
                beginning early on in their education, by making it clear that
                anyone can succeed in these careers. As a result, opinions about
                manufacturing are changing among women, with more and more women
                seeing a place for themselves in this exciting and challenging
                industry.
              </p>
              <p class="mt-4 text-lg text-gray-600">
                Manufacturing is not the dirty, dangerous and dull profession it
                used to be. It’s increasingly safe, clean — high-tech — and it
                supports more than operators on the shop floor. In fact,
                American manufacturers are facing a looming shortage of 2.4
                million workers by 2028 (and potentially nearly half a trillion
                dollars in lost GDP), so now is the perfect time for women to
                think about pursuing a career in manufacturing and all the jobs
                associated with it. That includes women with skills ranging from
                design and marketing to administration, finance and sales, and
                cybersecurity. All these skills are needed to make any
                manufacturing enterprise a success.
              </p>
              <p class="mt-4 text-lg text-gray-600">
                Still, while women make up about 47% of the total workforce,
                they only make up about 30% percent of the 15.8 million people
                employed in manufacturing industries, and only 1 in 4
                manufacturing leaders are women. Census data shows that those
                who work in manufacturing earn over 21% more than the median
                income (and women in manufacturing make 16% more than the median
                for women), though women still make only 72% of the median male
                salary in the industry, so there is progress to be made both in
                terms of representation and pay equity. And seeing as
                manufacturing is becoming increasingly high-tech, manufacturers
                risk overlooking highly skilled workers by not recruiting women,
                who earn more than half of the associate, undergraduate and
                graduate degrees awarded in the United States.
              </p>
              <p class="mt-4 text-lg text-gray-600">
                Women have the same potential to contribute to manufacturing as
                men, and we need them to have that opportunity if manufacturing
                in this country is to reach its full potential. Their conscious
                inclusion in the industry will bring a much-needed infusion of
                new ideas, different perspectives, and talent into this vital
                sector of the American economy as we seek to “build back
                better.” Manufacturing in America is rooted in the idea of
                teamwork, where a group of people work together to accomplish a
                common goal. Today’s manufacturing challenges demand a diverse,
                multifaceted approach to problem solving, production and
                leadership. Manufacturing jobs are the kinds of jobs that make
                America strong, and we’re stronger when we all stand together.
              </p>
              <p class="mt-4 text-base text-gray-500">
                Source: US Chamber of Commerce
              </p>
            </div>
            <div class="mt-10">
              <h2 class="text-base font-semibold text-sky-600">
                October 24, 2022
              </h2>
              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900">
                Future job growth
              </p>
              <p class="mt-4 text-lg text-gray-600">
                According to this latest study, the 2021 growth of Industrial
                Lighting will have significant change from previous year. By the
                most conservative estimates of global Industrial Lighting market
                size (most likely outcome) will be a year-over-year revenue with
                magnificent growth rate in 2022, from USD million in 2021. Over
                the next five years the Industrial Lighting market will register
                an Impressive CAGR in terms of revenue, the global market size
                will reach Multimillion USD by 2028.
              </p>
              <p class="mt-4 text-lg text-gray-600">
                The Major Players projecting major growth are:
              </p>
              <ul class="list-disc list-inside mt-4">
                <li>GE Lighting</li>
                <li>Cree</li>
                <li>LG</li>
                <li>Philips</li>
                <li>OSRAM</li>
                <li>Panasonic</li>
                <li>Schneider Electric</li>
              </ul>
              <p class="mt-4 text-lg text-gray-600">And may more..</p>
            </div>
          </dt>
        </div>
      </dl>
    </div>
  </div>
</div>

<app-cta></app-cta>
