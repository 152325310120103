<body id="bg">
  <div class="page-wraper">
    <!-- <div id="loading-area"></div> -->
    <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#4C96D7"
  type="ball-square-clockwise-spin">
      <p style="color: white">Loading...</p>
    </ngx-spinner>
    <!-- Content -->
    <div class="page-content bg-white">
      <!-- contact area -->
      <div class="content-block">
        <!-- Browse Jobs -->
        <div class="section-full bg-white p-t50 p-b20">
          <div class="container">
            <div class="row">
              <div class="col-xl-2 col-lg-4 m-b30">
                <div class="sticky-top">
                  <div class="candidate-info company-info">
                    <div class="candidate-detail text-center">
                      <div class="canditate-des">
                        <a href="javascript:void(0);">
                          <img alt="" src="../assets/images/logo/logo.png" />
                        </a>
                      </div>
                      <div class="candidate-title">
                        <h4 class="m-b5">
                          <a href="javascript:void(0);">SkillSet</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-10 col-lg-8 m-b30">
                <div class="job-bx submit-resume">
                  <div class="job-bx-title clearfix">
                    <h5 class="font-weight-700 pull-left text-uppercase">
                      SKILLSET-{{ skillSectorName }}
                    </h5>
                    <a
                      href=""
                      routerLink="/skill-checklist"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      class="site-button right-arrow button-sm float-right"
                      style="text-decoration: none"
                      >Back</a
                    >
                  </div>

                  <div class="candidate-info company-info">
                    <form [formGroup]="candidateForm">
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label
                              >Name<span class="requiredField">*</span></label
                            >
                            <input
                              class="form-control"
                              formControlName="name"
                              type="text"
                              style="height: 40px"
                            />
                            <div *ngIf=" candidateForm.controls['name'].invalid && (candidateForm.controls['name'].dirty || candidateForm.controls['name'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem" >
                              <div *ngIf=" candidateForm.controls['name'].errors?.['required'] "
                              >
                                Name is required.
                              </div>
                              <div *ngIf=" candidateForm.controls['name'].errors?.['maxlength'] " >
                                Name must be at most 100 characters long.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label
                              >E-Mail Address
                              <span class="requiredField">*</span></label
                            >
                            <input
                              class="form-control"
                              formControlName="email"
                              type="email"
                              style="height: 40px"
                            />
                            <div *ngIf=" candidateForm.controls['email'].invalid && (candidateForm.controls['email'].dirty || candidateForm.controls['email'].touched) " class="alert alert-danger" style=" font-size: 12px; padding: 0.5rem 1.25rem; margin-bottom: 0px; " >
                              <div *ngIf=" candidateForm.controls['email'].errors?.['required'] " >
                                Email is required.
                              </div>
                              <div *ngIf="candidateForm.controls['email'].invalid">
                                Email is invalid.
                              </div>
                              <div *ngIf=" candidateForm.controls['email'].errors?.['maxlength'] " >
                                Email must be at most 60 characters long.
                              </div>
                            </div>
                            <div
                              *ngIf="
                                (candidateForm.controls['email'].dirty ||
                                  candidateForm.controls['email'].touched) &&
                                checkEmail
                              "
                              class="alert alert-danger"
                              style="font-size: 12px; padding: 0.5rem 1.25rem"
                            >
                              <div *ngIf="checkEmail">Email already exist.</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label
                              >Date<span class="requiredField">*</span></label
                            >

                            <dp-date-picker
                              dir="ltr"
                              theme="dp-material"
                              placeholder="MM/DD/YYYY"
                              required="true"
                              class="form-control"
                              formControlName="edit_date"
                              [config]="datePickerConfig"
                              (onSelect)="dateChange($event)"
                            >
                            </dp-date-picker>
                            <div *ngIf=" candidateForm.controls['edit_date'].invalid && (candidateForm.controls['edit_date'].dirty || candidateForm.controls['edit_date'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem" >
                              <div *ngIf=" candidateForm.controls['edit_date'].errors ?.['required'] " >
                                Date is required.
                              </div>
                              <div *ngIf="candidateForm.controls['edit_date'].invalid" >
                                Invalid Date.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-lg-12 col-md-12">
                          <table class="requiredField">
                            <col span="1" style="width: 12%" />
                            <col span="1" style="width: 88%" />
                            <tbody>
                              <tr>
                                <td><strong>Instructions :</strong></td>
                                <td>
                                  This checklist is meant to serve as a general
                                  guideline for our client facilities as to the
                                  level of your skills within your nursing
                                  specialty. Please use the scale below to
                                  describe your experience/expertise in each
                                  area listed below.
                                </td>
                              </tr>
                              <tr>
                                <td><strong>Scale :</strong></td>
                                <td>
                                  <ul style="list-style: none">
                                    <li>1 = No Experience</li>
                                    <li>2 = Need Training</li>
                                    <li>
                                      3 = Able to perform with supervision
                                    </li>
                                    <li>4 = Able to perform independently</li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="row" *ngIf="jobDomain">
                        <div
                          class="col-lg-12 m-b30"
                          *ngFor="let a of jobDomain; let k = index"
                        >
                          <div class="job-bx bg-white">
                            <div class="job-bx-title clearfix">
                              <h6 class="text-uppercase">
                                {{ a.skill_domain_name }}
                                <div
                                  class="float-right font-14 text-primary"
                                  style="margin-right: 15%"
                                >
                                  Rating
                                </div>
                              </h6>
                            </div>
                            <div class="row" *ngIf="a.skillset">
                              <table>
                                <colgroup>
                                  <col span="1" style="width: 75%" />
                                  <col span="1" style="width: 25%" />
                                </colgroup>
                                <tbody>
                                  <tr
                                    *ngFor="let b of a.skillset; let i = index"
                                  >
                                    <td>{{ b.skillset_name }}</td>
                                    <td>
                                      <ngb-rating
                                        [(rate)]="b.skillset_rate"
                                        [max]="4"
                                        [resettable]="true"
                                      >
                                        <ng-template
                                          let-fill="fill"
                                          let-index="index"
                                        >
                                          <span
                                            class="star"
                                            [class.filled]="fill === 100"
                                            [class.bad]="index < 1"
                                            >&#9733;</span
                                          >
                                        </ng-template>
                                      </ngb-rating>

                                      <b style="margin-left: 10%">{{
                                        b.skillset_rate
                                      }}</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="site-button m-b30"
                        (click)="addCandidate()"
                        [disabled]="!candidateForm.valid"
                        [ngStyle]="{
                          cursor: !candidateForm.valid
                            ? 'not-allowed'
                            : 'pointer'
                        }"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        class="site-button m-b30"
                        style="margin-left: 10px"
                        *ngIf="user !== 'N'"
                        (click)="downloadPDF()"
                      >
                        Download
                      </button>
                    </form>
                  </div>
                </div>
              </div>

              <!-- Div PDF  -->

              <div
                class="modal-content canvas_div_pdf"
                *ngIf="showDivPdf && details"
                id="a"
              >
                <div
                  class="modal-header"
                  style="
                    background-color: transparent;
                    display: flex;
                    width: 100%;
                    margin-top: 30px;
                    margin-left: 30px;
                    margin-bottom: 0px;
                  "
                >
                  <div style="width: 25%; margin-top: 0px">
                    <img
                      style="height: 80px; width: 200px"
                      src="../assets/images/logo.png"
                    />
                  </div>
                  <div style="width: 75%; text-align: left; color: black">
                    <h3>Vish Consulting Services Inc.</h3>
                    <p
                      style="
                        font-size: 18px;
                        line-height: 8px;
                        margin-top: 12px;
                      "
                    >
                      9655 Granite Ridge Dr, STE 200, San Diego, CA 92123
                    </p>
                    <p style="font-size: 18px; line-height: 8px">
                      E: info@vishusa.com P: +1 (916) 800-3762
                    </p>
                  </div>
                </div>
                <div
                  class="modal-body"
                  id="exportthis"
                  style="margin-bottom: 20px"
                >
                  <div class="candidate-info company-info">
                    <div class="row">
                      <table *ngIf="details">
                        <col span="1" style="width: 12%" />
                        <col span="1" style="width: 88%" />
                        <tbody>
                          <tr>
                            <td><strong>Name :</strong></td>
                            <td>{{ details.name }}</td>
                          </tr>
                          <tr>
                            <td><strong>Email :</strong></td>
                            <td style="text-transform: none">
                              {{ details.email }}
                            </td>
                          </tr>
                          <tr>
                            <td><strong>Date :</strong></td>
                            <td style="text-transform: none">
                              {{ details.edit_date }}
                            </td>
                          </tr>

                          <tr>
                            <td style="padding-top: 3%" colspan="2">
                              <h5>
                                Skill Set For {{ category_name }} >
                                {{ area_name }}
                              </h5>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <table class="requiredField">
                          <col span="1" style="width: 13%" />
                          <col span="1" style="width: 87%" />
                          <tbody>
                            <tr>
                              <td><strong>Instructions :</strong></td>
                              <td>
                                This checklist is meant to serve as a general
                                guideline for our client facilities as to the
                                level of your skills within your nursing
                                specialty. Please use the scale below to
                                describe your experience/expertise in each area
                                listed below.
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Scale :</strong></td>
                              <td>
                                <ul style="list-style: none">
                                  <li>1 = No Experience</li>
                                  <li>2 = Need Training</li>
                                  <li>3 = Able to perform with supervision</li>
                                  <li>4 = Able to perform independently</li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row" *ngIf="jobDomain">
                      <div
                        class="col-lg-12 m-b30"
                        *ngFor="let a of jobDomain; let k = index"
                      >
                        <div class="job-bx bg-white">
                          <div class="job-bx-title clearfix">
                            <h6 class="text-uppercase">
                              {{ a.skill_domain_name }}
                              <div
                                class="float-right font-14 text-primary"
                                style="margin-right: 16%"
                              >
                                Rating
                              </div>
                            </h6>
                          </div>
                          <div class="row" *ngIf="a.skillset">
                            <table>
                              <colgroup>
                                <col span="1" style="width: 60%" />
                                <col span="1" style="width: 20%" />
                                <!-- <col span="1" style="width: 20%;"> -->
                              </colgroup>
                              <tbody>
                                <tr *ngFor="let b of a.skillset; let i = index">
                                  <td>{{ b.skillset_name }}</td>
                                  <td>
                                    <ngb-rating
                                      [(rate)]="b.skillset_rate"
                                      [max]="4"
                                      [readonly]="true"
                                    >
                                      <ng-template
                                        let-fill="fill"
                                        let-index="index"
                                      >
                                        <span
                                          class="star"
                                          [class.filled]="fill === 100"
                                          [class.bad]="index < 1"
                                          >&#9733;</span
                                        >
                                      </ng-template>
                                    </ngb-rating>

                                    <b style="margin-left: 10%">{{
                                      b.skillset_rate
                                    }}</b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Browse Jobs END -->
      </div>
    </div>
    <!-- Content END-->

    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</body>
