import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { HistoryAlertServiceService } from "./history-alert-service.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-history-alert',
  templateUrl: './history-alert.component.html',
  styleUrls: ['./history-alert.component.css']
}) 
export class HistoryAlertComponent implements OnInit {
  editClientForm: FormGroup;
    
  get apiUrl(): string {
    return environment.apiUrl;
  }

  /*paginate */
  public count:any = 20;
  public page: any;
  /**paginate  */
  historyDocumentAlert: any = [];
 
  data: any;

  orignalFileId:string = "";

  constructor(public service: HistoryAlertServiceService, public fb: FormBuilder,public route: ActivatedRoute,
    public router: Router, public http: AdminService) {
  }

  ngOnInit() {
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);

    this.historyOfExpireData();
  }

  /////////////////////////////
  public onPageChanged(event){
    this.page = event; 
    window.scrollTo(0,0); 
  }
  ///////////////////////


  historyOfExpireData() {
    var company_id = sessionStorage.getItem("user_id");
    this.service.getApiHistoryOfExpireDocument().subscribe(r => {      
      this.historyDocumentAlert = r['results']['data'];
    });
  }

}
