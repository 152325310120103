import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AdminService } from "src/app/services/admin.service";
import Swal from "sweetalert2";
import * as moment from "moment";
import { IDayCalendarConfig } from "ng2-date-picker";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-incentives",
  templateUrl: "./incentives.component.html",
  styleUrls: ["./incentives.component.scss"],
})
export class IncentivesComponent implements OnInit {
  @ViewChild("processIncentiveClose", { static: false })
  private processIncentiveClose: ElementRef;
  @ViewChild("backlogIncentiveClose", { static: false })
  private backlogIncentiveClose: ElementRef;

  @ViewChild("processIncentiveViewClose", { static: false })
  private processIncentiveViewClose: ElementRef;

  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */
  moduleArray: any = [];
  user_id: any;
  clientList: any = [];
  year1: any = "ALL";
  month1: any = "ALL";

  start_date: any;
  end_date: any;

  yearList2: any;
  clientList1: any;
  monthList2: any;
  yearList: any;
  monthList: any;
  weekList: any = [];
  year: any;
  month: any;
  incentiveDataListMain: any = [];
  approveErr: boolean = false;
  approveSuccess: boolean = false;
  weeklist_id: any = [];
  acclist_id: any = [];
  alreadyExistErr: boolean = false;
  reprocess: boolean = false;
  excelfileName: any;
  excelfileIndividualName: any;
  backlogData: any;
  backlog_recruitee: any = [];
  backlog_recruitee_id: any = [];

  datePickerConfig = <IDayCalendarConfig>{
    // dayBtnFormat: 'DD', // Set the format to 'D' to display only the first character of the day
    drops: 'down',
    weekDayFormat: 'dd',
    dayBtnFormat: 'D',
    format: 'MM/DD/YYYY',
  } 
  employeeList: any = [];
  vcs_employee: any = "ALL";
  from_month: any;
  to_month: any;
  empListFilter: any = [];
  empListShow: boolean = false;
  vcs_person_name: any = "";

  showModalBox: boolean = false;

  payrollIncentiveView: any = [];
  reprocessIncentiveSelected: any = [];

  get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {
    this.user_id = sessionStorage.getItem("user_id");
    this.excelfileName =
      "incentive(" + moment(new Date()).format("MM-DD-YYYY") + ")";
    this.excelfileIndividualName =
      "VCS_Individual_Incentive_(" + moment(new Date()).format("MM-DD-YYYY") + ")";
  }

  ngOnInit() {
    if (
      this.year1 === "ALL" &&
      this.month1 === "ALL"
    ) {
      this.searchList();
    }
    // this.reProcessList();
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 400);
  }
  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }
  /////////////////////////

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  editSubmit(val: any) {
    this.showModalBox = true;
    this.payrollIncentiveView = [];

    this.http.getSingleIncentiveData(val.inc_file_id).subscribe((res: any) => {
      this.payrollIncentiveView = res;
    });
  } 

  // reProcessIncentiveFile(){
  //   Swal.fire({
  //     title: `Are you sure to re-process files?`,
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#4C96D7',
  //     confirmButtonText: 'Yes, Re-Process it!',
  //     allowOutsideClick: false,
  //     showClass: {
  //       popup: 'animate__animated animate__fadeInDown'
  //     },
  //     hideClass: {
  //       popup: 'animate__animated animate__fadeOutUp'
  //     }
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let data = {
  //         // inc_file_ids: this.reprocessIncentiveSelected
  //       }
  //       this.http.reprocessIncentive(data).subscribe(res => {
  //         if (res["message"] === 'OK') {
  //           this.successMsg("Incentive file has been Re-Process successfully.");
  //           this.searchList();
  //         }
  //         else {
  //           this.errorMsg("Something went wrong,please try again!");
  //         }
  //       }, err => {
  //         this.errorMsg("Something went wrong,please try again!");
  //       })
  //     }
  //   });
  // }

  ////delete employee
  incentiveDelete(value: any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          inc_file_id: value.inc_file_id
        }
        this.http.deleteIncentive(data).subscribe(res => {
          if (res["message"] === 'OK') {
            this.successMsg("Incentive file has been deteted successfully.");
            this.searchList();
          }
          else {
            this.errorMsg("Something went wrong,please try again!");
          }
        }, err => {
          this.errorMsg("Something went wrong,please try again!");
        })
      }
    });

  }

  searchList() {
    this.http.spinnerShow();
    this.incentiveDataListMain = [];
    this.http.getIncentiveData().subscribe(
      (res: any) => {
        this.incentiveDataListMain = res;
        // this.reProcessList();
        this.http.spinnerHide();
      },
      (err) => {
        this.errorMsg("Something went wrong,please try again!");
      }
    );
  }
  // reProcessList() {
  //   this.http.getReprocessData().subscribe(
  //     (res: any) => {
  //       if(res && (res.post && res.post.length > 0 ) || (res.checkEngagement && res.checkEngagement.length > 0)){
  //         this.reprocess = true;
  //       }else{
  //         this.reprocess = false;
  //       }
  //     },
  //     (err) => {
  //       this.errorMsg("Something went wrong,please try again!");
  //     }
  //   );
  // }

  generateIncentives() {
    let data = {
      start_date: moment(new Date(this.start_date)).format( "MM/DD/YYYY" ),
      end_date: moment(new Date(this.end_date)).format( "MM/DD/YYYY" ),
    };
    this.http.generateIncentivesforPayroll(data).subscribe(
      (res: any) => {
        if(res === "already exists") return this.errorMsg("Already Exist!");
        if (res === "success") {
          this.successMsg("Incentive processed successfully.");
          this.processIncentiveClose.nativeElement.click();
          this.start_date = ''
          this.end_date = ''
          this.searchList();
        } else if (res === "No data to be inserted.") {
          this.errorMsg("No data to be inserted!");
        } else {
          this.errorMsg("Something went wrong,please try again!");
        }
      },
      (err) => {
        this.errorMsg("Something went wrong,please try again!");
      }
    );
  }

  generateBacklog() {
    let obj = {
      data: this.backlog_recruitee,
      create_by: sessionStorage.getItem("user_id"),
    };
    this.http.generateBacklog(obj).subscribe(
      (res: any) => {
        if (res === "success") {
          this.successMsg("Backlog incentive generated.");
          this.backlogIncentiveClose.nativeElement.click();
        } else {
          this.errorMsg("Something went wrong. Please Try Again.");
        }
      },
      (err) => {
        this.errorMsg("Something went wrong. Please Try Again.");
      }
    );
  }

  focusEmpList() {
    this.empListShow = true;
  }

  selectEmpName(val) {
    this.vcs_employee = val.user_id;
    if (val.user_middle_name) {
      this.vcs_person_name =
        val.user_first_name +
        " " +
        val.user_middle_name +
        " " +
        val.user_last_name;
    } else {
      this.vcs_person_name = val.user_first_name + " " + val.user_last_name;
    }
    this.empListShow = false;
  }

  searchEmployee(ev) {
    let search_data = this.vcs_person_name;
    this.employeeList = search_data
      ? this.filterListEmp(search_data)
      : this.empListFilter;
  }

  filterListEmp(filterby) {
    filterby = filterby.toLocaleLowerCase();
    return this.empListFilter.filter(
      (list: any) =>
        list.user_first_name.toLocaleLowerCase().indexOf(filterby) !== -1 ||
        list.user_last_name.toLocaleLowerCase().indexOf(filterby) !== -1
    );
  }

  fDateChanged(ev) {
    this.from_month = moment(ev + "/01").format("YYYY-MM-DD");
  }

  lDateChanged(ev) {
    this.to_month = moment(ev + "/01").format("YYYY-MM-DD");
  }

  ////////////////////////////

  errorMsg(msg) {
    Swal.fire({
      title: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  errorMsg2(msg) {
    Swal.fire({
      title: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.backlogIncentiveClose.nativeElement.click();
      }
    });
  }

  successMsg(msg) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton:  false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }
}
