import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortPipe implements PipeTransform {
  transform(array: any[], field: string): any[] {
    if (!field) {
      return array;
    }
    if (!Array.isArray(array) || array.length <= 1) {
      return array;
    }
    return array.slice().sort((a, b) => {
      const valueA = typeof a[field] == 'string' && a[field].trim().toLowerCase();
      const valueB = typeof b[field] == 'string' && b[field].trim().toLowerCase();
      
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    
  }
}