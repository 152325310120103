import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { RecruiteeService } from 'src/app/services/recruitee.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent implements OnInit {
  title = 'Contact us for better job';
  name: any = '';
  email: any = '';
  message: any = '';

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    public router: Router,
    public service: RecruiteeService,
    public route: ActivatedRoute
  ) { }


  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag({
      name: 'keywords',
      content: 'Nursing jobs, RN jobs, LPN jobs, technologist jobs',
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Nursing jobs',
    });
  }

  sendContactMail() {
    this.service.sendContactMail({ name: this.name, email: this.email, message: this.message })
      .subscribe(
        (res: any) => {
          if(res.success){
            this.successMsg(res.data);
          }else{
            this.errorMsg(res.data)
          }
        },
        (err) => {
          this.errorMsg('Something went wrong. Please Try Again.');
        }
      );
  }
  errorMsg(msg: string) {
    Swal.fire({
      title: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  successMsg(msg: string) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }
}
