import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { ReminderServiceService } from "./reminder-service.service";
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reminder-dashboard',
  templateUrl: './reminder-dashboard.component.html',
  styleUrls: ['./reminder-dashboard.component.css']
})
export class ReminderDashboardComponent implements OnInit {
  data: any;
  moduleArray: any = [];
  companyDocumentAlert: any = [];
  
  get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(public service: ReminderServiceService, public fb: FormBuilder, public route: ActivatedRoute,
    public router: Router, public http: AdminService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900)
    this.documentExpireData();
  } 

  markToRead(val){
    Swal.fire({
      title: 'Do you want to Mark?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      // denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let data = {
          docId: val.id
        }
        this.service.markApiCompany(data).subscribe(res => {
          if (res["message"] === 'OK') {
            Swal.fire('Marked successfully!', '', 'success')
            this.documentExpireData();
          }
          else {
            Swal.fire("Something went wrong,please try again!", '', 'error');
          }
        }, err => {
          Swal.fire("Something went wrong,please try again!", '', 'error');
        })
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  documentExpireData() {
    var company_id = sessionStorage.getItem("user_id");
    this.service.getApiAllExpireDocumentCompany(company_id).subscribe(r => {
      
      this.companyDocumentAlert = r['results']['data'];
    });
  }
}