import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ia-blog',
  templateUrl: './ia-blog.component.html',
})
export class IaBlogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
