<body id="bg">
  <div class="page-wraper">
    <!-- Content -->
    <div class="page-content">
      <app-page-header
        heading="Skills Checklist"
        subheading=""
      ></app-page-header>
      <!-- contact area -->
      <div class="content-block">
        <!-- Browse Jobs -->
        <div class="section-full content-inner jobs-category-bx">
          <div class="container">
            <div class="row" *ngIf="jobCategory">
              <div class="col-lg-12 m-b30" *ngFor="let a of jobCategory">
                <div class="job-bx bg-white">
                  <div class="job-bx-title clearfix">
                    <h6 class="text-uppercase">
                      {{ a.skill_category_name }}
                    </h6>
                  </div>
                  <div class="row" *ngIf="a.skillArea">
                    <div
                      class="col-lg-4 col-sm-12"
                      *ngFor="let b of a.skillArea"
                    >
                      <ul class="category-list category-bx">
                        <li>
                          <a
                            href="javascript:void(0);"
                            (click)="getJobSector(b)"
                          >
                            <div class="logo">
                              <img
                                src="../assets/images/logo/logo18.png"
                                alt=""
                              />
                            </div>
                            <span>{{ b.skill_area_name }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Browse Jobs END -->
      </div>
    </div>
    <!-- Content END-->

    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</body>
