import { Component, ElementRef, OnInit, ViewChild, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { StoreDataService } from 'src/app/services/store-data.service';
import { State } from 'country-state-city';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-applicants',
  templateUrl: './applicants.component.html',
  styleUrls: ['./applicants.component.scss'],
})
export class ApplicantsComponent implements OnInit {
  @ViewChild('closeEdit', { static: false }) private closeEdit: ElementRef;
  @ViewChild('closeAdd', { static: false }) private closeAdd: ElementRef;
  @ViewChild('closeUserStatus', { static: false })
  private closeUserStatus: ElementRef;
  @ViewChild('closeApplyStatus', { static: false })
  private closeApplyStatus: ElementRef;
  @ViewChild('changePasswordModal', { static: false })
  private changePasswordModal: ElementRef;
  @ViewChild('changePasscodeModal', { static: false })
  private changePasscodeModal: ElementRef;
  @ViewChild('uploadDocModal', { static: false })
  private uploadDocModal: ElementRef;

  get apiUrl(): string {
    return environment.apiUrl;
  }
  
  /*paginate */
  public count: any = 20;
  public page: any = 1;
  /**paginate  */

  checkEmail: boolean = false;
  recruitee_id_exist: any = '';
  moduleArray: any[];
  applicantList: any = [];
  details: any;
  first_name: any;
  last_name: any;
  email: any;
  phone_no: any;
  middle_name: any;
  profession: any = [];
  speciality: any = [];
  ssn_4digit: any;
  dob: any = '';
  profession_id: any;
  speciality_id: any;
  editApplicant: UntypedFormGroup;
  addApplicant: UntypedFormGroup;
  recruitee_id: any;
  user_status: any;
  apply_status: any;
  user_id: any;
  docType: any;
  doc_name: any = '';
  showSecInput: boolean = false;

  fileToUpload: any | null = null;
  file_name: any = '';
  doc_id: any = '';
  viewShow: any = '';
  showPercentage: number = 0;
  showProgressBar: boolean = false;
  uploaded_data: any;
  viewfinalErr: boolean = false;
  docs: any;
  remark: any;

  recruitment_status: any = 'all';
  position_type: any = 'ALL';
  profession_type: any = 'ALL';
  prefered_location: any = 'ALL';

  datePickerConfig = <IDayCalendarConfig>{
    drops: 'up',
    format: 'MM/DD/YYYY',
  };
  from_date: any = moment(new Date()).format('MM-DD-YYYY');
  to_date: any = moment(new Date()).format('MM-DD-YYYY');
  datePickerConfig2 = <IDayCalendarConfig>{
    drops: 'down',
    format: 'MM-DD-YYYY',
    max: this.to_date,
  };
  datePickerConfig3 = <IDayCalendarConfig>{
    drops: 'down',
    format: 'MM-DD-YYYY',
    min: this.from_date,
  };

  doc_expiry_date: any;

  detailsData: any = [];
  filterArray: any = [];
  search_data: any;

  conf_doc_name: any;
  ConffileToUpload: any | null = null;
  conf_file_name: any = '';
  conf_doc_list: any = [];
  assignment_data: any = [];
  user_id_by: any;
  excelfileName: any;

  prefLocation: any = [];

  standard_doc_list: any = [];
  fac_specc_doc: any = [];
  others_doc: any = [];
  specific_doc_list: any = [];
  other_doc_list: any = [];
  doc_name_spec: any;
  showSecInput2: boolean = false;
  showtrdInput: boolean = false;
  doc_name_Other: any;
  req_doc_list: any = [];
  pendingReqDoc: any;

  searchData2: any;
  user_type: any;
  emailError: any = false;

  constructor(
    public http: AdminService,
    public route: ActivatedRoute,
    public router: Router,
    public fb: UntypedFormBuilder,
    public storeData: StoreDataService
  ) {
    this.user_id_by = sessionStorage.getItem('user_id');
    this.excelfileName =
      'applicants_report(' + moment(new Date()).format('MM-DD-YYYY') + ')';
    this.user_type = sessionStorage.getItem('user_type');
  }
  ngOnInit() {
    const emailOrPhoneRequiredValidator: ValidatorFn = (group: UntypedFormGroup): { [key: string]: boolean } | null => {
      const email = group.get('email').value;
      const phone = group.get('phone_no').value;
    
      if (!email && !phone) {
        return { 'emailOrPhoneRequired': true };
      }
    
      return null;
    };
    this.storeData.data2.subscribe((res) => (this.searchData2 = res));
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
    this.prefLocation = State.getStatesOfCountry("US");
    this.getAllApplicantListType();

    if (
      this.recruitment_status === 'all' &&
      this.searchData2.length === 0 &&
      this.prefered_location === 'ALL' &&
      this.profession_type === 'ALL' &&
      this.position_type === 'ALL'
    ) {
      this.searchAppl();
    } else if (this.searchData2.length !== 0) {
      this.recruitment_status = this.searchData2.recruit_status;
      this.position_type = this.searchData2.position_type;
      this.prefered_location = this.searchData2.prefered_location;
      this.profession_type = this.searchData2.profession;
      this.page = this.searchData2.page;
      this.searchAppl();
    }

    this.editApplicant = this.fb.group({
      first_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      middle_name: new UntypedFormControl([Validators.maxLength(100)]),
      last_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(60),
      ]),
      phone_no: new UntypedFormControl(null, [Validators.max(99999999999999)]),
      dob: new UntypedFormControl(null),
      ssn_4digit: new UntypedFormControl(null, [Validators.max(9999)]),
      profession_id: new UntypedFormControl(null),
      speciality_id: new UntypedFormControl(null),
    });
    this.addApplicant = this.fb.group({
      first_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      middle_name: new UntypedFormControl([Validators.maxLength(100)]),
      last_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(60),
      ]),
      phone_no: new UntypedFormControl(null, [
        // Validators.required,
         Validators.max(99999999999999)]),
      dob: new UntypedFormControl(null),
      ssn_4digit: new UntypedFormControl(null, [Validators.max(9999)]),
      profession_id: new UntypedFormControl(null),
      speciality_id: new UntypedFormControl(null),
    },
    // { validator: emailOrPhoneRequiredValidator }
    );
  }

  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
    let data2 = {
      recruit_status: this.recruitment_status,
      page: this.page,
      prefered_location: this.prefered_location,
      profession: this.profession_type,
      position_type: this.position_type,
    };
    this.storeData.changeData2(data2);
  } 

  navigateTo(val: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  focusFunction() {
    document.getElementById('mbody').style.height = '300px';
  }

  focusOutFunction() {
    document.getElementById('mbody').style.height = '138px';
    this.datePickerConfig2 = <IDayCalendarConfig>{
      drops: 'down',
      format: 'MM-DD-YYYY',
      max: this.to_date,
    };
    this.datePickerConfig3 = <IDayCalendarConfig>{
      drops: 'down',
      format: 'MM-DD-YYYY',
      min: this.from_date,
    };
  }
  
  positiontype: any = [];
  getAllApplicantListType() {
    this.positiontype = [];
    this.docType = [];
    this.http.getAllTypeApplicant().subscribe((res: any) => {
      if(res.success){
        this.profession = res.data.profession;
        this.speciality = res.data.speciality;
        this.positiontype = res.data.position_type;
        this.docType = res.data.standard_documents;
      }
    });
  }

  searchAppl() {
    this.applicantList = [];
    this.http.spinnerShow();
    let data = {
      recruit_status: this.recruitment_status,
      prefered_location: this.prefered_location,
      profession: this.profession_type,
      position_type: this.position_type,
    };
    let data2 = {
      recruit_status: this.recruitment_status,
      prefered_location: this.prefered_location,
      profession: this.profession_type,
      position_type: this.position_type,
      page: this.page,
    };
    this.storeData.changeData2(data2);
    this.http.getApplicants(data).subscribe(
      (res: any) => {
        if (res.length !== 0) {
          this.applicantList = res;
          this.filterArray = res;
          this.http.spinnerHide();
        } else {
          this.http.spinnerHide();
          Swal.fire({
            title: 'No search result found!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      },
      (err) => {
        this.http.spinnerHide();
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    );
  }

  get searchData() {
    return this.search_data;
  }

  set searchData(value) { 
    this.search_data = value;
    this.applicantList = this.search_data
      ? this.filterList(this.search_data)
      : this.filterArray;
  }

  filterList(filterby) {
    filterby = filterby.toLocaleLowerCase();
    return this.filterArray.filter(
      (list: any) =>
        list.user_first_name.toLocaleLowerCase().indexOf(filterby) !== -1 ||
        list.recruitee_code.toLocaleLowerCase().indexOf(filterby) !== -1 ||
        list.user_last_name.toLocaleLowerCase().indexOf(filterby) !== -1
    );
  }

  EditApp(val: any) {
    this.details = '';
    this.details = val;
    this.recruitee_id = this.details.user_id;
    this.first_name = this.details.user_first_name;
    this.last_name = this.details.user_last_name;
    this.email = this.details.email;
    this.ssn_4digit = this.details.ssn_4digit;
    this.profession_id = Number(this.details.profession);
    this.speciality_id = Number(this.details.speciality);
    this.phone_no = this.details.phone;
    //this.dob = moment(this.details.dob).format("MM/DD/YYYY");
    if (this.details.user_middle_name !== null) {
      this.middle_name = this.details.user_middle_name;
    }
    if (this.details.dob === '') {
      this.dob = '';
    } else if (this.details.dob === 'Invalid date') {
      this.dob = '';
    } else {
      this.dob = moment(this.details.dob).format('MM/DD/YYYY');
    }
  }

  clickOpen(val: any) {
    this.details = '';
    this.viewfinalErr = false;
    this.viewShow = '';
    this.details = val;
    this.recruitee_id = this.details.recruitee_id;
    this.user_status = this.details.user_status;
    this.apply_status = this.details.apply_status;
    this.user_id = this.details.user_id;
  }

  clickOpenAppl(val: any) {
    this.details = '';
    this.details = val;
    this.recruitee_id = this.details.recruitee_id;
    this.user_id = this.details.user_id;
    this.getAllApplByUser(this.user_id);
  }

  clickOpenASSign(val: any) {
    this.details = '';
    this.assignment_data = [];
    this.details = val;
    this.recruitee_id = this.details.recruitee_id;
    this.user_id = this.details.user_id;
    this.assignment_data = val.assignment_data;
  }

  clickOpenDoc(val: any) {
    this.details = '';
    this.details = val;
    this.recruitee_id = this.details.recruitee_id;
    this.user_status = this.details.user_status;
    this.apply_status = this.details.apply_status;
    this.user_id = this.details.user_id;
    this.getAllDocs();
  }

  updateDetails() {
    this.http.spinnerShow();
    let DOB: any;
    if (this.dob) {
      DOB = moment(this.editApplicant.controls['dob'].value).format(
        'MM/DD/YYYY'
      );
    } else {
      DOB = '';
    }
    let data = {
      user_id: this.recruitee_id,
      user_first_name: this.editApplicant.controls['first_name'].value,
      user_middle_name: this.editApplicant.controls['middle_name'].value,
      user_last_name: this.editApplicant.controls['last_name'].value,
      phone: this.editApplicant.controls['phone_no'].value,
      email: this.editApplicant.controls['email'].value,
      dob: DOB,
      profession: this.editApplicant.controls['profession_id'].value,
      speciality: this.editApplicant.controls['speciality_id'].value,
      ssn_4digit: this.editApplicant.controls['ssn_4digit'].value,
    };
    this.http.updateApplicant(data).subscribe(
      (res: any) => {
        if (res === 'success') {
          this.http.spinnerHide();
          this.closeEdit.nativeElement.click();
          Swal.fire({
            title: 'Applicant updated successfully.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              //window.location.reload();
              this.searchAppl();
              this.editApplicant.reset();
            }
          });
        } else {
          this.http.spinnerHide();
          this.closeEdit.nativeElement.click();
          Swal.fire({
            title: 'Something went wrong,please try again.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      },
      (err) => {
        this.http.spinnerHide();
        this.closeEdit.nativeElement.click();
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    );
  }

  checkUniqueEmail() {
    if(!this.addApplicant.controls['email'].invalid){
      this.http.check_email_register({ email: this.addApplicant.controls['email'].value }).subscribe((res) => {
        let result: any = res;
        if (result.user_id) {
          this.checkEmail = true;
        } else {
          this.checkEmail = false;
        }
      });
    }
  }

  addApplicantData() {
    this.http.spinnerShow();
    let DOB = moment(this.addApplicant.controls['dob'].value).format('MM/DD/YYYY');

    let obj = {
      user_first_name: this.addApplicant.controls['first_name'].value,
      user_middle_name: '',
      user_last_name: this.addApplicant.controls['last_name'].value,
      phone: this.addApplicant.controls['phone_no'].value,
      email: this.addApplicant.controls['email'].value,
      dob: DOB,
      profession: this.addApplicant.controls['profession_id'].value,
      speciality: this.addApplicant.controls['speciality_id'].value,
      ssn_4digit: this.addApplicant.controls['ssn_4digit'].value,
      password: '1234',
      user_type: 'recruitee',
    };
    this.http.registerUser(obj, 'NO', 0).subscribe(
      (res) => {
        this.http.spinnerHide();
        let result: any = res;
        if (result.message === 'OK') {
          this.closeUserStatus.nativeElement.click();
          this.addApplicant.reset();
          this.successMsg('Registered successfully.');
        } else if (result === 'user exists') {
          this.errorMsg('Already registered with this email address.');
        }
      },
      (err) => {
        this.http.spinnerHide();
        this.errorMsg('Something went wrong. Please Try Again.');
      }
    );
  }

  changeUserStatus() {
    this.http.spinnerShow();
    let data = {
      user_id: this.user_id,
      user_status: this.user_status,
    };
    this.http.changeUserStatus(data).subscribe(
      (res: any) => {
        if (res === 'success') {
          this.http.spinnerHide();
          this.successMsg('Status changed successfully.');
        } else {
          this.http.spinnerHide();
          this.closeUserStatus.nativeElement.click();
          this.errorMsg('Something went wrong,please try again.');
        }
      },
      (err) => {
        this.http.spinnerHide();
        this.closeUserStatus.nativeElement.click();
        this.errorMsg('Something went wrong,please try again.');
      }
    );
  }

  changeApplyStatus() {
    this.http.spinnerShow();
    let data = {
      recruitee_id: this.recruitee_id,
      apply_status: this.apply_status,
    };
    this.http.changeApplyStatus(data).subscribe(
      (res: any) => {
        if (res === 'success') {
          this.http.spinnerHide();
          this.closeApplyStatus.nativeElement.click();
          this.successMsg('Status changed successfully.');
        } else {
          this.http.spinnerHide();
          this.closeApplyStatus.nativeElement.click();
          this.errorMsg('Something went wrong,please try again.');
        }
      },
      (err) => {
        this.http.spinnerHide();
        this.closeApplyStatus.nativeElement.click();
        this.errorMsg('Something went wrong,please try again.');
      }
    );
  }

  changePassword() {
    this.http.spinnerShow();
    let data = {
      user_id: this.user_id,
    };
    this.http.changePassword(data).subscribe(
      (res: any) => {
        if (res === 'success') {
          this.http.spinnerHide();
          this.changePasswordModal.nativeElement.click();
          this.successMsg2('Password changed successfully.');
        } else {
          this.http.spinnerHide();
          this.changePasswordModal.nativeElement.click();
          this.errorMsg('Something went wrong,please try again.');
        }
      },
      (err) => {
        this.http.spinnerHide();
        this.changePasswordModal.nativeElement.click();
        this.errorMsg('Something went wrong,please try again.');
      }
    );
  }

  changePasscode() {
    this.http.spinnerShow();
    let data = {
      user_id: this.user_id,
    };
    this.http.changePasscode(data).subscribe(
      (res: any) => {
        if (res === 'success') {
          this.http.spinnerHide();
          this.changePasscodeModal.nativeElement.click();
          this.successMsg2('Passcode changed successfully.');
        } else {
          this.http.spinnerHide();
          this.changePasscodeModal.nativeElement.click();
          this.errorMsg('Something went wrong,please try again.');
        }
      },
      (err) => {
        this.http.spinnerHide();
        this.changePasscodeModal.nativeElement.click();
        this.errorMsg('Something went wrong,please try again.');
      }
    );
  }

  onSelectedDoc(val: any) {
    //this.doc_name = "";
    //this.doc_id = "";
    this.docType.forEach((e) => {
      if (e.doc_id === Number(val)) {
        this.doc_name = e.doc_name;
        this.doc_id = val;

        if (this.doc_name === 'other') {
          this.showSecInput = true;
          this.doc_name = '';
        } else if (this.doc_name === 'facility_spec') {
          this.showSecInput = true;
          this.doc_name = '';
        } else {
          this.showSecInput = false;
        }
      }
    });

  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.file_name = this.fileToUpload.name;
  }

  uploadFileToActivity() {
    this.showProgressBar = true;
    this.showPercentage = 0;
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    this.http
      .uploadDoc(
        formData,
        this.user_id,
        this.doc_id,
        this.doc_name,
        moment(this.doc_expiry_date).format('MM-DD-YYYY')
      )
      .subscribe(
        (res: any) => {
          this.showPercentage = Math.round((100 * res.loaded) / res.total);
          if (res.body !== undefined) {
            if (res.body.message === 'success') {
              this.fileToUpload = '';
              this.file_name = '';
              this.doc_name = '';
              this.doc_id = '';
              this.showProgressBar = false;
              this.viewShow = 'true';
              this.uploadDocModal.nativeElement.click();
              this.successMsg2('File uploaded successfully.');
            }
          } else if (res === 'doc not uploaded') {
            this.viewfinalErr = true;
            this.viewShow = 'false';
            //this.errorMsg('Something went wrong,please try again.');
          }
        },
        (err) => {
          this.viewfinalErr = true;
          this.viewShow = 'false';
          //this.errorMsg('Something went wrong,please try again.');
        }
      );
  }

  gotoDetails(val: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.user_id),
      },
    };
    this.router.navigate(['/applicant-details'], navigationExtras);
  }

  getAllDocs() {
    this.docs = [];
    this.http.getAllDocs(this.user_id).subscribe((res: any) => {
      this.docs = res;
      if (this.docs.length === 0) {
        this.errorMsg('No document uploaded yet!');
      } else {
        this.docs.forEach((e) => {
          let cDate = moment(new Date()).format('MM/DD/YYYY');
          if (
            new Date(cDate).getTime() > new Date(e.expiry_date).getTime() &&
            e.expiry_date
          ) {
            e.expirystatus = 'expired';
          } else {
            e.expirystatus = 'current';
          }
        });
      }
    });
  }

  sendRemark() {

  }

  getAllApplByUser(user_id) {
    this.detailsData = [];
    let data = {
      user_id: user_id,
    };
    this.http.getAllApplnByUser(data).subscribe(
      (res: any) => {
        this.detailsData = res;
      },
      (err) => {}
    );
  }

  clickOpenConfDoc(val: any) {
    this.details = '';
    this.ConffileToUpload = '';
    this.conf_file_name = '';
    this.conf_doc_name = '';
    this.viewfinalErr = false;
    this.viewShow = '';
    this.details = val;
    this.recruitee_id = this.details.recruitee_id;
    this.getConfDFiles(this.details.recruitee_id);
  }

  ConfFileUpload(files: FileList) {
    this.ConffileToUpload = files.item(0);
    this.conf_file_name = this.ConffileToUpload.name;
    this.showProgressBar = true;
    this.showPercentage = 0;
    let formData = new FormData();
    formData.append('file', this.ConffileToUpload, this.ConffileToUpload.name);
    this.http
      .uploadConfDoc(
        formData,
        this.conf_doc_name,
        sessionStorage.getItem('user_id'),
        this.recruitee_id
      )
      .subscribe(
        (res: any) => {
          this.showPercentage = Math.round((100 * res.loaded) / res.total);
          if (res.body !== undefined) {
            if (res.body === 'success') {
              this.ConffileToUpload = '';
              this.conf_file_name = '';
              this.conf_doc_name = '';
              this.showProgressBar = false;
              this.viewShow = 'true';
              this.successMsg2('Document uploaded successfully.');
              this.getConfDFiles(this.recruitee_id);
            }
          } else if (res === 'error') {
            this.viewfinalErr = true;
            this.viewShow = 'false';
            //this.errorMsg('Something went wrong,please try again.');
          }
        },
        (err) => {
          this.viewfinalErr = true;
          this.viewShow = 'false';
          //this.errorMsg('Something went wrong,please try again.');
        }
      );
  }

  getConfDFiles(recruitee_id) {
    this.http.getConfDFiles(recruitee_id).subscribe(
      (res: any) => {
        this.conf_doc_list = res;
      },
      (err) => {}
    );
  }

  clickOpenReqDoc(val: any) {
    this.details = '';
    this.details = val;
    this.recruitee_id = this.details.recruitee_id;
    this.user_status = this.details.user_status;
    this.apply_status = this.details.apply_status;
    this.user_id = this.details.user_id;
    this.getPendingReqDoc();
  }

  addNewSpecDoc() {
    this.showSecInput2 = true;
  }

  addNewOtherDoc() {
    this.showtrdInput = true;
  }

  entryValue(ev) {
    var index = this.req_doc_list.findIndex((e) => e.doc_id === ev.doc_id);
    if (index > -1) {
      this.req_doc_list.splice(index, 1);
    } else {
      let body = {
        recruitee_id: this.recruitee_id,
        req_doc_type: 'standard',
        req_doc_name: ev.doc_name,
        doc_id: ev.doc_id,
      };
      this.req_doc_list.push(body);
    }
  }

  entryValue22(ev) {
    var index = this.req_doc_list.findIndex(
      (e) => e.rec_doc_name === ev.rec_doc_name
    );
    if (index > -1) {
      this.req_doc_list.splice(index, 1);
    } else {
      let body = {
        recruitee_id: this.recruitee_id,
        req_doc_type: 'facility_spec',
        req_doc_name: ev.rec_doc_name,
        doc_id: 0,
      };
      this.req_doc_list.push(body);
    }
  }

  entryValue3(ev) {
    var index = this.req_doc_list.findIndex(
      (e) => e.req_doc_name === ev.rec_doc_name
    );
    if (index > -1) {
      this.req_doc_list.splice(index, 1);
    } else {
      let body = {
        recruitee_id: this.recruitee_id,
        req_doc_type: 'other',
        req_doc_name: ev.rec_doc_name,
        doc_id: 0,
      };
      this.req_doc_list.push(body);
    }
  }

  entryValueSpec() {
    var index = this.req_doc_list.findIndex(
      (e) => e.req_doc_name === this.doc_name_spec
    );
    if (index > -1) {
      //this.specific_doc_list.splice(index, 1);
    } else {
      let val = {
        rec_doc_name: this.doc_name_spec,
        availability: 'Unavailable',
        value: true,
        rec_doc_status: 'not_current',
      };
      let body = {
        recruitee_id: this.recruitee_id,
        req_doc_type: 'facility_spec',
        req_doc_name: this.doc_name_spec,
        doc_id: 0,
      };
      this.req_doc_list.push(body);
      this.fac_specc_doc.push(val);
    }
    this.showSecInput2 = false;
  }

  entryValueOther() {
    var index = this.req_doc_list.findIndex(
      (e) => e.req_doc_name === this.doc_name_Other
    );
    if (index > -1) {
      //this.other_doc_list.splice(index, 1);
    } else {
      let val = {
        rec_doc_name: this.doc_name_Other,
        availability: 'Unavailable',
        value: true,
      };
      let body = {
        recruitee_id: this.recruitee_id,
        req_doc_type: 'other',
        req_doc_name: this.doc_name_Other,
        doc_id: 0,
      };
      this.req_doc_list.push(body);
      this.others_doc.push(val);
    }
    this.showtrdInput = false;
  }

  sendRequest() {
    let body = {
      data: this.req_doc_list,
    };
    this.http.insertRequestDoc(body).subscribe(
      (res: any) => {
        if (res === 'success') {
          this.successMsg2('Request sent successfully.');
        } else {
          this.errorMsg('Something went wrong,please try again!');
        }
      },
      (err) => {
        this.errorMsg('Something went wrong,please try again!');
      }
    );
  }

  getPendingReqDoc() {
    this.pendingReqDoc = [];
    this.http.getCurrentReqDocs(this.user_id).subscribe(
      (res: any) => {
        this.pendingReqDoc = res;
      },
      (err) => {}
    );
  }

  applicant_id_list: any = [];
  selectApplicantToDelete(value) {
    let index = this.applicant_id_list.indexOf(value);
    if (index > -1) {
      this.applicant_id_list.splice(index, 1);
    } else {
      this.applicant_id_list.push(value);
    }
  }

  deleteSelectedApplicants() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          user_ids: this.applicant_id_list,
        };
        this.http.deleteMultipleApplicant(data).subscribe(
          (res: any) => {
            if (res === 'success') {
              this.successMsg('Applicants deteted successfully.');
              this.applicant_id_list = [];
            } else {
              this.errorMsg('Something went wrong,please try again!');
            }
          },
          (err) => {
            this.errorMsg('Something went wrong,please try again!');
          }
        );
      }
    });
  }

  ////////////////////////////

  errorMsg(msg) {
    Swal.fire({
      title: msg,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  successMsg(msg) {
    Swal.fire({
      title: msg,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        //window.location.reload();
        this.searchAppl();
      }
    });
  }

  successMsg2(msg) {
    Swal.fire({
      title: msg,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        //window.location.reload();
      }
    });
  }
}
