import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-partners',
  templateUrl: './client-partners.component.html',
})
export class ClientPartnersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
