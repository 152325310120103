<body id="bg">
  <div class="page-wraper">
    <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#4C96D7"
  type="ball-square-clockwise-spin">
      <p style="color: white">Loading...</p>
    </ngx-spinner>
    <!-- Content -->
    <div class="page-content bg-white">
      <!-- contact area -->
      <div class="content-block">
        <!-- Browse Jobs -->
        <div class="section-full bg-white p-t50 p-b20">
          <div class="container">
            <div class="row">
              <div class="col-xl-2 col-lg-2 m-b30">
                <div class="sticky-top">
                  <div class="candidate-info company-info">
                    <div class="candidate-detail text-center">
                      <div class="canditate-des">
                        <a href="javascript:void(0);">
                          <img alt="" src="../assets/images/logo/logo.png" />
                        </a>
                      </div>
                      <div class="candidate-title">
                        <h4 class="m-b5">
                          <a
                            href="javascript:void(0);"
                            style="font-size: 1.2rem"
                            >ASSIGNMENTS</a
                          >
                        </h4>
                      </div>
                    </div>
                    <ul>
                      <li *ngIf="checkUserType">
                        <a
                          class="oldclass"
                          style="text-decoration: none"
                          routerLink="/current-assignment"
                          routerLinkActive="active"
                          [routerLinkActiveOptions]="{ exact: true }"
                        >
                          <i class="fa fa-briefcase" aria-hidden="true"></i>
                          <span>Current Assgn</span></a
                        >
                      </li>
                      <li *ngIf="checkUserType">
                        <a
                          class="oldclass"
                          style="text-decoration: none"
                          routerLink="/assignment-history"
                          routerLinkActive="active"
                          [routerLinkActiveOptions]="{ exact: true }"
                        >
                          <i class="fa fa-briefcase" aria-hidden="true"></i>
                          <span>Assgn History</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-xl-10 col-lg-10 m-b30">
                <div class="job-bx clearfix">
                  <div class="job-bx-title clearfix">
                    <h5 class="font-weight-700 pull-left text-uppercase">
                      Current Assignments
                    </h5>
                    <div class="float-right" style="display: flex">
                      <button
                        type="button"
                        class="site-button m-b30"
                        data-toggle="modal"
                        data-target="#workModal"
                      >
                        Submit Work Hour
                      </button>
                    </div>
                  </div>

                  <div class="job-bx-title clearfix">
                    <div class="form-group" style="display: flex">
                      <div>
                        <label style="font-size: 14px">Client</label>
                        <select class="form-control" [(ngModel)]="client_id">
                          <option value="ALL" selected>All</option>
                          <ng-container *ngFor="let data of clientList">
                            <option
                              *ngIf="data.client_status === 'active'"
                              [value]="data.client_id"
                              style="text-transform: capitalize"
                            >
                              {{ data.client_name }}
                            </option>
                          </ng-container>
                        </select>
                      </div>

                      <div style="margin-left: 12px">
                        <label style="font-size: 14px">Year</label>
                        <select
                          class="form-control"
                          [(ngModel)]="year"
                          #selectYear
                          (change)="getMonthData(selectYear.value)"
                        >
                          (selectYear.value)'>
                          <option value="ALL" selected>All</option>
                          <option
                            *ngFor="let data of yearList"
                            [value]="data.year"
                            style="text-transform: capitalize"
                          >
                            {{ data.year }}
                          </option>
                        </select>
                      </div>

                      <div style="margin-left: 12px">
                        <label style="font-size: 14px">Month</label>
                        <select class="form-control" [(ngModel)]="month">
                          <option value="ALL" selected>All</option>
                          <option
                            *ngFor="let data of monthList"
                            [value]="data.month"
                            style="text-transform: capitalize"
                          >
                            {{ data.month }}
                          </option>
                        </select>
                      </div>

                      <div>
                        <div
                          style="display: inline-block; margin-bottom: 0.5rem"
                        ></div>
                        <div
                          type="button"
                          class="site-button2"
                          (click)="searchAssignments()"
                        >
                          View
                        </div>
                      </div>
                    </div>
                  </div>

                  <table class="table-job-bx cv-manager company-manage-job">
                    <thead>
                      <tr>
                        <th style="text-align: center">Client</th>
                        <th style="text-align: center">Job ID</th>
                        <th style="text-align: center">Week</th>
                        <th style="text-align: center">Pay Slip</th>
                        <th style="text-align: center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let a of assignmentList"
                        style="text-align: center"
                      >
                        <td class="application text-primary">
                          {{ a.client_name }}
                        </td>
                        <td class="application text-primary">{{ a.job_no }}</td>
                        <td class="application text-primary">
                          {{ a.wk_start_date }}-{{ a.wk_end_date }}
                        </td>
                        <td
                          class="application"
                          style="text-transform: capitalize"
                        >
                          <span
                            class="appCount"
                            (click)="onClickPaySlip(a)"
                            data-toggle="modal"
                            data-target="#paySlipView"
                          >
                            <i class="fa fa-eye"></i>
                          </span>
                        </td>
                        <td class="job-links">
                          <div class="dropdown">
                            <a href="javascript:void(0);">
                              <i class="fa fa-cog"></i
                            ></a>
                            <div class="dropdown-content">
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#workhourView"
                                (click)="workHourData(a)"
                                >Hour Submitted
                              </a>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#assignmentView"
                                (click)="onClickAssignment(a)"
                                >Assgn Details
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Browse Jobs END -->

        <!-- SUBMIT WORK Modal -->
        <div
          class="modal fade modal-bx-info editor"
          id="workModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="EducationModalLongTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog"
            role="document"
            style="max-width: 40% !important"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="EducationModalLongTitle">
                  Submit Work Hour
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  (click)="onClose()"
                  #workModalClose
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form class="job-alert-bx" [formGroup]="assignmentForm">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <div class="form-group" style="display: grid">
                          <label
                            >Job ID<span class="requiredField">*</span>
                          </label>
                          <select
                            class="form-control"
                            formControlName="job_id"
                            #job
                            (change)="getClientDataOnChange(job.value)"
                          >
                            <option selected>-Select-</option>
                            <ng-container
                              *ngFor="let i of jobIdList; let a = index"
                            >
                              <option [value]="i.job_id">{{ i.job_no }}</option>
                            </ng-container>
                          </select>
                          <div
                            *ngIf="
                              assignmentForm.controls['job_id'].invalid &&
                              (assignmentForm.controls['job_id'].dirty ||
                                assignmentForm.controls['job_id'].touched)
                            "
                            class="alert alert-danger"
                            style="font-size: 12px; padding: 0.5rem 1.25rem"
                          >
                            <div
                              *ngIf="
                                assignmentForm.controls['job_id'].errors?.['required']
                              "
                            >
                              Job Id is required.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Client</label>
                        <input
                          class="form-control"
                          formControlName="client"
                          type="text"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <div class="form-group" style="display: grid">
                          <label
                            >Week <span class="requiredField">*</span></label
                          >
                          <select
                            #week
                            class="form-control"
                            formControlName="week_id"
                            (change)="getWeekDataOnChange(week.value)"
                          >
                            <option selected>-Select-</option>
                            <ng-container *ngFor="let i of weekList">
                              <option [value]="i.week_id">
                                {{ i.wk_start_date }}-{{ i.wk_end_date }}
                              </option>
                            </ng-container>
                          </select>
                          <div
                            *ngIf="
                              assignmentForm.controls['week_id'].invalid &&
                              (assignmentForm.controls['week_id'].dirty ||
                                assignmentForm.controls['week_id'].touched)
                            "
                            class="alert alert-danger"
                            style="font-size: 12px; padding: 0.5rem 1.25rem"
                          >
                            <div
                              *ngIf="
                                assignmentForm.controls['week_id'].errors?.['required']
                              "
                            >
                              Week is required.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Regular + OT Hours<span class="requiredField"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control"
                          formControlName="reg_ot_hr"
                          type="number"
                        />
                        <div
                          *ngIf="
                            assignmentForm.controls['reg_ot_hr'].invalid &&
                            (assignmentForm.controls['reg_ot_hr'].dirty ||
                              assignmentForm.controls['reg_ot_hr'].touched)
                          "
                          class="alert alert-danger"
                          style="font-size: 12px; padding: 0.5rem 1.25rem"
                        >
                          <div
                            *ngIf="
                              assignmentForm.controls['reg_ot_hr'].errors?.['required']
                            "
                          >
                            Regular & OT Hours is required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Holiday Work Hours<span class="requiredField"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control"
                          formControlName="holiday_wk_hr"
                          type="number"
                        />
                        <div
                          *ngIf="
                            assignmentForm.controls['holiday_wk_hr'].invalid &&
                            (assignmentForm.controls['holiday_wk_hr'].dirty ||
                              assignmentForm.controls['holiday_wk_hr'].touched)
                          "
                          class="alert alert-danger"
                          style="font-size: 12px; padding: 0.5rem 1.25rem"
                        >
                          <div
                            *ngIf="
                              assignmentForm.controls['holiday_wk_hr'].errors
                                ?.['required']
                            "
                          >
                            Holiday Work Hours is required.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="site-button"
                  (click)="onClose()"
                  data-dismiss="modal"
                >
                  Back
                </button>
                <button
                  type="button"
                  class="site-button"
                  (click)="onSubmit()"
                  data-toggle="modal"
                  data-target="#workNextModal"
                  [disabled]="assignmentForm.invalid"
                  [ngStyle]="{
                    cursor: !assignmentForm.valid ? 'not-allowed' : 'pointer'
                  }"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal End -->

        <!-- SUBMIT NEXT WORK Modal -->
        <div
          class="modal fade modal-bx-info editor"
          id="workNextModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="EducationModalLongTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog"
            role="document"
            style="max-width: 40% !important"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="EducationModalLongTitle">
                  Re-confirm
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  #workNextModalClose
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" *ngIf="details">
                <table>
                  <colgroup>
                    <col span="1" style="width: 35%" />
                    <col span="1" style="width: 65%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td><strong>Job ID :</strong></td>
                      <td>{{ details.job }}</td>
                    </tr>

                    <tr>
                      <td><strong>Client :</strong></td>
                      <td>{{ details.client }}</td>
                    </tr>

                    <tr>
                      <td><strong>Week :</strong></td>
                      <td>{{ details.week }}</td>
                    </tr>

                    <tr>
                      <td><strong>Regular + OT Hours :</strong></td>
                      <td>{{ details.reg_ot_hr }}</td>
                    </tr>

                    <tr>
                      <td><strong>Holiday Work Hours :</strong></td>
                      <td>{{ details.holiday_wk_hr }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button type="button" class="site-button" data-dismiss="modal">
                  Back
                </button>
                <button
                  type="button"
                  class="site-button"
                  (click)="insertDetails()"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal End -->

        <!-- Pay slip Modal -->
        <div
          class="modal fade modal-bx-info editor"
          id="paySlipView"
          tabindex="-1"
          role="dialog"
          aria-labelledby="EducationModalLongTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog"
            role="document"
            style="max-width: 60% !important"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="EducationModalLongTitle">
                  Pay Slip
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  #payslipModalClose
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" *ngIf="details">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Job ID :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">{{
                        details.job_no
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Applicant Name :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">{{
                        details.name
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Job :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">{{
                        details.job_title
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Assignment Status :&nbsp;&nbsp;</label
                      >
                      <span
                        class="clearfix"
                        style="margin-top: 1px"
                        *ngIf="details.assignment_status === 'not_started'"
                        >Not Started</span
                      >
                      <span
                        class="clearfix"
                        style="margin-top: 1px"
                        *ngIf="details.assignment_status === 'working'"
                        >Working</span
                      >
                      <span
                        class="clearfix"
                        style="margin-top: 1px"
                        *ngIf="details.assignment_status === 'closed'"
                        >Closed</span
                      >
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Client :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">{{
                        details.client_name
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Application No. :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">{{
                        details.application_no
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Hiring Date :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">{{
                        details.hiring_date | date : "MM/dd/yyyy"
                      }}</span>
                    </div>
                  </div>

                  <div class="divider"></div>

                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Regular Pay Rate :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px"
                        >$ {{ details.onb_regular_pay_rate }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Holiday Pay Rate :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px"
                        >$ {{ details.onb_holiday_pay_rate }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Regular Work Hours :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 1px">
                        {{ details.reg_hr }}</span
                      >
                    </div>

                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Holiday Work Hours :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 1px">
                        {{ details.holiday_hr }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Taxable Income :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">
                        $ {{ details.taxable_amt }}</span
                      >
                    </div>

                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Bonus :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">
                        $ {{ details.bonus_amount }}</span
                      >
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">OT Pay Rate :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px"
                        >$ {{ details.onb_ot_pay_rate }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Allowed Per Diem/Week :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 1px">
                        $ {{ details.per_dieum_wk }}</span
                      >
                    </div>

                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">OT Work Hours :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">
                        {{ details.ot_hr }}</span
                      >
                    </div>

                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Total Work Hours :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">
                        {{ details.total_wk_hr }}</span
                      >
                    </div>

                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Non-Taxable Income :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 1px">
                        $ {{ details.nontaxable_amt }}</span
                      >
                    </div>

                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Gross Income :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 1px">
                        $ {{ details.gross_amt }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="site-button" data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  class="site-button"
                  (click)="downloadPayslip()"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal End -->

        <!-- Assignment Modal -->
        <div
          class="modal fade modal-bx-info editor"
          id="assignmentView"
          tabindex="-1"
          role="dialog"
          aria-labelledby="EducationModalLongTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog"
            role="document"
            style="max-width: 60% !important"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="EducationModalLongTitle">
                  Assignment Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  #assignmentModalClose
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" *ngIf="details">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Job ID :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 3px">{{
                        details.job_no
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Applicant Name :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 3px">{{
                        details.name
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Job :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 3px">{{
                        details.job_title
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Assignment Status :&nbsp;&nbsp;</label
                      >
                      <span
                        class="clearfix"
                        style="margin-top: 3px"
                        *ngIf="details.assignment_status === 'not_started'"
                        >Not Started</span
                      >
                      <span
                        class="clearfix"
                        style="margin-top: 3px"
                        *ngIf="details.assignment_status === 'working'"
                        >Working</span
                      >
                      <span
                        class="clearfix"
                        style="margin-top: 3px"
                        *ngIf="details.assignment_status === 'closed'"
                        >Closed</span
                      >
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Client :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 3px">{{
                        details.client_name
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Application No. :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 3px">{{
                        details.application_no
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Hiring Date :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 3px">{{
                        details.hiring_date | date : "MM/dd/yyyy"
                      }}</span>
                    </div>
                  </div>

                  <div class="divider"></div>

                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Proposed start date :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px">{{
                        details.proposed_start_date | date : "MM/dd/yyyy"
                      }}</span>
                    </div>

                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >OT Pay Rate ($/Hr) :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px"
                        >$ {{ details.onb_ot_pay_rate }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Allowed Per Diem/Week :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px">
                        {{ details.per_dieum_wk }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Contract Duration (Week) :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px">
                        {{ details.contract_duration_wk }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Shift (Hours/Week) :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px">
                        {{ details.total_shift_hr }}</span
                      >
                    </div>

                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Pay Package/Miscellaneous :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px">
                        {{ details.pay_package_remarks }}</span
                      >
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Proposed end date :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px">{{
                        details.proposed_end_date | date : "MM/dd/yyyy"
                      }}</span>
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Regular Pay Rate ($/Hr) :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px"
                        >$ {{ details.onb_regular_pay_rate }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >Holiday Pay Rate ($/Hr) :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px"
                        >$ {{ details.onb_holiday_pay_rate }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0"
                        >OT Starts After (Hours) :&nbsp;&nbsp;</label
                      >
                      <span class="clearfix" style="margin-top: 3px">
                        {{ details.ot_starts_after_wk }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">RTO :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 3px">
                        {{ details.rto }}</span
                      >
                    </div>
                    <div class="clearfix m-b20" style="display: flex">
                      <label class="m-b0">Shift Details :&nbsp;&nbsp;</label>
                      <span class="clearfix" style="margin-top: 3px">
                        {{ details.shift_details }}</span
                      >
                    </div>
                  </div>

                  <div
                    style="display: flex; width: 100%"
                    *ngIf="standard_doc.length > 0"
                  >
                    <div class="divider2"></div>
                    <div
                      style="width: 32%; margin-top: 4px; text-align: center"
                    >
                      <h5 class="font-weight-700 text-uppercase">
                        Standard Documents
                      </h5>
                    </div>
                    <div class="divider2"></div>
                  </div>

                  <ul style="width: 100%; margin-top: 2%; margin-left: 2%">
                    <li class="innerLi" *ngFor="let a of standard_doc">
                      <strong class="innerStrng">{{ a.rec_doc_name }} :</strong>
                      <a
                        class="site-button"
                        style="margin-top: -5px; text-decoration: none"
                        href="{{apiUrl}}vcsapi/download/{{
                          a.rec_doc_id
                        }}/{{ user_id }}"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        >Download</a
                      >
                      <span class="uploadField" style="margin-left: 5%"
                        ><span style="font-weight: 500">Upload Date :</span>
                        {{ a.upload_date_time }}</span
                      >
                      <span
                        class="requiredField"
                        style="margin-left: 5%"
                        *ngIf="a.expiry_date"
                        ><span style="font-weight: 500">Expiry Date :</span>
                        {{ a.expiry_date }}</span
                      >
                      <!-- <hr> -->
                    </li>
                  </ul>

                  <div
                    style="display: flex; width: 100%"
                    *ngIf="fac_specc_doc.length > 0"
                  >
                    <div
                      class="divider2"
                      style="width: 25%; margin-top: 4%"
                    ></div>
                    <div
                      style="width: 46%; margin-top: 22px; text-align: center"
                    >
                      <h5 class="font-weight-700 text-uppercase">
                        Facility Specific Documents
                      </h5>
                    </div>
                    <div
                      class="divider2"
                      style="width: 25%; margin-top: 4%"
                    ></div>
                  </div>

                  <ul style="width: 100%; margin-top: 2%; margin-left: 2%">
                    <li class="innerLi" *ngFor="let a of fac_specc_doc">
                      <strong class="innerStrng">{{ a.rec_doc_name }} :</strong>
                      <a
                        class="site-button"
                        style="margin-top: -5px; text-decoration: none"
                        href="{{apiUrl}}vcsapi/download/{{
                          a.rec_doc_id
                        }}/{{ user_id }}"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        >Download</a
                      >
                      <span class="uploadField" style="margin-left: 5%"
                        ><span style="font-weight: 500">Upload Date :</span>
                        {{ a.upload_date_time }}</span
                      >
                      <span
                        class="requiredField"
                        style="margin-left: 5%"
                        *ngIf="a.expiry_date"
                        ><span style="font-weight: 500">Expiry Date :</span>
                        {{ a.expiry_date }}</span
                      >
                      <!-- <hr> -->
                    </li>
                  </ul>

                  <div
                    style="display: flex; width: 100%"
                    *ngIf="others_doc.length > 0"
                  >
                    <div class="divider2"></div>
                    <div
                      style="width: 32%; margin-top: 4px; text-align: center"
                    >
                      <h5 class="font-weight-700 text-uppercase">
                        Other Documents
                      </h5>
                    </div>
                    <div class="divider2"></div>
                  </div>

                  <ul style="width: 100%; margin-top: 2%; margin-left: 2%">
                    <li class="innerLi" *ngFor="let a of others_doc">
                      <strong class="innerStrng">{{ a.rec_doc_name }} :</strong>
                      <a
                        class="site-button"
                        style="margin-top: -5px; text-decoration: none"
                        href="{{apiUrl}}vcsapi/download/{{
                          a.rec_doc_id
                        }}/{{ user_id }}"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        >Download</a
                      >
                      <span class="uploadField" style="margin-left: 5%"
                        ><span style="font-weight: 500">Upload Date :</span>
                        {{ a.upload_date_time }}</span
                      >
                      <span
                        class="requiredField"
                        style="margin-left: 5%"
                        *ngIf="a.expiry_date"
                        ><span style="font-weight: 500">Expiry Date :</span>
                        {{ a.expiry_date }}</span
                      >
                      <!-- <hr> -->
                    </li>
                  </ul>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="site-button" data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  class="site-button"
                  (click)="downloadApplForm()"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal End -->
        <!-- WORK HOUR Modal -->
        <div
          class="modal fade modal-bx-info editor"
          id="workhourView"
          tabindex="-1"
          role="dialog"
          aria-labelledby="EducationModalLongTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog"
            role="document"
            style="max-width: 35% !important"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="EducationModalLongTitle">
                  Work Hour
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  #assignmentModalClose
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" *ngIf="details">
                <div class="row">
                  <table>
                    <colgroup>
                      <col span="1" style="width: 35%" />
                      <col span="1" style="width: 65%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td><strong>Job ID :</strong></td>
                        <td>{{ details.job_no }}</td>
                      </tr>
                      <tr>
                        <td><strong>Client :</strong></td>
                        <td>{{ details.client_name }}</td>
                      </tr>
                      <tr>
                        <td><strong>Week :</strong></td>
                        <td>
                          {{ details.wk_start_date }} -
                          {{ details.wk_end_date }}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Regular + OT Hours :</strong></td>
                        <td>{{ details.rec_ot_wk_hr }}</td>
                      </tr>
                      <tr>
                        <td><strong>Holiday Hours :</strong></td>
                        <td>{{ details.rec_holiday_hr }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal End -->

        <!-- PDF Div -->

        <div
          class="modal-content"
          id="assignFormFDiv"
          *ngIf="showDivPdf && details"
          style="font-size: 30px; padding: 25px"
        >
          <div
            class="modal-header"
            style="
              background-color: transparent;
              display: flex;
              width: 100%;
              margin-top: 30px;
              margin-left: 30px;
              margin-bottom: 20px;
            "
          >
            <div style="width: 25%; margin-top: 0px">
              <img
                style="height: 150px; width: 370px"
                src="../assets/images/logo.png"
              />
            </div>
            <div style="width: 75%; text-align: left; color: black">
              <h3 style="font-size: 50px">Vish Consulting Services Inc.</h3>
              <p style="font-size: 30px; line-height: 25px; margin-top: 20px">
                9655 Granite Ridge Dr, STE 200, San Diego, CA 92123
              </p>
              <p style="font-size: 30px; line-height: 25px">
                E: info@vishusa.com P: +1 (916) 800-3762
              </p>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Job ID :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px">{{
                    details.job_no
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Applicant Name :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px"
                    >{{ details.user_first_name }}
                    {{ details.user_middle_name }}
                    {{ details.user_last_name }}</span
                  >
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Job :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px">{{
                    details.job_title
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Assignment Status :&nbsp;&nbsp;</label>
                  <span
                    class="clearfix"
                    style="margin-top: 3px"
                    *ngIf="details.assignment_status === 'not_started'"
                    >Not Started</span
                  >
                  <span
                    class="clearfix"
                    style="margin-top: 3px"
                    *ngIf="details.assignment_status === 'working'"
                    >Working</span
                  >
                  <span
                    class="clearfix"
                    style="margin-top: 3px"
                    *ngIf="details.assignment_status === 'closed'"
                    >Closed</span
                  >
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Client :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px">{{
                    details.client_name
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Application No. :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px">{{
                    details.application_no
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Hiring Date :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px">{{
                    details.hiring_date | date : "MM/dd/yyyy"
                  }}</span>
                </div>
              </div>

              <div class="divider"></div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Proposed start date :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px">{{
                    details.proposed_start_date | date : "MM/dd/yyyy"
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0"
                    >Regular Pay Rate ($/Hr) :&nbsp;&nbsp;</label
                  >
                  <span class="clearfix" style="margin-top: 3px"
                    >$ {{ details.onb_regular_pay_rate }}</span
                  >
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0"
                    >Allowed Per Diem/Week :&nbsp;&nbsp;</label
                  >
                  <span class="clearfix" style="margin-top: 3px">
                    {{ details.per_dieum_wk }}</span
                  >
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0"
                    >Confirmed (Hours/Week) :&nbsp;&nbsp;</label
                  >
                  <span class="clearfix" style="margin-top: 3px">
                    {{ details.total_shift_hr }}</span
                  >
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0"
                    >Contract Duration (Week) :&nbsp;&nbsp;</label
                  >
                  <span class="clearfix" style="margin-top: 3px">
                    {{ details.contract_duration_wk }}</span
                  >
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0"
                    >Pay Package/Miscellaneous :&nbsp;&nbsp;</label
                  >
                  <span class="clearfix" style="margin-top: 3px">
                    {{ details.pay_package_remarks }}</span
                  >
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Proposed end date :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px">{{
                    details.proposed_end_date | date : "MM/dd/yyyy"
                  }}</span>
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0"
                    >Holiday Pay Rate ($/Hr) :&nbsp;&nbsp;</label
                  >
                  <span class="clearfix" style="margin-top: 3px"
                    >$ {{ details.onb_holiday_pay_rate }}</span
                  >
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0"
                    >OT Starts After (Hours) :&nbsp;&nbsp;</label
                  >
                  <span class="clearfix" style="margin-top: 3px">
                    {{ details.ot_starts_after_wk }}</span
                  >
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Shift Details :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px">
                    {{ details.shift_details }}</span
                  >
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">RTO :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 3px">
                    {{ details.rto }}</span
                  >
                </div>
              </div>

              <div
                style="display: flex; width: 100%"
                *ngIf="standard_doc.length !== 0"
              >
                <div class="divider2"></div>
                <div style="width: 32%; margin-top: 4px; text-align: center">
                  <h5
                    class="font-weight-700 text-uppercase"
                    style="font-size: 30px"
                  >
                    Standard Documents
                  </h5>
                </div>
                <div class="divider2"></div>
              </div>

              <ul
                style="
                  width: 100%;
                  margin-top: 2%;
                  margin-left: 2%;
                  list-style: none;
                "
              >
                <li class="innerLi" *ngFor="let a of standard_doc">
                  <table style="margin: 2px">
                    <colgroup>
                      <col span="1" style="width: 15%" />
                      <col span="1" style="width: 10%" />
                      <col span="1" style="width: 25%" />
                      <col span="1" style="width: 20%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{{ a.rec_doc_name }} :</strong>
                        </td>
                        <td>
                          <span style="color: Green; margin-left: 15px"
                            >Available</span
                          >
                        </td>
                        <td>
                          <span class="uploadField" style="margin-left: 5%"
                            ><span style="font-weight: 500">Upload Date :</span>
                            {{ a.upload_date_time }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="requiredField"
                            style="margin-left: 5%"
                            *ngIf="a.expiry_date"
                            ><span style="font-weight: 500">Expiry Date :</span>
                            {{ a.expiry_date }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <hr> -->
                </li>
              </ul>

              <div
                style="display: flex; width: 100%"
                *ngIf="fac_specc_doc.length !== 0"
              >
                <div class="divider2" style="width: 25%; margin-top: 4%"></div>
                <div style="width: 46%; margin-top: 22px; text-align: center">
                  <h5
                    class="font-weight-700 text-uppercase"
                    style="font-size: 30px"
                  >
                    Facility Specific Documents
                  </h5>
                </div>
                <div class="divider2" style="width: 25%; margin-top: 4%"></div>
              </div>

              <ul
                style="
                  width: 100%;
                  margin-top: 2%;
                  margin-left: 2%;
                  list-style: none;
                "
              >
                <li class="innerLi" *ngFor="let a of fac_specc_doc">
                  <table style="margin: 2px">
                    <colgroup>
                      <col span="1" style="width: 15%" />
                      <col span="1" style="width: 10%" />
                      <col span="1" style="width: 25%" />
                      <col span="1" style="width: 20%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{{ a.rec_doc_name }} :</strong>
                        </td>
                        <td>
                          <span style="color: Green; margin-left: 15px"
                            >Available</span
                          >
                        </td>
                        <td>
                          <span class="uploadField" style="margin-left: 5%"
                            ><span style="font-weight: 500">Upload Date :</span>
                            {{ a.upload_date_time }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="requiredField"
                            style="margin-left: 5%"
                            *ngIf="a.expiry_date"
                            ><span style="font-weight: 500">Expiry Date :</span>
                            {{ a.expiry_date }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>

              <div
                style="display: flex; width: 100%"
                *ngIf="others_doc.length !== 0"
              >
                <div class="divider2"></div>
                <div style="width: 32%; margin-top: 4px; text-align: center">
                  <h5
                    class="font-weight-700 text-uppercase"
                    style="font-size: 30px"
                  >
                    Other Documents
                  </h5>
                </div>
                <div class="divider2"></div>
              </div>

              <ul
                style="
                  width: 100%;
                  margin-top: 2%;
                  margin-left: 2%;
                  list-style: none;
                "
              >
                <li class="innerLi" *ngFor="let a of others_doc">
                  <table style="margin: 2px">
                    <colgroup>
                      <col span="1" style="width: 15%" />
                      <col span="1" style="width: 10%" />
                      <col span="1" style="width: 25%" />
                      <col span="1" style="width: 20%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{{ a.rec_doc_name }} :</strong>
                        </td>
                        <td>
                          <span style="color: Green; margin-left: 15px"
                            >Available</span
                          >
                        </td>
                        <td>
                          <span class="uploadField" style="margin-left: 5%"
                            ><span style="font-weight: 500">Upload Date :</span>
                            {{ a.upload_date_time }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="requiredField"
                            style="margin-left: 5%"
                            *ngIf="a.expiry_date"
                            ><span style="font-weight: 500">Expiry Date :</span>
                            {{ a.expiry_date }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Div PDF  -->

        <div
          class="modal-content"
          *ngIf="showDivPdf2 && details"
          id="payslipDiv"
          style="font-size: 30px; padding: 25px"
        >
          <div
            class="modal-header"
            style="
              background-color: transparent;
              display: flex;
              width: 100%;
              margin-top: 30px;
              margin-left: 30px;
              margin-bottom: 20px;
            "
          >
            <div style="width: 25%; margin-top: 0px">
              <img
                style="height: 150px; width: 370px"
                src="../assets/images/logo.png"
              />
            </div>
            <div style="width: 75%; text-align: left; color: black">
              <h3 style="font-size: 50px">Vish Consulting Services Inc.</h3>
              <p style="font-size: 30px; line-height: 25px; margin-top: 12px">
                9655 Granite Ridge Dr, STE 200, San Diego, CA 92123
              </p>
              <p style="font-size: 30px; line-height: 25px">
                E: info@vishusa.com P: +1 (916) 800-3762
              </p>
            </div>
          </div>
          <div class="modal-body" *ngIf="details">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Job ID :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">{{
                    details.job_no
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Applicant Name :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">{{
                    details.name
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Job :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">{{
                    details.job_title
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Assignment Status :&nbsp;&nbsp;</label>
                  <span
                    class="clearfix"
                    style="margin-top: 1px"
                    *ngIf="details.assignment_status === 'not_started'"
                    >Not Started</span
                  >
                  <span
                    class="clearfix"
                    style="margin-top: 1px"
                    *ngIf="details.assignment_status === 'working'"
                    >Working</span
                  >
                  <span
                    class="clearfix"
                    style="margin-top: 1px"
                    *ngIf="details.assignment_status === 'closed'"
                    >Closed</span
                  >
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Client :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">{{
                    details.client_name
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Application No. :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">{{
                    details.application_no
                  }}</span>
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Hiring Date :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">{{
                    details.hiring_date | date : "MM/dd/yyyy"
                  }}</span>
                </div>
              </div>

              <div class="divider"></div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Regular Pay Rate :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px"
                    >$ {{ details.onb_regular_pay_rate }}</span
                  >
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Holiday Pay Rate :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px"
                    >$ {{ details.onb_holiday_pay_rate }}</span
                  >
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Regular Work Hours :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">
                    {{ details.reg_hr }}</span
                  >
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Holiday Work Hours :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">
                    {{ details.holiday_hr }}</span
                  >
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Taxable Income :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">
                    $ {{ details.taxable_amt }}</span
                  >
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Bonus :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">
                    $ {{ details.bonus_amount }}</span
                  >
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">OT Pay Rate :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px"
                    >$ {{ details.onb_ot_pay_rate }}</span
                  >
                </div>
                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0"
                    >Allowed Per Diem/Week :&nbsp;&nbsp;</label
                  >
                  <span class="clearfix" style="margin-top: 1px">
                    $ {{ details.per_dieum_wk }}</span
                  >
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">OT Work Hours :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">
                    {{ details.ot_hr }}</span
                  >
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Total Work Hours :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">
                    {{ details.total_wk_hr }}</span
                  >
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Non-Taxable Income :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">
                    $ {{ details.nontaxable_amt }}</span
                  >
                </div>

                <div class="clearfix m-b20" style="display: flex">
                  <label class="m-b0">Gross Income :&nbsp;&nbsp;</label>
                  <span class="clearfix" style="margin-top: 1px">
                    $ {{ details.gross_amt }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content END-->
    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</body>
