import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ip-services',
  templateUrl: './ip-services.component.html',
})
export class IpServicesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
