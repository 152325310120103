<app-page-header heading="Healthcare Services" subheading=""></app-page-header>
<div class="relative overflow-hidden bg-white pt-16 pb-32">
  <div class="w-full text-center">
    <h2 class="typog_h2 staffinga" style="padding-bottom: 24px !important;">Staffing Capabilities</h2>
    <h4>The Joint Commission Certified Company</h4>
  </div>
  <div class="relative">
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div
        class="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0"
      >
        <div>
          <div class="mt-2">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">
              Healthcare Staffing and Recruiting
            </h2>
            <ul class="mt-6 leading-8 list-disc pl-6">
              <li>Nursing</li>
              <li>Allied Health</li>
              <li>Pharmacists</li>
              <li>Physicians</li>
              <li>Healthcare Executives</li>
              <li>Non-Clinical (IT, Administrative, etc…)</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div
          class="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0"
        >
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src="../../assets/images/main/senior-Nursing-Care.jpeg"
            alt="Inbox user interface"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mt-24">
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div
        class="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0"
      >
        <div>
          <div class="mt-6">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">IT</h2>
            <ul class="mt-6 leading-8 list-disc pl-6">
              <li>Cloud based clinical systems</li>
              <li>Evidence based treatment support systems</li>
              <li>FHIR API Integrations</li>
              <li>State Government</li>
              <li>ERP System integrations</li>
              <li>Pharmaceutical IT Staffing</li>
              <li>Life Sciences IT Staffing</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
        <div
          class="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0"
        >
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
            src="../../assets/images/main/Allied-Health-Jobs.webp"
            alt="Customer profile user interface"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mt-24">
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div
        class="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0"
      >
        <div>
          <div class="mt-2">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">
              Nurses
            </h2>
            <ul class="mt-6 leading-8 list-disc pl-6">
              <li>ICU/CCU</li>
              <li>Medsurg</li>
              <li>Emergency</li>
              <li>Rehabilitation and Behavioral Health</li>
              <li>PT</li>
              <li>Labor and Delivery</li>
              <li>Telemetry</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div
          class="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0"
        >
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src="../../assets/images/main/RN-jobs.webp"
            alt="Inbox user interface"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<app-cta></app-cta>
