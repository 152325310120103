<nav class="header-1">
  <div class="page-container">
    <div class="inner-container">
      <div class="flex">
        <div class="mobile-menu-icon">
          <mat-icon [matMenuTriggerFor]="mobileMenu">menu</mat-icon>
          <mat-menu #mobileMenu="matMenu" class="mobile-drop-menu">
            <ng-container *ngFor="let item of navbarItems">
              <ng-container *ngIf="!item.children">
                <button
                  mat-menu-item
                  [routerLink]="item.link"
                  class="links"
                  aria-current="page"
                >
                  {{ item.label }}
                </button>
              </ng-container>
              <ng-container *ngIf="item.children">
                <button
                  mat-menu-item
                  [matMenuTriggerFor]="mobileSubMenu"
                  [routerLink]="item.link"
                  class="links"
                  aria-current="page"
                >
                  {{ item.label }}
                </button>
                <mat-menu
                  #mobileSubMenu="matMenu"
                  class="mobile-drop-menu-small"
                >
                  <button
                    mat-menu-item
                    *ngFor="let child of item.children"
                    [routerLink]="child.link"
                  >
                    {{ child.label }}
                  </button>
                </mat-menu>
              </ng-container>
            </ng-container>
          </mat-menu>
        </div>
        <div class="logo" routerLink="/">
          <img
            class="mobile"
            src="../../../../assets/images/logo/logo.png"
            alt="Elite Mente"
          />
          <img
            class="desktop"
            src="../../../../assets/images/logo/logo.png"
            alt="Elite Mente"
          />
        </div>
        <div class="menu-bar">
          <ng-container *ngFor="let item of navbarItems">
            <ng-container *ngIf="!item.children">
              <button
                mat-button
                [routerLink]="item.link"
                class="links"
                routerLinkActive="selected"
                aria-current="page"
              >
                {{ item.label }}
              </button>
            </ng-container>
            <ng-container *ngIf="item.children">
              <button
                mat-button
                [matMenuTriggerFor]="subMenu"
                class="cursor-pointer links"
                routerLinkActive="selected"
                aria-current="page"
              >
                {{ item.label }}
                <mat-icon class="text-base ml-1 md:ml-2 order-2 -mt-[6px]"
                  >keyboard_arrow_down</mat-icon
                >
              </button>
              <mat-menu #subMenu="matMenu" class="mat-top-menu">
                <button
                  mat-menu-item
                  *ngFor="let child of item.children"
                  [routerLink]="child.link"
                  class="font-google text-xs"
                >
                  {{ child.label }}
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="flex items-center">
        <div class="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
          <div class="relative ml-3">
            <div *ngIf="!user_id">
              <app-buttons
                label="Sign In"
                iconLocation="left"
                [hasIcon]="true"
                size="normal"
                routerLink="login"
                buttonType="transparent"
                iconType="lock"
              ></app-buttons>
            </div>
            <div *ngIf="user_id">
              <app-buttons
                [label]="user_name"
                iconLocation="left"
                [hasIcon]="true"
                size="normal"
                buttonType="transparent"
                [matMenuTriggerFor]="profileMenu"
                iconType="badge"
              ></app-buttons>
              <mat-menu #profileMenu="matMenu" class="mat-top-menu">
                <button
                  *ngIf="checkUserType"
                  (click)="navigateToProfile()"
                  mat-menu-item
                  class="font-google text-xs"
                >
                  Profile
                </button>
                <button
                  *ngIf="adminPanel"
                  mat-menu-item
                  (click)="navigateToAdminPanel()"
                  class="font-google text-xs"
                >
                  Admin Panel
                </button>
                <button
                  *ngFor="let a of dataModuleArray; index as i"
                  mat-menu-item
                  (click)="navigateTo(a)"
                  class="font-google text-xs name-{{a.module_name_lower}}"
                >
                  {{ a.module_name_lower }}
                </button>
                <button
                  *ngIf="checkUserType"
                  (click)="navigateToRecruitee()"
                  mat-menu-item
                  class="font-google text-xs"
                >
                  Assignments
                </button>
                
                <!-- <button (click)="navigateToReminder()"  mat-menu-item class="font-google text-xs name-Reminder">Reminder</button> -->
                <!-- <button (click)="navigateToIncentiveDetail()"  mat-menu-item class="font-google text-xs name-Reminder">Incentive Details</button> -->
                <button
                  (click)="logout()"
                  mat-menu-item
                  class="font-google text-xs"
                >
                  Logout
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
