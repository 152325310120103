<app-page-header
  heading="Frequently Asked Questions"
  subheading=""
></app-page-header>
<div class="space-y-10">
  <section class="page-container px-6 md:px-4 mt-8 md:mt-16 pb-10">
    <h3 class="typog_h3 text-gray-900">
      Payroll and Accounts Related Information
    </h3>
    <div class="pt-6 md:pt-10">
      <dl
        class="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 md:space-y-0"
      >
        <div>
          <dt class="text-lg font-medium leading-6 text-gray-900">
            When will I get paid?
          </dt>
          <dd class="mt-2 text-base text-gray-600">
            Every following Friday. The work week starts on Sunday and ends on
            Saturday. Every employee is expected to share their approved time no
            later than following Wednesday to ensure guaranteed paycheck on the
            upcoming Friday. In the below scenario - all billed hours for the
            week 1st through 7th of the month shall be paid on 14th of the
            month.
          </dd>
        </div>

        <div>
          <dt class="text-lg font-medium leading-6 text-gray-900">
            How will I share my timesheets?
          </dt>
          <dd class="mt-2 text-base text-gray-600">
            <p>
              Every employee is recommended to follow the respective guidelines
              of the facility to clock in-out hours on the available system -
              Kronos etc. Our payroll department would expect the employees to
              share the picture of their Kronos in some situations to avoid
              delays in payroll.
            </p>
            <p class="mt-2">
              At certain facilities, in absence of any time tracking system,
              paper timesheets approved and signed by the respective reporting
              supervisor or manager will be required. Please be advised -
              unsigned timesheets will not be accepted by our payroll department
              and can potentially cause delays in payroll.
            </p>
            <p class="mt-2">
              Please use the preferred format for paper timesheet tracking: URL
            </p>
          </dd>
        </div>

        <div>
          <dt class="text-lg font-medium leading-6 text-gray-900">
            How do I access my pay stubs or W2 forms?
          </dt>
          <dd class="mt-2 text-base text-gray-600">
            <p>
              All pay stubs and W2 forms are available on ADP (our payroll
              processor). Our accounts team shall grant you the access to ADP
              (our payroll processor), at the time of your first paycheck
              processed through our company. You shall receive an email from
              ADP. Please follow the instructions as outlined in the email to
              set up an account.
            </p>
            <p class="mt-2">
              If you already have an account setup and have been locked out.
              Please follow the following steps to reset password
            </p>
            <ol class="mt-2 list-decimal pl-8">
              <li>Click https://my.adp.com</li>
              <li>Click Forgot Your Password</li>
              <li>Enter your User ID</li>
              <li>
                Follow the instructions to answer a series of security questions
              </li>
              <li>
                Note: If you do not know the answers to the security questions
                contact your Company Payroll or HR Contact to make the change
                for you.
              </li>
              <li>Create a new Password</li>
              <li>Click Continue</li>
            </ol>
          </dd>
        </div>

        <div>
          <dt class="text-lg font-medium leading-6 text-gray-900">
            Will I be paid holiday rate for working on public holidays?
          </dt>
          <dd class="mt-2 text-base text-gray-600">
            <p>
              Yes, some of the public holidays are eligible for Holiday rate.
              Please refer to your contracts for the terms and conditions. Also,
              please check with the accounts team regarding timings eligible for
              the higher rate; some facilities start holiday pay rate at 7 PM on
              day prior to the holiday
            </p>
            <ul class="mt-2 list-disc pl-8">
              <li>New Year’s Day</li>
              <li>Memorial Day</li>
              <li>Independence Day</li>
              <li>Labor Day</li>
              <li>Thanksgiving Day</li>
              <li>Christmas</li>
            </ul>
          </dd>
        </div>

        <div>
          <dt class="text-lg font-medium leading-6 text-gray-900">
            Will I get overtime for hours worked beyond 40 hours per week?
          </dt>
          <dd class="mt-2 text-base text-gray-600">
            Yes, all billable hours beyond 40 in a work week are eligible for OT
            rate. Please refer to your contract for all details
          </dd>
        </div>

        <div>
          <dt class="text-lg font-medium leading-6 text-gray-900">
            How will VCS resolve any timesheet or paycheck discrepancy?
          </dt>
          <dd class="mt-2 text-base text-gray-600">
            We have an extensive timesheet reconciliation process to ensure
            accurate compensation to our employees for every billable hour. If
            any billable time is missed during payroll processing, it shall be
            compensated through following payroll. Accounts team shall also
            notify you detailed breakdown of the discrepancy and any further
            action if needed.
          </dd>
        </div>

        <div>
          <dt class="text-lg font-medium leading-6 text-gray-900">
            Who do I contact for any payroll related queries?
          </dt>
          <dd class="mt-2 text-base text-gray-600">
            <p>
              Please send all your timesheet and payroll related questions to
              timecard@vishusa.com Our specialists shall respond through email
              or phone no later than 24 hours.
            </p>
            <p class="mt-2">
              Please share your timesheets to the same email address.
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </section>
</div>
<app-cta></app-cta>
