import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AdminService } from "src/app/services/admin.service";
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import * as moment from "moment";
import { IDayCalendarConfig } from 'ng2-date-picker';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-payroll-invoice",
  templateUrl: "./payroll-invoice.component.html",
  styleUrls: ["./payroll-invoice.component.scss"],
})
export class PayrollInvoiceComponent implements OnInit {
  @ViewChild("processSecondClose", { static: false })
  private processSecondClose: ElementRef;

  @ViewChild("calculatePayroleClose", { static: false })
  private calculatePayroleClose: ElementRef;
  
  moduleArray: any = [];

  client_id1: any = "ALL";
  year1: any = "ALL";
  month1: any = "ALL";
  week1: any = "ALL";
  yearList2: any;
  clientList1: any;
  monthList2: any;
  weekList2: any;
  clientList: any = [];
  yearList: any;
  monthList: any;
  weekList: any;
  client_id: any;
  year: any;
  month: any;
  week: any;
  status: any = "ALL";
  payrollDataListMain: any = [];

  payrollDataList: any = [];
  acc_file_id: any;
  showModalBox: boolean = false;
  showPaidModalBox: boolean = false;
  user_id: any;

  showNameList: boolean = false;
  showCodeList: boolean = false;
  recruitee_name: any;
  nameList: any = [];
  recruitee_code: any;
  codeList: any = [];
  recruitee_id: any;
  searchPayrollList: any = [];
  showPayslipModalBox: boolean = false;
  details: any;
  showDivPdf: boolean = false;
  total_work_hr: any;
  excelfileName: any;


  data: any;

  total_hr_worked: any;
  total_pay: any;
  invoice_after_msp_fee:any;
  comment: any;
  invoice_amount: any;
  profit: any;
  paid_status: any;
  received_amount:any;
  nbo_amount:any;
  received_date:any;
  check_no : any;
  pending_amount : any;
  initial_profit : any;
  payrollDataTotalList: any;

  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */

  submitErr: any = "";

  get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {
    this.user_id = sessionStorage.getItem("user_id");
    this.excelfileName =
      "VCS_Process_Payroll_Reconcile_Download_sheet(" + moment(new Date()).format("MM-DD-YYYY") + ")";
  }

  ngOnInit() {
    this.getClients();
    this.getYear();
    if (
      this.client_id1 === "ALL" &&
      this.year1 === "ALL" &&
      this.month1 === "ALL" &&
      this.week1 === "ALL"
    ) {
      this.searchList();
    }
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 400);
  }

  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }
  /////////////////////////

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }
  ////////////////////////////

  getClients() {
    this.http.getClientHired().subscribe((res: any) => {
      this.clientList1 = res;
      this.clientList = res;
    });
  }

  onOptionsSelected(val) {
    this.yearList2 = [];
    this.monthList2 = [];
    this.weekList2 = [];
    if (val !== "ALL") {
      this.month1 = "ALL";
      this.week1 = "ALL";
      this.year1 = "ALL";
      this.http.getYearFromClientID(val, 'approved').subscribe((res: any) => {
        this.yearList2 = res;
      });
    }else{
      this.month1 = "ALL";
      this.week1 = "ALL";
      this.year1 = "ALL";
    }
  }

  onYearSelected2(val) {
    this.monthList2 = [];
    this.weekList2 = [];
    if (val !== "ALL") {
      this.month1 = "";
      this.week1 = "";
      this.http
        .getMonthFromClientIDandYr(this.client_id1, val, 'approved')
        .subscribe((res: any) => {
          this.monthList2 = res;
        });
    }else{
      this.month1 = "";
      this.week1 = "";
    }
  }

  onMonthSelected2(val) {
    if (val !== "") {
      this.week1 = "ALL";
      this.http.getWeekByMonth(this.client_id1, this.year1, val, 'approved').subscribe((res: any) => {
        this.weekList2 = res;
      });
    }
  }

  getYear() {
    this.http.getYearByClient().subscribe((res: any) => {
      this.yearList = res;
    });
  }

  onYearSelected(val) {
    if (val !== "ALL") {
      this.http.getMonthByYear(val).subscribe((res: any) => {
        this.monthList = res;
      });
    }
  }

  onMonthSelected(val) {
    if (val !== "ALL") {
      this.http.getWeekByMonthOLD(this.year, val).subscribe((res: any) => {
        this.weekList = res;
      });
    }
  }

  searchList() {
    this.http.spinnerShow();
    this.payrollDataListMain = [];
    let body = {
      client_id: this.client_id1,
      year: this.year1,
      month: this.month1,
      week_id: this.week1,
      status: this.status,
      // status: 'ALL',
    };
    this.http.getPayrollInvoiceData(body).subscribe((res: any) => {
      this.payrollDataListMain = res;
      this.http.spinnerHide();
    });
  }

  editSubmit(val) {
    this.showModalBox = true;
    this.payrollDataList = [];
    this.acc_file_id = val.acc_file_id;
    let body = {
      client_id: val.client_id,
      start_date: val.start_date,
      end_date: val.end_date,
      acc_file_id: val.acc_file_id,
    };
    this.http.getDataByFileID(body).subscribe((res: any) => {
      this.payrollDataList = res;
      this.payrollDataTotalList = {
        total_earnings : 0,
        invoice_amount : 0,
        invoice_after_msp_fee : 0,
        received_amount : 0,
        nbo_amount : 0,
        vcs_profit:0
      };
      this.payrollDataList.forEach((el) => {

        this.payrollDataTotalList.total_earnings += Number(el.total_pay);
        this.payrollDataTotalList.invoice_amount += Number(el.invoice_income);
        this.payrollDataTotalList.invoice_after_msp_fee += Number(el.invoice_after_msp_fee);
        this.payrollDataTotalList.received_amount += Number(el.received_amount);
        this.payrollDataTotalList.nbo_amount += Number(el.nbo_amount);
        this.payrollDataTotalList.vcs_profit += Number(el.profit);
        
        if (el.payroll_status !== "submitted") {
          this.submitErr = "true";
        } else if (el.payroll_status === "submitted") {
          this.submitErr = "";
        }
      });
    });
  }
  paidUpdate(val) {
    if(val.paid_status == 'paid') {
      return;
    }else{
      Swal.fire({
        title: 'Are you sure?',
        // text: "You won't be able to revert this!",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Paid',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            // client_id: val.client_id,
            // start_date: val.start_date,
            // end_date: val.end_date,
            acc_file_id: val.acc_file_id,
          };
          this.http.paidPayrollUpdate(data).subscribe(
            (res: any) => {
              if (res === 'success') {
                this.successMsg2("Status update successfully.");
              } else {
                this.errorMsg('Something went wrong,please try again!');
              }
            },
            (err) => {
              this.errorMsg('Something went wrong,please try again!');
            }
          );
        }
      });
    }
  }

  openCalculation(val) {
    
    this.details = "";
    
    this.data = "";
    
    this.details = val;
    
    let body = {
      engagement_id: this.details.engagement_id,
      start_date: this.details.start_date,
      end_date: this.details.end_date,
      rec_payroll_id: this.details.rec_payroll_id,
      week_id: `${this.details.start_date} - ${this.details.end_date}`,
    };
    
    this.http.getPayrollDataByAssignID(body).subscribe(
      (res: any) => {
        this.data = []
        
        if(res && res.length > 0){
          this.data = res[0];
          this.total_hr_worked = res[0].total_hr_worked !== null ? res[0].total_hr_worked : 0;
          this.total_pay = res[0].total_pay !== null ? res[0].total_pay : 0;
          this.comment = res[0].comments;
          this.invoice_after_msp_fee = res[0].invoice_after_msp_fee;
          this.profit = this.initial_profit = res[0].profit;
          this.paid_status = res[0].paid_status;
          this.received_amount = res[0].received_amount;
          this.nbo_amount = res[0].nbo_amount;
          this.check_no = res[0].check_no;
          this.pending_amount = (Number(this.invoice_after_msp_fee) - Number(this.received_amount)).toFixed(2);

          if (res[0].received_date === '') {
            this.received_date = '';
          } else if (res[0].received_date === 'Invalid date') {
            this.received_date = '';
          } else {
            this.received_date = res[0].received_date;
          }
        }
      },
      (err) => {}
    );
  }

  calculateFieldAutomation(e){

    this.profit = (Number(this.initial_profit) - Number(this.nbo_amount)).toFixed(2);

    this.pending_amount = (Number(this.invoice_after_msp_fee) - Number(this.received_amount)).toFixed(2);

    // Restrict negative by typing
    var key = !isNaN(e.charCode) ? e.charCode : e.keyCode;
    function keyAllowed() {
      var keys = [
        8, 9, 13, 16, 17, 18, 19, 20, 27, 46, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 91, 92, 93,
      ];
      if (key && keys.indexOf(key) === -1) return false;
      else return true;
    }
    if (!keyAllowed()) e.preventDefault();

  }

  submitPayroll() {
    let insertJson = {
      rec_payroll_id: this.data.rec_payroll_id,
      engagement_id: this.details.engagement_id,
      start_date: moment(new Date(this.details.start_date)).format( "MM/DD/YYYY" ),
      end_date: moment(new Date(this.details.end_date)).format( "MM/DD/YYYY" ),
      acc_file_id: this.details.account_file_id,
      comments: this.comment,
      // total_hr_worked: Number(this.total_hr_worked),
      total_pay: Number(this.total_pay),
      // invoice_after_msp_fee: Number(this.invoice_after_msp_fee),
      profit: Number(this.profit),
      paid_status : this.paid_status,
      received_amount : Number(this.received_amount),
      nbo_amount : Number(this.nbo_amount),
      received_date : moment(new Date(this.received_date)).format( "MM/DD/YYYY" ),
      check_no : this.check_no,
      pending_amount : this.pending_amount
    };

    this.http.updatePayrollInvoiceData(insertJson).subscribe(
      (res: any) => {

        if (res === "success") {
          this.successMsg("Payroll calculated successfully.");
        } else {
          this.errorMsg("Something went wrong. Please Try Again.");
        }
      },
      (err) => {
        this.errorMsg("Something went wrong. Please Try Again.");
      }
    );
  }

  clickDownload(val) {
    this.showModalBox = true;
    this.payrollDataList = [];
    this.acc_file_id = val.acc_file_id;
    let body = {
      acc_file_id: val.acc_file_id,
    };
    this.http.getInvoiceDataByFileID(body).subscribe((res: any) => {
      this.payrollDataList = res;
    });
  }

  findrecuiteeCode(ev) {
    let data = {
      name: this.recruitee_name,
    };
    this.http.getRecruiteeCodebyName(data).subscribe((res: any) => {
      this.nameList = res;
      if (this.nameList.length !== 0) {
        this.showNameList = true;
      } else {
        this.showNameList = false;
      }
    });
  }

  findrecuiteeName(ev) {
    let data = {
      code: this.recruitee_code,
    };
    this.http.getRecruiteeNameByCode(data).subscribe((res: any) => {
      this.codeList = res;
      if (this.codeList.length !== 0) {
        this.showCodeList = true;
      } else {
        this.showCodeList = false;
      }
    });
  }

  selectName(val) {
    this.recruitee_code = val.recruitee_code;
    this.recruitee_name =
      val.user_first_name +
      " " +
      val.user_middle_name +
      " " +
      val.user_last_name;
    this.recruitee_id = val.recruitee_id;
    this.showNameList = false;
    this.showCodeList = false;
  }

  selectCode(val) {
    this.recruitee_code = val.recruitee_code;
    this.recruitee_name =
      val.user_first_name +
      " " +
      val.user_middle_name +
      " " +
      val.user_last_name;
    this.recruitee_id = val.recruitee_id;
    this.showNameList = false;
    this.showCodeList = false;
  }

  search() {
    let data = {
      client_id: this.client_id,
      recruitee_id: this.recruitee_id,
      week_id: this.week,
      year: this.year,
      month: this.month,
    };
    this.http.seachRecruiteePayroll(data).subscribe(
      (res: any) => {
        this.searchPayrollList = res;
        this.showPayslipModalBox = true;
        if (this.searchPayrollList.length === 0) {
          this.errorMsg("No search result found!");
        } else {
        }
      },
      (err) => {
        this.errorMsg("Something went wrong,please try again!");
      }
    );
  }

  downloadPayslip() {
    this.showDivPdf = true;
    setTimeout(() => {
      let data = document.getElementById("payslipDiv");
      html2canvas(data).then((canvas) => {
        var imgWidth = 22;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL("image/png");
        //let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
        let pdf = new jspdf("p", "cm", "a4"); //Generates PDF in portrait mode
        var position = 0;
        pdf.setFont("helvetica");
        pdf.setFontSize(20);
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        pdf.save("payslip.pdf");
      });
    }, 100);

    setTimeout(() => {
      this.showDivPdf = false;
    }, 100);
  }

  ////////////////////////////

  errorMsg(msg) {
    Swal.fire({
      title: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  successMsg(msg) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.calculatePayroleClose.nativeElement.click();
        this.refreshSecondModal();
      }
    });
  }
  successMsg2(msg) {
    Swal.fire({
      title: msg,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#4C96D7",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.searchList();
      }
    });
  }
  approvePayroll() {
    this.processSecondClose.nativeElement.click();
    this.searchList();
  }
  refreshSecondModal() {
    this.showModalBox = true;
    this.payrollDataList = [];
    let body = {
      client_id: this.details.client,
      start_date: this.details.acc_start_date,
      end_date: this.details.acc_end_date,
      acc_file_id: this.details.account_file_id
    };
    this.http.getDataByFileID(body).subscribe((res: any) => {
      this.payrollDataList = res;
      this.acc_file_id = this.details.account_file_id;

      this.payrollDataTotalList = {
        total_earnings : 0,
        invoice_amount : 0,
        invoice_after_msp_fee : 0,
        received_amount : 0,
        nbo_amount : 0,
        vcs_profit:0
      };

      this.payrollDataList.forEach((el) => {

        this.payrollDataTotalList.total_earnings += Number(el.total_pay);
        this.payrollDataTotalList.invoice_amount += Number(el.invoice_income);
        this.payrollDataTotalList.invoice_after_msp_fee += Number(el.invoice_after_msp_fee);
        this.payrollDataTotalList.received_amount += Number(el.received_amount);
        this.payrollDataTotalList.nbo_amount += Number(el.nbo_amount);
        this.payrollDataTotalList.vcs_profit += Number(el.profit);

        if (el.payroll_status !== "submitted") {
          this.submitErr = "true";
        } else if (el.payroll_status === "submitted") {
          this.submitErr = "";
        }
      });
    });
  }
  
  datePickerConfig = <IDayCalendarConfig>{
    drops: 'down',
    format: 'MM/DD/YYYY',
  };

}
