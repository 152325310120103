<app-admin-default-layout>
  <div class="col-xl-10 col-lg-8 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Employee
        </h5>
        <div class="float-right" style="display: flex">
          <div class="form-group" style="margin-right: 10px">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="searchData"
              placeholder="Search Employee"
            />
          </div>
          <button
            type="button"
            class="site-button m-b30"
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#AddEmployee"
            style="margin-right: 10px"
          >
            Add Employee
          </button>
          <a
            type="button"
            class="site-button m-b30"
            href="{{apiUrl}}vcsapi/api/generate/excel/employees/{{
              user_id
            }}/{{ excelfileName }}"
            target="_blank"
            rel="noopener noreferrer"
            download
            >Report</a
          >
        </div>
      </div>
      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th class="feature">
            </th>
            <th>Code</th>
            <th style="text-align: center">Name</th>
            <th style="text-align: center">Designation</th>
            <th style="text-align: center">Role</th>
            <th style="text-align: center">Department</th>
            <th>Status</th>
            <th style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="employeeData">
          <tr
            *ngFor="
              let d of employeeData | sortBy: 'user_first_name'
                | paginate
                  : { itemsPerPage: count, currentPage: page }
            "
          >
            <td class="feature"></td>
            <td class="job-name">
              {{ d.employee_code }}
            </td>
            <td
              class="application text-primary"
              style="text-align: center"
            >
              <a
                href="javascript:void(0);"
                data-toggle="modal"
                data-target="#employeeViewDetails"
                (click)="employeeViewDetails(d)"
              >
                {{ d.user_first_name }}&nbsp;{{
                  d.user_middle_name
                }}&nbsp;{{ d.user_last_name }}
              </a>
            </td>
            <td
              class="application text-primary"
              style="text-align: center"
            >
              {{ d.designation_name }}
            </td>
            <td
              class="application text-primary"
              style="text-align: center"
            >
              {{ d.role_name }}
            </td>
            <td
              class="application text-primary"
              style="text-align: center"
            >
              {{ d.dept_name }}
            </td>

            <td
              class="expired pending"
              style="color: green"
              *ngIf="d.user_status === 'active'"
            >
              Active
            </td>
            <td
              class="expired pending"
              *ngIf="d.user_status === 'inactive'"
            >
              Inactive
            </td>

            <td class="job-links">
              <div class="dropdown">
                <a href="javascript:void(0);">
                  <i class="fa fa-cog"></i
                ></a>
                <div class="dropdown-content">
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#EditEmployee"
                    (click)="modelBindData(d)"
                    >Edit</a
                  >
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#employeeAssignAccess"
                    (click)="getDedfaultAccessData(d)"
                    >Assgn Accs(Web)</a
                  >
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#resetPassword"
                    (click)="changeStatusBindData(d)"
                    >Change Password</a
                  >

                  <a
                    href="#"
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#employeeChangeStatus"
                    (click)="changeStatusBindData(d)"
                    >Change Status</a
                  >
                  <a
                    href="#"
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#blockUser"
                    (click)="changeStatusBindData(d)"
                    >Block User</a
                  >
                  <a class="" href="javascript:void(0);"  (click)="employeeDelete(d)">Delete</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" *ngIf="employeeData">
        <pagination-controls
          *ngIf="employeeData.length"
          class="product-pagination"
          autoHide="false"
          maxSize="8"
          (pageChange)="onPageChanged($event)"
        ></pagination-controls>
      </div>
      <!--model start-->
      <div
        class="modal fade modal-bx-info editor"
        id="AddEmployee"
        tabindex="-1"
        role="dialog"
        aria-labelledby="AddEmployeeModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="AddEmployeeModalLongTitle"
              >
                Add Employee
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                #closeEdit
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="employeeAddFrom">
                <div class="row">
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>First Name<span style="color: red">*</span></label>
                      <input
                        type="text"
                        class="form-control"
                        id="first_name"
                        formControlName="first_name"
                      />
                      <div
                        *ngIf="
                          employeeAddFrom.controls['first_name']
                            .invalid &&
                          (employeeAddFrom.controls['first_name']
                            .dirty ||
                            employeeAddFrom.controls['first_name']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['first_name']
                              .errors?.['required']
                          "
                        >
                          First name is required.
                        </div>
                        <div
                          *ngIf="
                            employeeAddFrom.controls['first_name']
                              .errors?.['maxlength']
                          "
                        >
                          First name must be at most 100 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>Middle Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="middle_name"
                        formControlName="middle_name"
                      />
                      <div
                        *ngIf="
                          employeeAddFrom.controls['middle_name']
                            .invalid &&
                          (employeeAddFrom.controls['middle_name']
                            .dirty ||
                            employeeAddFrom.controls['middle_name']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['middle_name']
                              .errors?.['maxlength']
                          "
                        >
                          Middle name must be at most 100 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label
                        >Last Name<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="last_name"
                        formControlName="last_name"
                      />
                      <div
                        *ngIf="
                          employeeAddFrom.controls['last_name']
                            .invalid &&
                          (employeeAddFrom.controls['last_name']
                            .dirty ||
                            employeeAddFrom.controls['last_name']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['last_name']
                              .errors?.['required']
                          "
                        >
                          Last name is required.
                        </div>
                        <div
                          *ngIf="
                            employeeAddFrom.controls['last_name']
                              .errors?.['maxlength']
                          "
                        >
                          Last name must be at most 100 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Designation<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        class="form-control"
                        formControlName="designation"
                        style="height: 33px"
                      >
                        <option value="" disabled>
                          Select Designation
                        </option>
                        <option
                          *ngFor="let data of DesignationList | sortBy:'designation_name'"
                          [value]="data.designation_id"
                          style="text-transform: capitalize"
                        >
                          {{ data.designation_name }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          employeeAddFrom.controls['designation']
                            .invalid &&
                          (employeeAddFrom.controls['designation']
                            .dirty ||
                            employeeAddFrom.controls['designation']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['designation']
                              .errors?.['required']
                          "
                        >
                          Designation is required.
                        </div>
                        <div
                          *ngIf="
                            employeeAddFrom.controls['designation']
                              .errors?.['maxlength']
                          "
                        >
                          Designation must be at most 100 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Role<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        class="form-control"
                        formControlName="role"
                        style="height: 33px"
                      >
                        <option value="" disabled>
                          Select Role
                        </option>
                        <option
                          *ngFor="let data of showRole | sortBy:'role_name'"
                          [value]="data.role_id"
                          style="text-transform: capitalize"
                        >
                          {{ data.role_name }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          employeeAddFrom.controls['role']
                            .invalid &&
                          (employeeAddFrom.controls['role'].dirty ||
                            employeeAddFrom.controls['role']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['role'].errors
                              ?.['required']
                          "
                        >
                          Role is required.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Department<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        class="form-control"
                        formControlName="department"
                        style="height: 33px"
                      >
                        <option value="" disabled>
                          Select Department
                        </option>
                        <option
                          *ngFor="let data of showDepartment | sortBy:'dept_name'"
                          [value]="data.dept_id"
                          style="text-transform: capitalize"
                        >
                          {{ data.dept_name }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          employeeAddFrom.controls['department']
                            .invalid &&
                          (employeeAddFrom.controls['department']
                            .dirty ||
                            employeeAddFrom.controls['department']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['department']
                              .errors?.['required']
                          "
                        >
                          Department is required.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-5 col-md-5">
                    <div class="form-group">
                      <label
                        >Email<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        formControlName="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        (focusout)="checkEmailUniqueAdd()"
                      />
                      <div
                        *ngIf="
                          employeeAddFrom.controls['email']
                            .invalid &&
                          (employeeAddFrom.controls['email']
                            .dirty ||
                            employeeAddFrom.controls['email']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['email'].errors
                              ?.['required']
                          "
                        >
                          Email is required.
                        </div>
                        <div
                          *ngIf="
                            employeeAddFrom.controls['email'].invalid
                          "
                        >
                          Email is invalid.
                        </div>
                        <div
                          *ngIf="
                            employeeAddFrom.controls['email'].errors
                              ?.['maxlength']
                          "
                        >
                          Email must be at most 60 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 col-md-7">
                    <div class="form-group">
                      <label>Phone</label>
                      <div style="display: flex">
                        <input
                          type="text"
                          class="form-control"
                          formControlName="phoneCode"
                          style="width: 90px"
                          pattern="[-+]?\d*"
                        />

                        <input
                          type="number"
                          class="form-control"
                          formControlName="phone"
                          style="margin-left: 10px"
                          pattern="[-+]?\d*"
                        />
                      </div>

                      <div
                        *ngIf="
                          employeeAddFrom.controls['phoneCode']
                            .invalid &&
                          (employeeAddFrom.controls['phoneCode']
                            .dirty ||
                            employeeAddFrom.controls['phoneCode']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['phoneCode']
                              .invalid
                          "
                        >
                          Enter valid number.
                        </div>
                        <div
                          *ngIf="
                            employeeAddFrom.controls['phoneCode']
                              .errors?.['max']
                          "
                        >
                          Code must be at most 6 characters long.
                        </div>
                      </div>

                      <div
                        *ngIf="
                          employeeAddFrom.controls['phone']
                            .invalid &&
                          (employeeAddFrom.controls['phone']
                            .dirty ||
                            employeeAddFrom.controls['phone']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['phone'].invalid
                          "
                        >
                          Enter valid number.
                        </div>
                        <div
                          *ngIf="
                            employeeAddFrom.controls['phone'].errors
                              ?.['max']
                          "
                        >
                          Phone number must be at most 13 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Date of Joining<span style="color: red"
                          >*</span
                        ></label
                      >
                      <dp-date-picker
                        dir="ltr"
                        theme="dp-material"
                        placeholder="MM/DD/YYYY"
                        required="true"
                        formControlName="joiningDate"
                        [config]="datePickerConfig"
                        style="height: 33px"
                      >
                      </dp-date-picker>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Supervisor Name<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Supervisor Name"
                        formControlName="supervisorName"
                        (input)="searchEmp($event)"
                        (focus)="focusInputEmp()"
                      />

                      <div
                        class="alert alert-success"
                        *ngIf="empList"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngFor="let data of dropDownEmpList | sortBy:'user_first_name'"
                          (click)="onOptionsSelectedEmp(data)"
                          style="margin: 5px"
                        >
                          {{ data.user_first_name }}
                          {{ data.user_middle_name }}
                          {{ data.user_last_name }} ({{
                            data.designation_name
                          }})
                        </div>
                      </div>

                      <div
                        *ngIf="
                          employeeAddFrom.controls['supervisorName']
                            .invalid &&
                          (employeeAddFrom.controls[
                            'supervisorName'
                          ].dirty ||
                            employeeAddFrom.controls[
                              'supervisorName'
                            ].touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeAddFrom.controls['supervisorName']
                              .errors?.['required']
                          "
                        >
                          Supervisor is required.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Signatory</label>
                      <div class="row" style="margin-top: 10px">
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              [ngModelOptions]="{
                                standalone: true
                              }"
                              [(ngModel)]="defaultSignatory"
                              id="yes"
                              name="example1"
                              value="yes"
                            />
                            <label
                              class="custom-control-label"
                              for="yes"
                              >Yes</label
                            >
                          </div>
                        </div>
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="no"
                              [ngModelOptions]="{
                                standalone: true
                              }"
                              [(ngModel)]="defaultSignatory"
                              name="example2"
                              value="no"
                            />
                            <label
                              class="custom-control-label"
                              for="no"
                              >No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="submitAddEmployee()"
                [disabled]="
                  !employeeAddFrom.valid ||
                  duplicateCheck ||
                  sup_id === ''
                "
                data-dismiss="modal"
                [ngStyle]="{
                  cursor:
                    !employeeAddFrom.valid ||
                    duplicateCheck ||
                    sup_id === ''
                      ? 'not-allowed'
                      : 'pointer'
                }"
                class="site-button"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="EditEmployee"
        tabindex="-1"
        role="dialog"
        aria-labelledby="EditEmployeeModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="EditEmployeeModalLongTitle"
              >
                Edit Employee
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                #closeEdit
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="employeeEditFrom">
                <div class="row">
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label
                        >First Name<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="first_name"
                        formControlName="first_name"
                      />
                      <div
                        *ngIf="
                          employeeEditFrom.controls['first_name']
                            .invalid &&
                          (employeeEditFrom.controls['first_name']
                            .dirty ||
                            employeeEditFrom.controls['first_name']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['first_name']
                              .errors?.['required']
                          "
                        >
                          First name is required.
                        </div>
                        <div
                          *ngIf="
                            employeeEditFrom.controls['first_name']
                              .errors?.['maxlength']
                          "
                        >
                          First name must be at most 100 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label>Middle Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="middle_name"
                        formControlName="middle_name"
                      />
                      <div
                        *ngIf="
                          employeeEditFrom.controls['middle_name']
                            .invalid &&
                          (employeeEditFrom.controls['middle_name']
                            .dirty ||
                            employeeEditFrom.controls['middle_name']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['middle_name']
                              .errors?.['maxlength']
                          "
                        >
                          Middle name must be at most 100 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="form-group">
                      <label
                        >Last Name<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="last_name"
                        formControlName="last_name"
                      />
                      <div
                        *ngIf="
                          employeeEditFrom.controls['last_name']
                            .invalid &&
                          (employeeEditFrom.controls['last_name']
                            .dirty ||
                            employeeEditFrom.controls['last_name']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['last_name']
                              .errors?.['required']
                          "
                        >
                          Last name is required.
                        </div>
                        <div
                          *ngIf="
                            employeeEditFrom.controls['last_name']
                              .errors?.['maxlength']
                          "
                        >
                          Last name must be at most 100 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Employee Code</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="employee_code"
                        readonly
                      />
                      <div
                        *ngIf="
                          employeeEditFrom.controls['employee_code']
                            .invalid &&
                          (employeeEditFrom.controls[
                            'employee_code'
                          ].dirty ||
                            employeeEditFrom.controls[
                              'employee_code'
                            ].touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['employee_code']
                              .errors?.['maxlength']
                          "
                        >
                          Employee Code must be at most 30
                          characters long.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Designation<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        class="form-control"
                        formControlName="designation"
                        style="height: 33px"
                      >
                        <option
                          *ngFor="let data of DesignationList | sortBy:'designation_name'"
                          [value]="data.designation_id"
                          style="text-transform: capitalize"
                        >
                          {{ data.designation_name }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          employeeEditFrom.controls['designation']
                            .invalid &&
                          (employeeEditFrom.controls['designation']
                            .dirty ||
                            employeeEditFrom.controls['designation']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['designation']
                              .errors?.['required']
                          "
                        >
                          Designation is required.
                        </div>
                        <div
                          *ngIf="
                            employeeEditFrom.controls['designation']
                              .errors?.['maxlength']
                          "
                        >
                          Designation must be at most 100 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Role<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        class="form-control"
                        formControlName="role"
                        style="height: 33px"
                      >
                        <option value="" disabled>
                          Select Role
                        </option>
                        <option
                          *ngFor="let data of showRole | sortBy:'role_name'"
                          [value]="data.role_id"
                          style="text-transform: capitalize"
                        >
                          {{ data.role_name }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          employeeEditFrom.controls['role']
                            .invalid &&
                          (employeeEditFrom.controls['role']
                            .dirty ||
                            employeeEditFrom.controls['role']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['role'].errors
                              ?.['required']
                          "
                        >
                          Role is required.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Department<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        class="form-control"
                        formControlName="department"
                        style="height: 33px"
                      >
                        <option value="" disabled>
                          Select Department
                        </option>
                        <option
                          *ngFor="let data of showDepartment | sortBy:'dept_name'"
                          [value]="data.dept_id"
                          style="text-transform: capitalize"
                        >
                          {{ data.dept_name }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          employeeEditFrom.controls['department']
                            .invalid &&
                          (employeeEditFrom.controls['department']
                            .dirty ||
                            employeeEditFrom.controls['department']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['department']
                              .errors?.['required']
                          "
                        >
                          Department is required.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-5 col-md-5">
                    <div class="form-group">
                      <label
                        >Email<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        formControlName="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        (focusout)="checkEmailUniqueEdit()"
                      />
                      <div
                        *ngIf="
                          employeeEditFrom.controls['email']
                            .invalid &&
                          (employeeEditFrom.controls['email']
                            .dirty ||
                            employeeEditFrom.controls['email']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['email'].errors
                              ?.['required']
                          "
                        >
                          Email is required.
                        </div>
                        <div
                          *ngIf="
                            employeeEditFrom.controls['email'].invalid
                          "
                        >
                          Email is invalid.
                        </div>
                        <div
                          *ngIf="
                            employeeEditFrom.controls['email'].errors
                              ?.['maxlength']
                          "
                        >
                          Email must be at most 60 characters long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 col-md-7">
                    <div class="form-group">
                      <label>Phone</label>
                      <div style="display: flex">
                        <input
                          type="text"
                          class="form-control"
                          formControlName="phoneCode"
                          style="width: 90px"
                          pattern="[-+]?\d*"
                        />

                        <input
                          type="number"
                          class="form-control"
                          formControlName="phone"
                          style="margin-left: 10px"
                          pattern="[-+]?\d*"
                        />
                      </div>

                      <div
                        *ngIf="
                          employeeEditFrom.controls['phoneCode']
                            .invalid &&
                          (employeeEditFrom.controls['phoneCode']
                            .dirty ||
                            employeeEditFrom.controls['phoneCode']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['phoneCode']
                              .invalid
                          "
                        >
                          Enter valid number.
                        </div>
                        <div
                          *ngIf="
                            employeeEditFrom.controls['phoneCode']
                              .errors?.['max']
                          "
                        >
                          Code must be at most 6 characters long.
                        </div>
                      </div>

                      <div
                        *ngIf="
                          employeeEditFrom.controls['phone']
                            .invalid &&
                          (employeeEditFrom.controls['phone']
                            .dirty ||
                            employeeEditFrom.controls['phone']
                              .touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['phone'].invalid
                          "
                        >
                          Enter valid number.
                        </div>
                        <div
                          *ngIf="
                            employeeEditFrom.controls['phone'].errors
                              ?.['max']
                          "
                        >
                          Phone number must be at most 13 characters
                          long.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Date of Joining<span style="color: red"
                          >*</span
                        ></label
                      >
                      <dp-date-picker
                        dir="ltr"
                        theme="dp-material"
                        placeholder="MM/DD/YYYY"
                        required="true"
                        formControlName="joiningDate"
                        [config]="datePickerConfig"
                        style="height: 33px"
                      >
                      </dp-date-picker>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label
                        >Supervisor Name<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Supervisor Name"
                        formControlName="supervisorName"
                        (input)="searchEmp($event)"
                        (focus)="focusInputEmp2()"
                      />

                      <div
                        class="alert alert-success"
                        *ngIf="empList2"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngFor="let data of dropDownEmpList | sortBy:'user_first_name'"
                          (click)="onOptionsSelectedEmp2(data)"
                          style="margin: 5px"
                        >
                          {{ data.user_first_name }}
                          {{ data.user_middle_name }}
                          {{ data.user_last_name }} ({{
                            data.designation_name
                          }})
                        </div>
                      </div>

                      <div
                        *ngIf="
                          employeeEditFrom.controls[
                            'supervisorName'
                          ].invalid &&
                          (employeeEditFrom.controls[
                            'supervisorName'
                          ].dirty ||
                            employeeEditFrom.controls[
                              'supervisorName'
                            ].touched)
                        "
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="
                            employeeEditFrom.controls['supervisorName']
                              .errors?.['required']
                          "
                        >
                          Supervisor is required.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Signatory</label>
                      <div class="row" style="margin-top: 10px">
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              [ngModelOptions]="{
                                standalone: true
                              }"
                              [(ngModel)]="defaultSignatory2"
                              id="yess"
                              name="example1"
                              value="yes"
                            />
                            <label
                              class="custom-control-label"
                              for="yess"
                              >Yes</label
                            >
                          </div>
                        </div>
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="noo"
                              [ngModelOptions]="{
                                standalone: true
                              }"
                              [(ngModel)]="defaultSignatory2"
                              name="example2"
                              value="no"
                            />
                            <label
                              class="custom-control-label"
                              for="noo"
                              >No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="submitUpdateEmployee()"
                [disabled]="
                  !employeeEditFrom.valid ||
                  duplicateCheck2 ||
                  sup_id === ''
                "
                data-dismiss="modal"
                [ngStyle]="{
                  cursor:
                    !employeeEditFrom.valid ||
                    duplicateCheck2 ||
                    sup_id === ''
                      ? 'not-allowed'
                      : 'pointer'
                }"
                class="site-button"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="employeeChangeStatus"
        tabindex="-1"
        role="dialog"
        aria-labelledby="employeeChangeStatusModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="employeeChangeStatusModalLongTitle"
              >
                Change Status
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Status</label>
                      <div class="row" style="margin-top: 10px">
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              [(ngModel)]="defaultStatus"
                              id="active"
                              name="example1"
                              value="active"
                            />
                            <label
                              class="custom-control-label"
                              for="active"
                              >Active
                            </label>
                          </div>
                        </div>
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="inactive"
                              [(ngModel)]="defaultStatus"
                              name="example2"
                              value="inactive"
                            />
                            <label
                              class="custom-control-label"
                              for="inactive"
                              >Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
                (click)="updateStatusEmployee()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="resetPassword"
        tabindex="-1"
        role="dialog"
        aria-labelledby="eresetPasswordModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="resetPasswordModalLongTitle"
              >
                Reset Password
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p style="text-transform: none">
                Click on reset to change the password. Password will
                be send to the respective email ID.
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
                (click)="changePassword()"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="blockUser"
        tabindex="-1"
        role="dialog"
        aria-labelledby="blockUserModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="blockUserModalLongTitle">
                Block User
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <!--<label></label>-->
                      <div class="row" style="margin-top: 10px">
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              [(ngModel)]="defaultBlockUser"
                              id="block"
                              name="example1"
                              value="block"
                            />
                            <label
                              class="custom-control-label"
                              for="block"
                              >Block
                            </label>
                          </div>
                        </div>
                        <div
                          class="col-lg-3 col-md-6 col-sm-6 col-6"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="unblock"
                              [(ngModel)]="defaultBlockUser"
                              name="example2"
                              value="unblock"
                            />
                            <label
                              class="custom-control-label"
                              for="unblock"
                              >Unblock
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
                (click)="blockUserFunction()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="employeeAssignAccess"
        tabindex="-1"
        role="dialog"
        aria-labelledby="employeeAssignAccessModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="employeeAssignAccessModalLongTitle"
              >
                Assign Access
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              class="modal-body"
              style="height: 500px; overflow-y: scroll"
            >
              <form>
                <div
                  id="desired_career_profile_bx"
                  class="job-bx bg-white m-b30"
                  *ngFor="let d of sortedModuleIds; let i = index"
                >
                  <form>
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                          <label style="font-weight: 700">{{
                            sortedModuleName[i]
                          }}</label>
                          <div class="row">
                            <div *ngFor="let k of accessWebAllData">
                              <div
                                *ngIf="k.module_id === d"
                                style="width: 220px"
                              >
                                <div
                                  class="custom-control custom-checkbox"
                                  style="margin-bottom: 20px"
                                >
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    (click)="getList($event, k)"
                                    id="{{ k.action_id }}"
                                    [checked]="k.value"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="{{ k.action_id }}"
                                    >{{ k.submodule_name }}</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
                [disabled]="assignAccessCheckBoxList.length === 0"
                [ngStyle]="{
                  cursor:
                    assignAccessCheckBoxList.length === 0
                      ? 'not-allowed'
                      : 'pointer'
                }"
                (click)="insertWebAccessData()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="employeeViewDetails"
        tabindex="-1"
        role="dialog"
        aria-labelledby="employeeViewDetailsModalLongTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="eemployeeViewDetailsModalLongTitle"
              >
                View Details
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Employee Name
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>{{ empName }}</div>
              </div>

              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Code
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>{{ empCode }}</div>
              </div>
              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Role
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>{{ empRole }}</div>
              </div>
              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Designation
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>{{ empDesignation }}</div>
              </div>
              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Department
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>{{ empDepartment }}</div>
              </div>
              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Email
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>{{ empEmail }}</div>
              </div>
              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Phone
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>{{ empPhone }}</div>
              </div>
              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Date Of Joining
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>
                  {{ EmpDataOfJoining | date : "MM/dd/yyyy" }}
                </div>
              </div>

              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Supervisor Name
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>{{ supervisorName }}</div>
              </div>
              <div class="wordAlign" style="margin-top: 10px">
                <div style="min-width: 120px; font-weight: 600">
                  Signatory
                </div>
                <div style="min-width: 40px; text-align: center">
                  :
                </div>
                <div>{{ EmpSignatory }}</div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--model end-->
    </div>
  </div>
</app-admin-default-layout>