<app-admin-default-layout>
  <div class="col-xl-10 col-lg-8 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Client
        </h5>
        <div class="float-right" style="display: flex">
          <button
            type="button"
            class="site-button m-b30"
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#clientAdd"
          >
            Add Client
          </button>
        </div>
      </div>
      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th class="feature">
            </th>
            <th>Name</th>
            <th>Status</th>
            <th style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="allClients">
          <tr *ngFor=" let d of allClients | sortBy:'client_name' | paginate : { itemsPerPage: count, currentPage: page } " >
            <td class="feature"> </td>
            <td class="job-name"> {{ d.client_name }} </td>
            <td class="expired pending" style="color: green" *ngIf="d.client_status == 'active'" > Active </td>
            <td class="expired pending" *ngIf="d.client_status == 'inactive'" > Inactive </td>
            <td class="job-links">
              <div class="dropdown">
                <a href="javascript:void(0);">
                  <i class="fa fa-cog"></i
                ></a>
                <div class="dropdown-content">
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#clientEdit" (click)="setClientId(d)" >Edit</a>
                  <a href="javascript:void(0);" data-target="#alldocuments" (click)="getDocumentClientId(d)" data-toggle="modal">Documents</a>
                  <a href="#" href="javascript:void(0);" data-toggle="modal" data-target="#clientChangeStatus" (click)="setClientId(d)" >Change Status</a >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table> 
      <div class="pagination" *ngIf="allClients">
        <pagination-controls
          *ngIf="allClients.length"
          class="product-pagination"
          autoHide="false"
          maxSize="8"
          (pageChange)="onPageChanged($event)"
        ></pagination-controls>
      </div>

      <!--model start-->
      <div
        class="modal fade modal-bx-info editor"
        id="clientAdd"
        tabindex="-1"
        role="dialog"
        aria-labelledby="clientAddModalLongTitle"
        aria-hidden="true"
      >
      <div class="faltu-wrapper">

        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="clientAddModalLongTitle">
                Add Client
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="addClientForm">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Name"
                        formControlName="client_name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>MSP fee(%)</label>
                      <input type="number" class="form-control" placeholder="Enter MSP fee"
                        formControlName="msp_fee">
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Payroll Expenses(%) </label>
                      <input type="number" class="form-control" placeholder="Enter Payroll Expenses"
                        formControlName="payroll_expenses">
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                [disabled]="!addClientForm.valid"
                (click)="insertClient()"
                [ngStyle]="{
                  cursor: !addClientForm.valid
                    ? 'not-allowed'
                    : 'pointer'
                }"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="clientEdit"
        tabindex="-1"
        role="dialog"
        aria-labelledby="clientEditModalLongTitle"
        aria-hidden="true"
      >
      <div class="faltu-wrapper">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="clientEditModalLongTitle">
                Edit Client
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="editClientForm">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Name"
                        formControlName="edit_client"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>MSP fee(%)</label>
                      <input type="number" class="form-control" placeholder="Enter MSP fee"
                        formControlName="edit_msp_fee">
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Payroll Expenses(%) </label>
                      <input type="number" class="form-control" placeholder="Enter Payroll Expenses"
                        formControlName="edit_payroll_expenses">
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                [disabled]="!editClientForm.valid"
                (click)="updateClient()"
                [ngStyle]="{
                  cursor: !editClientForm.valid
                    ? 'not-allowed'
                    : 'pointer'
                }"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div
        class="modal fade modal-bx-info editor"
        id="clientChangeStatus"
        tabindex="-1"
        role="dialog"
        aria-labelledby="clientChangeStatusModalLongTitle"
        aria-hidden="true"
      >
      <div class="faltu-wrapper">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="clientChangeStatusModalLongTitle"
              >
                Change Status
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Status</label>
                      <div class="row" style="margin-top: 10px">
                        <div class="col-md-6 col-sm-6 col-6" >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              [(ngModel)]="defaultStatus"
                              id="active"
                              name="example1"
                              value="active"
                            />
                            <label
                              class="custom-control-label"
                              for="active"
                              >Active
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6" >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="inactive"
                              [(ngModel)]="defaultStatus"
                              name="example2"
                              value="inactive"
                            />
                            <label
                              class="custom-control-label"
                              for="inactive"
                              >Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
                (click)="changeStatusClient()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>

      <!--model end-->
      <!-- document -->
      <div class="modal fade modal-bx-info editor" id="alldocuments" tabindex="-1" role="dialog"
        aria-labelledby="clientEditModalLongTitle" aria-hidden="true">
        <div class="faltu-wrapper">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="clientEditModalLongTitle">All Documents</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- document list -->
            <div class="modal-body addDocu" *ngIf="docuList == true">
              <div class="job-bx-titlde documentUp clearfix" style="font-size: 13px;border-top: 1px solid #e1e7ff;padding: 11px 0px;">
                <div class="float-right" style="display: flex;">
                  <button class="site-button m-b30"  href="javascript:void(0);" type="button" (click)="enableDocumentForm()">Add Document</button>
                </div>
              </div>
              <table class="table-job-bx cv-manager company-manage-job" *ngIf="docuList == true">
                <thead>
                  <tr>
                    <th>Doc. Name</th>
                    <th>Doc. Type</th>
                    <th>Expiry Date</th>
                    <th>Document</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="companyDocumentData.length == 0">
                  <tr><td colspan="7">No record Found</td></tr>
                </tbody>
                <tbody *ngIf="companyDocumentData.length != 0">
                  <tr *ngFor="let a of companyDocumentData" class="itemList">
                      <td class="job-name">
                          <a href="javascript:void(0);"
                              style="text-transform: capitalize; font-size:14px;" class="link"
                              >{{a.doc_name}}</a>
                      </td>
                      <td class="application text-primary" style="text-align: center;text-transform: capitalize;font-size:14px;">
                          {{a.doc_type}}</td>
                      <td class="job-name">
                          <a href="javascript:void(0);" style="text-transform: capitalize;font-size:14px;" class="link2" >{{a.expiry_date}}</a>
                      </td>
                      <td class="application text-primary" style="text-align: center;text-transform: capitalize;font-size:14px;">
                          <a style="text-decoration: none;" href="{{apiUrl}}vcsapi/clientdownload/{{a.id}}/{{a.client_id}}" target="_blank" class="site-button">Download</a>
                      </td>
                      <td class="job-name">
                        <a href="javascript:void(0);"  (click)="editDocumentList(a)">Edit</a>
                    </td>
                  </tr>
              </tbody>
                
              </table>
            </div>

            <!-- addDocument -->
            <div *ngIf="docuAdd == true">
              <div class="modal-body">
                <form [formGroup]="addDocumentsList">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Name <span style="color: red;">*</span></label>
                        <input type="text" class="form-control" placeholder="Enter Document Name" formControlName="document_name">
                        <div *ngIf="addDocumentsList.controls['document_name'].invalid && (addDocumentsList.controls['document_name'].dirty || addDocumentsList.controls['document_name'].touched)" class="alert alert-danger" style="font-size: 12px;padding: .5rem 1.25rem;">
                          <div *ngIf="addDocumentsList.controls['document_name'].errors?.['required']">
                            Name is required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Expiry date </label>
                        <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                        formControlName="expirDate" [config]="datePickerConfig">
                      </dp-date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Document <span style="color: red;">*</span></label>
                            <input 
                              formControlName="file"
                              id="file" 
                              type="file" 
                              class="form-control"
                              (change)="onFileChange($event)">

                              <div *ngIf="addDocumentsList.controls['file'].invalid && (addDocumentsList.controls['file'].dirty || addDocumentsList.controls['file'].touched)" class="alert alert-danger" style="font-size: 12px;padding: .5rem 1.25rem;">
                                <div *ngIf="addDocumentsList.controls['file'].errors?.['required']">
                                  File is required.
                                </div>
                              </div>
                        </div>
                      </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="site-button" data-dismiss="modal" (click)="enableDocumentList()">Back</button>
                <button type="button" class="site-button" [disabled]="!addDocumentsList.valid"
                  (click)="submitDocuments()"
                  [ngStyle]="{'cursor': (!addDocumentsList.valid? 'not-allowed' : 'pointer')}"
                  data-dismiss="modal">Submit
                </button>
              </div>

            </div>

            <!-- edit document -->
            <div *ngIf="docuEdit == true">
              <div class="modal-body">
                <form [formGroup]="editDocumentsList">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Name <span style="color: red;">*</span></label>
                        <input type="text" class="form-control" placeholder="Enter Name"
                          formControlName="edit_document_name">
                          <div *ngIf="editDocumentsList.controls['edit_document_name'].invalid && (editDocumentsList.controls['edit_document_name'].dirty || editDocumentsList.controls['edit_document_name'].touched)" class="alert alert-danger" style="font-size: 12px;padding: .5rem 1.25rem;">
                            <div *ngIf="editDocumentsList.controls['edit_document_name'].errors?.['required']">
                              Name is required.
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group"> 
                        <label>Expiry date </label>
                        <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                        formControlName="edit_expirDate" [config]="datePickerConfig">
                      </dp-date-picker>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                          <label>Document <span style="color: red;">*</span></label>
                          <input 
                            formControlName="edit_file"
                            id="file" 
                            type="file" 
                            class="form-control"
                            (change)="onUpdateFileChange($event)">
                      </div>
                      
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                          <a href="{{apiUrl}}vcsapi/clientdownload/{{documentId}}/{{orignalFileId}}" target="_blank" >View</a>
                      </div>
                    </div>
                  </div>


                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="site-button" data-dismiss="modal" (click)="enableDocumentList()">Back</button>
                <button type="button" class="site-button" [disabled]="!editDocumentsList.valid"
                  (click)="updateDocument()"
                  [ngStyle]="{'cursor': (!editDocumentsList.valid? 'not-allowed' : 'pointer')}"
                  data-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </div>
            

          </div>
        </div>
        </div>
      </div>

      <!--model end-->
    </div>
  </div>
</app-admin-default-layout>