<body id="bg">
  <div class="page-wraper">
    <!-- Content -->
    <div class="page-content bg-white">
      <!-- inner page banner -->
      <div
        class="dez-bnr-inr overlay-black-middle"
        style="
          background-image: url(../assets/images/banner/healthcare_banner_2.jpg);
        "
      >
        <div class="container">
          <div class="dez-bnr-inr-entry">
            <h1 class="text-white">Healthcare Services</h1>
            
            <!-- Breadcrumb row -->
            <div class="breadcrumb-row">
              <ul class="list-inline">
                <li><a href="#">Home</a></li>
                <li>Healthcare Services</li>
              </ul>
            </div>
            <!-- Breadcrumb row END -->
          </div>
        </div>
      </div>
      <!-- inner page banner END -->
      <div class="content-block">
        <div class="section-full content-inner overlay-white-middle">
          <div class="container">
            <div class="section-head text-black text-center">
              <h2 class="text-uppercase m-b0">Healthcare Staffing</h2>
              <p>
                Our heartfelt gratitude to the healthcare heroes
                #SupportHealthcareHeroes
              </p>
            </div>

            <div class="row align-items-center m-b50">
              <div class="col-md-12 col-lg-6 m-b20">
                <h2 class="m-b5">Healthcare staffing & recruitment</h2>
                <p class="m-b15">NURSING</p>
                <p>ALLIED HEALTH</p>
                <p>PHARMACIST</p>
                <p>PHYSICIANS</p>
                <p>HEALTHCARE EXECUTIVES</p>
                <p>NON- CLINICAL (IT, ADMIN ETC.)</p>
              </div>
              <div class="col-md-12 col-lg-6">
                <img
                  src="../assets/images/our-work/senior-Nursing-Care.jpeg"
                  alt="Pharmacist"
                  title="Pharmacist "
                />
              </div>
            </div>

            <div class="row align-items-center m-b50">
              <div class="col-md-12 col-lg-6">
                <img
                  src="../assets/images/our-work/Allied-Health-Jobs.jpg"
                  alt="Allied Health"
                  title="Allied Health"
                />
              </div>
              <div class="col-md-12 col-lg-6 m-b20">
                <h2 class="m-b5">Our experience with healthcare IT</h2>
                <p class="m-b15">CLOUD BASED CLINICAL SYSTEMS</p>
                <p>EVIDENCE BASED TREATMENT SUPPORT SYSTEMS</p>
                <p>SEAMLESS FHIR API INTEGRATIONS</p>
                <p>STATE GOVERNMENTS</p>
                <p>ERP SYSTEM INTEGRATIONS</p>
                <p>PHARMACEUTICAL IT STAFFING</p>
                <p>LIFE SCIENCE IT STAFFING</p>
              </div>
            </div>

            <div class="row align-items-center m-b50">
              <div class="col-md-12 col-lg-6 m-b20">
                <h2 class="m-b5">Our nursing specialization</h2>
                <p class="m-b15">ICU/CCU</p>
                <p>MEDSURG</p>
                <p>EMERGENCY</p>
                <p>REHABILITATION AND BEHAVIORAL HEALTH</p>
                <p>THERAPIST</p>
                <p>LABOR AND DELIVERY</p>
                <p>TELEMETRY NURSES</p>
              </div>

              <div class="col-md-12 col-lg-6">
                <img
                  src="../assets/images/our-work/RN-jobs.jpg"
                  alt="Employment services for Nursing"
                  title="Employment services for Nursing"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Why Chose Us -->
      </div>
      <!-- contact area END -->
    </div>
    <!-- Content END-->
    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</body>
