import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';
import { ClientAlertServiceService } from "./client-alert-service.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-alert',
  templateUrl: './client-alert.component.html',
  styleUrls: ['./client-alert.component.css']
})
export class ClientAlertComponent implements OnInit {
  editClientForm: FormGroup;
  /*paginate */
  public count:any = 20;
  public page: any;
  /**paginate  */

  get apiUrl(): string {
    return environment.apiUrl;
  }

  clientDocumentAlert: any = [];

  editDocumentsList: FormGroup;
  data: any;

  orignalFileId:string = "";

  constructor(
    public service: ClientAlertServiceService, 
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router, 
    public http: AdminService
  ) {}

  ngOnInit() {
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
    this.documentExpireData();
  }

  /////////////////////////////
  public onPageChanged(event){
    this.page = event; 
    window.scrollTo(0,0); 
  }
  ///////////////////////

  documentExpireData() {
    var company_id = sessionStorage.getItem("user_id");
    this.service.getApiClientExpiredDocument().subscribe(r => {      
      this.clientDocumentAlert = r['results']['data'];
    });
  }

  markToRead(val){
    Swal.fire({
      title: 'Do you want to Mark?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      // denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let data = {
          docId: val.id
        }
        this.service.markApiClient(data).subscribe(res => {
          if (res["message"] === 'OK') {
            Swal.fire('Marked successfully!', '', 'success')
            this.documentExpireData();
          }
          else {
            Swal.fire("Something went wrong,please try again!", '', 'error');
          }
        }, err => {
          Swal.fire("Something went wrong,please try again!", '', 'error');
        })
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
}
