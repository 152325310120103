<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Applications
        </h5>
        <div class="float-right" style="display: flex">
          <div class="form-group" style="margin-right: 10px">
            <input
              type="text"
              class="form-control2"
              [(ngModel)]="searchData"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <table class="table-job-bx cv-manager company-manage-job">
        <thead>
          <tr>
            <th class="feature">
            </th>
            <th style="text-align: center">Appl. No.</th>
            <th style="text-align: center">Appl. Date</th>
            <th style="text-align: center">Job Title</th>
            <th style="text-align: center">Appl. Status</th>
            <th style="text-align: center">Offer Acceptance</th>
            <th style="text-align: center">VCS Remarks</th>
            <th style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let a of applicationData" class="itemList">
            <td class="feature">
            </td>
            <td class="job-name">
              <a
                href="javascript:void(0);"
                data-toggle="modal"
                data-target="#applicationView"
                style="text-transform: capitalize; font-size: 14px"
                class="link"
                (click)="viewApplication(a)"
                >{{ a.application_no }}</a
              >
            </td>
            <td
              class="application text-primary"
              style="text-align: center"
            >
              {{ a.apply_date }}
            </td>
            <td class="job-name">
              <a
                href="javascript:void(0);"
                data-toggle="modal"
                data-target="#jobView"
                style="text-transform: capitalize; font-size: 14px"
                class="link2"
                (click)="viewApplication(a)"
                >{{ a.job_title }}</a
              >
            </td>
            <td
              class="application text-primary"
              style="text-align: center"
            >
              {{ a.application_stage }}
            </td>
            <td
              class="expired pending"
              *ngIf="a.application_stage === 'offered'"
              style="text-transform: capitalize; text-align: center"
            >
              <button
                type="button"
                class="button-offer"
                data-toggle="modal"
                data-target="#changeStatus"
                style="text-transform: capitalize"
                (click)="onChangeStatus(a)"
              >
                Accept Offer
              </button>
            </td>
            <td *ngIf="a.application_stage !== 'offered'"></td>

            <td
              class="application"
              style="text-transform: capitalize"
              *ngIf="a.latest_remarks"
            >
              {{ a.latest_remarks }}
              <span
                class="appCount"
                (click)="viewRemarks(a)"
                data-toggle="modal"
                style="float: right; background-color: coral"
                data-target="#remarksView"
              >
                <i class="fa fa-eye"></i>
              </span>
            </td>
            <td *ngIf="!a.latest_remarks"></td>

            <td class="job-links">
              <div class="dropdown">
                <a href="javascript:void(0);">
                  <i class="fa fa-cog"></i
                ></a>
                <div class="dropdown-content">
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#uploadDoc"
                    (click)="clickOpen(a)"
                    >Upload Doc</a
                  >
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#viewOffer"
                    *ngIf="
                      a.application_stage === 'offered' ||
                      a.application_stage === 'not offered' ||
                      a.application_stage === 'offer accepted' ||
                      a.application_stage === 'offer declined' ||
                      a.application_stage === 'on boarding' ||
                      a.application_stage === 'hired'
                    "
                    (click)="viewOffer(a)"
                    >View Offer
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!--View JOB Modal -->
      <div
        class="modal fade modal-bx-info"
        id="jobView"
        tabindex="-1"
        role="dialog"
        aria-labelledby="jobViewTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog"
          role="document"
          style="max-width: 60% !important"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" *ngIf="details">
                {{ details.job_title }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <table>
                <colgroup>
                  <col span="1" style="width: 28%" />
                  <col span="1" style="width: 22%" />
                  <col span="1" style="width: 25%" />
                  <col span="1" style="width: 25%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td><strong>Job Title :</strong></td>
                    <td colspan="3">{{ details.job_title }}</td>
                  </tr>
                  <tr>
                    <td><strong>Job Location :</strong></td>
                    <td colspan="3">
                      {{ details.city }}, {{ details.state }},
                      {{ details.country }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Job ID :</strong></td>
                    <td colspan="3">{{ details.job_no }}</td>
                  </tr>

                  <tr>
                    <td>
                      <strong
                        >Blended Pay Rate (Traveller $/Hr) :</strong
                      >
                    </td>
                    <td>$ {{ details.blended_pay_rate }}</td>
                    <td>
                      <strong
                        >Regular Pay Rate (Locals $/Hr) :</strong
                      >
                    </td>
                    <td>$ {{ details.regular_pay_rate }}</td>
                  </tr>

                  <tr>
                    <td><strong>Job Type :</strong></td>
                    <td>{{ details.job_type_name }}</td>
                    <td><strong>Position Type :</strong></td>
                    <td>{{ details.position_type_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Duration :</strong></td>
                    <td>{{ details.duration }}</td>
                    <td>
                      <strong>Confirmed (hour/week) :</strong>
                    </td>
                    <td>{{ details.confirm_hr }}</td>
                  </tr>

                  <tr>
                    <td><strong>Shift :</strong></td>
                    <td>{{ details.shift }}</td>
                  </tr>

                  <tr>
                    <td><strong>Required Information :</strong></td>
                    <td colspan="3">
                      {{ details.req_information }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Description :</strong></td>
                    <td colspan="3">
                      {{ details.job_description }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--View JOB OFFER -->
      <div
        class="modal fade modal-bx-info"
        id="viewOffer"
        tabindex="-1"
        role="dialog"
        aria-labelledby="viewOfferTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog"
          role="document"
          style="max-width: 60% !important"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Job Offer</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <p style="text-transform: none">
                We are pleased to inform that you are offered for
                the job. Details of job are given below. Kindly
                accept the job offer and proceed for on-boarding
                process.
              </p>

              <hr />
              <div class="job-bx-title clearfix">
                <h5
                  class="font-weight-700 pull-left text-uppercase"
                >
                  Job Details
                </h5>
              </div>
              <table class="table_td">
                <colgroup>
                  <col span="1" style="width: 30%" />
                  <col span="1" style="width: 20%" />
                  <col span="1" style="width: 30%" />
                  <col span="1" style="width: 20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td class="table_td">
                      <strong>Job Id :</strong>
                    </td>
                    <td class="table_td">{{ details.job_no }}</td>
                    <td class="table_td">
                      <strong>Client :</strong>
                    </td>
                    <td class="table_td">
                      {{ details.client_name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td">
                      <strong>Applicant Name :</strong>
                    </td>
                    <td class="table_td">{{ details.name }}</td>
                    <td class="table_td">
                      <strong>Application No. :</strong>
                    </td>
                    <td class="table_td">
                      {{ details.application_no }}
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td">
                      <strong>Proposed Start Date :</strong>
                    </td>
                    <td class="table_td">
                      {{
                        details.proposed_start_date
                          | date : "MM/dd/yyyy"
                      }}
                    </td>
                    <td class="table_td">
                      <strong>Proposed End Date :</strong>
                    </td>
                    <td class="table_td">
                      {{
                        details.proposed_end_date
                          | date : "MM/dd/yyyy"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td">
                      <strong>Regular Pay Rate ($/hr):</strong>
                    </td>
                    <td class="table_td">
                      {{ details.onb_regular_pay_rate }}
                    </td>
                    <td class="table_td">
                      <strong>Holiday Pay Rate ($/hr) :</strong>
                    </td>
                    <td class="table_td">
                      {{ details.onb_holiday_pay_rate }}
                    </td>
                  </tr>

                  <tr>
                    <td class="table_td">
                      <strong>Allowed Per Diem/Week :</strong>
                    </td>
                    <td class="table_td">
                      {{ details.per_dieum_wk }}
                    </td>
                    <td class="table_td">
                      <strong>OT Starts after (hours) :</strong>
                    </td>
                    <td class="table_td">
                      {{ details.ot_starts_after_wk }}
                    </td>
                  </tr>

                  <tr>
                    <td class="table_td">
                      <strong>Confirmed (hours/week) :</strong>
                    </td>
                    <td class="table_td">
                      {{ details.total_shift_hr }}
                    </td>
                    <td class="table_td">
                      <strong>Shift Details :</strong>
                    </td>
                    <td class="table_td">
                      {{ details.shift_details }}
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td"><strong>RTO :</strong></td>
                    <td class="table_td">{{ details.rto }}</td>
                    <td class="table_td">
                      <strong>Contract Duration (weeks) :</strong>
                    </td>
                    <td class="table_td">
                      {{ details.contract_duration_wk }}
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td">
                      <strong>Pay Package / Miscellaneous :</strong>
                    </td>
                    <td class="table_td" colspan="3">
                      {{ details.pay_package_remarks }}
                    </td>
                  </tr>

                  <tr>
                    <td class="table_td">
                      <strong>Due Date :</strong>
                    </td>
                    <td class="table_td" colspan="3">
                      {{ details.due_date }}
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td">
                      <strong>Comments :</strong>
                    </td>
                    <td class="table_td" colspan="3">
                      {{ details.comments }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--View APPLICATION Modal -->
      <div
        class="modal fade modal-bx-info"
        id="applicationView"
        tabindex="-1"
        role="dialog"
        aria-labelledby="applicationViewTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog"
          role="document"
          style="max-width: 60% !important"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" *ngIf="details">
                {{ details.application_no }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <table>
                <colgroup>
                  <col span="1" style="width: 30%" />
                  <col span="1" style="width: 70%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td><strong>Name :</strong></td>
                    <td>{{ details.name }}</td>
                  </tr>

                  <tr>
                    <td><strong>Email :</strong></td>
                    <td style="text-transform: lowercase">
                      {{ details.email }}
                    </td>
                  </tr>

                  <tr>
                    <td><strong>Phone No. :</strong></td>
                    <td>{{ details.phone }}</td>
                  </tr>

                  <tr>
                    <td><strong>Date Of Birth :</strong></td>
                    <td>{{ details.dob | date : "MM/dd/yyyy" }}</td>
                  </tr>

                  <tr>
                    <td><strong>SSN(Last 4-Digit) :</strong></td>
                    <td>{{ details.ssn_4digit }}</td>
                  </tr>

                  <tr>
                    <td><strong>Profession :</strong></td>
                    <td>{{ details.profession_name }}</td>
                  </tr>

                  <tr>
                    <td><strong>Speciality :</strong></td>
                    <td>{{ details.speciality_name }}</td>
                  </tr>

                  <tr>
                    <td><strong>Current Location :</strong></td>
                    <td>{{ details.current_location }}</td>
                  </tr>

                  <tr>
                    <td><strong>Desired Location :</strong></td>
                    <td>{{ details.loc }}</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Employement Preferences :</strong>
                    </td>
                    <td>{{ details.employement_preference }}</td>
                  </tr>

                  <tr>
                    <td><strong>Application Status :</strong></td>
                    <td>{{ details.application_stage }}</td>
                  </tr>

                  <tr>
                    <td><strong>Recruitment Status :</strong></td>
                    <td>{{ details.recruit_status }}</td>
                  </tr>

                  <tr>
                    <td><strong>User Status :</strong></td>
                    <td>{{ details.user_status }}</td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div class="job-bx-title clearfix">
                <h5
                  class="font-weight-700 pull-left text-uppercase"
                >
                  Documents
                </h5>
              </div>

              <ul>
                <li class="innerLi" *ngFor="let a of docs">
                  <strong class="innerStrng"
                    >{{ a.rec_doc_name }} :</strong
                  >
                  <a
                    class="site-button"
                    style="
                      padding: 7px;
                      margin: 5px;
                      text-decoration: none;
                    "
                    href="{{apiUrl}}vcsapi/download/{{
                      a.rec_doc_id
                    }}/{{ user_id }}/{{ a.rec_doc_name }}"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    >Download</a
                  >
                  <span class="uploadField" style="margin-left: 5%"
                    ><span style="font-weight: 500"
                      >Upload Date :</span
                    >
                    {{ a.upload_date_time }}</span
                  >
                  <span
                    class="requiredField"
                    style="margin-left: 5%"
                    *ngIf="a.expiry_date"
                    ><span style="font-weight: 500"
                      >Expiry Date :</span
                    >
                    {{ a.expiry_date }}</span
                  >
                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- REMARKS Modal -->
      <div
        class="modal fade modal-bx-info editor"
        id="remarksView"
        tabindex="-1"
        role="dialog"
        aria-labelledby="remarksViewTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="remarksViewTitle">
                Remarks
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                #closeEdit
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <table
                class="table-job-bx cv-manager company-manage-job"
              >
                <colgroup>
                  <col span="1" style="width: 20%" />
                  <col span="1" style="width: 80%" />
                </colgroup>
                <tbody>
                  <tr
                    *ngFor="let a of details.allRemarks"
                    class="itemList"
                  >
                    <td>
                      {{ a.remarks_date }}
                    </td>
                    <td>
                      {{ a.remarks }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!-- Upload Doc Modal -->
      <div
        class="modal fade modal-bx-info editor"
        id="uploadDoc"
        tabindex="-1"
        role="dialog"
        aria-labelledby="EducationModalLongTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="EducationModalLongTitle">
                Upload Document
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                #uploadDocModal
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="details">
              <form style="margin-top: 15px; margin-bottom: 15px">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Document Name</label>
                      <select
                        class="form-control"
                        #docValue
                        (change)="onSelectedDoc(docValue.value)"
                        [(ngModel)]="doc_id"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <ng-container *ngFor="let data of docType">
                          <option
                            *ngIf="
                              data.doc_name !== 'facility_spec'
                            "
                            [value]="data.doc_id"
                            style="text-transform: capitalize"
                          >
                            {{ data.doc_name }}
                          </option>
                        </ng-container>
                      </select>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter document name"
                        [(ngModel)]="doc_name"
                        onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                        [ngModelOptions]="{ standalone: true }"
                        *ngIf="showSecInput"
                        style="margin-top: 10px"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Upload File</label>
                      <div style="display: flex">
                        <div class="custom-file" style="width: 60%">
                          <p class="m-a0" *ngIf="!file_name">
                            <i class="fa fa-upload"></i>
                            Upload File
                          </p>

                          <p class="m-a0" *ngIf="file_name">
                            {{ file_name }}
                          </p>
                          <input
                            type="file"
                            class="site-button form-control"
                            id="customFile"
                            (change)="
                              handleFileInput($event.target.files)
                            "
                          />
                        </div>
                        <div
                          *ngIf="showProgressBar"
                          style="margin-top: -20px"
                        >
                          <circle-progress
                            [percent]="showPercentage"
                            [radius]="40"
                            [outerStrokeWidth]="6"
                            [innerStrokeWidth]="4"
                            [outerStrokeColor]="'#4C96D7'"
                            [innerStrokeColor]="'#a6cbeb'"
                            [animation]="false"
                            [animationDuration]="10"
                            [titleFontSize]="10"
                            [renderOnClick]="false"
                          >
                          </circle-progress>
                        </div>
                      </div>
                      <div
                        *ngIf="viewShow === 'true'"
                        class="alert alert-success"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div>Document uploaded successfully.</div>
                      </div>

                      <div
                        *ngIf="viewfinalErr"
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div>
                          Something went wrong,please try again.
                        </div>
                      </div>

                      <div
                        *ngIf="viewShow === 'false'"
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div *ngIf="viewShow === 'false'" class="">
                          Document can not be uploaded,please try
                          again.
                        </div>
                      </div>
                      <div
                        *ngIf="viewShow === 'fileSizeError'"
                        class="alert alert-danger"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div
                          *ngIf="viewShow === 'fileSizeError'"
                          class=""
                        >
                          File Size cannot exceed the maximum limit
                          of 25 mb.
                        </div>
                      </div>
                      <div
                        *ngIf="viewShow === 'try'"
                        class="alert alert-warning"
                        style="
                          font-size: 12px;
                          padding: 0.5rem 1.25rem;
                        "
                      >
                        <div *ngIf="viewShow === 'try'" class="">
                          Uploading in process...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                (click)="uploadFileToActivity()"
                [disabled]="doc_name === '' || !fileToUpload"
                [ngStyle]="{
                  cursor:
                    doc_name === '' || !fileToUpload
                      ? 'not-allowed'
                      : 'pointer'
                }"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

      <!--Change Status Modal -->
      <div
        class="modal fade modal-bx-info editor"
        id="changeStatus"
        tabindex="-1"
        role="dialog"
        aria-labelledby="changeStatusTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="changeStatusTitle">
                Offer Accept/Reject
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                #closeStatusModal
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <!-- <label>Status</label> -->
                      <div class="row">
                        <div
                          class="col-lg-4 col-md-4 col-sm-4 col-4"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="offer_accepted"
                              name="accept1"
                              [(ngModel)]="job_offer_status"
                              value="offer_accepted"
                            />
                            <label
                              class="custom-control-label"
                              for="offer_accepted"
                              >Accept</label
                            >
                          </div>
                        </div>
                        <div
                          class="col-lg-4 col-md-4 col-sm-4 col-4"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="offer_declined"
                              name="decline1"
                              [(ngModel)]="job_offer_status"
                              value="offer_declined"
                            />
                            <label
                              class="custom-control-label"
                              for="offer_declined"
                              >Reject</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="site-button"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="button"
                class="site-button"
                (click)="changeStatus()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->
    </div>
  </div>
</app-admin-default-layout>