import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Country, State, City } from 'country-state-city';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-jobs',
  templateUrl: './manage-jobs.component.html',
  styleUrls: ['./manage-jobs.component.scss'],
})
export class ManageJobsComponent implements OnInit {
  @ViewChild('closeEdit', { static: false }) private closeEdit: ElementRef;
  @ViewChild('closeStatusModal', { static: false })
  private closeStatusModal: ElementRef;
  @ViewChild('closeDeleteModal', { static: false })
  private closeDeleteModal: ElementRef;

  get apiUrl(): string {
    return environment.apiUrl;
  }

  filteredCity: any = [];
  /*paginate */
  public count: any = 20;
  public page: any;
  /**paginate  */
  jobList: any = [];
  details: any;
  moduleArray: any;
  jobType: any = [];
  positiontype: any = [];
  jobSector: any = [];
  clientList: any = [];
  systemList: any = [];

  job_desc: any;
  req_info: any;
  job_status: any;
  job_id: any;
  job_title: any;
  job_No: any;
  job_state: any;
  job_city: any;

  minimum_rate: any;
  maximum_rate: any;
  rate: any;

  position_type: any;
  job_type: any;
  job_sector: any;
  client_id: any;
  system_name: any;
  EditJobForm: UntypedFormGroup;
  job_duration: any;
  shit_details: any = [];
  shiftArry: any = [{ shift: 'Day' }, { shift: 'Night' }, { shift: 'Evening' }];
  filterArray: any = [];
  search_data: any;
  user_id: any;
  excelfileName: any;
  job_status_select: any = 'open';
  confirmed_hr: any;
  jobIDData: any;
  jobPostedByData: any;
  states: any = [];
  stateList: boolean = false;
  filterArrayState: any = [];
  cityList: boolean = false;
  filterArrayCity: any = [];

  state = new UntypedFormControl(null, [Validators.required])
  city = new UntypedFormControl(null, [Validators.required])

  datePickerConfig = <IDayCalendarConfig>{
    drops: 'down',
    format: 'MM/DD/YYYY',
  };
  delete_date: any;
  user_type: any;

  constructor(
    public http: AdminService,
    public route: ActivatedRoute,
    public router: Router,
    public fb: UntypedFormBuilder
  ) {
    this.user_id = sessionStorage.getItem('user_id');
    this.excelfileName =
      'manage_jobs(' + moment(new Date()).format('MM-DD-YYYY') + ')';
    this.user_type = sessionStorage.getItem('user_type');
    this.EditJobForm = this.fb.group({
      job_id: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      state: this.state,
      city: this.city,
      job_title: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      job_desc: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(1000),
      ]),

      minimum_rate: new UntypedFormControl(null, [
        Validators.required,
        Validators.max(999999999999999999999999999999),
      ]),
      maximum_rate: new UntypedFormControl(null, [
        Validators.required,
        Validators.max(999999999999999999999999999999),
      ]),
      rate: new UntypedFormControl(null, [ Validators.required]),

      position: new UntypedFormControl(null),
      job_type: new UntypedFormControl(null),
      job_sector: new UntypedFormControl(null, [Validators.required]),
      system_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(200),
      ]),
      client_name: new UntypedFormControl(null, [Validators.required]),
      req_info: new UntypedFormControl(null, Validators.maxLength(1000)),
      job_duration: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(50),
      ])
    });
  }

  ngOnInit() {
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
    if (this.job_status_select === 'open') {
      this.getAllJob();
    }
    this.state.enable();
    this.states = State.getStatesOfCountry("US");
    this.filterArrayState = State.getStatesOfCountry("US");
    this.state.valueChanges.subscribe((state) => {
      // this.city.reset();
      this.city.disable();
      if (state) {
        this.filteredCity = City.getCitiesOfState('US', state);
        this.city.enable();
      }
      
    });
    this.getAllManageDetails();
  }

  getSortedJobTypes(data:any[], type:string) {
    let filter = []
    if(type == "positionType"){
      filter = data.slice().sort((a,b) => a.position_type_name.localeCompare(b.position_type_name));
    }else if(type == "jobType"){
      filter = data.slice().sort((a,b) => a.job_type_name.localeCompare(b.job_type_name));
    }else if(type == "jobSector"){
      filter = data.slice().sort((a,b) => a.job_sector_name.localeCompare(b.job_sector_name));
    }else if(type == "clientList"){
      filter = data.slice().sort((a,b) => a.client_name.localeCompare(b.client_name));
    }else if(type == "systemList"){
      filter = data.slice().sort((a,b) => a.system_name.localeCompare(b.system_name));
    }
    return filter
  }
  /////////////////////////////
  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }

  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  getAllJob() {
    this.jobList = [];
    let body = {
      status: this.job_status_select,
    };
    this.http.getAllJobs(body).subscribe(
      (res: any) => {
        //// //console.log(res);
        if (res.length !== 0) {
          this.jobList = res;
          this.filterArray = res;
        } else {
          Swal.fire({
            title: 'No search result found!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      },
      (err) => {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    );
  }

  onJobSelected(val) {
    this.http.spinnerShow();
    this.jobList = [];
    let body = {
      status: val,
    };
    this.http.getAllJobs(body).subscribe(
      (res: any) => {
        //// //console.log(res);
        if (res.length !== 0) {
          this.http.spinnerHide();
          this.jobList = res;
          this.filterArray = res;
        } else {
          this.http.spinnerHide();
          Swal.fire({
            title: 'No search result found!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      },
      (err) => {
        this.http.spinnerHide();
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    );
  }

  get searchData() {
    return this.search_data;
  }

  set searchData(value) {
    this.search_data = value;
    this.jobList = this.search_data
      ? this.filterList(this.search_data)
      : this.filterArray;
  }

  filterList(filterby) {
    filterby = filterby.toLocaleLowerCase();
    return this.filterArray.filter(
      (list: any) =>
        list.job_title.toLocaleLowerCase().indexOf(filterby) !== -1 ||
        //list.job_type_name.toLocaleLowerCase().indexOf(filterby) !== -1 ||
        list.state.toLocaleLowerCase().indexOf(filterby) !== -1 ||
        list.city.toLocaleLowerCase().indexOf(filterby) !== -1
    );
  }

  get jobID() {
    return this.jobIDData;
  }

  set jobID(val) {
    this.jobIDData = val;
    this.jobList = this.jobIDData
      ? this.filterListforjobID(this.jobIDData)
      : this.filterArray;
  }

  filterListforjobID(filterby) {
    filterby = filterby.toLocaleLowerCase();
    return this.filterArray.filter(
      (list: any) => list.job_no.toLocaleLowerCase().indexOf(filterby) !== -1
    );
  }

  get job_posted_by() {
    return this.jobPostedByData;
  }

  set job_posted_by(val) {
    this.jobPostedByData = val;
    this.jobList = this.jobPostedByData
      ? this.filterListforjobPostBy(this.jobPostedByData)
      : this.filterArray;
    //// //console.log(this.jobList)
  }

  filterListforjobPostBy(filterby) {
    filterby = filterby.toLocaleLowerCase();
    return this.filterArray.filter(
      (list: any) =>
        list.job_post_by_first_name.toLocaleLowerCase().indexOf(filterby) !==
          -1 ||
        list.job_post_by_last_name.toLocaleLowerCase().indexOf(filterby) !== -1
    );
  }

  positionTypeData: any;
  get position_type_name() {
    return this.positionTypeData;
  }

  set position_type_name(val) {
    this.positionTypeData = val;
    this.jobList = this.positionTypeData
      ? this.filterListforPosition(this.positionTypeData)
      : this.filterArray;
    // //// //console.log(this.jobList)
  }

  filterListforPosition(filterby) {
    filterby = filterby.toLocaleLowerCase();
    return this.filterArray.filter(
      (list: any) =>
        list.position_type_name.toLocaleLowerCase().indexOf(filterby) !== -1
      //list.position_type.toLocaleLowerCase().indexOf(filterby) !== -1
    );
  }

  viewJob(val) {
    this.details = '';
    this.details = val;
    //// //console.log(this.details)
  }

  getAllManageDetails() {
    this.jobType = [];
    this.positiontype = [];
    this.jobSector = [];
    this.clientList = [];
    this.systemList = [];

    this.http.getAllManageJobDetails().subscribe((res: any) => {
      if(res.success){
        this.jobType = res.data.job_type;
        this.positiontype = res.data.position_type;
        this.jobSector = res.data.job_sector;
        this.clientList = res.data.client;
        this.systemList = res.data.jobclients;
      }
    });
  }

  // getJobType() {
  //   this.jobType = [];
  //   this.http.getAlljobType().subscribe((res: any) => {
  //     res.forEach((e) => {
  //       if (e.job_type_status === 'active') {
  //         this.jobType.push(e);
  //       }
  //     });
  //   });
  // }

  // getPositionType() {
  //   this.positiontype = [];
  //   this.http.getAllPositionType().subscribe((res: any) => {
  //     res.forEach((e) => {
  //       if (e.position_type_status === 'active') {
  //         this.positiontype.push(e);
  //       }
  //     });
  //   });
  // }

  // getAlljobSector() {
  //   this.jobSector = [];
  //   this.http.getAlljobSector().subscribe((res: any) => {
  //     res.forEach((e) => {
  //       if (e.job_sector_status === 'active') {
  //         this.jobSector.push(e);
  //       }
  //     });
  //   });
  // }

  // getAllClients() {
  //   this.clientList = [];
  //   this.http.getClients().subscribe((res: any) => {
  //     this.clientList = res;
  //   });
  // }

  // getAllSystemNames() {
  //   this.systemList = [];
  //   this.http.getSystems().subscribe((res: any) => {
  //     this.systemList = res;
  //   });
  // }

  EditJob(val) {
    this.details = '';
    this.stateList = false;
    this.cityList = false;
    this.shit_details = [];
    this.details = val;
    this.job_state = this.details.state;

    let array = this.details.shift.split(',');
    for (var i in this.shiftArry) {
      this.shiftArry[i].value = false;
      if (array.indexOf(this.shiftArry[i].shift) > -1) {
        this.shit_details.push(this.shiftArry[i].shift);
        this.shiftArry[i].value = true;
      }
    }

    let editSelectedState = this.states.filter(el => {
      if(el.name == this.job_state){
        this.filteredCity = City.getCitiesOfState('US', el.isoCode);
        this.filterArrayCity = City.getCitiesOfState('US', el.isoCode);
        return el
      }
    })
    
    this.EditJobForm.get('city').setValue(this.details.city);
    this.EditJobForm.get('state').setValue(editSelectedState && editSelectedState.length > 0 ? editSelectedState[0].isoCode : null);

    this.job_desc = this.details.job_description;
    this.req_info = this.details.req_information;
    this.job_status = this.details.job_status;
    this.job_title = this.details.job_title;
    this.job_No = this.details.job_no;

    this.minimum_rate = this.details.minimum_rate,
    this.maximum_rate = this.details.maximum_rate,
    this.rate = this.details.rate,

    this.position_type = this.details.position_type;
    this.job_type = this.details.job_type;
    this.job_sector = this.details.job_sector;
    this.client_id = this.details.client_id;
    this.system_name = this.details.system_name;
    this.job_id = this.details.job_id;
    this.job_duration = this.details.duration;
    
  }

  checkBoxSelect(value) {
    let index = this.shit_details.indexOf(value);
    if (index > -1) {
      this.shit_details.splice(index, 1);
    } else {
      this.shit_details.push(value);
    }
  }

  changeStatus() {
    this.http.spinnerShow();
    let data = {
      job_id: this.job_id,
      job_status: this.job_status,
    };
    this.http.changeJobStatus(data).subscribe(
      (res: any) => {
        if (res === 'success') {
          this.http.spinnerHide();
          this.closeStatusModal.nativeElement.click();
          Swal.fire({
            title: 'Status changed successfully.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.getAllJob();
            }
          });
        } else {
          this.http.spinnerHide();
          this.closeStatusModal.nativeElement.click();
          Swal.fire({
            title: 'Something went wrong,please try again.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      },
      (err) => {
        this.http.spinnerHide();
        this.closeStatusModal.nativeElement.click();
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    );
  }

  updateDetails() {
    let editSelectedState = this.states.filter(el => el.isoCode == this.EditJobForm.controls['state'].value)
    let job_no = ''
    if(this.EditJobForm.controls['job_id'].value.startsWith("VCS101")){
      job_no = this.EditJobForm.controls['job_id'].value
    }else {
      job_no = `VCS101${this.EditJobForm.controls['job_id'].value}`
    }
    let date = new Date();
    let strTime = date.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    });
    let data = {
      job_id: this.job_id,
      position_type: this.EditJobForm.controls['position'].value,
      system_name: this.EditJobForm.controls['system_name'].value,
      client_id: this.EditJobForm.controls['client_name'].value,
      job_no: job_no,
      job_title: this.EditJobForm.controls['job_title'].value,
      job_type: this.EditJobForm.controls['job_type'].value,
      country: 'USA',
      state: editSelectedState && editSelectedState.length > 0 ? editSelectedState[0].name : null,
      city: this.EditJobForm.controls['city'].value,

      minimum_rate: this.EditJobForm.controls['minimum_rate'].value,
      maximum_rate: this.EditJobForm.controls['maximum_rate'].value,
      rate: this.EditJobForm.controls['rate'].value,

      job_description: this.EditJobForm.controls['job_desc'].value,
      job_post_edit_by: sessionStorage.getItem('user_id'),
      job_post_edit_date: moment(strTime).format('MM/DD/YYYY'),
      req_information: this.EditJobForm.controls['req_info'].value,
      job_sector: this.EditJobForm.controls['job_sector'].value,
      duration: this.EditJobForm.controls['job_duration'].value,
      shift: this.shit_details.join(','),

    };

    if(this.EditJobForm.valid){
      this.http.updateJobDetails(data).subscribe(
        (res: any) => {
          if (res === 'success') {
            Swal.fire({
              title: 'Info updated successfully.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
                if (result.isConfirmed) {
                this.closeEdit.nativeElement.click();
                this.getAllJob();
              }
            });
          } else {
            Swal.fire({
              title: 'Something went wrong,please try again.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.closeEdit.nativeElement.click();
              }
            });
          }
        },
        (err) => {
          Swal.fire({
            title: 'Something went wrong,please try again.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.closeEdit.nativeElement.click();
            }
          });
        }
      );
    }
  }

  search(event) {
    const cols = document.querySelectorAll('.itemList');
    const query = event.target.value.toLowerCase();
    requestAnimationFrame(() => {
      [].forEach.call(cols, (e) => {
        const shouldShow = e.textContent.toLowerCase().indexOf(query) > -1;
        e.style.display = shouldShow ? 'block' : 'none';
      });
    });
  }

  job_id_list: any = [];
  selectJobsToDelete(value) {
    let index = this.job_id_list.indexOf(value);
    if (index > -1) {
      this.job_id_list.splice(index, 1);
    } else {
      this.job_id_list.push(value);
    }
  }

  delete_type: any;
  deleteJobs() {
    if (this.delete_type === 'delete_by_id') {
      let data = {
        job_id: this.job_id_list,
      };
      this.http.jobDeleteById(data).subscribe(
        (res: any) => {
          if (res === 'success') {
            Swal.fire({
              title: 'Job deleted successfully.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.closeDeleteModal.nativeElement.click();
                this.getAllJob();
              }
            });
          } else {
            Swal.fire({
              title: 'Something went wrong,please try again.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.closeDeleteModal.nativeElement.click();
              }
            });
          }
        },
        (err) => {
          Swal.fire({
            title: 'Something went wrong,please try again.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.closeDeleteModal.nativeElement.click();
            }
          });
        }
      );
    } else if (this.delete_type === 'delete_by_date') {
      let data = {
        job_delete_date: moment(this.delete_date).format('MM/DD/YYYY'),
      };
      this.http.jobDeleteByDate(data).subscribe(
        (res: any) => {
          if (res === 'success') {
            Swal.fire({
              title: 'Job deleted successfully.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.closeDeleteModal.nativeElement.click();
                this.getAllJob();
              }
            });
          } else {
            Swal.fire({
              title: 'Something went wrong,please try again.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#4C96D7',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.closeDeleteModal.nativeElement.click();
              }
            });
          }
        },
        (err) => {
          Swal.fire({
            title: 'Something went wrong,please try again.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4C96D7',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.closeDeleteModal.nativeElement.click();
            }
          });
        }
      );
    }
  }

  generateReport() {
    const reportUrl = `${this.apiUrl}vcsapi/api/generate/excel/manage/jobPost/${this.user_id}/${this.excelfileName}`;
    window.location.href = reportUrl;
  }
}
 