import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';
import { DropdownServiceService } from './dropdown-service.service';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent implements OnInit {
  allProfessionData: any;
  allSpecialityData: any;
  allJobSectorData: any;
  allPositionTypeData: any;
  allJobTypeData: any;
  allSystemNameData: any;
  allStandardDocumentData: any;
  allGetDesignation: any;
  option_name: string = '';
  type_vendor: any = [];

  hideShowHeader: boolean = false;

  addHeaderShow: any;

  addDropDownList: UntypedFormGroup;
  editDropDownList: UntypedFormGroup;

  editHeaderShow: any;

  editCommonId: any;

  defaultStatus: any = 'active';
  moduleArray: any = [];

  addDesignationList: UntypedFormGroup;
  editDesignationList: UntypedFormGroup;

  constructor(
    public service: DropdownServiceService,
    public fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public http: AdminService
  ) {}

  ngOnInit() {
    /** spinner starts on init */
    this.http.spinnerShow();
    setTimeout(() => {
      this.http.spinnerHide();
    }, 900);
    this.getDataProfession();
    this.getDataSpeciality();
    this.getDataJobSector();
    this.getDataPositionType();
    this.getDataJobType();
    this.getDataSystemName();
    this.getDataStandardDocument();
    this.getDesignationData();
    this.getDataTypesVendor();

    this.addDropDownList = this.fb.group({
      name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(80),
      ]),
    });

    this.editDropDownList = this.fb.group({
      edit_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(80),
      ]),
    });

    this.addDesignationList = this.fb.group({
      name: new UntypedFormControl(null, [Validators.required,Validators.maxLength(80)]),
      incentive_perc: new UntypedFormControl(),
    });

    this.editDesignationList = this.fb.group({
      edit_name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(80)]),
      edit_incentive_perc: new UntypedFormControl(),
    });

  }

  ///////////////////////

  changeOptionHeading(val: string) {
    this.option_name = val
  }
  
  navigateTo(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(val.module_id),
      },
    };
    this.router.navigate([val.routing], navigationExtras);
  }

  /////////////

  headerSHowFunctions(value) {
    this.hideShowHeader = value;
  }
  handleNewDropList(value: boolean, type: string) {
    this.headerSHowFunctions(value)
    this.changeOptionHeading(type)
  }

  /// add button

  addButtonHit(header) {
    this.addHeaderShow = header;
    this.addDropDownList.reset();
  }

  addsubmitHit() {
    if (this.addHeaderShow === 'Profession') {
      this.insertProfession();
    } else if (this.addHeaderShow === 'Speciality') {
      this.insertSpeciality();
    } else if (this.addHeaderShow === 'Job Sector') {
      this.insertJobSector();
    } else if (this.addHeaderShow === 'Position Type') {
      this.insertPositionType();
    } else if (this.addHeaderShow === 'Job Type') {
      this.insertJobType();
    } else if (this.addHeaderShow === 'System Name') {
      this.insertSystemName();
    } else if (this.addHeaderShow === 'Standard Document') {
      this.insertStandardDocument();
    } else if (this.addHeaderShow === 'Designation') {
      this.insertDesignation();
    } else if (this.addHeaderShow === 'Type' || this.addHeaderShow === 'Vendor') {
      this.insertTypeVendor(this.addHeaderShow);
    }
  }

  /// edit  button

  editButtonHit(header, value) {
    this.editHeaderShow = header;
    if (this.editHeaderShow === 'Profession') {
      this.editCommonId = value.profession_id;
      this.defaultStatus = value.profession_status;
      this.editDropDownList.setValue({
        edit_name: value.profession_name,
      });
    } else if (this.editHeaderShow === 'Speciality') {
      this.editCommonId = value.speciality_id;
      this.defaultStatus = value.speciality_status;
      this.editDropDownList.setValue({
        edit_name: value.speciality_name,
      });
    } else if (this.editHeaderShow === 'Job Sector') {
      this.editCommonId = value.job_sector_id;
      this.defaultStatus = value.job_sector_status;
      this.editDropDownList.setValue({
        edit_name: value.job_sector_name,
      });
    } else if (this.editHeaderShow === 'Position Type') {
      this.editCommonId = value.position_type_id;
      this.defaultStatus = value.position_type_status;

      this.editDropDownList.setValue({
        edit_name: value.position_type_name,
      });
    } else if (this.editHeaderShow === 'Job Type') {
      this.editCommonId = value.job_type_id;
      this.defaultStatus = value.job_type_status;
      this.editDropDownList.setValue({
        edit_name: value.job_type_name,
      });
    } else if (this.editHeaderShow === 'System Name') {
      this.editCommonId = value.system_name_id;
      this.defaultStatus = value.system_name_status;
      this.editDropDownList.setValue({
        edit_name: value.system_name,
      });
    } else if (this.editHeaderShow === 'Standard Document') {
      this.editCommonId = value.doc_id;
      this.defaultStatus = value.doc_status;
      this.editDropDownList.setValue({
        edit_name: value.doc_name,
      });
    } else if (this.editHeaderShow === 'Designation') {
      this.editCommonId = value.designation_id;
      this.defaultStatus = value.designation_status;
      this.editDesignationList.setValue({
        edit_name: value.designation_name,
        edit_incentive_perc: value.incentive_percentage,
      });
    } else if (this.editHeaderShow === 'Type' || this.editHeaderShow === 'Vendor') {
      this.editCommonId = value.tv_id;
      this.defaultStatus = value.status;
      this.editDropDownList.setValue({
        edit_name: value.name
      });
    }
  }

  editsubmitHit() {
    if (this.editHeaderShow === 'Profession') {
      this.updateProfession();
    } else if (this.editHeaderShow === 'Speciality') {
      this.updateSpeciality();
    } else if (this.editHeaderShow === 'Job Sector') {
      this.updateJobSector();
    } else if (this.editHeaderShow === 'Position Type') {
      this.updatePositionType();
    } else if (this.editHeaderShow === 'Job Type') {
      this.updateJobType();
    } else if (this.editHeaderShow === 'System Name') {
      this.updateSystemName();
    } else if (this.editHeaderShow === 'Standard Document') {
      this.updateStandardDocument();
    } else if (this.editHeaderShow === 'Designation') {
      this.updateDesignation();
    } else if (this.editHeaderShow === 'Type' || this.editHeaderShow === 'Vendor') {
      this.updateTypeVendor(this.editHeaderShow);
    }
  }

   //delete
   deleteBtntHit(header, value) {
    this.editHeaderShow = header;
    if (this.editHeaderShow === 'Profession') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            profession_id: value.profession_id
          }
          this.service.deleteApiProfession(data).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg("Profession deteted successfully.");
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
          }, err => {
           
            this.errorMsg("Something went wrong,please try again!");
          })
        }
      });
      //this.updateProfession();
    } else if (this.editHeaderShow === 'Speciality') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            speciality_id: value.speciality_id
          }
          this.service.deleteApiSpeciality(data).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg("Speciality deteted successfully.");
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
          }, err => {
           
            this.errorMsg("Something went wrong,please try again!");
          })
        }
      });
      //this.updateSpeciality();
    } else if (this.editHeaderShow === 'Job Sector') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            job_sector_id: value.job_sector_id
          }
          this.service.deleteApiJobSector(data).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg("Job sector deteted successfully.");
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
          }, err => {
           
            this.errorMsg("Something went wrong,please try again!");
          })
        }
      });
      //this.updateJobSector();
    } else if (this.editHeaderShow === 'Position Type') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            position_type_id: value.position_type_id
          }
          this.service.deleteApiPositionType(data).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg("Position type deteted successfully.");
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
          }, err => {
           
            this.errorMsg("Something went wrong,please try again!");
          })
        }
      });
     // this.updatePositionType();
    } else if (this.editHeaderShow === 'Job Type') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            job_type_id: value.job_type_id
          }
          this.service.deleteApiJobType(data).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg("Job type deteted successfully.");
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
          }, err => {
           
            this.errorMsg("Something went wrong,please try again!");
          })
        }
      });
      //this.updateJobType();
    } else if (this.editHeaderShow === 'System Name') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            system_name_id: value.system_name_id
          }
          this.service.deleteApiSystemName(data).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg("System name deteted successfully.");
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
          }, err => {
           
            this.errorMsg("Something went wrong,please try again!");
          })
        }
      });
      //this.updateSystemName();
    } else if (this.editHeaderShow === 'Standard Document') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            doc_id: value.doc_id
          }
          this.service.deleteApiStandardDocument(data).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg("Standard document deteted successfully.");
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
          }, err => {
           
            this.errorMsg("Something went wrong,please try again!");
          })
        }
      });
      //this.updateStandardDocument();
    } else if (this.editHeaderShow === 'Designation') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            designation_id: value.designation_id
          }
          this.service.deleteApiDesignation(data).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg("Designation deteted successfully.");
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
          }, err => {
           
            this.errorMsg("Something went wrong,please try again!");
          })
        }
      });
      //this.updateDesignation();
    } else if (this.editHeaderShow === 'Type' || this.editHeaderShow === 'Vendor') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4C96D7',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            id: value.tv_id
          }
          this.service.deleteApiTypeVendor(data).subscribe(res => {
            if (res["message"] === 'OK') {
              this.successMsg(`${this.editHeaderShow} deteted successfully.`, this.editHeaderShow);
            }
            else {
              this.errorMsg("Something went wrong,please try again!");
            }
          }, err => {
           
            this.errorMsg("Something went wrong,please try again!");
          })
        }
      });
    }

  }

  errorMsg(msg) {
    Swal.fire({
      title: msg,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
  }

  successMsg(msg, type:string = null) {
    Swal.fire({
      title: msg,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#4C96D7',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        if(type === 'Type' || type === 'Vendor') {
          this.getDataTypesVendor()
        }else{
          //window.location.reload();
          this.getDataProfession();
          this.getDataSpeciality();
          this.getDataJobSector();
          this.getDataPositionType();
          this.getDataJobType();
          this.getDataSystemName();
          this.getDataStandardDocument();
          this.getDesignationData();
        }
      }
    })
  }

  /// status button
  statussubmitHit() {
    if (this.editHeaderShow === 'Profession') {
      this.statusProfession();
    } else if (this.editHeaderShow === 'Speciality') {
      this.statusSpeciality();
    } else if (this.editHeaderShow === 'Job Sector') {
      this.statusJobSector();
    } else if (this.editHeaderShow === 'Position Type') {
      this.statusPositionType();
    } else if (this.editHeaderShow === 'Job Type') {
      this.statusJobType();
    } else if (this.editHeaderShow === 'System Name') {
      this.statusSystemName();
    } else if (this.editHeaderShow === 'Standard Document') {
      this.statusStandardDocument();
    } else if (this.editHeaderShow === 'Designation') {
      this.statusDesignation();
    } else if (this.editHeaderShow === 'Type' || this.editHeaderShow === 'Vendor') {
      this.statusTypeVendor();
    }
  }

  getDataTypesVendor() {
    this.service.getApiTypesVendor().subscribe((r) => {
      this.type_vendor = r;
    });
  }

  getDataProfession() {
    this.service.getApiProfession().subscribe((r) => {
     
      this.allProfessionData = r;
    });
  }

  getDataSpeciality() {
    this.service.getApiSpeciality().subscribe((r) => {
     
      this.allSpecialityData = r;
    });
  }

  getDataJobSector() {
    this.service.getApiJobSector().subscribe((r) => {
     
      this.allJobSectorData = r;
    });
  }

  getDataPositionType() {
    this.service.getApiPositionType().subscribe((r) => {
     
      this.allPositionTypeData = r;
    });
  }

  getDataJobType() {
    this.service.getApiJobType().subscribe((r) => {
     
      this.allJobTypeData = r;
    });
  }

  getDataSystemName() {
    this.service.getApiSystemName().subscribe((r) => {
     
      this.allSystemNameData = r;
    });
  }

  getDataStandardDocument() {
    this.service.getApiStandardDocument().subscribe((r) => {
     
      this.allStandardDocumentData = r;
    });
  }

  getDesignationData() {
    this.service.getApiDesignation().subscribe((r) => {
     
      this.allGetDesignation = r;
    });
  }

  // insert Api work

  insertTypeVendor(type: string) {
    const data = {
      name: this.addDropDownList.controls['name'].value,
      type: type,
    };
    this.service.addApiTypeVendor(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: `${type} Added successfully.`,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDropDownList.reset();
            this.getDataTypesVendor();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  insertProfession() {
    const data = {
      profession_name: this.addDropDownList.controls['name'].value,
    };
    this.service.addApiProfession(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Profession Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDropDownList.reset();
            this.getDataProfession();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  insertSpeciality() {
    const data = {
      speciality_name: this.addDropDownList.controls['name'].value,
    };
    this.service.addApiSpeciality(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Speciality Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDropDownList.reset();
            this.getDataSpeciality();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  insertJobType() {
    const data = {
      job_type_name: this.addDropDownList.controls['name'].value,
    };
    this.service.addApiJobType(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Job Type Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDropDownList.reset();
            this.getDataJobType();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  insertJobSector() {
    const data = {
      job_sector_name: this.addDropDownList.controls['name'].value,
    };
    this.service.addApiJobSector(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Job Sector Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDropDownList.reset();
            this.getDataJobSector();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  insertPositionType() {
    const data = {
      position_type_name: this.addDropDownList.controls['name'].value,
    };
    this.service.addApiPositionType(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Position Type Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDropDownList.reset();
            this.getDataPositionType();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  insertSystemName() {
    const data = {
      system_name: this.addDropDownList.controls['name'].value,
    };
    this.service.addApiSystemName(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'System Name Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDropDownList.reset();
            this.getDataSystemName();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  insertStandardDocument() {
    const data = {
      doc_name: this.addDropDownList.controls['name'].value,
    };
    this.service.addApiDocumentName(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Standard Document Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDropDownList.reset();
            this.getDataStandardDocument();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  checkUniqueDesignation() {
    if (this.addHeaderShow === 'Designation') {
      this.service
        .checkUniqueDesignation(this.addDropDownList.controls['name'].value)
        .subscribe(
          (res: any) => {
           
            if (res === 'exist') {
              Swal.fire({
                title: 'Designation Name already exist!',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#4C96D7',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  this.addDropDownList.reset();
                  this.editDropDownList.reset();
                }
              });
            } else if (res === 'do not exist') {
            } else {
              Swal.fire({
                title: 'Something went wrong,please try again.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#4C96D7',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              }).then((result) => {
                if (result.isConfirmed) {
                }
              });
            }
          },
          (err) => {}
        );
    }
  }

  checkUniqueDesignationEdit() {
    if (this.editHeaderShow === 'Designation') {
      this.service
        .checkUniqueDesignation(
          this.editDropDownList.controls['edit_name'].value
        )
        .subscribe(
          (res: any) => {
           
            if (res === 'exist') {
              Swal.fire({
                title: 'Designation Name already exist!',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#4C96D7',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  this.editDropDownList.reset();
                }
              });
            } else if (res === 'do not exist') {
            } else {
              Swal.fire({
                title: 'Something went wrong,please try again.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#4C96D7',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              }).then((result) => {
                if (result.isConfirmed) {
                }
              });
            }
          },
          (err) => {}
        );
    }
  }

  insertDesignation() {
    const data = {
      designation_name: this.addDesignationList.controls['name'].value,
      incentive_perc: this.addDesignationList.controls['incentive_perc'].value,
    };
    this.service.addApiDesignationName(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Designation Added successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.addDesignationList.reset();
            this.getDesignationData();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  //// update api work

  updateTypeVendor(type: string) {
    const data = {
      name: this.editDropDownList.controls['edit_name'].value,
      id: this.editCommonId,
    };
    this.service.editApiTypeVendor(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: `${type} updated successfully.`,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDataTypesVendor();
            this.editDropDownList.reset();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }
  updateProfession() {
    const data = {
      profession_name: this.editDropDownList.controls['edit_name'].value,
      profession_id: this.editCommonId,
    };
    this.service.editApiProfession(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Profession updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDropDownList.reset();
            this.getDataProfession();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  updateSpeciality() {
    const data = {
      speciality_name: this.editDropDownList.controls['edit_name'].value,
      speciality_id: this.editCommonId,
    };
    this.service.editApiSpeciality(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Speciality Updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDropDownList.reset();
            this.getDataSpeciality();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  updateJobType() {
    const data = {
      job_type_name: this.editDropDownList.controls['edit_name'].value,
      job_type_id: this.editCommonId,
    };
    this.service.editApiJobType(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Job Type updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDropDownList.reset();
            this.getDataJobType();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  updateJobSector() {
    const data = {
      job_sector_name: this.editDropDownList.controls['edit_name'].value,
      job_sector_id: this.editCommonId,
    };
    this.service.editApiJobSector(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Job Sector Updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDropDownList.reset();
            this.getDataJobSector();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  updatePositionType() {
    const data = {
      position_type_name: this.editDropDownList.controls['edit_name'].value,
      position_type_id: this.editCommonId,
    };
    this.service.editApiPositionType(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Position Type updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDropDownList.reset();
            this.getDataPositionType();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  updateSystemName() {
    const data = {
      system_name: this.editDropDownList.controls['edit_name'].value,
      system_name_id: this.editCommonId,
    };
    this.service.editApiSystemName(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'System Name updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDropDownList.reset();
            this.getDataSystemName();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  updateStandardDocument() {
    const data = {
      doc_name: this.editDropDownList.controls['edit_name'].value,
      doc_id: this.editCommonId,
    };
    this.service.editApiStandardDocument(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Document updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDropDownList.reset();
            this.getDataStandardDocument();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  updateDesignation() {
    const data = {
      designation_name: this.editDesignationList.controls['edit_name'].value,
      designation_id: this.editCommonId,
      incentive_percentage: this.editDesignationList.controls['edit_incentive_perc'].value,
    };
    this.service.editApiDesignation(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Designation updated successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.editDesignationList.reset();
            this.getDesignationData();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  // status change api work

  statusTypeVendor() {
    const data = {
      status: this.defaultStatus,
      id: this.editCommonId
    };
    this.service.statusApiTypeVendor(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Status Change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDataTypesVendor();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  statusProfession() {
    const data = {
      profession_status: this.defaultStatus,
      profession_id: this.editCommonId,
    };
    this.service.statusApiProfession(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Status Change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDataProfession();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  statusSpeciality() {
    const data = {
      speciality_status: this.defaultStatus,
      speciality_id: this.editCommonId,
    };
    this.service.statusApiSpeciality(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Status Change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDataSpeciality();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  statusJobType() {
    const data = {
      job_type_status: this.defaultStatus,
      job_type_id: this.editCommonId,
    };
    this.service.statusApiJobType(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Status change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDataJobType();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  statusJobSector() {
    const data = {
      job_sector_status: this.defaultStatus,
      job_sector_id: this.editCommonId,
    };
    this.service.statusApiJobSector(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Status change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDataJobSector();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  statusPositionType() {
    const data = {
      position_type_status: this.defaultStatus,
      position_type_id: this.editCommonId,
    };
    this.service.statusApiPositionType(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Status change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDataPositionType();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  statusSystemName() {
    const data = {
      system_name_status: this.defaultStatus,
      system_name_id: this.editCommonId,
    };
    this.service.statusApiSystemName(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Status change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDataSystemName();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  statusStandardDocument() {
    const data = {
      doc_status: this.defaultStatus,
      doc_id: this.editCommonId,
    };
    this.service.statusApiStandardDocument(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Status change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDataStandardDocument();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }

  statusDesignation() {
    const data = {
      designation_status: this.defaultStatus,
      designation_id: this.editCommonId,
    };
    this.service.statusApiDesignation(data).subscribe((r) => {
     
      if (r === 'success') {
        Swal.fire({
          title: 'Status change successfully.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.getDesignationData();
          }
        });
      } else {
        Swal.fire({
          title: 'Something went wrong,please try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#4C96D7',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  }
}
