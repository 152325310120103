<div class="sticky-top">
      <div class="candidate-info company-info">
            <div class="candidate-detail text-center">
                  <div class="canditate-des">
                        <a href="javascript:void(0);">
                              <img alt="" src="../assets/images/logo/logo.png" />
                        </a>
                  </div>
                  <div class="candidate-title">
                        <h4 class="m-b5">
                              <a href="javascript:void(0);">
                                    {{ currentRoute === '/candi-profile' ?
                                                'My Profile'
                                          : currentRoute === '/my-documents' ?
                                                'My Documents'
                                          : currentRoute === '/password-change' ?
                                                'Change Password'
                                          : currentRoute === '/change-passcode' ?
                                                'Change Passcode'
                                          : currentRoute === '/edit-skill-set' ? 
                                                'Skill Set'
                                          : ''

                                    }}    
                              </a>
                        </h4>
                  </div>
            </div>
            <ul>
                  <li *ngIf="checkUserType">
                        <a class="oldclass" style="text-decoration: none" routerLink="/candi-profile"
                              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                              <i class="fa fa-briefcase" aria-hidden="true"></i>
                              <span>Profile</span>
                        </a>
                  </li>
                  <li *ngIf="checkUserType">
                        <a class="oldclass" style="text-decoration: none" routerLink="/my-documents"
                              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                              <i class="fa fa-briefcase" aria-hidden="true"></i>
                              <span>My Documents</span>
                              <span class="badge badge-danger" style="margin-left: 2%" *ngIf="badge">{{ badge }}</span>
                        </a>
                  </li>
                  <li *ngIf="checkUserType">
                        <a class="oldclass" style="text-decoration: none" routerLink="/password-change"
                              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                              <i class="fa fa-briefcase" aria-hidden="true"></i>
                              <span>Change Password</span>
                        </a>
                  </li>
                  <li *ngIf="checkUserType">
                        <a class="oldclass" style="text-decoration: none" routerLink="/change-passcode"
                              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                              <i class="fa fa-briefcase" aria-hidden="true"></i>
                              <span>Change Passcode</span>
                        </a>
                  </li>
                  <li *ngIf="checkUserType">
                        <a class="oldclass" style="text-decoration: none" routerLink="/edit-skill-set"
                              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                              <i class="fa fa-briefcase" aria-hidden="true"></i>
                              <span>Skill Set</span>
                        </a>
                  </li>
            </ul>
      </div>
</div>