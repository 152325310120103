<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx clearfix">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Income & Expenses
        </h5>
        <div class="float-right" style="display: flex">
        </div>
      </div>

      <div class="w-full">
        <!-- class="form-control" -->

        <div class="col-lg-6 col-md-6 mx-auto">
          <table class="">
            <tr>
              <td class="p-1">
                <label class="font-size-14 mr-2">Financial </label>
              </td>
              <td class="p-1">
                <div class="form-group flex justify-start items-center">
                  <!-- (change)="onOptionsSelected({type: 'financial', val: income_expense.value})"> -->
                  <select class="form-control" [(ngModel)]="selected_income_expense" #income_expense>
                    <option value="">Select Value</option>
                    <option value="+">Income</option>
                    <option value="-">Expense</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td class="p-1">
                <label class="font-size-14 mr-2">Finance Type</label>
              </td>
              <td class="p-1">
                <div class="form-group flex justify-start items-center">
                  <!-- (change)="onOptionsSelected({type: 'type', val: type1.value})" -->
                  <select class="form-control" [(ngModel)]="selected_type" #type1>
                    <option value="" selected>Select Type</option>
                    <option *ngFor="let data of type_vendor['Type']" [value]="data?.tv_id" style="text-transform: capitalize">
                      {{ data?.name }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td class="p-1">
                <label class="font-size-14 mr-2">Amount</label>
              </td>
              <td class="p-1">
                <div class="form-group flex justify-start items-center">
                  <input [ngStyle]="inputStyles" class="form-control" type="number"
                    placeholder="Enter Amount" min="0" [(ngModel)]="selected_amount"
                    [ngModelOptions]="{ standalone: true }" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="p-1">
                <label class="font-size-14 mr-2">Finance Vendor</label>
              </td>
              <td class="p-1">
                <div class="form-group flex justify-start items-center">
                  <!-- (change)="onOptionsSelected({type: 'vendor', val: vendor1.value})"> -->
                  <select class="form-control" [(ngModel)]="selected_vendor" #vendor1>
                    <option value="" selected>Select Vendor</option>
                    <option *ngFor="let data of type_vendor['Vendor']" [value]="data?.tv_id" style="text-transform: capitalize">
                      {{ data?.name }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td class="p-1">
                <label class="font-size-14 mr-2">Date</label>
              </td>
              <td class="p-1">
                <div class="form-group flex justify-start items-center">
                  <dp-date-picker dir="ltr" theme="dp-material" placeholder="MM/DD/YYYY" required="true"
                    [(ngModel)]="date" [config]="datePickerConfig">
                  </dp-date-picker>
                </div>
              </td>
            </tr>
            <tr>
              <td class="p-1">
                <label class="font-size-14 mr-2">Comment</label>
              </td>
              <td class="p-1">
                <div class="form-group flex justify-start items-center">
                  <textarea type="text" class="form-control" rows="4" [style.border]="'1px solid #ced4da'"
                    [style.borderRadius]="'0.25rem'" placeholder="Enter Comment" [(ngModel)]="comment"
                    [ngModelOptions]="{ standalone: true }"></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="p-1 text-center">
                <button (click)="submitIncomeExpenses()" class="site-button mr-2" >
                  {{loading ? 'Submitting...' : 'Submit' }}
                </button>
                <button type="button" class="site-button" data-dismiss="modal">
                  Cancel
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</app-admin-default-layout>