<app-admin-default-layout>
  <div class="col-xl-10 col-lg-10 m-b30">
    <div class="job-bx submit-resume">
      <div class="job-bx-title clearfix">
        <h5 class="font-weight-700 pull-left text-uppercase">
          Post A Job
        </h5>
        <a href="/" class="site-button right-arrow button-sm float-right">Back</a>
      </div>
      <form [formGroup]="postJobForm">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <div class="form-group">
              <label>Job ID<span class="spanStar">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Job ID" formControlName="job_id"
                (focusout)="verifyJobId()" />

              <div *ngIf=" postJobForm.controls['job_id'].invalid && (postJobForm.controls['job_id'].dirty || postJobForm.controls['job_id'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf=" postJobForm.controls['job_id'].errors?.['required'] ">
                  Job ID is required.
                </div>
                <div *ngIf=" postJobForm.controls['job_id'].errors?.['maxlength'] ">
                  Job ID must be at-most 20 characters long.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="form-group" style="display: grid">
              <label>State<span class="spanStar">*</span></label>
              <select class="form-control" formControlName="state">
                <option>Select state</option>
                <option *ngFor="let state of states" [value]="state.isoCode">
                  {{ state.name }} ({{ state.isoCode }})
                </option>
              </select>
              <div *ngIf=" postJobForm.controls['state'].invalid && (postJobForm.controls['state'].dirty || postJobForm.controls['state'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf=" postJobForm.controls['state'].errors?.['required'] ">
                  State is required.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="form-group" style="display: grid">
              <label>City<span class="spanStar">*</span></label>
              <select class="form-control" formControlName="city">
                <option>Select city</option>
                <option *ngFor="let city of filteredCity" [value]="city.name" style="text-transform: capitalize">
                  {{ city.name }}
                </option>
              </select>

              <div *ngIf=" postJobForm.controls['city'].invalid && (postJobForm.controls['city'].dirty || postJobForm.controls['city'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf=" postJobForm.controls['city'].errors?.['required'] ">
                  City is required.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>Job Title<span class="spanStar">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Job Title" formControlName="job_title" />

              <div *ngIf=" postJobForm.controls['job_title'].invalid && (postJobForm.controls['job_title'].dirty || postJobForm.controls['job_title'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf=" postJobForm.controls['job_title'].errors ?.['required'] ">
                  Job title is required.
                </div>
                <div *ngIf=" postJobForm.controls['job_title'].errors ?.['maxlength'] ">
                  Job title must be at-most 100 characters long.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="form-group">
              <label>Minimum
                <span class="spanStar">*</span>
              </label>
              <div class="input-group custom-input-group">
                <div class="input-group-prepend h-100">
                  <span class="input-group-text dollar-sign bg-white">$</span>
                </div>
                <input type="number" class="custom-edit-control" placeholder="Enter minimum value"
                  formControlName="minimum_rate" step="0.01" min="0" id="minimumRate" />
              </div>

              <div
                *ngIf="postJobForm.controls['minimum_rate'].invalid && (postJobForm.controls['minimum_rate'].dirty || postJobForm.controls['minimum_rate'].touched)"
                class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf="postJobForm.controls['minimum_rate'].errors?.['required']">
                  Minimum rate is required.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-1 col-md-1">
            <div class="form-group">
              <label></label>
              <br />
              <label class="to-label">to</label>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="form-group">
              <label>Maximum
                <span class="spanStar">*</span>
              </label>
              <div class="input-group custom-input-group">
                <div class="input-group-prepend h-100">
                  <span class="input-group-text dollar-sign bg-white">$</span>
                </div>
                <input type="number" class="custom-edit-control" placeholder="Enter maximum value"
                  formControlName="maximum_rate" step="0.01" min="0" id="maximumRate" />
              </div>

              <div
                *ngIf="postJobForm.controls['maximum_rate'].invalid && (postJobForm.controls['maximum_rate'].dirty || postJobForm.controls['maximum_rate'].touched)"
                class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf="postJobForm.controls['maximum_rate'].errors?.['required']">
                  Maximum rate is required.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="form-group" style="display: grid">
              <label>Rate
                <span class="spanStar">*</span>
              </label>
              <select class="form-control" formControlName="rate">
                <option></option>
                <option>per hour</option>
                <option>per week</option>
                <option>per month</option>
                <option>per year</option>
              </select>
              <div
                *ngIf="postJobForm.controls['rate'].invalid && (postJobForm.controls['rate'].dirty || postJobForm.controls['rate'].touched)"
                class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf="postJobForm.controls['rate'].errors?.['required']">
                  Rate is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6">
            <div class="form-group" style="display: grid">
              <label>Position Type<span class="spanStar">*</span></label>
              <select class="form-control" formControlName="position" *ngIf="positiontype.length > 0">
                <option selected></option>
                <option *ngFor="let data of positiontype | sortBy:'position_type_name'" [value]="data.position_type_id"
                  style="text-transform: capitalize">
                  {{ data.position_type_name }}
                </option>
              </select>
              <div *ngIf=" postJobForm.controls['position'].invalid && (postJobForm.controls['position'].dirty || postJobForm.controls['position'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf=" postJobForm.controls['position'].errors?.['required'] ">
                  Position type is required.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="form-group">
              <div class="form-group" style="display: grid">
                <label>Job Type</label>
                <select class="form-control" formControlName="job_type" *ngIf="jobType.length > 0">
                  <option selected></option>
                  <option *ngFor="let data of jobType | sortBy:'job_type_name'" [value]="data.job_type_id"
                    style="text-transform: capitalize">
                    {{ data.job_type_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="form-group">
              <div class="form-group" style="display: grid">
                <label>Job Sector<span class="spanStar">*</span></label>
                <select class="form-control" formControlName="job_sector" *ngIf="jobSector.length > 0">
                  <option selected></option>
                  <option *ngFor="let data of jobSector | sortBy:'job_sector_name'" [value]="data.job_sector_id"
                    style="text-transform: capitalize">
                    {{ data.job_sector_name }}
                  </option>
                </select>
                <div *ngIf=" postJobForm.controls['job_sector'].invalid && (postJobForm.controls['job_sector'].dirty || postJobForm.controls['job_sector'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                  <div *ngIf=" postJobForm.controls['job_sector'].errors ?.['required'] ">
                    Job sector is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="form-group">
              <div class="form-group" style="display: grid">
                <label>Client Name<span class="spanStar">*</span></label>
                <select class="form-control" formControlName="client_name">
                  <option selected></option>
                  <option *ngFor="let data of clientList | sortBy:'client_name'" [value]="data.client_id"
                    style="text-transform: capitalize">
                    {{ data.client_name }}
                  </option>
                </select>
                <div *ngIf=" postJobForm.controls['client_name'].invalid && (postJobForm.controls['client_name'].dirty || postJobForm.controls['client_name'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                  <div *ngIf=" postJobForm.controls['client_name'].errors ?.['required'] ">
                    Client name rate is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="form-group">
              <label>System Name<span class="spanStar">*</span></label>
              <select class="form-control" formControlName="system_name" *ngIf="systemList.length > 0">
                <option selected></option>
                <option *ngFor="let data of systemList | sortBy:'system_name'" [value]="data.system_name_id"
                  style="text-transform: capitalize">
                  {{ data.system_name }}
                </option>
              </select>

              <div *ngIf=" postJobForm.controls['system_name'].invalid && (postJobForm.controls['system_name'].dirty || postJobForm.controls['system_name'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf=" postJobForm.controls['system_name'].errors ?.['required'] ">
                  System name rate is required.
                </div>
                <div *ngIf=" postJobForm.controls['system_name'].errors ?.['maxlength'] ">
                  System name rate must be at-most 200 characters
                  long.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="form-group">
              <label>Duration<span class="spanStar">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Job Duration" formControlName="job_duration" />

              <div *ngIf=" postJobForm.controls['job_duration'].invalid && (postJobForm.controls['job_duration'].dirty || postJobForm.controls['job_duration'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf=" postJobForm.controls['job_duration'].errors ?.['required'] ">
                  Job duration is required.
                </div>
                <div *ngIf=" postJobForm.controls['job_duration'].errors ?.['maxlength'] ">
                  Job duration must be at-most 50 characters long.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6">
            <div class="form-group">
              <label>Shift<span class="spanStar">*</span></label>
              <div style="display: flex; padding: 10px">
                <div class="custom-control custom-checkbox checkbox-margin">
                  <input type="checkbox" class="custom-control-input" id="Day" name="Day" checked
                    (click)="checkBoxSelect('Day')" />
                  <label class="custom-control-label font-normal" for="Day">Day</label>
                </div>
                <div class="custom-control custom-checkbox checkbox-margin" style="margin-left: 10px">
                  <input type="checkbox" class="custom-control-input" id="Night" name="Night"
                    (click)="checkBoxSelect('Night')" />
                  <label class="custom-control-label font-normal" for="Night">Night</label>
                </div>
                <div class="custom-control custom-checkbox checkbox-margin" style="margin-left: 10px">
                  <input type="checkbox" class="custom-control-input" id="Evening" name="Evening"
                    (click)="checkBoxSelect('Evening')" />
                  <label class="custom-control-label font-normal" for="Evening">Evening</label>
                </div>
              </div>
              <div *ngIf="shit_details.length === 0" class="alert alert-danger"
                style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div>Shift is required.</div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>Required Information For Submission</label>
              <textarea type="text" class="form-control" rows="4" cols="50" placeholder="Enter Information"
                formControlName="req_inf"></textarea>

              <div *ngIf=" postJobForm.controls['req_inf'].invalid && (postJobForm.controls['req_inf'].dirty || postJobForm.controls['req_inf'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf=" postJobForm.controls['req_inf'].errors ?.['maxlength'] ">
                  Required Information must be at-most 1000
                  characters long.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>Job Description<span class="spanStar">*</span></label>
              <textarea type="text" class="form-control" rows="4" cols="50" placeholder="Enter Job Description"
                formControlName="job_desc"></textarea>

              <div *ngIf=" postJobForm.controls['job_desc'].invalid && (postJobForm.controls['job_desc'].dirty || postJobForm.controls['job_desc'].touched) " class="alert alert-danger" style="font-size: 12px; padding: 0.5rem 1.25rem">
                <div *ngIf=" postJobForm.controls['job_desc'].errors ?.['required'] ">
                  Job description is required.
                </div>
                <div *ngIf=" postJobForm.controls['job_desc'].errors ?.['maxlength'] ">
                  Job description must be at-most 1000 characters
                  long.
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" type="submit" (click)="submit()"
          class="relative inline-flex items-center rounded-sm border border-transparent bg-sky-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800 uppercase transition-colors duration-300">
          <span>Post Job</span>
        </button>
      </form>
    </div>
  </div>
</app-admin-default-layout>